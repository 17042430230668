export function getCustomerTabData(store) {

    if (store.state.tabCustomerData.selectedNationality === "سعودي") {
        store.state.bodySearch.set("CustomerNationality", 1);
    }else if (store.state.tabCustomerData.selectedNationality === "غير سعودي") {
        store.state.bodySearch.set("CustomerNationality", 5);
    }else{
        store.state.bodySearch.delete("CustomerNationality");
    }

    if (store.state.tabCustomerData.selectedYouHave === "شركة") {
        store.state.bodySearch.set("CustomerYouHave", "company");
    }else if (store.state.tabCustomerData.selectedYouHave === "مؤسسة") {
        store.state.bodySearch.set("CustomerYouHave", "institution");
    }else if (store.state.tabCustomerData.selectedYouHave === "فرد") {
        store.state.bodySearch.set("CustomerYouHave", "individual");
    }else{
        store.state.bodySearch.delete("CustomerYouHave");
    }

    if (store.state.tabCustomerData.selectedSourceOfIncome === "نقاط بيع") {
        store.state.bodySearch.set("CustomerSourceOfIncome", "selling_points");
    }else if (store.state.tabCustomerData.selectedSourceOfIncome === "قوائم مالية") {
        store.state.bodySearch.set("CustomerSourceOfIncome", "financial_menu");
    }else if (store.state.tabCustomerData.selectedSourceOfIncome === "لا يوجد") {
        store.state.bodySearch.set("CustomerSourceOfIncome", "nothing");
    }else{
        store.state.bodySearch.delete("CustomerSourceOfIncome");
    }

    if (store.state.tabCustomerData.selectedHaveSalary === "نعم") {
        store.state.bodySearch.set("CustomerHaveSalary", "yes");
    }else if (store.state.tabCustomerData.selectedHaveSalary === "لا") {
        store.state.bodySearch.set("CustomerHaveSalary", "no");
    }else{
        store.state.bodySearch.delete("CustomerHaveSalary");
    }

    if (store.state.tabCustomerData.phoneNumber) {
        store.state.bodySearch.set("customer_phone", store.state.tabCustomerData.phoneNumber);
    }else{
        store.state.bodySearch.delete("customer_phone");
    }

    if (store.state.tabCustomerData.salaryFrom) {
        store.state.bodySearch.set("customer_salary_from", store.state.tabCustomerData.salaryFrom);
    }else{
        store.state.bodySearch.delete("customer_salary_from");
    }

    if (store.state.tabCustomerData.salaryTo) {
        store.state.bodySearch.set("customer_salary_to", store.state.tabCustomerData.salaryTo);
    }else{
        store.state.bodySearch.delete("customer_salary_to");
    }

    if (store.state.tabCustomerData.birthDate) {
        store.state.bodySearch.set("customer_birth_date", store.state.tabCustomerData.birthDate);
    } else {
        store.state.bodySearch.delete("customer_birth_date");
    }

    if (store.state.tabCustomerData.selectedRegionList.length > 0) {
        store.state.tabCustomerData.selectedRegionList.forEach((item, index) => {
            store.state.bodySearch.set(`customer_region_ip[${index}]`, item["text"]);
        });
    }else{
        store.state.bodySearch.delete("customer_region_ip");
    }

    if (store.state.tabCustomerData.isHasProperty === "نعم") {
        store.state.bodySearch.set("real_estate_has_property", 1);
    }else if (store.state.tabCustomerData.isHasProperty === "لا") {
        store.state.bodySearch.set("real_estate_has_property", 0);
    }else{
        store.state.bodySearch.delete("real_estate_has_property");
    }

    if (store.state.tabCustomerData.isAplicationInstal === "نعم") {
        store.state.bodySearch.set("customer_app_download", 1);
    }else if (store.state.tabCustomerData.isAplicationInstal === "لا") {
        store.state.bodySearch.set("customer_app_download", 0);
    }else{
        store.state.bodySearch.delete("customer_app_download");
    }









    // const formDataKeys = Array.from(store.state.bodySearch.keys());

    // // Mapping values to keys
    // const mappings = {
    //     CustomerNationality: {
    //         سعودي: 1,
    //         "غير سعودي": 5,
    //     },
    //     CustomerYouHave: {
    //         شركة: "company",
    //         مؤسسة: "institution",
    //         فرد: "individual",
    //     },
    //     CustomerSourceOfIncome: {
    //         "نقاط بيع": "selling_points",
    //         "قوائم مالية": "financial_menu",
    //         "لا يوجد": "nothing",
    //     },
    //     CustomerHaveSalary: {
    //         نعم: "yes",
    //         لا: "no",
    //     },
    //     real_estate_has_property: {
    //         نعم: 1,
    //         لا: 0,
    //     },
    //     customer_app_download: {
    //         نعم: 1,
    //         لا: 0,
    //     },
    // };

    // // Helper function for appending mapped values
    // const appendMappedValue = (field, key) => {
    //     const value = store.state.tabCustomerData[field];
    //     if (value && mappings[key] && mappings[key][value] !== undefined && !formDataKeys.includes(key)) {
    //         store.state.bodySearch.append(key, mappings[key][value]);
    //     }
    // };

    // // Append mapped values
    // appendMappedValue("selectedNationality", "CustomerNationality");
    // appendMappedValue("selectedYouHave", "CustomerYouHave");
    // appendMappedValue("selectedSourceOfIncome", "CustomerSourceOfIncome");
    // appendMappedValue("selectedHaveSalary", "CustomerHaveSalary");
    // appendMappedValue("isHasProperty", "real_estate_has_property");
    // appendMappedValue("isAplicationInstal", "customer_app_download");

    // // Append direct values
    // const directFields = [
    //     { key: "customer_phone", value: store.state.tabCustomerData.phoneNumber },
    //     { key: "customer_salary_from", value: store.state.tabCustomerData.salaryFrom },
    //     { key: "customer_salary_to", value: store.state.tabCustomerData.salaryTo },
    //     { key: "customer_birth_date", value: store.state.tabCustomerData.birthDate },
    // ];

    // directFields.forEach(({ key, value }) => {
    //     if (value) {
    //         if (!formDataKeys.includes(key)) store.state.bodySearch.append(key, value);
    //     } else if (key === "customer_birth_date") {
    //         // Remove key if birth date is not provided
    //         store.state.bodySearch.delete(key);
    //     }
    // });

    // // Append regions
    // if (store.state.tabCustomerData.selectedRegionList.length > 0) {
    //     store.state.tabCustomerData.selectedRegionList.forEach((item, index) => {
    //         const key = `customer_region_ip[${index}]`;
    //         if (!formDataKeys.includes(key)) {
    //             store.state.bodySearch.append(key, item["text"]);
    //         }
    //     });
    // }








    

    /*if (store.state.tabCustomerData.selectedNationality === "سعودي") {
        store.state.bodySearch.append("CustomerNationality", 1);
    }

    if (store.state.tabCustomerData.selectedNationality === "غير سعودي") {
        store.state.bodySearch.append("CustomerNationality", 5);
    }

    if (store.state.tabCustomerData.selectedYouHave === "شركة") {
        store.state.bodySearch.append("CustomerYouHave", "company");
    }

    if (store.state.tabCustomerData.selectedYouHave === "مؤسسة") {
        store.state.bodySearch.append("CustomerYouHave", "institution");
    }

    if (store.state.tabCustomerData.selectedYouHave === "فرد") {
        store.state.bodySearch.append("CustomerYouHave", "individual");
    }

    if (store.state.tabCustomerData.selectedSourceOfIncome === "نقاط بيع") {
        store.state.bodySearch.append("CustomerSourceOfIncome", "selling_points");
    }

    if (store.state.tabCustomerData.selectedSourceOfIncome === "قوائم مالية") {
        store.state.bodySearch.append("CustomerSourceOfIncome", "financial_menu");
    }

    if (store.state.tabCustomerData.selectedSourceOfIncome === "لا يوجد") {
        store.state.bodySearch.append("CustomerSourceOfIncome", "nothing");
    }

    if (store.state.tabCustomerData.selectedHaveSalary === "نعم") {
        store.state.bodySearch.append("CustomerHaveSalary", "yes");
    }

    if (store.state.tabCustomerData.selectedHaveSalary === "لا") {
        store.state.bodySearch.append("CustomerHaveSalary", "no");
    }

    if (store.state.tabCustomerData.phoneNumber) {
        store.state.bodySearch.append("customer_phone", store.state.tabCustomerData.phoneNumber);
    }

    if (store.state.tabCustomerData.salaryFrom) {
        store.state.bodySearch.append("customer_salary_from", store.state.tabCustomerData.salaryFrom);
    }

    if (store.state.tabCustomerData.salaryTo) {
        store.state.bodySearch.append("customer_salary_to", store.state.tabCustomerData.salaryTo);
    }

    if (store.state.tabCustomerData.birthDate) {
        store.state.bodySearch.append("customer_birth_date", store.state.tabCustomerData.birthDate);
    } else {
        store.state.bodySearch.delete("customer_birth_date");
    }

    if (store.state.tabCustomerData.selectedRegionList.length > 0) {
        store.state.tabCustomerData.selectedRegionList.forEach((item, index) => {
            store.state.bodySearch.append(`customer_region_ip[${index}]`, item["text"]);
        });
    }

    if (store.state.tabCustomerData.isHasProperty === "نعم") {
        store.state.bodySearch.append("real_estate_has_property", 1);
    }

    if (store.state.tabCustomerData.isHasProperty === "لا") {
        store.state.bodySearch.append("real_estate_has_property", 0);
    }

    if (store.state.tabCustomerData.isAplicationInstal === "نعم") {
        store.state.bodySearch.append("customer_app_download", 1);
    }

    if (store.state.tabCustomerData.isAplicationInstal === "لا") {
        store.state.bodySearch.append("customer_app_download", 0);
    }*/

  }