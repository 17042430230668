<template>
  <div class="tech-support">
    <template>
      <v-row justify="center">
        <v-list dense width="100%">
          <v-list-item
            style="cursor: pointer; margin-right: 10px"
            @click.stop="dialog = true"
          class="pr-9"
          >
            <!-- <v-list-item-icon class="ml-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17.599"
                height="17.599"
                viewBox="0 0 17.599 17.599"
              >
                <path
                  id="support"
                  d="M20.746,12.41v-.463a6.947,6.947,0,1,0-13.894,0v.463A1.852,1.852,0,0,0,5,14.262v2.779a1.852,1.852,0,0,0,1.852,1.852h.083a1.389,1.389,0,0,0,2.7-.463V12.873a1.389,1.389,0,0,0-1.852-1.311,6.021,6.021,0,0,1,12,0,1.389,1.389,0,0,0-1.815,1.311v5.557a1.389,1.389,0,0,0,1.389,1.389h.153a3.242,3.242,0,0,1-2.932,1.852H15.189v-.463A1.389,1.389,0,1,0,13.8,22.6h2.779a4.168,4.168,0,0,0,4.14-3.7h.028A1.852,1.852,0,0,0,22.6,17.041V14.262A1.852,1.852,0,0,0,20.746,12.41ZM5.926,17.041V14.262a.926.926,0,0,1,.926-.926v4.631A.926.926,0,0,1,5.926,17.041ZM8.242,12.41a.463.463,0,0,1,.463.463v5.557a.463.463,0,1,1-.926,0V12.873A.463.463,0,0,1,8.242,12.41ZM13.8,21.672a.463.463,0,1,1,.463-.463v.463Zm5.557-2.779a.463.463,0,0,1-.463-.463V12.873a.463.463,0,0,1,.926,0v5.557A.463.463,0,0,1,19.357,18.894Zm2.316-1.852a.926.926,0,0,1-.926.926V13.336a.926.926,0,0,1,.926.926Z"
                  transform="translate(-5 -5)"
                  :fill="$vuetify.theme.themes.light.iconColor1"
                />
              </svg>
            </v-list-item-icon> -->
            <v-list-item-icon class="mx-0 px-0 align-center"
            ><v-icon style="color: white;">mdi-menu-left</v-icon></v-list-item-icon
          >
            <v-list-item-content>
              <v-list-item-title class="item-text-color">الدعم الفنى</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-dialog v-model="dialog" :max-width="dialogWidth">
          <v-card class="pb-4">
            <div style="padding: 15px;" class="supportContainer">
            <v-card-title
              class="text-h5 d-flex justify-space-between"
              style="    border: 1px solid #ECECEC;
    border-radius: 4px;
    margin-bottom: 15px;"
            >
              <span style="color: #0000009c">الدعم الفني</span>
              <span
                ><v-icon style="cursor: pointer" @click="dialog = !dialog"
                  >mdi-close</v-icon
                ></span
              >
            </v-card-title>

            <template>
              <v-container fluid>
                <v-row align="center">
                  <v-col cols="12">
                    <label
                      style="font-size: 18px; color: #0000009c"
                      class="mr-4"
                    >
                      نوع الرسالة
                    </label>
                    <v-autocomplete
                      :items="items"
                      label="اختر نوع الرسالة"
                      dense
                      outlined
                      class="mt-2"
                      v-model="selectedIssue"
                      item-text="key"
                      item-value="value"
                    ></v-autocomplete>

                    <span
                      class="err"
                      style="
                        color: indianred;
                        display: block;
                        margin-top: -20px;
                        font-size: 17px;
                      "
                      >{{ selectErr }}</span
                    >
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <label
                      style="font-size: 18px; color: #0000009c"
                      class="mr-4"
                    >
                      نص الرسالة</label
                    >
                    <v-textarea
                      class="mt-2"
                      outlined
                      autocomplete="email"
                      v-model="message"
                    ></v-textarea>
                    <span
                      class="err"
                      style="
                        color: indianred;
                        display: block;
                        margin-top: -20px;
                        font-size: 17px;
                        margin-bottom: 10px;
                      "
                      >{{ messageErr }}</span
                    >
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <label
                      style="font-size: 18px; color: #0000009c"
                  
                    >
                      ارفاق ملف
                    </label>
                    <v-file-input
                      chips
                      class="mt-2"
                      multiple
                      label="قم باختيار الملفات"
                      outlined
                      v-model="files"
                      accept="image/*,video/*"
                    ></v-file-input>
                  </v-col>
                </v-row>
              </v-container>
            </template>
            <v-card-actions class="pl-sm-and-down-2 excluded">
              <v-spacer></v-spacer>

              <v-btn
                color="#5A6268"
                style="font-size: 16px"
                dark
                @click="dialog = false"
              >
                الغاء
              </v-btn>

              <v-btn
                style="font-size: 16px;background-color:rgb(63, 14, 64) !important; color: #ECECEC;"
                @click="sendMessage"
                color="primary"
                dark
                :disabled="sending"
              >
                {{ sending ? "قيد الارسال ..." : "ارسال" }}
              </v-btn>
            </v-card-actions>
          </div>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
  </div>
</template>
<script>
import Axios from "axios";

export default {
  data() {
    return {
      dialog: false,
      selectedIssue: "",
      message: "",
      items: [],
      files: [],
      selectErr: "",
      messageErr: "",
      sending: false,
    };
  },
  computed: {
    dialogWidth() {
      if (window.matchMedia("(min-width: 767px)").matches) {
        return "50%";
      } else {
        return "100%";
      }
    },
  },
  methods: {
    async getSelections() {
      await Axios.get(
        `${this.$store.state.url}/employee/collaborator/list-all-message-type`,
        { headers: this.request_headers() }
      ).then((res) => {
        this.items = res.data.payload;
      });
    },
    sendMessage() {
      this.selectErr = "";
      this.messageErr = "";
      if (!this.selectedIssue) {
        this.selectErr = "قم باختيار نوع الرسالة";
      } else if (!this.message) {
        this.messageErr = "قم بكتابة نص الرسالة";
      } else {
        this.sending = true;
        const formdata = new FormData();
        formdata.append(`files[]`, this.files[0]);
        formdata.append("descrebtion", this.message);
        formdata.append("msg_type", this.selectedIssue);
        Axios.post(
          `${this.$store.state.url}/employee/collaborator/add-new-technical-support`,
          formdata,
          { headers: this.request_headers() }
        )
          .then((res) => {
            this.sending = false;
            this.dialog = false;
            //Reset Data
            this.selectedIssue = "";
            this.message = "";
            this.files = [];
            this.showPopup("success", res.data.message);
          })
          .catch((err) => {
            this.showPopup("error", err.response.data.errors["files.0"][0]);
            this.sending = false;
          });
      }
    },
  },
  async created() {
    await this.getSelections();
  },
};
</script>

<!-- <style lang="scss"> -->
<style>
.v-autocomplete__slot .v-label,
.v-input__control .v-label {
  font-size: 17px;
}
.v-dialog {
  overflow-x: hidden !important;
}

.supportContainer .v-input__icon{
  visibility: visible;
}

.supportContainer .v-application--is-rtl .v-input__prepend-outer{
  margin: 0px !important;
}

.supportContainer .v-application .mr-4{
  margin: 0px !important;

}
</style>
