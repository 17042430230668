<template>
  <v-row justify="center">
    <v-dialog
        v-model="$store.state.waitingNewFilter"
        persistent
        width="824"
    >
      <v-card id="adminFilter">
        <v-card-title>
          <span class="text-h5">البحث</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>

              <v-col cols="12" sm="12" md="12">
                <SelectInput
                    @selectedValues="getSelectedValueAgent"
                    :originalItems="getAgentsName"
                    label="استشاري المبيعات"
                />
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <SelectInput
                    @selectedValues="getSelectedAgentType"
                    :originalItems="agentsType"
                    label="تصنيف استشاري المبيعات"
                />
              </v-col>

              <v-col cols="12" lg="6" md="6" class="pb-5 pt-0" style="margin-top: 12px;">
                <v-autocomplete
                    hide-details
                    item-text="text"
                    item-value="code"
                    class="mr-1"
                    outlined
                    dense
                    append-icon
                    multiple
                    color="primary"
                    v-model="processSelect"
                    :items="['طلب مضاف من قبل الجوده' ,'طلب مضاف من قبل مدير النظام', 'رساله جديده من العميل', 'مهمه جديده', 'طلب بدون تحديث', 'عميل مكرر لاستشاري مؤرشف']"

                    label="العمليات "
                >
                </v-autocomplete>
              </v-col>

            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue-darken-1"
              variant="text"
              @click="$store.state.waitingNewFilter = false"
          >
            الغاء
          </v-btn>
          <v-btn
              color="blue-darken-1"
              variant="text"
              @click='searchRequest'
          >
            بحث
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>


<script>
import Axios from "axios";
import {removeDuplicated} from "@/mixins/removeDublicated";
import SelectInput from "@/components/Charts/SelectInput.vue";
import {SelectItems} from "@/mixins/selectItems";
import {formatDate} from "@/mixins/formatDate";
import {SourceValue} from "@/mixins/sourceValue";

export default {
  components: {SelectInput},
  data: () => ({
    dialog: false,
    product_code: [],
    selectedProductType: [],
    allMangers:[],
    productType: [],
    agentsType: [],
    processType: [],
    allAgents:[],
    request_types: [],
    allSelectedAgent: [],
    mangerChecked: false,
    agentChecked: false,
    selectedManger: [],
    selectedAgent: [],
    processSelect: [],
    AllSelectedTable: [],
    allProcessType: [],
    selectedMang: [],
    active: false,
    message: "",
    color: '',
    body: {},
  }),
  computed:{

    getAgentsName(){
      return this.allAgents;
    }
  },


  mounted() {
    this.getAllAgents('agent' , 0);
    this.getAgentClarification();
  },


  methods:{

    searchRequest(){
      this.$store.state.isSearch = true;
      this.$store.state.loadingData = true
      this.body = new FormData();
      this.$store.state.waitingNewFilter = false
      this.$store.state.tableData = [];
      this.$store.state.InfinityPageNumber = 1;
      this.$store.state.back = true
      this.$store.state.startElement = [];

      this.$store.state.waitingNewSearch = [];
      this.$store.state.waitingNewData = [];

      this.$store.state.waitingNewLoading = true;

      this.$store.state.bodySearch = {}
      this.counter++

      this.body.append('page', 1);


      if (this.selectedAgent.length) {
        this.selectedAgent.forEach((item, index) => {
          this.body.append(`waiting_list_new_agents[${index}]`, item["id"]);
        });
      }

      if (this.allSelectedAgent.length > 0) {
        this.allSelectedAgent.forEach((item, index) => {
          this.body.append(`waiting_list_agent_classification[${index}]`, item["id"]);
        });
      }

      if (this.processSelect.length) {
        this.processSelect.forEach((item, index) => {
          this.body.append(`waiting_list_action[${index}]`, item);
        });
      }


      let url = `${this.$store.state.url}/v2/employee/Requests/search?type=waiting_list_new`
      Axios.post(url, this.body, {headers: this.request_headers()}).then(res => {
        this.$store.state.pagination = {
          limit: 3,
          count: res.data.payload.searched
        }
        let response = res.data.payload.data

        this.$store.state.waitingNewData.push(...response);
        this.$store.state.waitingNewSearch.push(...response);

        this.$store.state.loadingData = false
        res.data.payload.data.map((item) => {
          this.setTbData(item);
          item["starChecked"] = false;
          return item;
        });

        this.$store.state.waitingNewLoading = false;
        this.$store.state.pagination.count = res.data.payload.searched
      }).catch(err => {
        this.$store.state.waitingNewLoading = false;
      })
      this.$store.state.bodySearch = this.body;

    },

    setTbData(items) {
      this.$store.state.tableData.push({
        "id": items.req_id,
        "تاريخ انشاء طلب التحويل": items.request.agent_date ? formatDate(items.request.agent_date) : '',
        "تاريخ دخول الطلب الي النظام": items.request.created_at ? formatDate(items.request.created_at) : '',
        "حاله الطلب": items.request.status,
        "استشاري المبيعات": items.request.user?.name,
        "عميل":  items.request.customer.name,
        "الجوال" : items.request.customer.mobile,
        "مصدر المعامله": SourceValue(items.request.source),
        "تصنيف الاستشاري": items.request.class_id_agent_text,
        "comment": items.request.comment,
        "العمليه": items.action
      });
    },


    getSelectedAgentType(val) {
      this.allSelectedAgent = val
    },

    getAgentClarification() {
      let url = `${this.$store.state.url}/agent/Request/Classification?type_of_classification=0`
      Axios.get(url, {headers: this.request_headers()}).then(res => {
        let response = res.data.payload
        this.agentsType = SelectItems(response)
      })
    },

    getSelectedProductType(val) {
      this.selectedProductType = val
    },


    getProductType() {
      let url = `${this.$store.state.url}/customer/SelectedProduct`
      Axios.get(url, {headers: this.request_headers()}).then(res => {
        this.productType = res.data.payload.map((item) => {
          item["color"] = "red";
          item["text"] = item["value"];
          return item;
        });
      })
    },

    getProcessType(val){
      this.allProcessType = val
    },


    handleChangeUser(){
      this.$store.state.turnuserDialog = false;
      let body = new FormData()
      let url = `${this.$store.state.url}/v2/employee/Requests/moveAllRequests`

      this.selectedItems.length && this.selectedItems.forEach((item, index) => {
        body.append(`request_ids[${index}]`, item["id"]);
      });

      this.$store.state.startElement.length && this.$store.state.startElement.forEach((item, index) => {
        body.append(`request_ids[${index}]`, item["id"]);
      });

      this.selectedAgent.length && this.selectedAgent.forEach((item, index) => {
        body.append(`agents_ids[${index}]`, item["id"]);
      });

      Axios.post(url , body , {headers: this.request_headers()}).then(res => {
        this.active = true;
        this.message = res.data.message;
        this.color = 'green'
      }).catch(err => {
        this.active = true;
        let error = { err };
        this.snackMessage = error.err.response.data.message
        this.color = 'red'
      })
    },

    getSelectedValueAgent(val){
      this.selectedAgent = val
    },

    getSelectedValueManger(val){
      this.selectedMang = val
      if (this.agentChecked === false) {
        this.getAllAgents('agent' , 0 ,val);
      } else if (this.agentChecked === true) {
        this.getAllAgents('agent' , 1 , val)
      }
    },

    handleChecked(){
      this.mangerChecked = !this.mangerChecked;
    },

    handleCheckedAgent(){
      this.agentChecked = !this.agentChecked
    },

    getAllManger(role ,status) {
      this.allMangers = [];
      let body = {'role': role}
      if (status === 0) {
        body = {
          status: status,
          role: role
        }
      }

      let url = `${this.$store.state.url}/employee/admin/filterUsers`;
      Axios.post(url, body, {headers: this.request_headers()}).then(res => {
        this.allMangers = (res.data.payload);
        this.allMangers.map((item) => {
          item["color"] = "red";
          item["text"] = item["name"];
          return item;
        });
      })
    },

    getAllAgents(role, status , val) {
      this.allAgents = [];
      let body = new FormData();
      body.append('role',role);
      if (status === 0 && !val) {
        body.append('status', status);
        body.append('role',role);
      }else if (status === 1 && !val){
        body.append('status', status);
        body.append('role',role);
      }
      if (val) {
        val.forEach((item, index) => {
          this.selectedManger.push(`'manager_id[${index}]': '${item["id"]}'`);
        });
      }

      let removeDublicate = removeDuplicated(this.selectedManger)


      if(removeDublicate.length){
        val.forEach((item, index) => {
          body.append(`manager_id[${index}]`, item["id"]);
        });
        body.append('status', status);
        body.append('role',role);
      }

      let url = `${this.$store.state.url}/employee/admin/filterUsers`;
      Axios.post(url, body, {headers: this.request_headers()}).then(res => {
        this.allAgents = (res.data.payload);
        this.allAgents.map((item) => {
          item["color"] = "red";
          item["text"] = item["name"];
          return item;
        });
      })
    },
  }
}
</script>