<template>
  <div class="calender">
    <h3>جدول التذكيرات :</h3>
    <template>
      <v-toolbar
        dark
        color="white"
        class="py-6 mb-5"
        style="height: fit-content; box-shadow: 0px 0px 8px -5px black"
        :loading="loading"
      >
        <v-toolbar-title style="color: black !important"
          >جوال العميل</v-toolbar-title
        >
        <v-text-field
          v-model="phone_number"
          class="mr-15"
          hide-details
          solo-inverted
          style="font-size: 17px"
        ></v-text-field>
        <v-btn
          :color="$vuetify.theme.themes.light.CalanderColor"
          :loading="btn_loading"
          @click="search_by_phone"
          class="pb-2 px-9 mr-15 font-weight-bold"
          >بحث</v-btn
        >
      </v-toolbar>
    </template>
    <v-progress-linear
      indeterminate
      color="primary"
      :loading="loading"
      :active="loading"
    ></v-progress-linear>
    <div class="calender-view"></div>
    <v-btn
      fab
      :color="$vuetify.theme.themes.light.dialogActionBTN"
      @click="addNewRequestDialog = true"
      class="my-10"
      style="position: fixed; left: 20px; bottom: 30px; z-index: 1"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25.425"
        height="21.166"
        viewBox="0 0 25.425 21.166"
      >
        <g
          id="Icon_feather-user-plus"
          data-name="Icon feather-user-plus"
          transform="translate(1 1)"
        >
          <path
            id="Path_2554"
            data-name="Path 2554"
            d="M17.471,28.889v-2.13A4.259,4.259,0,0,0,13.212,22.5H5.759A4.259,4.259,0,0,0,1.5,26.759v2.13"
            transform="translate(-1.5 -9.723)"
            fill="none"
            stroke="#fff"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
          <path
            id="Path_2555"
            data-name="Path 2555"
            d="M15.268,8.759A4.259,4.259,0,1,1,11.009,4.5,4.259,4.259,0,0,1,15.268,8.759Z"
            transform="translate(-3.023 -4.5)"
            fill="none"
            stroke="#fff"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
          <path
            id="Path_2556"
            data-name="Path 2556"
            d="M30,12v6.389"
            transform="translate(-9.77 -6.676)"
            fill="none"
            stroke="#fff"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
          <path
            id="Path_2557"
            data-name="Path 2557"
            d="M31.889,16.5H25.5"
            transform="translate(-8.464 -7.982)"
            fill="none"
            stroke="#fff"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
        </g>
      </svg>
    </v-btn>
    <add-new-request v-model="addNewRequestDialog" width="350px" />
  
    <agent-chat />
    <!--Hover Dialog-->
    <v-dialog v-model="dialog" max-width="290">
      <v-progress-circular
        :indeterminate="loading"
        color="primary"
        :style="
          loading
            ? `position: absolute;
          z-index: 1;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);`
            : `
          position: absolute;
          z-index: -1;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          `
        "
      ></v-progress-circular>
      <v-card :style="loading ? 'height:100px' : 'height:fit-content'">
        <div v-if="!loading">
          <v-card-title style="font-size: 16px !important" class="text-h5">
            الاسم
          </v-card-title>

          <v-card-text style="font-size: 15px !important">
            {{ hoveredData.name }}
          </v-card-text>

          <v-card-title style="font-size: 16px !important" class="text-h5">
            رقم الهاتف
          </v-card-title>

          <v-card-text> {{ hoveredData.phone }} </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              color="green darken-1"
              router
              :to="{
                name: 'request_details',
                params: { id: id },
              }"
              text
            >
              عرض المزيد
            </v-btn>
            <v-btn color="green darken-1" text @click="dialog = false">
              اغلاق
            </v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Axios from "axios";
import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";

export default {
  name: "TheCalender",
  data() {
    return {
      theCookie: document.cookie
        .split(";")
        .map((coki) => coki.split("="))
        .reduce(
          (acc, [key, val]) => ({
            ...acc,
            [key.trim()]: decodeURIComponent(val),
          }),
          {}
        )["wsataJwt"],
      events: [],
      phone_number: "",
      btn_loading: false,
      dialog: false,
      loading: false,
      hoveredData: {
        name: "",
        phone: "",
      },
      id: "",
      addNewRequestDialog: false,
      CalenderColor: this.$vuetify.theme.themes.light.CalanderColor
    };
  },
  methods: {
    async getData() {
      this.loading = true;
      await Axios.get(`${this.$store.state.url}/agent/Reminders`, {
        headers: {
          "x-api-key":
            "WFKvB4Vjr8v5BVmxY7CDQ6ZMAU8DSHSBFKCMVpkfd5hhZyKTvnV3uh5XwxSwhnfeUbptZ7Z4XFJCR4vKXeHWGHTZ6djsYXjdAatf",
          Authorization: `Bearer ${JSON.parse(this.theCookie).token}`,
        },
      })
        .then((res) => {
          const data = res.data.payload;
          data.forEach((rem) => {
            let color = "";
            if(rem.status == 0){
              color = "#F4516C";
            }else if(rem.status == 1){
              color = "#25d366";
            }else{
              color = "#3788D8";
            }
            let event = {
              title: rem.value,
              start: rem.reminder_date,
              color: rem.color,
              id: rem.req_id,
              // backgroundColor: "#0F5B94",
            };
            this.events.push(event);
          });
        })
        .finally(() => (this.loading = false));
    },
    search_by_phone() {
      this.btn_loading = true;
      Axios.post(
        `${this.$store.state.url}/agent/Check/Mobile`,
        { mobile: this.phone_number },
        { headers: this.request_headers() }
      )
        .then((res) => {
          const data = res.data.payload;
          data.forEach((rem) => {
            let color = "";
            if(rem.status == 0){
              color = "#F4516C";
            }else if(rem.status == 1){
              color = "#25d366";
            }else{
              color = "#3788D8";
            }
            let event = {
              title: rem.value,
              start: rem.reminder_date,
              // color: rem.status == 0 ? "#F4516C" : "#3788D8",
              color: rem.color,
              id: rem.req_id,
              // backgroundColor: "#0F5B94",
            };
            this.events.push(event);
          });
        })
        .catch((err) => {
          this.showPopup("error", err.response.data.message);
        })
        .finally(() => {
          this.btn_loading = false;
        });
    },
    async hoverEvent(id) {
      this.dialog = true;
      this.loading = true;
      await Axios.get(
        `${this.$store.state.url}/v2/employee/Requests/${id}`,
        {
          headers: this.request_headers(),
        }
      )
        .then((res) => {
          this.hoveredData.name = res.data.payload.customer.name;
          this.hoveredData.phone = res.data.payload.customer.mobile;
          this.loading = false;
          this.id = id;
        })
        .catch((err) => {
          this.dialog = false;
          this.loading = false;
          this.showPopup("error", err.response.data.message);
        });
    },
  },

  watch:{
    "$store.state.first_not_processed_task_id": {
      handler(val) {
        if (val > 0 && localStorage["role"] == "0") {
          this.$store.state.showAlertOfTasksDidntProccess = true;
          this.$router.push({ name: 'ticket_details', params: { ticket: this.$store.state.first_not_processed_task_id } });
        }
      },
      immediate: true 
    },
    "$store.state.count_of_archived_in_quality": {
      handler(val) {
        if (val > this.$store.state.redirect_count_of_archived_in_quality && localStorage["role"] == "0") {
          this.$store.state.showAlertOfArchivedQualityDidntProccess = true;
          this.$router.push({ name: "archievedInQuality" });
        }
      },
      immediate: true
    },
  },

  async created() {
    this.$store.state.opened = -1
    await this.getData();
    let el = document.querySelector(".calender-view");
    let calendar = new Calendar(el, {
      locale: "ar-kw",
      direction: "rtl",
      plugins: [dayGridPlugin, timeGridPlugin, listPlugin],
      initialView: "dayGridMonth",
      navLinks: true,
      editable: false,
      selectable: false,
      initialDate: new Date(),
      headerToolbar: {
        left: "today prev,next",
        center: "title",
        right: "timeGridDay,timeGridWeek,dayGridMonth",
      },
      buttonText: {
        today: "اليوم",
        month: "شهر",
        week: "اسبوع",
        day: "يوم",
        allDay: "اليوم كله",
      },
      events: this.events,
      eventClick: async (info) => {
        await this.hoverEvent(info.event.id);
      },
    });
    calendar.render();
  },
};
</script>

<style lang="scss">
h3 {
  color: v-bind(CalenderColor) !important;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}
.calender-view {
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 8px -5px black;
}
table th tr {
  background-color: v-bind(CalenderColor);
  a {
    color: #ffffff !important;
  }
}
.fc .fc-button-primary {
  border: 1px solid;
  background-color: #f5f5f5;
  background-image: -webkit-linear-gradient(top, #fff, #e6e6e6);
  background-image: -o-linear-gradient(top, #fff, #e6e6e6);
  background-image: linear-gradient(to bottom, #fff, #e6e6e6);
  background-repeat: repeat-x;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  color: #333;
  text-shadow: 0 1px 1px rgb(255 255 255 / 75%);
  box-shadow: inset 0 1px 0 rgb(255 255 255 / 20%), 0 1px 2px rgb(0 0 0 / 5%);
  &:hover,
  :focus,
  ::selection {
    color: #333 !important;
    border: 1px solid !important;
    outline: none !important;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    background-image: -webkit-linear-gradient(top, #fff, #d9d9d9);
    background-image: -o-linear-gradient(top, #fff, #d9d9d9);
    background-image: linear-gradient(to bottom, #fff, #d9d9d9);
    box-shadow: inset 0 1px 0 rgb(255 255 255 / 20%), 0 1px 2px rgb(0 0 0 / 5%) !important;
  }
}
.fc-button-active,
.fc-today-button {
  color: #333 !important;
  border: 1px solid;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25) !important;
  background-image: -webkit-linear-gradient(
    top,
    rgb(220, 220, 220),
    #c2c0c0
  ) !important;
  background-image: -o-linear-gradient(
    top,
    rgb(220, 220, 220),
    #c2c0c0
  ) !important;
  background-image: linear-gradient(
    to bottom,
    rgb(220, 220, 220),
    #c2c0c0
  ) !important;
}
.fc .fc-daygrid-day-top {
  background-color: v-bind(CalenderColor);
  a {
    color: #ffffff !important;
  }
}
.fc .fc-day-other .fc-daygrid-day-top {
  opacity: 1;
}
.fc-timegrid-event .fc-event-time,
.fc-v-event .fc-event-title {
  font-size: 15px !important;
  padding-right: 10px;
}
.calender .v-input__slot {
  box-shadow: unset !important;
  border: 1px solid #ced4da;
}
</style>
