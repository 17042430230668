<template>
  <v-row justify="center">
    <v-dialog v-model="$store.state.FMCreatePaymentDialog" persistent max-width="1024px">
      <v-card>
        <v-card-title>
          <span class="text-h5" style="margin-bottom: 30px">إنشاء الدفعة</span>
        </v-card-title>
        <v-card-text>
          <!-- Form 1 -->
          <v-card class="mb-5">
            <v-card-title>
              <h3 class="text-center title-2">بيانات الدفعة والإلتزامات</h3>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="4">
                  <v-text-field label="قيمة الشيك" v-model="checkValue" readonly outlined></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field label="القيمة الفعلية للعقار" v-model="real" type="number" outlined
                    @blur="calculateIncrease"></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field label="قيمة الزيادة" v-model="increaseValue" readonly outlined></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4">
                  <v-text-field label="قيمة الدفعة" v-model="prepaymentValue" type="number" outlined
                    @blur="calculatePrepayment"></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field label="نسبة الدفعة" v-model="prepaymentPercentage" type="number" outlined
                    @blur="calculatePrepayment"></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field label="المبلغ" v-model="prepaymentCost" readonly outlined></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field label="صافي العميل" v-model="customerNet" type="number" outlined></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field label="عجز العميل" v-model="customerDeficit" type="number" outlined></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <!-- Form 2 -->
          <v-card>
            <v-card-title>
              <h3 class="text-center title-2">بيانات تساهيل</h3>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field label="بطاقة الفيزا" v-model="visaCard" type="number" outlined
                    @blur="calculateDebt"></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field label="قرض السيارة" v-model="carLoan" type="number" outlined
                    @blur="calculateDebt"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field label="قرض الشخصي" v-model="personalLoan" type="number" outlined
                    @blur="calculateDebt"></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field label="قرض عقاري" v-model="realEstateLoan" type="number" outlined
                    @blur="calculateDebt"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field label="بنك التسليف" v-model="bankDebt" type="number" outlined
                    @blur="calculateDebt"></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field label="ديون أخرى" v-model="otherDebt" type="number" outlined
                    @blur="calculateDebt"></v-text-field>
                </v-col>
              </v-row>
              <v-text-field label="إجمالي الديون" v-model="totalDebt" readonly outlined></v-text-field>

              <v-row>
                <v-col cols="12" md="3">
                  <v-text-field label="نسبة الرهن" v-model="morpre" type="number" min="0" max="100" outlined
                    @blur="mortcalculate"></v-text-field>
                </v-col>
                <v-col cols="12" md="9">
                  <v-text-field label="المبلغ" v-model="morcos" readonly outlined></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="3">
                  <v-text-field label="نسبة السعي" v-model="propre" type="number" min="0" max="100" outlined
                    @blur="profcalculate"></v-text-field>
                </v-col>
                <v-col cols="12" md="9">
                  <v-text-field label="المبلغ" v-model="procos" readonly outlined></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field label="القيمة المضافة" v-model="valadd" type="number" min="0" outlined></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field label="الرسوم الإدارية" v-model="admfe" type="number" min="0" outlined></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" variant="text" @click="$store.state.FMCreatePaymentDialog = false">إلغاء</v-btn>
          <v-btn color="primary" variant="text" @click="savePrepayment">إنشاء الدفعة</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>


<script>
import { axiosInstance } from "@/axios/config";

export default {
  name: "FMCreatePaymentDialog",
  props: ["reqId", "requestData"],

  data() {
    return {
      checkValue: this.requestData.real_estate.cost,
      real: 0,
      increaseValue: 0,
      prepaymentValue: 0,
      prepaymentPercentage: 0,
      prepaymentCost: 0,
      customerNet: 0,
      customerDeficit: 0,
      visaCard: 0,
      carLoan: 0,
      personalLoan: 0,
      realEstateLoan: 0,
      bankDebt: 0,
      otherDebt: 0,
      totalDebt: 0,
      morpre: 0,
      morcos: 0,
      propre: 0,
      procos: 0,
      valadd: 0,
      admfe: 0,
    };
  },
  methods: {
    calculateIncrease() {
      this.increaseValue = (this.checkValue - this.real).toFixed(2);
    },
    calculatePrepayment() {
      const prepaymentValue = parseFloat(this.prepaymentValue);
      const prepaymentPercentage = parseFloat(this.prepaymentPercentage);
      this.prepaymentCost = (prepaymentValue + (prepaymentValue * (prepaymentPercentage / 100))).toFixed(2);
    },
    calculateDebt() {
      this.totalDebt = (parseFloat(this.visaCard) + parseFloat(this.carLoan) + parseFloat(this.personalLoan) + parseFloat(this.realEstateLoan) + parseFloat(this.bankDebt) + parseFloat(this.otherDebt)).toFixed(2);
    },

    mortcalculate() {
      this.morcos = (parseFloat(this.morpre) / 100) * parseFloat(this.totalDebt);
    },
    profcalculate() {
      this.procos = (parseFloat(this.propre) / 100) * parseFloat(this.totalDebt);
    },

    savePrepayment() {
      let req_id = "";
      req_id = parseInt(this.reqId);
      const payload = {
        checkValue: this.checkValue,
        real: this.realCost,
        incr: this.increaseValue,
        preval: this.prepaymentValue,
        prepre: this.prepaymentPercentage,
        precos: this.prepaymentCost,
        net: this.customerNet,
        deficit: this.customerDeficit,
        visa: this.visaCard,
        carlo: this.carLoan,
        perlo: this.personalLoan,
        realo: this.realEstateLoan,
        credban: this.bankDebt,
        other: this.otherDebt,
        debt: this.totalDebt,
        morpre: this.morpre,
        morcos: this.morcos,
        propre: this.propre,
        procos: this.procos,
        valadd: this.valadd,
        admfe: this.admfe,
      };
      axiosInstance.post(`/employee/funding-manager/createPrepay/${req_id}`, payload, { headers: this.request_headers(), })
        .then((res) => {
          this.$store.state.FMCreatePaymentDialog = false;
          this.showPopup("success", res.data.message);
        })
        .catch((err) => {
          console.log(err)
          this.$store.state.FMCreatePaymentDialog = false;
          this.showPopup("error", err.response.data.message);
        });
    },

  },
};
</script>
