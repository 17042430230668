
<template>
  <div class="all-requests">
    <div
        style="
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px;
      "
        class="btns-dad"
    >
      <h2>طلب اجازة</h2>
      <v-btn
          :color="$vuetify.theme.themes.light.dataActionBTN"
          style="font-size: 16px"
          class="pb-2 receive-btn"
          @click="goto_add_vacation"
          dark
      >
        <v-icon
            right
            dark
        >
          mdi-plus
        </v-icon>
        اضافة طلب اجازة</v-btn
      >
    </div>
    <v-row class="px-2 mx-1 vRowToShowData" style="display: inline-block; width: 100%; margin-top: 1rem;">

      <v-col  class="app no-requests font-weight-bold" style="font-size: 36px" cols="12" v-if="!loading && !items.length">
        لا توجد بيانات
      </v-col>
      <v-col  v-if="loading" class="app no-requests font-weight-bold" style="font-size: 36px" cols="12" >
        <v-progress-linear
            indeterminate
            color="green"
        ></v-progress-linear>
      </v-col>

      <v-layout row wrap>
        <v-col cols="12" lg="3" md="4" sm="6" xs="12" v-for="(item, i) in items" :key="i" class="px-2 py-1">
          <v-card class="pa-3 position-relative" style="height: 100%">
            <span class="right-color requestType"
                  :style="`background-color:#3f0e40`">
            </span>
            <div class="card-head d-flex justify-space-between align-center">
              <v-card-title class="pa-0 requestTitle" style="color: #3f0e40;">
                {{ item.user_name}}
              </v-card-title>
              <div class="icons">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn width="30" height="30" color="transparent" depressed fab light v-bind="attrs" v-on="on"
                           style="margin-left: -10px">
                      <img src="@/assets/icons/eyeIcon.svg"/>
                    </v-btn>
                  </template>
                  <v-list>
<!--                    <v-list-item @click="accept_vacation(item)">-->
<!--                      <v-list-item-icon class="ml-3">-->
<!--                        <v-icon-->
<!--                            right-->
<!--                            dark-->
<!--                            color="#45c455"-->
<!--                        >-->
<!--                          mdi-check-circle-->
<!--                        </v-icon>-->
<!--                      </v-list-item-icon>-->
<!--                      <v-list-item-title>موافقة</v-list-item-title>-->
<!--                    </v-list-item>-->
<!--                    <v-list-item @click="reject_vacation(item)">-->
<!--                      <v-list-item-icon class="ml-3">-->
<!--                        <v-icon-->
<!--                            right-->
<!--                            dark-->
<!--                            color="#e43c30"-->
<!--                        >-->
<!--                          mdi-close-circle-->
<!--                        </v-icon>-->
<!--                      </v-list-item-icon>-->
<!--                      <v-list-item-title>رفض</v-list-item-title>-->
<!--                    </v-list-item>-->
                    <v-list-item @click="order_vacation(item)">
                      <v-list-item-icon class="ml-3">

                          <HolidayOrder />

                      </v-list-item-icon>
                      <v-list-item-title>سجل الطلب</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="edit_vacation(item)" v-if="!item.history.manager_id">
                      <v-list-item-icon class="ml-3">
                        <v-icon dark color="#45c455">mdi-pencil</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>تعديل الاجازه</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="delete_vacation(item)" v-if="!item.history.manager_id">
                      <v-list-item-icon class="ml-3">
                        <v-icon
                            right
                            dark
                            color="#e43c30"
                        >
                          mdi-delete
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>حذف الاجازه</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>

              </div>
            </div>
            <div class="req-status-parent mt-1 d-flex justify-space-between align-center">
              <span class="req-type" style="color: #017e84 ; font-size: 15px;">
                البديل
              </span>
              <span class="req-status ">
                {{item.alternative_name}}
              </span>
            </div>
            <div class="req-status-parent mt-1 d-flex justify-space-between align-center">
              <span class="req-type" style="color: #eeb902 ; font-size: 15px;">
                اليوم / من
              </span>
              <span class="req-status ">{{ item.from }}
              </span>
            </div>
            <div class="req-status-parent mt-1 d-flex justify-space-between align-center">
              <span class="req-type" style="color: #116a9d ; font-size: 15px;">
                اليوم / الي
              </span>
              <span class="req-status ">{{ item.to }}
              </span>
            </div>
            <div class="req-status-parent mt-1 d-flex justify-space-between align-center">
              <span class="req-type" style="color: #6dcb92 ; font-size: 15px;">
               رصيد الاجازة
              </span>
              <span class="req-status ">{{ item.vacation_balance }}
              </span>
            </div>
            <div class="req-status-parent mt-1 d-flex justify-space-between align-center">
              <span class="req-type" style="color: #fe816e ; font-size: 15px;">
               مدة الاجازة
              </span>
              <span class="req-status ">{{ item.vacation_duration }}
              </span>
            </div>
            <div class="req-status-parent mt-1 d-flex justify-space-between align-center">
              <span class="req-type" style="color: #116a9d ; font-size: 15px;">
               الرصيد المتبقي بعد الموافقة
              </span>
              <span class="req-status ">{{ item.remainder_balance }}
              </span>
            </div>

          </v-card>
        </v-col>
        <template>
          <v-dialog v-model="dialogVisible" max-width="400">
            <v-card>
              <v-card-title class="headline">...</v-card-title>
              <v-card-text>
                هل انت متاكد من حذف الاجازه؟
              </v-card-text>
              <v-card-actions>
                <v-btn color="red darken-1" text @click="dialogVisible = false">الغاء</v-btn>
                <v-btn color="green darken-1" text @click="confirmDelete">حذف</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
      </v-layout>

    </v-row>
    <AgentChat />
    <!-- cards -->
  </div>
</template>

<script>
import Axios from "axios";
import {HolidayOrder} from "@/assets/adminIcons/holidayOrder";
import AgentChat from "@/components/AgentChat/AgentChat.vue";


export default {
  name: "AgentVacation",
  components:{HolidayOrder, AgentChat},
  data: () => ({
    loading: true,
    page: 1,
    /* eslint-disable */
    dialogVisible: false,
    items: [],
    addNewRequestDialog: false,
    selected_requests: [],
  }),
  methods: {
    goto_add_vacation() {
      this.$router.push({
        name: "agent_add_vacation",
      });
    },
    async get_all_items() {
      this.loading = true;
      await Axios.get(
          `${this.$store.state.url}/agent/all_vacation`,
          {
            headers: this.request_headers(),
          }
      ).then((response) => {
        this.items = response.data.payload;
        this.loading = false;
      });
    },
    async accept_vacation(item) {
      this.loading = true;
      let body = {
        user_type: "alternative",
        vacation_id: item.id,
        accept: 1

      }
      await Axios.post(
          `${this.$store.state.url}/agent/accept_vacation`, body,
          {
            headers: this.request_headers(),
          }
      ).then((res) => {
        this.showPopup("success", res.data.payload);
        this.get_all_items();
      })
          .catch((err) => {
            this.showPopup("error", err.response.data.message);
            this.verifing = false;
          });
    },
    async reject_vacation(item) {
      this.loading = true;
      let data = {
        user_type: "alternative",
        vacation_id: item.id,
        accept: 0
      }
      await Axios.post(
          `${this.$store.state.url}/agent/accept_vacation`, data,
          {
            headers: this.request_headers(),
          }
      ).then((res) => {
        this.showPopup("success", res.data.payload);
        this.get_all_items();
      })
          .catch((err) => {
            this.showPopup("error", err.response.data.message);
            this.verifing = false;
          });
    },
    edit_vacation(item) {
      this.$router.push({
        name: "edit_agent_vacation",
        params: {id: item.id ,
            alternative_name: item.alternative_name,
          alternative_id: item.alternative_user_id
          }
      })
    },
    order_vacation(item) {
      this.$router.push({
        name: "vacation_history",
        params: {id: item.id , isAgent:true},
      });
    },
    async delete_vacation(item) {
      this.dialogVisible = true;
      this.selectedItem = item;
    },
    async confirmDelete() {
      try {
        this.dialogVisible = false;
        this.loading = true;
        const response = await Axios.delete(
            `${this.$store.state.url}/agent/delete_vacation/${this.selectedItem.id}`,
            {
              headers: this.request_headers(),
            }
        );
        this.showPopup("success", response.data.payload);
        this.get_all_items();
      } catch (error) {
        this.showPopup("error", error.response.data.message);
        this.verifing = false;
      }
    },

  },
  async created() {
    this.$store.state.opened = -1
    await this.get_all_items();
  },
};
</script>



