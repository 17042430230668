import SMTheNotifc from "@/views/funding_manager/TheNotifc.vue";



// New Design
import fundingManagerAllRequests from "@/views/funding_manager/Requests/AllRequests.vue";
import fundingManagerRecievedRequests from "@/views/funding_manager/Requests/RecievedRequests.vue";
import fundingManagerFinanceReport from "@/views/funding_manager/Reports/FinanceReport.vue";
import fundingMangerHome from "@/views/funding_manager/fundingHome.vue";
import ProfilePage from "@/views/agent2/ProfilePage.vue";
import QuestionAndAnswer from "@/views/funding_manager/questionAndAnswer/PostData.vue";


export const fundingManagerRouter = [
    {
        path: "/funding-manager/notifications",
        name: "fundingManager_TheNotifc",
        component: SMTheNotifc,
        meta: {
            icon: `
        <svg xmlns="http://www.w3.org/2000/svg" width="18.552" height="19" viewBox="0 0 20.552 21">
          <g id="message_1_" data-name="message (1)" transform="translate(1 1)">
            <g id="bell">
              <line id="Line_3" data-name="Line 3" x2="3" transform="translate(8 19)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              <path id="Path_2642" data-name="Path 2642" d="M22.552,19.179H4a3.044,3.044,0,0,0,1.687-2.732V11.59A7.59,7.59,0,0,1,13.276,4h0a7.59,7.59,0,0,1,7.59,7.59v4.857a3.044,3.044,0,0,0,1.687,2.732Z" transform="translate(-4 -4)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
            </g>
          </g>
        </svg>
        `,
            listed: true,
            group: '',
            page_name: "الإشعارات",
        },
    },
    
    {
        path: "/funding-manager",
        name: "fundingMangerHome",
        component: fundingMangerHome,
        meta: {
            icon: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                <g id="home_icon" transform="translate(1 1)">
                    <path id="roof" d="M11.293,2.293,2,10H5v8h6V14h2v4h6V10h3L12.707,2.293a1,1,0,0,0-1.414,0Z" 
                        fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    <line id="door" x1="4" y1="0" x2="4" y2="4" transform="translate(8 12)" 
                        fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                </g>
                </svg>
                `,
            listed: true,
            is_top: true,
            group: "",
            page_name: "الرئيسية",
            breadcrumb: "",
        },
    },
    
    {
        path: "/fundingManager/requests/all",
        name: "fundingManagerAll",
        component: fundingManagerAllRequests,
        meta: {
            icon: "",
            listed: true,
            group: "الطلبات..",
            page_name: "جميع الطلبات",
            breadcrumb: "",
            badge: true,
            badge_count: "f_all",
            badge_color: '#000000',
            badge_f_color: '#ffffff'
        },
    },
    
    {
        path: "/fundingManager/requests/recieved",
        name: "fundingManagerRecieved",
        component: fundingManagerRecievedRequests,
        meta: {
            icon: "",
            listed: true,
            group: "الطلبات..",
            page_name: "الطلبات المستلمة",
            breadcrumb: "",
        },
    },
    
    {
        path: "/fundingManager/finance-report",
        name: "fundingManagerFinanceReport",
        component: fundingManagerFinanceReport,
        meta: {
            icon: "",
            listed: true,
            group: "الطلبات..",
            page_name: "التقارير المالية",
            breadcrumb: "",
        },
    },
    
    {
        path: "/funding-manager/profile",
        name: "funding_profile",
        component: ProfilePage,
        meta: {
            // icon: "mdi-update",
            listed: false,
            // group: " ",
            page_name: "تعديل البيانات ",
        },
    },

    {
        path: "/funding-manager/question-and-answers",
        name: "FundingQuestionAndAnswer",
        component: QuestionAndAnswer,
        meta: {
            icon: `
       <svg xmlns="http://www.w3.org/2000/svg" width="18.552" height="19" viewBox="0 0 20.552 21">
        <g id="post_icon" transform="translate(1 1)">
            <g id="post">
            <path id="outline" d="M2,3H19.552A2.448,2.448,0,0,1,22,5.448v12.1A2.448,2.448,0,0,1,19.552,20H2.448A2.448,2.448,0,0,1,0,17.552V5.448A2.448,2.448,0,0,1,2.448,3Z" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
            <line id="line_1" x2="14" transform="translate(3 7)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
            <line id="line_2" x2="10" transform="translate(3 11)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
            </g>
        </g>
        </svg>

        `,
            listed:true ,
            page_name: "منتدى الوساطة",
            is_top: true,
            // group: "منتدى الوساطة",
            group: "",
        },
    },

   
    
]