<template>
  <page-component style="position: relative">
    <template #content>
      <!-- بيانات العميل -->
      <v-sheet class="px-5 py-5">
        <span class="font-weight-bold my-5" style="display: inline">المكالمات</span>
        <v-row
          style="background-color: #f8f9fa; border: 1px solid #eff2f7; margin-top: 20px;"
          class="mx-0 file-info-row"
        >
          <v-progress-linear
            color="primary"
            :indeterminate="loading"
            reverse
          ></v-progress-linear>
          <v-col cols="2">اسم الاستشاري</v-col>
          <v-col cols="4"> المكالمه</v-col>
          <v-col cols="3">تاريخ المكالمه</v-col>
        </v-row>

        <v-row
          v-for="item in calls_details"
          :key="item.id"
          class="mx-0 py-4 file-info-row"
        >
          <v-col cols="2">{{ item.agent_name }}</v-col>
          <v-col cols="4"><audio controls :src="item.path"></audio></v-col>
          <v-col cols="3">{{ item.day_date }}</v-col>
        </v-row>
      </v-sheet>
    </template>
  </page-component>
</template>

<script>
import Axios from "axios";
export default {
  props: ["agent"],
  data() {
    return {
      calls_details: [],
      loading: false,
      testArray: [
        { id: 1, agent_name: 'Agent 1', path: '/path1', day_date: '2023-01-01' },
        { id: 2, agent_name: 'Agent 2', path: '/path2', day_date: '2023-01-02' },
        { id: 3, agent_name: 'Agent 3', path: '/path3', day_date: '2023-01-03' },
      ],
    };
  },
  created() {
    this.get_request_calls();
    console.log(this.agent);
  },

  methods: {
    get_request_calls() {
      this.loading = true;
      Axios.get(
        `${this.$store.state.url}/agent/Request/call-center/${this.agent.id}`,
        { headers: this.request_headers() }
      )
        .then((response) => {
          this.calls_details = response.data.payload;
        })
        .catch((err) => {
          this.showPopup("error", err.response.data.message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
   
  },
};
</script>

<style lang="scss">
@media (max-width: 576px) {
  .file-info-row {
    div {
      font-size: 12px;
    }
    button {
      font-size: 16px !important;
    }
  }
}
</style>
