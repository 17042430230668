<template>
    <v-row>
      <div style="width: 100%; display: flex; border: 1px solid #3f0e40; border-radius: 10px; height: 80px;" class="filter">
        <v-col cols="12" sm="3" md="3" style="margin-top:  -5px!important;padding-top: 0 !important;">
          <PickerInputWithOutIcon ref="targetStartDate" label="من تاريخ" @dateValue="targetStartDate($event)" :selected="start_date_val" />
        </v-col>
  
        <v-col cols="12" sm="3" md="3" style="margin-top: -5px!important;padding-top: 0 !important;">
          <PickerInputWithOutIcon ref="targetEndDate" label="الي تاريخ" @dateValue="targetEndDate($event)" :selected="end_date_val" />
        </v-col>
        <v-col cols="12" sm="1" md="1">
            <div >
              <v-text-field
                label="الايام"
                v-model="filterObj.dayNumber"
                style="width: 80px; margin-right: 10px; height: 48px"
                outlined
                dense
              ></v-text-field>
            </div>
        </v-col>
        
  
        <v-col v-if="0" cols="12" sm="3" md="3">
            <v-autocomplete
                :items="allStatus"
                style="width: 100%; padding: 2px"
                label="الحالة"
                v-model="filterObj.status"
                outlined
                dense
            >
              <template v-slot:item="{ item, attrs, on }">
                <v-list-item v-bind="attrs" v-on="on">
                  <v-list-item-title
                      :id="attrs['aria-labelledby']"
                      v-text="item"
                  ></v-list-item-title>
                </v-list-item>
              </template>
            </v-autocomplete>

        </v-col>
        <v-col cols="12" sm="2" md="2">
              <v-btn
                depressed
                color="primary"
                @click="handleFilter()"
                style="height: 48px; font-size: 16px; font-weight: 700; margin-right: 20px"
              >
                تطبيق
              </v-btn>
        </v-col>
      </div>
    </v-row>
  </template>
  
  <script>
//   import SelectInput from "@/components/Charts/SelectInput.vue";
  import PickerInputWithOutIcon from "@/components/Charts/PickerInputWithOutIcon.vue";
  import Axios from "axios";
  export default {
    // components: { SearchIconBlack, SelectInput, PickerInput },
    components: { 
        // SelectInput, 
        PickerInputWithOutIcon 
    },
    props: ["role"],
    data() {
      return {
        start_date_val: '',
        end_date_val: '',
        today:
          new Date().getFullYear() +
          "-" +
          ("0" + (new Date().getMonth() + 1)).slice(-2) +
          "-" +
          ("0" + new Date().getDate()).slice(-2),
        allStatus: ["الكل", "استشاري مؤرشف", "استشاري قائم"],
        selectedAgents: [],
        selectedManagers: [],
        managerNames: [],
        agentNames: [],
        selectedManager: [],
        isSalesManager: this.role == 1 ? true : false,
        mangers: [],
        agents: [],
        status: null,
        range: {},
        pickerActive: false,
        filterObj: {
          endDate: "",
          startDate: "",
          status: "استشاري قائم",
          range: "",
          dayNumber: "",
          managerId: [],
          agentId: [],
        },
      };
    },
  
    watch: {
      selectedManagers(val) {
        this.selectedManager = val;
        this.filterObj.managerId = val;
        this.getMangersAndAgents("agent", this.status);
      },
  
      selectedAgents(val) {
        this.filterObj.agentId = val;
      },
  
      "filterObj.status": function (newStatus, oldStatus) {
        console.log("this.role", this.role);
        this.status = newStatus;
        switch (newStatus) {
          case "استشاري قائم":
            if (this.role != 1) {
              this.getMangersAndAgents("manager", 1);
            }
            
            this.getMangersAndAgents("agent", 1);
            break;
          case "استشاري مؤرشف":
            if (this.role != 1) {
              this.getMangersAndAgents("manager", 0);
            }
            
            this.getMangersAndAgents("agent", 0);
            break;
          case "الكل":
            if (this.role != 1) {
              this.getMangersAndAgents("manager");
            }
            
            this.getMangersAndAgents("agent");
            break;
          default:
            break;
        }
      },
      "filterObj.dayNumber": function (val, old) {
        if (val != old && val) {
          let currentDate = new Date();
          let newDate = new Date();
  
          this.end_date_val = currentDate.toISOString().split('T')[0];
          this.filterObj.endDate = this.end_date_val;
          newDate.setDate(newDate.getDate() - parseInt(val));
          this.start_date_val = newDate.toISOString().split('T')[0];
          this.filterObj.startDate = this.start_date_val;
        }
      },
    },
  
    methods: {
      handleRangeDate(val) {
        this.filterObj.startDate = val.startDate;
        this.filterObj.endDate = val.endDate;
      },
      togglePicker() {
        this.$refs.daterange.toggle();
      },
      handleFilter() {
        this.$emit("handleFilter", this.filterObj);
      },
      getExcelFile() {
        this.$emit("getExcelFile", this.filterObj);
      },
      getSelectedValueManger(selectedValue) {
        this.selectedManagers = selectedValue;
      },
  
      getSelectedValueAdviser(selectedValue) {
        this.selectedAgents = selectedValue;
      },
  
      targetEndDate(endDate) {
        this.filterObj.dayNumber = '';
        this.filterObj.endDate = endDate;
      },
  
      targetStartDate(startDate) {
        this.filterObj.dayNumber = '';
        this.filterObj.startDate = startDate;
      },
      setDefaultValues() {
        this.filterObj.endDate = this.$route.name == "rateAverage" ? this.today : "";
        this.filterObj.startDate = this.$route.name == "rateAverage" ? this.today : "";
        // this.filterObj.status = this.$route.name == "rateAverage" ? "استشاري قائم" : "";
        this.filterObj.status =  "استشاري قائم";
      },
  
      getMangersAndAgents(role, status = "") {
        /*
        this.agentNames = [];
        let body = new FormData();
  
        body.append("role", role);
        if (this.role == 1) {
          const id = JSON.parse(
          document.cookie
            .split(";")
            .map((coki) => coki.split("="))
            .reduce(
              (acc, [key, val]) => ({
                ...acc,
                [key.trim()]: decodeURIComponent(val),
              }),
              {}
            )["wsataJwt"]
        )["agent_id"];
          body.append("manager_id[0]", id);
          
        }
  
        if (status !== "") {
          body.append("status", status);
        }
  
        if (this.selectedManagers.length || localStorage["id"]) {
          const managerProperties = {};
          this.selectedManagers.forEach((item, index) => {
            body.append([`manager_id[${index}]`], item.id);
          });
          if (this.isSalesManager)
            body.append(
              [`manager_id[${this.selectedManagers.length}]`],
              localStorage["id"]
            );
        }
        Axios.post(
          `${this.$store.state.url}/employee/admin/filterUsers`,
          body,
          { headers: this.request_headers() }
        ).then((res) => {
          if (role === "manager") {
            this.managerNames = res["data"]["payload"];
            this.managerNames.map((item) => {
              item["text"] = item["name"];
              item["color"] = "red";
              return item;
            });
          } else {
            this.agentNames = res["data"]["payload"];
            this.agentNames.map((item) => {
              item["text"] = item["name"];
              item["color"] = "red";
              return item;
            });
          }
        });
        */
      },
    },
  
    created() {
      // this.handleFilter({});
      if (this.role != 1) {
        this.getMangersAndAgents("manager");
      }
      
      this.setDefaultValues();
      this.getMangersAndAgents("agent", 1);
    },
  };
  </script>
  
  <style>
  .filter label {
    font-size: 16px;
  }
  .filter
    .selectInput
    .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
    > .v-input__control
    > .v-input__slot {
    background: #f3f5f7;
    margin-top: 0px;
  }
  
  .filter .v-text-field--outlined.v-input--dense .v-label {
    font-size: 16px;
    font-weight: 700;
    color: black;
  }
  
  .filter .v-input__slot {
    height: 48px;
  }
  
  .filter .v-btn:not(.v-btn--round).v-size--default {
    min-width: 40px !important;
  }
  
  .filter .v-text-field.v-text-field--solo .v-label {
    font-size: 16px;
    font-weight: 700;
    color: black;
  }
  
  .filter .v-input:not(.file-inp) .v-input__icon {
    visibility: visible !important;
    left: 0px;
    color: black;
  }
  
  .filter
    .selectInput
    .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
    > .v-input__control
    > .v-input__slot {
    padding-bottom: 17px !important;
  }
  .filter .v-text-field.v-text-field--solo .v-label {
    top: 10px;
  }
  </style>
  