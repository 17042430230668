<template>
  <v-row class="chatContainer">
    <v-col cols="12" sm="6" md="4">
      <v-expansion-panels accordion :value="opened">
        <v-expansion-panel>
          <v-expansion-panel-header class="headerContainer">
            <div style="display: flex">
              <div @click="handleBack" style="margin-left: 10px; margin-top: 4px">
                <IconFeatherArrowRight />
              </div>
              <div>{{ current_room.UserName }}</div>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content
            class="bodyContainer"
            ref="container2"
            id="container2"
          >
            <template>
              <div class="chat-content">
                <!-- Chat details and replies go here -->
                <div>
                  <div v-for="(message, index) in chats" :key="index">
                    <v-row>
                      <v-col>
                        <div :class="getClassObject(message)">
                          <div :class="getContentObject(message)">
                            <span
                              v-if="message.message_type === 'text' && message.text != ''"
                              style="font-weight: bold"
                              >{{ message.text }}</span
                            >
                            <div
                              v-else
                              style="
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                background: #fff;
                                padding: 0 5px 5px;
                                border-radius: 6px 6px 0 0;
                              "
                            >
                              <v-icon size="40" v-if="message.fileType">mdi-image</v-icon>
                              <div class="pr-2 pt-2" v-else>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24.5"
                                  height="31.719"
                                  viewBox="0 0 24.5 31.719"
                                >
                                  <g id="pdf" transform="translate(-15.592 -5.255)">
                                    <path
                                      id="Path_4092"
                                      data-name="Path 4092"
                                      d="M51.276,51.414a2.081,2.081,0,0,0-.438.038v2.8a1.813,1.813,0,0,0,.336.019,1.332,1.332,0,0,0,1.46-1.5A1.222,1.222,0,0,0,51.276,51.414Z"
                                      transform="translate(-22.824 -29.891)"
                                      fill="#ed1c24"
                                    />
                                    <path
                                      id="Path_4093"
                                      data-name="Path 4093"
                                      d="M34.81,12.255a1.718,1.718,0,0,1-1.718-1.717V5.255H17.309a1.718,1.718,0,0,0-1.717,1.717V35.256a1.718,1.718,0,0,0,1.717,1.718H38.374a1.718,1.718,0,0,0,1.717-1.717v-23Zm-12.759,12.9h-.006a.574.574,0,1,1,.006,0Zm3.934-2.03a2.033,2.033,0,0,1-1.4.45,1.663,1.663,0,0,1-.33-.025v1.536H23.3v-4.22a7.923,7.923,0,0,1,1.307-.089,2.032,2.032,0,0,1,1.314.349,1.224,1.224,0,0,1,.45.984,1.343,1.343,0,0,1-.387,1.015Zm4.142,1.453a3.089,3.089,0,0,1-1.98.552,8.214,8.214,0,0,1-1.1-.064V20.876a7.79,7.79,0,0,1,1.3-.1,2.751,2.751,0,0,1,1.758.444,1.909,1.909,0,0,1,.724,1.625A2.187,2.187,0,0,1,30.127,24.581Zm4.015-2.976H32.5v.977h1.535v.787H32.5v1.72h-.965V20.812h2.608v.793Z"
                                      fill="#ed1c24"
                                    />
                                    <path
                                      id="Path_4094"
                                      data-name="Path 4094"
                                      d="M40.581,21.516a1.733,1.733,0,0,0-.4.032v1.263a1.483,1.483,0,0,0,.324.025c.514,0,.831-.26.831-.691,0-.393-.266-.628-.755-.628Zm10.152-9.261h5.282l-7-7v5.283a1.718,1.718,0,0,0,1.718,1.718Z"
                                      transform="translate(-15.923)"
                                      fill="#ed1c24"
                                    />
                                    <path
                                      id="Path_4095"
                                      data-name="Path 4095"
                                      d="M66.964,12.255h5.282l-7-7v5.283a1.718,1.718,0,0,0,1.718,1.718Z"
                                      transform="translate(-32.154)"
                                      fill="#fff"
                                      opacity="0.6"
                                    />
                                    <path
                                      id="Path_4096"
                                      data-name="Path 4096"
                                      d="M32.887,52.512a.587.587,0,0,0-.012,1.174h.006a.587.587,0,0,0,.006-1.174Zm3.864-2.855a2.032,2.032,0,0,0-1.314-.349,7.923,7.923,0,0,0-1.307.089v4.22h.958V52.081a1.634,1.634,0,0,0,.33.025,2.033,2.033,0,0,0,1.4-.45,1.344,1.344,0,0,0,.387-1.015,1.223,1.223,0,0,0-.451-.984Zm-1.339,1.707a1.507,1.507,0,0,1-.324-.025V50.076a1.733,1.733,0,0,1,.4-.032c.489,0,.755.235.755.628C36.243,51.1,35.926,51.364,35.412,51.364Zm5.525-1.612a2.751,2.751,0,0,0-1.758-.444,7.787,7.787,0,0,0-1.3.1V53.6a8.215,8.215,0,0,0,1.1.064,3.091,3.091,0,0,0,1.98-.552,2.187,2.187,0,0,0,.7-1.732A1.909,1.909,0,0,0,40.937,49.752ZM39.18,52.906a1.814,1.814,0,0,1-.336-.019v-2.8a2.057,2.057,0,0,1,.438-.038,1.222,1.222,0,0,1,1.358,1.358A1.332,1.332,0,0,1,39.18,52.906Zm3.184.71h.965V51.9h1.536V51.11H43.329v-.977h1.643v-.793H42.364Z"
                                      transform="translate(-10.83 -28.527)"
                                      fill="#fff"
                                    />
                                  </g>
                                </svg>
                              </div>
                              <div
                                style="display: flex; flex-direction: column; gap: 4px"
                              >
                                <!--Download Menu-->
                                <v-menu transition="slide-y-transition" top>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      style="margin-right: -5px !important"
                                      v-bind="attrs"
                                      v-on="on"
                                      class="mx-2"
                                      >mdi-dots-vertical</v-icon
                                    >
                                  </template>
                                  <v-list>
                                    <v-list-item @click="openFile(message.file)">
                                      <v-list-item-title>
                                        <v-icon class="ml-1" size="18">mdi-eye</v-icon>
                                        فتح المرفق
                                      </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item @click="attachFile(message.fileId)">
                                      <v-list-item-title>
                                        <v-icon class="ml-1" size="18"
                                          >mdi-file-plus</v-icon
                                        >
                                        اضافة الي مرفقات الطلب
                                      </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item @click="downloadFile(message.file)">
                                      <v-list-item-title>
                                        <v-icon title="تحميل">mdi-download</v-icon>
                                        تحميل المرفق
                                      </v-list-item-title>
                                    </v-list-item>
                                  </v-list>
                                </v-menu>
                                <!--Download Menu-->
                              </div>
                            </div>
                            <span
                              v-if="message.file && 0"
                              style="
                                font-size: 10px;
                                margin-bottom: 12px;
                                display: block;
                                font-weight: bold;
                                background: #fff;
                                padding: 0 5px 5px;
                                border-radius: 0px 0px 6px 6px;
                              "
                              dir="ltr"
                              >{{ message.file }}
                            </span>
                          </div>
                          <div style="color: #676767" :class="getDateObject(message)">
                            {{ format_date(message.created_at) }}
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </div>
                <!--                 Reply form goes here-->
                <div class="bottom-form-container">
                  <v-form @submit.prevent="send_message" class="full-width-form">
                    <v-text-field
                      v-model="message"
                      placeholder="أكتب رسالتك ..."
                      @keyup.enter="send_message"
                      :disabled="disableText"
                    ></v-text-field>
                    <v-btn type="button" @click="send_message" icon class="sendIcon">
                      <SendIcon />
                    </v-btn>
                  </v-form>
                </div>
              </div>
            </template>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
  </v-row>
</template>

<script>
import { SendIcon } from "@/assets/sendIcon";
import { IconFeatherArrowRight } from "@/assets/adminIcons/IconFeatherArrowRight";
import css from "./Chat.css";
import firebase from "@/Firebase";
import { axiosInstance } from "@/axios/config";
var moment = require("moment");
export default {
  props: ["chatDetails"],
  components: { SendIcon, IconFeatherArrowRight },
  data() {
    return {
      opened: 0,
      replyMessage: "",
      fileData: "",
      message: "",
      disableText: false,
      isDisabled: true,
      current_room: {},
      current_room_id: "",
      chats: [],
      agent: JSON.parse(
        document.cookie
          .split(";")
          .map((coki) => coki.split("="))
          .reduce(
            (acc, [key, val]) => ({
              ...acc,
              [key.trim()]: decodeURIComponent(val),
            }),
            {}
          )["wsataJwt"]
      ),
    };
  },

  watch: {
    message(val) {
      if (val) {
        this.isDisabled = false;
      } else {
        this.isDisabled = true;
      }
    },
    fileData(val) {
      if (val) {
        this.isDisabled = false;
        this.disableText = true;
      } else {
        this.disableText = false;
        if (!this.message) {
          this.isDisabled = true;
        }
      }
    },
    showChatFile(val) {
      if (!val) {
        URL.revokeObjectURL(this.viewFile);
        this.fileData = "";
      }
    },
  },
  methods: {
    handleBack() {
      this.$store.state.opened = 0;
      this.$store.state.chatStatus = "all";
    },

    getContentObject(message) {
      if (message.from_type == "App\\User") {
        return "rightContent";
      } else {
        return "leftContent";
      }
    },

    getClassObject(message) {
      if (message.from_type == "App\\User") {
        return "rightMassage";
      } else {
        return "leftMassage";
      }
    },

    getDateObject(message) {
      if (message.from_type != "App\\User") {
        return "dateFormat";
      }
    },

    reply(chat) {
      const reply = {
        senderImage: "https://example.com/john-doe.jpg",
        content: this.replyMessage,
        date: new Date().toISOString().split("T")[0],
      };
      chat.messages.push(reply);
      this.replyMessage = ""; // Clear the reply input field
    },
    get_chat(room, room_id) {
      this.chats = [];
      axiosInstance
        .get(`/customerRequest/${room.UserId}`)
        .then((res) => {
          this.current_room.RequestId = res.data.payload.req_id;
        })
        .catch((err) => this.showPopup("error", err.response.data.payload.message));
      // Make sure to change messages & rooms to messages & rooms when going live
      let chat_ref = firebase
        .firestore()
        .collection("messages")
        .where("roomId", "==", `${room_id}`);
      chat_ref.onSnapshot((res) => {
        // this.emptyNavAction();
        this.chats = [];
        res.forEach((doc) => {
          if (doc.data().message_type != "transferring") {
            let msg_exist = this.chats.filter((msg) => {
              return msg["created_at"] == doc.data().created_at;
            });

            if (msg_exist.length == 0) {
              this.chats.push(doc.data());
            }
          }
        });
        this.chats.forEach((chat) => {
          if (chat.file) {
            const fileType = chat.file.split(".")[1];
            const imgTypes = [
              "jpg",
              "jpeg",
              "png",
              "apng",
              "avif",
              "jfif",
              "pjpeg",
              "pjp",
            ];
            imgTypes.forEach((type) => {
              if (fileType == type) {
                chat.fileType = "image";
              }
            });
          }
        });
        this.chats.sort((a, b) => {
          if (a.created_at < b.created_at) return -1;
          if (a.created_at > b.created_at) return 1;
          return 0;
        });
        setTimeout(() => {
          var container = document.getElementById("container2");
          if (container != null) {
            container.scrollTop = container.scrollHeight;
          }
        }, 5);
      });
      this.readChatMsgs(room_id);
      /*setTimeout(() => {
        var container = this.$el.querySelector("#container2");
            container.scrollTop = container.scrollHeight;
      }, 10);
      setTimeout(() => {
          var container = document.getElementById("container2");
          container.scrollTop = container.scrollHeight;
        }, 5);*/
    },
    readChatMsgs(room_id) {
      let chat_ref = firebase
        .firestore()
        .collection("messages")
        .where("roomId", "==", `${room_id}`);
      const unSub = chat_ref.onSnapshot((res) => {
        res.forEach((doc) => {
          if (doc.data().senderId != this.agent["agent_id"]) {
            if (doc.data().is_read == 0) {
              doc.ref.update({ is_read: 1 });
            }
          }
        });
        // this.emptyNavAction();
        unSub();
      });
    },
    isMe(chat) {
      return chat.from_type == "App\\User";
    },
    format_date(timestamp) {
      return moment(parseInt(timestamp)).format("HH:mm DD-MM-YYYY");
    },
    async send_message() {
      if (!this.isDisabled) {
        this.isDisabled = true;
        const id = JSON.parse(
          document.cookie
            .split(";")
            .map((coki) => coki.split("="))
            .reduce(
              (acc, [key, val]) => ({
                ...acc,
                [key.trim()]: decodeURIComponent(val),
              }),
              {}
            )["wsataJwt"]
        )["agent_id"];
        const name = JSON.parse(
          document.cookie
            .split(";")
            .map((coki) => coki.split("="))
            .reduce(
              (acc, [key, val]) => ({
                ...acc,
                [key.trim()]: decodeURIComponent(val),
              }),
              {}
            )["wsataJwt"]
        )["agent_name"];
        const msg = this.message;
        ///////////
        let fileRes = "";
        // هنا هنشوف لو اللي هيتبعت ملف هنخلي التكست فاضي و نبعت الملف في فايل باراميتر و نغير نوع المسج ل فايل
        if (this.fileData) {
          this.sending = true;
          fileRes = await this.sendFile();
          this.deleteFile();
        }

        const currentTime = Date.now().toString();
        const message_info = {
          created_at: currentTime,
          fileId: fileRes ? fileRes.id : "",
          file: fileRes ? fileRes.file_name : "", //here
          from_type: "App\\User",
          is_read: 0,
          message_type: fileRes ? "file" : "text", //file
          receiverId: this.current_room.UserId,
          roomId: this.current_room_id,
          senderId: id,
          targeted_device_token: "",
          text: msg, //""
          to_type: "App\\Customer",
          updated_at: currentTime,
          userName: name,
          fileUrl: fileRes ? fileRes.file_url : "",
        };

        // Update Room Last Msg Update
        await firebase
          .firestore()
          .collection("rooms")
          .doc(this.current_room_id)
          .update({ LastMessageTime: currentTime });
        // Update Room Last Msg Update

        await firebase
          .firestore()
          .collection("messages")
          .doc(Date.now().toString())
          .set(message_info);

        this.message = "";
        this.fileData = "";
        this.sending = false;
        this.get_chat(this.current_room, this.current_room_id);
        axiosInstance
          .get(`/agent/AgentReplay/${this.current_room.RequestId}`)
          .then((res) => {
            if (this.$route.query.customer_name) {
              this.$router.push({ name: "chat_component" });
            }
            this.readChatMsgs();
          })
          .catch((err) => {
            this.showPopup("error", err.response.data.message);
          });
      }
    },
    selectFile(e) {
      e.target.files[0] ? (this.fileData = e.target.files[0]) : (this.fileData = "");
    },
    async sendFile() {
      let data = "";
      const formData = new FormData();
      formData.append("chat_file", this.fileData);
      formData.append("customer_id", this.current_room.UserId);
      await axiosInstance.post(`/agent/chat/file`, formData).then((res) => {
        data = res.data.payload;
      });
      return data;
    },
    async openFile(fileName) {
      await fetch(
        `${this.$store.state.url}/agent/download/chat/file/${fileName}`,
        { headers: this.request_headers() }
      )
        .then((res) => res.blob())
        .then((data) => {
          const link = document.createElement("a");
          let urlCreator = window.URL || window.webkitURL;
          let fileUrl = urlCreator.createObjectURL(data);
          link.href = fileUrl;
          link.setAttribute("target", "_blank");
          document.documentElement.appendChild(link);
          link.click();
          link.remove();
          URL.revokeObjectURL(fileUrl);
        });
    },
    async downloadFile(fileName) {
      await fetch(
        `${this.$store.state.url}/agent/download/chat/file/${fileName}`,
        { headers: this.request_headers() }
      )
        .then((res) => res.blob())
        .then((data) => {
          const tempUrl = URL.createObjectURL(data);
          const theLink = document.createElement("a");
          theLink.href = tempUrl;
          theLink.download = fileName;
          document.documentElement.appendChild(theLink);
          theLink.click();
          theLink.remove();
          URL.revokeObjectURL(tempUrl);
        })
        .catch((err) => this.showPopup("error", "فشل عملية التحميل"));
    },
    async attachFile(fileId) {
      await axiosInstance
        .get(`/agent/Add/File/Document/${fileId}`)
        .then((res) => this.showPopup("success", res.data.message))
        .catch((err) => this.showPopup("error", err.response.data.message));
    },
    deleteFile() {
      this.fileData = "";
      document.querySelectorAll("input[type='file']").forEach((inp) => {
        inp.value = "";
      });
    },
  },
  mounted() {
    this.current_room = this.$store.state.details_active_room;
    this.current_room_id = this.$store.state.details_active_room_id;
    this.get_chat(
      this.$store.state.details_active_room,
      this.$store.state.details_active_room_id
    );
  },
};
</script>
<style>
#container2 {
  margin-bottom: 45px;
}
.v-menu {
  z-index: 15;
}
</style>
