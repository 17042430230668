<template>
  <div>
    <v-btn
      @click="dialog = true"
      fab
      class="mx-2 pa-0 genaralIcons"
      small
      style="left: 20px"
      :color="$vuetify.theme.themes.light.BtnBKColor"
      depressed
      width="35"
      height="35"
      title="بحث"
    >
      <SearchIcon />
    </v-btn>

    <v-btn
      @click="dialogOfCreating = true"
      fab
      class="mx-2 pa-0 genaralIcons"
      small
      style="left: 80px;"
      :color="$vuetify.theme.themes.light.BtnBKColor"
      depressed
      width="35"
      height="35"
      title="اضافة تنبيه"
    >
      <v-icon left style="color: white; font-size: 27px;margin: 0">mdi-plus</v-icon>
    </v-btn>

    <!-- search -->
    <v-dialog v-model="dialog" persistent width="60%">
      <v-card style="padding: 10px">
        <v-card-title>البحث</v-card-title>
        <v-container>
          <v-row>
            <v-col cols="12" sm="12" md="12">
<!--              <v-autocomplete
                :items="['غير نشط', 'نشط']"
                label="حالة الاعدادات"
                v-model="active_or_not"
                outlined
                dense
              >
                <template v-slot:item="{ item, attrs, on }">
                  <v-list-item v-bind="attrs" v-on="on">
                    <v-list-item-title
                      :id="attrs['aria-labelledby']"
                      v-text="item"
                    ></v-list-item-title>
                  </v-list-item>
                </template>
              </v-autocomplete>-->
              قريبا
            </v-col>
          </v-row>
        </v-container>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue-darken-1" variant="text" @click="dialog = false">
            الغاء
          </v-btn>
          <v-btn color="blue-darken-1" variant="text" @click="handleSearch">
            بحث
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- add new mortgage settings -->

    <v-dialog v-model="dialogOfCreating" persistent width="60%">
      <v-card style="padding: 10px">
        <v-card-title>أضافة تنبيه</v-card-title>
        <v-container>
          <v-row>
            <v-col cols="12" sm="12" md="12" class="py-0">
              <v-text-field
                  label="المحتوي "
                  v-model="content"
                  outlined
                  dense
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" class="py-0" >
              <label class="mr-2">تاريخ التعميم</label>
              <PickerInput ref="targetStartDate" label="تاريخ التعميم" @dateValue="targetStartDate($event)" />
            </v-col>
            <v-col cols="12" sm="6" md="6" class="py-0">
              <span  class="mr-2 mb-2"> حالة التعميم</span>

              <v-autocomplete
                :items="['غير نشط', 'نشط']"
                label="حالة التعميم"
                v-model="status"
                outlined
                dense
              >
                <template v-slot:item="{ item, attrs, on }">
                  <v-list-item v-bind="attrs" v-on="on">
                    <v-list-item-title
                      :id="attrs['aria-labelledby']"
                      v-text="item"
                    ></v-list-item-title>
                  </v-list-item>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="12" md="12"  class="py-0">
              <label
                  style="font-size: 18px; color: #0000009c"

              >
                ارفاق ملف
              </label>
              <v-file-input
                  chips
                  class="mt-2"
                  label="قم باختيار الملف"
                  outlined
                  v-model="files"
                  accept="image/*,video/*"
              ></v-file-input>
            </v-col>
            <v-col cols="12" sm="12" md="12" class="py-0">
              <div class="selectInput">
                <div class=""> المسمى الوظيفي</div>
              <v-combobox
                  :items="allRoles"
                  v-model="selectedRoles"
                  label=" المسمى الوظيفي"
                  class="my-0"
                  item-text="name"
                  item-value="value"
                  multiple
                  solo
              >
                <template v-slot:selection="{ attrs, item, parent, selected }">
                  <v-chip
                      v-if="item === Object(item)"
                      v-bind="attrs"
                      :color="`${item.color} lighten-3`"
                      :input-value="selected"
                      label
                      small
                  >
                  <span class="pr-2">
                    {{ item.name }}
                  </span>
                    <v-icon small @click="parent.selectItem(item)"> $delete </v-icon>
                  </v-chip>
                </template>
              </v-combobox>
<!--              @change="getSelectedAreas" v-model=""                   @selectedValues="getSelectedAreas"
            --></div>
            </v-col>
            <v-col cols="12" sm="12" md="12" class="py-0">
              <div class="selectInput">
                <div class=""> مدير المبيعات</div>
                <v-combobox
                    :items="allManagers"
                    v-model="selectedManagers"
                    label="مدير المبيعات"
                    class="my-0"
                    item-text="name"
                    item-value="id"
                    multiple
                    solo
                >
                  <template v-slot:selection="{ attrs, item, parent, selected }">
                    <v-chip
                        v-if="item === Object(item)"
                        v-bind="attrs"
                        :color="`${item.color} lighten-3`"
                        :input-value="selected"
                        label
                        small
                    >
                  <span class="pr-2">
                    {{ item.name }}
                  </span>
                      <v-icon small @click="parent.selectItem(item)"> $delete </v-icon>
                    </v-chip>
                  </template>
                </v-combobox>
              </div>
            </v-col>
            <v-col cols="12" sm="12" md="12" class="py-0">
              <div class="selectInput">
                <div class=""> المستخدمين</div>
                <v-combobox
                    :items="allUsers"
                    v-model="selectedUsers"
                    label="المستخدمين"
                    class="my-0"
                    item-text="name"
                    item-value="id"
                    multiple
                    solo
                >
                  <template v-slot:selection="{ attrs, item, parent, selected }">
                    <v-chip
                        v-if="item === Object(item)"
                        v-bind="attrs"
                        :color="`${item.color} lighten-3`"
                        :input-value="selected"
                        label
                        small
                    >
                  <span class="pr-2">
                    {{ item.name }}
                  </span>
                      <v-icon small @click="parent.selectItem(item)"> $delete </v-icon>
                    </v-chip>
                  </template>
                </v-combobox>
              </div>
            </v-col>
          </v-row>
        </v-container>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="dialogOfCreating = false"
          >
            الغاء
          </v-btn>
          <v-btn color="blue-darken-1" variant="text" @click="addNewAlert">
            اضافة
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- edit  mortgage settings -->
    <v-dialog
      v-model="$store.state.open_edit_dialog_announce"
      persistent
      width="60%"
    >
      <v-card style="padding: 10px">
        <v-card-title>تعديل التعميم</v-card-title>
        <v-container>
          <v-row>
            <v-col cols="12" sm="12" md="12" class="py-0">
              <v-text-field
                  label="المحتوي "
                  v-model="edited_announce.content"
                  outlined
                  dense
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" class="py-0" >
              <label class="mr-2">تاريخ التعميم</label>
              <v-text-field
                  type="date"
                  hide-details
                  :min="minDate"
                  v-model="edited_announce.end_at"
                  class="mr-1"
                  outlined
                  dense
                  append-icon
              >
                <template v-slot:append>
                  <svg
                      id="calendar"
                      xmlns="http://www.w3.org/2000/svg"
                      width="17.396"
                      height="16.989"
                      viewBox="0 0 17.396 16.989"
                      style="margin-top: 4px"
                  >
                    <path
                        id="Path_2784"
                        data-name="Path 2784"
                        d="M19.1,24.906H6.714a2.5,2.5,0,0,1-2.5-2.5V16.412a.626.626,0,0,1,1.252,0V22.4a1.252,1.252,0,0,0,1.252,1.252H19.1A1.252,1.252,0,0,0,20.354,22.4V11.814A1.252,1.252,0,0,0,19.1,10.562H6.714a1.252,1.252,0,0,0-1.252,1.252V14.28a.626.626,0,0,1-1.252,0V11.814a2.5,2.5,0,0,1,2.5-2.5H19.1a2.5,2.5,0,0,1,2.5,2.5V22.4A2.5,2.5,0,0,1,19.1,24.906Z"
                        transform="translate(-4.21 -7.917)"
                        fill="#6c757d"
                    />
                    <path
                        id="Path_2785"
                        data-name="Path 2785"
                        d="M18.476,11.849H4.836a.626.626,0,1,1,0-1.252h13.64a.626.626,0,0,1,0,1.252ZM9.23,8.9A.626.626,0,0,1,8.6,8.275V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,9.23,8.9Zm7.355,0a.626.626,0,0,1-.626-.626V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,16.585,8.9Z"
                        transform="translate(-4.21 -4.86)"
                        fill="#6c757d"
                    />
                  </svg>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" class="py-0">
              <span  class="mr-2 mb-2"> حالة التعميم</span>

              <v-autocomplete
                  :items="['غير نشط', 'نشط']"
                  label="حالة التعميم"
                  v-model="edited_announce.status"
                  outlined
                  dense
              >
                <template v-slot:item="{ item, attrs, on }">
                  <v-list-item v-bind="attrs" v-on="on">
                    <v-list-item-title
                        :id="attrs['aria-labelledby']"
                        v-text="item"
                    ></v-list-item-title>
                  </v-list-item>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="12" md="12"  class="py-0">
              <label
                  style="font-size: 18px; color: #0000009c"

              >
                ارفاق ملف
              </label>
              <v-file-input
                  chips
                  class="mt-2"
                  label="قم باختيار الملف"
                  outlined
                  v-model="files"
                  accept="image/*,video/*"
              ></v-file-input>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <div class="selectInput">
                <div class="my-5"> المسمى الوظيفي</div>
                <v-combobox
                    :items="userType"
                    label=" المسمى الوظيفي"
                    v-model="edited_announce.roles"
                    class="my-0"
                    item-text="name"
                    item-value="value"
                    multiple
                    solo
                >
                  <template v-slot:selection="{ attrs, item, parent, selected }">
                    <v-chip
                        v-if="item === Object(item)"
                        v-bind="attrs"
                        :color="`${item.color} lighten-3`"
                        :input-value="selected"
                        label
                        small
                    >
                  <span class="pr-2">
                    {{ item.name }}
                  </span>
                      <v-icon small @click="parent.selectItem(item)"> $delete </v-icon>
                    </v-chip>
                  </template>
                </v-combobox>
              </div>
            </v-col>

            <v-col cols="12" sm="12" md="12" class="py-0">
              <div class="selectInput">
                <div class="my-5"> مدير المبيعات</div>
                <v-combobox
                    :items="allManagers"
                    label=" مدير المبيعات"
                    v-model="edited_announce.managers"
                    class="my-0"
                    item-text="name"
                    item-value="id"
                    multiple
                    solo
                >
                  <template v-slot:selection="{ attrs, item, parent, selected }">
                    <v-chip
                        v-if="item === Object(item)"
                        v-bind="attrs"
                        :color="`${item.color} lighten-3`"
                        :input-value="selected"
                        label
                        small
                    >
                  <span class="pr-2">
                    {{ item.name }}
                  </span>
                      <v-icon small @click="parent.selectItem(item)"> $delete </v-icon>
                    </v-chip>
                  </template>
                </v-combobox>
              </div>

            </v-col>
            <v-col cols="12" sm="12" md="12" class="py-0">
              <div class="selectInput">
                <div class="my-5">المستخدمين</div>
                <v-combobox
                    :items="allUsers"
                    label="المستخدمين"
                    v-model="edited_announce.users"
                    class="my-0"
                    item-text="name"
                    item-value="id"
                    multiple
                    solo
                >
                  <template v-slot:selection="{ attrs, item, parent, selected }">
                    <v-chip
                        v-if="item === Object(item)"
                        v-bind="attrs"
                        :color="`${item.color} lighten-3`"
                        :input-value="selected"
                        label
                        small
                    >
                  <span class="pr-2">
                    {{ item.name }}
                  </span>
                      <v-icon small @click="parent.selectItem(item)"> $delete </v-icon>
                    </v-chip>
                  </template>
                </v-combobox>
              </div>

            </v-col>
          </v-row>
        </v-container>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="$store.state.open_edit_dialog_announce = false"
          >
            الغاء
          </v-btn>
          <v-btn color="blue-darken-1" variant="text" @click="updateSetting">
            تعديل
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<style>
.v-text-field {
  padding-top: 12px !important;
  margin-top: 4px;
}
</style>
<script>
import PickerInput from "@/components/Charts/PickerInputForUpComming.vue";

import { SearchIcon } from "@/assets/adminIcons/searchIcon";
import { axiosInstance } from "@/axios/config";
export default {
  components: {
    SearchIcon,
    PickerInput
  },
  data() {
    return {
      dialog: false,
      minDate:new Date().getFullYear() +
          "-" +
          ("0" + (new Date().getMonth() + 1)).slice(-2) +
          "-" +
          ("0" + new Date().getDate()).slice(-2),
      allManagers: [],
      allUsers: [],
      userType: [],
      userData: [],
      selectedRoles:[],
      selectedManagers: [],
      selectedUsers: [],


      dialogOfCreating: false,
      end_at: new Date().getFullYear() +
          "-" +
          ("0" + (new Date().getMonth() + 1)).slice(-2) +
          "-" +
          ("0" + new Date().getDate()).slice(-2),
      end_edit_at: "",

      content: "",
      status: "",
      edited_status: "",

      files: "",
      edited_announce: {},
      allRoles: [
        {
          'id'   : 1,
          'name' : 'استشاري مبيعات',
          'value' : 0,
        },
        {
          'id'   : 2,
          'name' : 'مدير مبيعات',
          'value' : 1,
        },
        {

          'id'   : 3,
          'name' : 'استشاري تمويل',
          'value' : 2,
        },
        {

          'id'   : 4,
          'name' : 'مدير الرهن',
          'value' : 3,
        },

        {
          'id'   : 5,
          'name' : 'مدير عام',
          'value' : 4,
        },
        {

          'id'   : 6,
          'name' : 'موظف جوده',
          'value' : 5,
        },
        {

          'id'   : 7,
          'name' : 'متعاون',
          'value' : 6,
        },
        {
          'id'   : 8,
          'name' : 'مدير النظام',
          'value' : 7,
        },
        {
          'id'   : 9,
          'name' : 'محاسب',
          'value' : 8,
        },
        {
          'id'   : 10,
          'name' : 'مدير الجوده',
          'value' : 9,
        },
        {
          'id'   : 11,
          'name' : 'متدرب',
          'value' : 11,
        },
        {
          'id'   : 12,
          'name' : 'مسؤل موارد بشريه',
          'value' : 12,
        },
        {
          'id'   : 13,
          'name' : 'موظف بنك',
          'value' : 13,
        },
        {
          'id'   : 14,
          'name' : 'مشرف عقارات',
          'value' : 14,
        }
      ],

      today:

          new Date().getFullYear() +
          "-" +
          ("0" + (new Date().getMonth() + 1)).slice(-2) +
          "-" +
          ("0" + new Date().getDate()).slice(-2),
      edited_roles: [],
      edited_managers: [],
      edited_users: [],
      mortgageSettingItems:[],
    };
  },

  watch: {
    "$store.state.id_of_announce": {
      handler() {
        this.openSettingToUpdate();
      },
    },
  },

  methods: {
    findRoleByName(name) {
      // Use Array.prototype.find to search for the role by name
      const role = this.allRoles.find(role => role.name === name);

      // If a role is found, return its id and value
      if (role) {
        return {
          id: role.id,
          value: role.value,
          name: role.name,
        };
      }

      // If no role is found, return null or a default value
      return null;
    },
    handleSearch() {
      this.dialog = false;
      const body = new FormData();

      if (this.active_or_not === "نشط") {
        body.append("active_or_not", 1);
      } else if (this.active_or_not === "غير نشط") {
        body.append("active_or_not", 0);
      }

      axiosInstance
        .post(`/employee/admin/announcements/all`, body)
        .then((res) => {
          this.$emit("filterData", res.data.payload);
        });
    },


    targetStartDate(startDate) {
      this.end_at = startDate;
    },
    targetEditDate(startDate) {
      this.end_edit_at = startDate;
    },

    getSelectedValueManger(selectedValue) {
      this.selectedManagers = selectedValue;
    },
    getSelectedValueUsers(selectedValue) {
      this.selectedUsers = selectedValue;
    },
    getSelectedValueRoles(selectedValue) {
      this.selectedRoles = selectedValue;
    },
    addNewAlert() {
      const body = new FormData();

      body.append("content", this.content);
      body.append("attachment", this.files);
      body.append("end_at", this.end_at);

      if (this.selectedRoles.length) {
        this.selectedRoles.forEach((item, index) => {
          this.userData[`roles[${index}]`] = item["id"];
        });
      }
      if (this.selectedUsers.length) {
        this.selectedUsers.forEach((item, index) => {
          this.userData[`users[${index}]`] = item["id"];
        });
      }
      if (this.selectedManagers.length) {
        this.selectedManagers.forEach((item, index) => {
          this.userData[`managers[${index}]`] = item["id"];
        });
      }
      const data = this.userData;
      for (const key in data) {
        if (data[key] !== "" && data[key] !== undefined && data[key] !== null) {
         // body[key] = data[key];
          body.append(key, data[key]);
        }
      }

     /* body.append("roles", this.userData["roles"]);
      body.append("users", this.selectedUsers);
      body.append("managers", this.selectedManagers);
*/
      if (this.status === "نشط") {
        body.append("status", 1);
      } else if (this.status === "غير نشط") {
        body.append("status", 0);
      }


      axiosInstance
        .post(`/employee/admin/announcements/addNewAnnounce`, body)
        .then((res) => {
          this.dialogOfCreating = false;
          this.$emit("alertIsAdded");
          this.showPopup("success", res.data.message);
          this.end_at = "";
          this.attachment = "";

          this.status = "";
          this.content = "";

          this.selectedRoles = [];
          this.selectedUsers = [];
          this.selectedManagers = [];
        })
        .catch((err) => {
          this.showPopup("error", err.response.data.message);
        });
    },

    openSettingToUpdate() {
      axiosInstance
        .get(
          `/employee/admin/announcements/editAnnounce/${this.$store.state.id_of_announce}`,
          { headers: this.request_headers() }
        )
        .then((res) => {
          this.edited_announce ={};

          this.edited_announce = res.data.payload;
          if (this.edited_announce.status == 1){
            this.edited_announce.status="نشط";

          }else{
            this.edited_announce.status="غير نشط";
          }
          this.targetEditDate(res.data.payload.end_at)
          if (this.edited_announce.roles) {
            this.edited_announce.roles = this.edited_announce.roles.map((item) => {
              if(item["role"] != "غير معروف"){
                if ( this.findRoleByName(item["role"]) != null){
                  item["id"] = this.findRoleByName(item["role"]).value;
                  item["color"] = "red";
                  item["text"] = this.findRoleByName(item["role"]).name;
                  item["name"] = this.findRoleByName(item["role"]).name;
                  return item;
                }

              }

            });
          }
          if (this.edited_announce.users) {
            this.edited_announce.users = this.edited_announce.users.map((item) => {
              if (item["name"] != "") {
                item["value"] = item["user_id"];
                item["id"] = item["user_id"];
                item["color"] = "red";
                item["text"] = item["name"]
                return item;
              }
            });
          }
          if (this.edited_announce.managers) {
            this.edited_announce.managers = this.edited_announce.managers.map((item) => {
              item["value"] = item["user_id"];
              item["id"] = item["user_id"];
              item["color"] = "red";
              item["text"] = item["name"]
              return item;
            });
          }

        })
        .catch((err) => {
          this.showPopup("error", err.response.data.message);
        });
    },

    getWorkName(){
      axiosInstance.get(`/employee/admin/announcements/allRoles`).then((res) => {

        this.userType = res.data.payload.map((item) => {
          item["color"] = "red";
          item["text"] = item["name"];
          item["id"] = item["value"];

          return item;
        });
      });
    },
    getManagersName(){
      axiosInstance.get(`/employee/admin/announcements/allManagers`).then((res) => {

        this.allManagers = res.data.payload.map((item) => {
          item["color"] = "red";
          item["text"] = item["name"];
          return item;
        });
      });
    },
    getAllUsersName(){
      axiosInstance.get(`/employee/admin/announcements/allUsers`).then((res) => {

        this.allUsers = res.data.payload.map((item) => {
          item["color"] = "red";
          item["text"] = item["name"];
          return item;
        });
      });
    },
    updateSetting() {
      const body = new FormData();
      body.append("content", this.edited_announce.content);
      body.append("end_at", this.end_edit_at);
      if (this.edited_announce.status === "نشط") {
        body.append("status", 1);
      } else if (this.edited_announce.status === "غير نشط") {
        body.append("status", 0);
      }
      console.log(this.edited_announce.roles)
      console.log(this.edited_announce.users)
      if (this.edited_announce.roles) {
        if (this.edited_announce.roles.length) {
          this.edited_announce.roles.forEach((item, index) => {
            if (item != undefined){
              this.userData[`roles[${index}]`] = item["id"];

            }
          });
        }
      }
      if (this.edited_announce.users) {
        if (this.edited_announce.users.length) {
          this.edited_announce.users.forEach((item, index) => {
            if (item != undefined) {
              this.userData[`users[${index}]`] = item["id"];
            }
          });
        }
      }
      if (this.edited_announce.managers) {
        if (this.edited_announce.managers.length) {
          this.edited_announce.managers.forEach((item, index) => {
            if (item != undefined) {
              this.userData[`managers[${index}]`] = item["id"];
            }
          });
        }
      }
      const data = this.userData;
      for (const key in data) {
        if (data[key] !== "" && data[key] !== undefined && data[key] !== null) {
          // body[key] = data[key];
          body.append(key, data[key]);
        }
      }

      axiosInstance
        .post(`/employee/admin/announcements/updateAnnounce/${this.$store.state.id_of_announce}`, body)
        .then((res) => {
          this.$store.state.open_edit_dialog_announce = false;
          this.$emit("alertIsAdded");
          this.showPopup("success", res.data.message);
          this.end_at = "";
          this.attachment = "";

          this.status = "";
          this.content = "";

          this.selectedRoles = [];
          this.selectedUsers = [];
          this.selectedManagers = [];
        })
        .catch((err) => {
          this.showPopup("error", err.response.data.message);
        });
    },
  
  },

  mounted() {
    this.getWorkName();
    this.getManagersName();
    this.getAllUsersName();
  },
};
</script>

<style>
.genaralIcons {
  position: absolute;
  top: -50px;
  min-width: unset;
  z-index: 5;
}
</style>

