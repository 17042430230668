<template>
  <page-component style="position: relative">
    <template #content>
      <!-- بيانات العميل -->
      <v-sheet class="px-5 py-5">
        <span class="font-weight-bold" style="display: inline"
          >مرفقات الطلب</span
        >
        <v-row class="mt-6">
          <v-col cols="12" class="my-2">
            <div class="mb-1">اسم الملف</div>
            <v-text-field
              :disabled="!for_agent"
              color=""
              hide-details
              v-model="file.name"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="my-2">
            <div
              v-if="file.attachment == null"
              class="mt-4"
              style="border: 2px dashed #ced4da; height: 134px"
            >
              <v-file-input
                :disabled="!for_agent"
                v-model="file.attachment"
                outlined
                class="mt-6 d-flex align-center justify-center file-inp"
                hide-input
                prepend-icon="mdi-cloud-upload-outline"
              >
              </v-file-input>
              <div class="d-flex align-center justify-center">
                <div>انقر للتحميل</div>
              </div>
            </div>
            <div class="d-flex align-center justify-center">
              <v-icon
                v-if="file.attachment != null"
                @click="file.attachment = null"
                class="ml-2"
                >mdi-close-circle</v-icon
              >
              <div :key="key" v-if="file.attachment != null">
                {{ file.attachment.name }}
              </div>
            </div>
            <div
              class="mt-5 d-flex align-center justify-center"
              v-if="file.attachment != null"
            >
              <v-btn
                color="primary"
                @click="upload_file"
                :disabled="!for_agent"
                :loading="btnLoadiing"
                >رفع الملف</v-btn
              >
            </div>
          </v-col>
        </v-row>
        <v-row
          style="background-color: #f8f9fa; border: 1px solid #eff2f7"
          class="mx-0 file-info-row"
        >
          <v-progress-linear
            color="primary"
            :indeterminate="loading"
            reverse
          ></v-progress-linear>
          <v-col cols="3">اسم المستخدم</v-col>
          <v-col cols="3">اسم الملف</v-col>
          <v-col cols="3">تاريخ الرفع</v-col>
          <v-col cols="3">العمليات</v-col>
        </v-row>
        <!-- Delete Popup -->
        <v-dialog v-model="delDialog" max-width="340px" class="text-center">
          <v-card class="pl-3 pb-3">
            <v-card-title class="justify-center"
              >تاكيد حذف المرفق ؟</v-card-title
            >
            <v-card-actions class="justify-center mt-3">
              <v-btn color="#d25b5b" dark @click="delDialog = false"
                >الغاء</v-btn
              >
              <v-btn color="primary" @click="deleteFile(deleteFileId)"
                >حذف</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- Delete Popup -->
        <v-row
          v-for="item in agent_data.documents"
          :key="item.id"
          class="mx-0 py-4 file-info-row"
        >
          <v-col cols="3">{{ item.user.name }}</v-col>
          <v-col cols="3">{{ item.filename }}</v-col>
          <v-col cols="3">{{ item.upload_date }}</v-col>
          <v-col cols="3 pr-4">
            <!--Download Menu-->
            <v-menu transition="slide-y-transition" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  style="margin-right: -5px !important"
                  v-bind="attrs"
                  v-on="on"
                  >mdi-dots-vertical</v-icon
                >
              </template>
              <v-list>
                <v-list-item @click="downloadFile(item.id, item.filename)">
                  <v-list-item-title>
                    <v-icon title="تحميل" style="margin-right: -3px"
                      >mdi-download</v-icon
                    >
                    تحميل المرفق
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="selectToDelete(item.id)">
                  <v-list-item-title>
                    <v-icon class="ml-1" size="18">mdi-trash-can</v-icon>
                    حذف المرفق
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <!--Download Menu-->
          </v-col>
        </v-row>
      </v-sheet>
    </template>
  </page-component>
</template>

<script>
import Axios from "axios";
export default {
  props: ["agent"],
  data() {
    return {
      agent_data: {},
      btnLoadiing: false,
      key: 0,
      loading: false,
      delDialog: false,
      deleteFileId: "",
      file: {
        name: "",
        request_id: this.$route.params.id,
        attachment: undefined,
      },
    };
  },
  created() {
    this.agent_data = this.agent;
  },
  computed: {
    for_agent() {
      return (
        this.$store.state.request_info.statusReq == 0 ||
        this.$store.state.request_info.statusReq == 1 ||
        this.$store.state.request_info.statusReq == 2 ||
        this.$store.state.request_info.statusReq == 4 || true
      );
    },
  },
  watch: {
    agent_data: {
      handler(val) {
        this.setBreadCrumbs(["الرئيسية", "الطلبات", val.customer.name]);
      },
    },
  },
  methods: {
    upload_handler(file) {
      let formData = new FormData();
      formData.append("attachment", file);
    },
    upload_file() {
      this.btnLoadiing = true;
      let formData = new FormData();
      formData.append("attachment", this.file.attachment);
      formData.append("request_id", this.file.request_id);
      formData.append("name", this.file.name);
      Axios.post(
        `${this.$store.state.url}/agent/Upload/Attachment`,
        formData,
        {
          headers: this.request_headers(),
        }
      )
        .then(() => {
          this.showPopup("success", "تم حفظ الملف بنجاح");
          this.btnLoadiing = false;
          setTimeout(() => {
            this.file.attachment = null;
            this.file.name = "";
          }, 1000);
          this.btnLoadiing = false;
          this.refresh();
        })
        .catch((err) => {
          this.showPopup("error", err.response.data.message);
          this.btnLoadiing = false;
        });
    },
    async downloadFile(fileId, fileName) {
      this.loading = true;
      await fetch(
        `${this.$store.state.url}/agent/download/Document/File/${fileId}`,
        { headers: this.request_headers() }
      )
        .then((res) => res.blob())
        .then((data) => {
          const tempUrl = URL.createObjectURL(data);
          const theLink = document.createElement("a");
          theLink.href = tempUrl;
          theLink.download = fileName;
          document.documentElement.appendChild(theLink);
          theLink.click();
          theLink.remove();
          URL.revokeObjectURL(tempUrl);
        })
        .catch((err) => this.showPopup("error", "فشل عملية التحميل"))
        .finally(() => {
          this.loading = false;
        });
    },
    selectToDelete(id) {
      this.deleteFileId = id;
      this.delDialog = true;
    },
    refresh() {
      this.$root.$emit("RD"); //like this
    },
    async deleteFile(fileId) {
      this.delDialog = false;
      this.loading = true;
      await Axios.delete(
        `${this.$store.state.url}/agent/Delete/Document/${fileId}`,
        { headers: this.request_headers() }
      )
        .then((res) => {
          this.showPopup("success", res.data.message);
          this.refresh();
        })
        .catch((err) => this.showPopup("error", err.response.data.message))
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss">
@media (max-width: 576px) {
  .file-info-row {
    div {
      font-size: 12px;
    }
    button {
      font-size: 16px !important;
    }
  }
}
</style>
