import Axios from "axios";
import * as mutations from "./types";

export default {
  [mutations.GET_WORK_SOURCES](state) {
    /*was product*/Axios.get(`${this.$store.state.url}/customer/WorkSources`, {
      headers: {
        "x-api-key":
          "WFKvB4Vjr8v5BVmxY7CDQ6ZMAU8DSHSBFKCMVpkfd5hhZyKTvnV3uh5XwxSwhnfeUbptZ7Z4XFJCR4vKXeHWGHTZ6djsYXjdAatf",
        Authorization:
          "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxNSIsImp0aSI6IjQ5ZTNiMjJkZTllNWU5OTYyOTUwNjY1OTc3MDZhYjlhZDkwZTY3NzhkNTg1OTVkNzU1MjIwOGE5OWE1Mjk5ZDBlMDEzMWI0NGE3ZDI4ZDA3IiwiaWF0IjoxNjYzMzM1NjcyLjgxODEyMDk1NjQyMDg5ODQzNzUsIm5iZiI6MTY2MzMzNTY3Mi44MTgxMjUwMDk1MzY3NDMxNjQwNjI1LCJleHAiOjE3MDAxNDIwNzIuODExMjIyMDc2NDE2MDE1NjI1LCJzdWIiOiIzMDgiLCJzY29wZXMiOlsidXNlciJdfQ.VyR2Yy1j-v4Tr2Ouo6SWKr6yCXooMiaondgB5c7_71hOhFYbReuwE1F0y0cgFzQ9l58rFXvPzpX0RSLXkIg2AWZNUxEBJSGobRXhx0HPc0_00WPhcBitvUYie0Qy_50mM35m9LaKdg1kapn99Rwg_AuRxnIuN4ZBD6jtrOs6Woc-sdAx6hJ4RoFfKVofBsGmhackkK66a3ofjBEXDKAVQDASQ7WtwjgxdsD6Bqj-n5F5iCFHTPtZ7GFM1GCDlCkBiGCrnCjLg2tcCg_qyvK3-b3Q93maAYvrpjDbRwYRv0359HWdfJP0DArAiMJB8wIeE-NUXGuZ4qU6O4iyzwzuKiWoLWOW1TRCab-JArc5st5RNG-l3_vNCGw9ED-Uk29xMnqFURDEDcbfDpH6XgGLde42R0S-x5rNWS9iw0psHI16t9xD7ITNdnWE2D1quvm3LnhS2ZkJP8PFRu2MWYRlHdzvrS-RJBlQbcwO4jNkjOmraFiOVsbxQXKmG28YFFpEwjwZD0gdIeD3i89wtmc49XTSPv6fvaTQK43u_Bi4wpd94lwE94GIgdS7f5_17wLzKnxI5QHki7yUS7c7Y7wz9OvOQt1m80GQeuhrk0IMGyU7qtlhdsze_2p6R80wDAUBx5Ty22qigfnzeYRm3a1uZFJp8JySq9UF3LHmCUCjw5A",
      },
    }).then((response) => {
      state.work_sources = response;
    });
  },
  [mutations.GET_SALARY_SOURCES](state) {
    Axios.get(`${this.$store.state.url}/customer/SalarySources`, {
      headers: {
        "x-api-key":
          "WFKvB4Vjr8v5BVmxY7CDQ6ZMAU8DSHSBFKCMVpkfd5hhZyKTvnV3uh5XwxSwhnfeUbptZ7Z4XFJCR4vKXeHWGHTZ6djsYXjdAatf",
        Authorization:
          "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxNSIsImp0aSI6IjQ5ZTNiMjJkZTllNWU5OTYyOTUwNjY1OTc3MDZhYjlhZDkwZTY3NzhkNTg1OTVkNzU1MjIwOGE5OWE1Mjk5ZDBlMDEzMWI0NGE3ZDI4ZDA3IiwiaWF0IjoxNjYzMzM1NjcyLjgxODEyMDk1NjQyMDg5ODQzNzUsIm5iZiI6MTY2MzMzNTY3Mi44MTgxMjUwMDk1MzY3NDMxNjQwNjI1LCJleHAiOjE3MDAxNDIwNzIuODExMjIyMDc2NDE2MDE1NjI1LCJzdWIiOiIzMDgiLCJzY29wZXMiOlsidXNlciJdfQ.VyR2Yy1j-v4Tr2Ouo6SWKr6yCXooMiaondgB5c7_71hOhFYbReuwE1F0y0cgFzQ9l58rFXvPzpX0RSLXkIg2AWZNUxEBJSGobRXhx0HPc0_00WPhcBitvUYie0Qy_50mM35m9LaKdg1kapn99Rwg_AuRxnIuN4ZBD6jtrOs6Woc-sdAx6hJ4RoFfKVofBsGmhackkK66a3ofjBEXDKAVQDASQ7WtwjgxdsD6Bqj-n5F5iCFHTPtZ7GFM1GCDlCkBiGCrnCjLg2tcCg_qyvK3-b3Q93maAYvrpjDbRwYRv0359HWdfJP0DArAiMJB8wIeE-NUXGuZ4qU6O4iyzwzuKiWoLWOW1TRCab-JArc5st5RNG-l3_vNCGw9ED-Uk29xMnqFURDEDcbfDpH6XgGLde42R0S-x5rNWS9iw0psHI16t9xD7ITNdnWE2D1quvm3LnhS2ZkJP8PFRu2MWYRlHdzvrS-RJBlQbcwO4jNkjOmraFiOVsbxQXKmG28YFFpEwjwZD0gdIeD3i89wtmc49XTSPv6fvaTQK43u_Bi4wpd94lwE94GIgdS7f5_17wLzKnxI5QHki7yUS7c7Y7wz9OvOQt1m80GQeuhrk0IMGyU7qtlhdsze_2p6R80wDAUBx5Ty22qigfnzeYRm3a1uZFJp8JySq9UF3LHmCUCjw5A",
      },
    }).then((response) => {
      state.salary_sources = response;
    });
  },
  [mutations.setNavbarChatNotifs](state, payload) {
    state.navbarUnreadCountNum = payload.unreadMsgs;
    state.navbarRooms = payload.rooms.slice(0, 3);
  },
  emptyNavData(state) {
    state.navbarUnreadCountNum = 0;
    state.navbarRooms = [];
  },

  SET_LOADING(state, payload) {
    console.log('state.isLoading' , payload);
    state.axios_loader = payload;
  }
};
