<template>
  <v-row>
    <div style="width: 70%; display: flex" class="filter">
      <svg style="position: absolute; z-index: 2; top: 32px; right: 19px" xmlns="http://www.w3.org/2000/svg" width="24"
        height="24" viewBox="0 0 24 24" fill="none">
        <path
          d="M19 6H5C3.89543 6 3 6.89543 3 8V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V8C21 6.89543 20.1046 6 19 6Z"
          stroke="#3F0E40" stroke-width="2" />
        <path
          d="M3 10C3 8.114 3 7.172 3.586 6.586C4.172 6 5.114 6 7 6H17C18.886 6 19.828 6 20.414 6.586C21 7.172 21 8.114 21 10H3Z"
          fill="#3F0E40" />
        <path d="M7 3V6M17 3V6" stroke="#3F0E40" stroke-width="2" stroke-linecap="round" />
        <path
          d="M10.5 12H7.5C7.22386 12 7 12.2239 7 12.5V13.5C7 13.7761 7.22386 14 7.5 14H10.5C10.7761 14 11 13.7761 11 13.5V12.5C11 12.2239 10.7761 12 10.5 12Z"
          fill="#3F0E40" />
        <path
          d="M10.5 16H7.5C7.22386 16 7 16.2239 7 16.5V17.5C7 17.7761 7.22386 18 7.5 18H10.5C10.7761 18 11 17.7761 11 17.5V16.5C11 16.2239 10.7761 16 10.5 16Z"
          fill="#3F0E40" />
        <path
          d="M16.5 12H13.5C13.2239 12 13 12.2239 13 12.5V13.5C13 13.7761 13.2239 14 13.5 14H16.5C16.7761 14 17 13.7761 17 13.5V12.5C17 12.2239 16.7761 12 16.5 12Z"
          fill="#3F0E40" />
        <path
          d="M16.5 16H13.5C13.2239 16 13 16.2239 13 16.5V17.5C13 17.7761 13.2239 18 13.5 18H16.5C16.7761 18 17 17.7761 17 17.5V16.5C17 16.2239 16.7761 16 16.5 16Z"
          fill="#3F0E40" />
      </svg>
      <v-col cols="12" sm="5" md="5">
        <DateRanageComponent @sendDate="handleRangeDate" :daysInputChangedTo="filterObj.dayNumber" />
      </v-col>

      <div style="margin-top: 13px">
        <v-text-field label="الايام" v-model="filterObj.dayNumber" style="width: 80px; margin-right: 10px; height: 48px"
          outlined dense></v-text-field>
      </div>

      <v-col cols="12" :sm="ReturnRequestResourceRoute ? 2 : 3" :md="ReturnRequestResourceRoute ? 2 : 3">
        <SelectInput @selectedValues="getSelectedValueManger" :originalItems="managerNames" label="اسم المدير" />
      </v-col>
      <v-col cols="12" :sm="ReturnRequestResourceRoute ? 2 : 3" :md="ReturnRequestResourceRoute ? 2 : 3" style="background: #f3f5f7; margin-top: 0px">
        <SelectInput @selectedValues="getSelectedValueAdviser" :originalItems="agentNames" label="اسم الاستشاري" />
      </v-col>

      <v-col cols="12" sm="2" md="2" style="background: #f3f5f7; margin-top: 0px" v-if="ReturnRequestResourceRoute" >
        <SelectInput @selectedValues="getSelectedValueCollaborator" :originalItems="collaborators" label="اسم المتعاون" />
      </v-col>
      



      <v-col cols="12" :sm="ReturnRequestResourceRoute ? 2 : 3" :md="ReturnRequestResourceRoute ? 2 : 3" style="background: #f3f5f7; margin-top: 0px" v-if="ReturnClassificationChartRoute">
        <v-autocomplete :items="allProductType" label="طلب شخصي او عقاري" v-model="filterObj.product_type" outlined
          dense>
          <template v-slot:item="{ item, attrs, on }">
            <v-list-item v-bind="attrs" v-on="on">
              <v-list-item-title :id="attrs['aria-labelledby']" v-text="item"></v-list-item-title>
            </v-list-item>
          </template>
        </v-autocomplete>
      </v-col>

      <v-col cols="12" :sm="ReturnRequestResourceRoute ? 2 : 3" :md="ReturnRequestResourceRoute ? 2 : 3">
        <div class="d-flex justify-space-between">
          <div class="d-flex">
            <v-autocomplete :items="allStatus" style="width: 180px; padding: 2px" label="الحالة"
              v-model="filterObj.status" outlined dense>
              <template v-slot:item="{ item, attrs, on }">
                <v-list-item v-bind="attrs" v-on="on">
                  <v-list-item-title :id="attrs['aria-labelledby']" v-text="item"></v-list-item-title>
                </v-list-item>
              </template>
            </v-autocomplete>

            <v-btn depressed color="primary" @click="handleFilter()"
              style="height: 48px; font-size: 16px; font-weight: 700; margin-right: 20px">
              تطبيق
            </v-btn>
          </div>
          <v-btn depressed color="primary"
            style="min-width: 40px !importent; width: 45px; height: 48px; margin-right: 10px;"
            @click="exportsToExcel()">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
              <path
                d="M20.5076 11.3992H18.5996V5.39922C18.5996 4.73922 18.0596 4.19922 17.3996 4.19922H12.5996C11.9396 4.19922 11.3996 4.73922 11.3996 5.39922V11.3992H9.49161C8.42361 11.3992 7.88361 12.6952 8.63961 13.4512L14.1476 18.9592C14.6156 19.4272 15.3716 19.4272 15.8396 18.9592L21.3476 13.4512C22.1036 12.6952 21.5756 11.3992 20.5076 11.3992ZM6.59961 23.3992C6.59961 24.0592 7.13961 24.5992 7.79961 24.5992H22.1996C22.8596 24.5992 23.3996 24.0592 23.3996 23.3992C23.3996 22.7392 22.8596 22.1992 22.1996 22.1992H7.79961C7.13961 22.1992 6.59961 22.7392 6.59961 23.3992Z"
                fill="white" />
            </svg>
          </v-btn>
        </div>
      </v-col>
    </div>
  </v-row>
</template>

<script>
import SelectInput from "@/components/Charts/SelectInput.vue";

// import TodayPickerInput from "@/components/AdminComponent/TodayPickerInput.vue";
// import PickerInputWithOutIcon from "@/components/Charts/PickerInputWithOutIcon.vue";
import Axios from "axios";
import DateRanageComponent from "@/components/Charts/DateRangeComponent.vue";
import * as XLSX from "xlsx";
import { qualityUser } from "@/mixins/qualityUser";
import { axiosInstance } from "@/axios/config";

export default {
  // components: { SearchIconBlack, SelectInput, PickerInput },
  components: { SelectInput,/*PickerInputWithOutIcon,TodayPickerInput,*/ DateRanageComponent },
  props: {
    role: {
      type: Number
    },
    page: {
      type: Number
    }
  },
  data() {
    const yesterdayDate = new Date();
    yesterdayDate.setDate(yesterdayDate.getDate() - 1); // Subtract one day

    return {
      allProductType: ["شخصي وعقاري", "طلب عقاري", "طلب شخصي"],
      newStartDate: '',

      today:

        new Date().getFullYear() +
        "-" +
        ("0" + (new Date().getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + new Date().getDate()).slice(-2),
      yesterday:
        yesterdayDate.getFullYear() +
        "-" +
        ("0" + (yesterdayDate.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + yesterdayDate.getDate()).slice(-2),
      allStatus: ["الكل", "استشاري مؤرشف", "استشاري قائم"],
      selectedAgents: [],
      selectedManagers: [],
      managerNames: [],
      agentNames: [],
      tableData: [],
      options: [],
      selectedManager: [],
      isSalesManager: this.role == 1 ? true : false,
      pageNumber: this.page == 1 ? true : false,
      mangers: [],
      agents: [],
      status: null,
      loading: false,
      range: {},
      pickerActive: false,
      filterObj: {
        endDate: "",
        startDate: "",
        status: "",
        range: "",
        dayNumber: "",
        managerId: [],
        agentId: [],
        product_type: "",
        collaborator_id:[]
      },
      collaborators:[],
      selectedCollaborators:[],
    };
  },

  watch: {
    selectedManagers(val) {
      this.selectedManager = val;
      this.filterObj.managerId = val;
      this.getMangersAndAgents("agent", this.status);
    },

    selectedAgents(val) {
      this.filterObj.agentId = val;
    },

    selectedCollaborators(val){
      this.filterObj.collaborator_id = val;
    },


    watch: {
      pageNumber(val) {
        alert(val)
      },
    },
    "filterObj.status": function (newStatus, oldStatus) {
      switch (newStatus) {
        case "استشاري قائم":
          this.status = 1;
          this.getMangersAndAgents("manager", 1);
          this.getMangersAndAgents("agent", 1);
          break;
        case "استشاري مؤرشف":
          this.status = 0;
          this.getMangersAndAgents("manager", 0);
          this.getMangersAndAgents("agent", 0);
          break;
        case "الكل":
          this.status = '';
          this.getMangersAndAgents("manager");
          this.getMangersAndAgents("agent");
          break;
        default:
          break;
      }
    },
    /*
    "filterObj.dayNumber": function (newDayNumber, oldDayNumber) {
     if(newDayNumber != oldDayNumber && newDayNumber > 0){
      const currentDate = new Date();
      const newDate = new Date();
      const new_start_date = new Date(currentDate);
      newDate.setDate(newDate.getDate() - parseInt(newDayNumber));
      this.start_date_val = currentDate.toISOString().split('T')[0];
      new_start_date.setDate(newDate.getDate() - newDayNumber + 1);
      const formattedStartDate = `${new_start_date.getFullYear()}-${(new_start_date.getMonth() + 1).
        toString().padStart(2, "0")}-${new_start_date.getDate().toString().padStart(2, "0")}`;
      this.newStartDate = formattedStartDate;
     }else{
      this.newStartDate = this.today;
     }
    },
    */
    "filterObj.dayNumber": function (newDayNumber, oldDayNumber) {
      if (newDayNumber != oldDayNumber && newDayNumber > 0) {
        const currentDate = new Date();
        const new_start_date = new Date(currentDate);
        new_start_date.setDate(new_start_date.getDate() - newDayNumber + 1);
        const formattedStartDate = `${new_start_date.getFullYear()}-${(new_start_date.getMonth() + 1).
          toString().padStart(2, "0")}-${new_start_date.getDate().toString().padStart(2, "0")}`;
        this.newStartDate = formattedStartDate;
      } else {
        this.newStartDate = this.today;
      }
    },
  },

  computed: {
    ReturnClassificationChartRoute() {
      return (
        window.location.pathname === "/admin/classification"
      );
    },

    ReturnRequestResourceRoute() {
      return (
        window.location.pathname === "/admin/charts/request-sources"
      );
    },

  },

  methods: {

    handleRangeDate(val) {
      this.filterObj.startDate = val.startDate;
      this.filterObj.endDate = val.endDate;
      console.log("this.filterObj.dayNumber", this.filterObj.dayNumber);
      if (this.filterObj.dayNumber == 1) {
        this.filterObj.dayNumber = '';
      }
    },
    togglePicker() {
      this.$refs.daterange.toggle();
    },
    handleFilter() {
      this.$emit("handleFilter", this.filterObj);
    },
    exportsToExcel() {
      this.$emit("exportsToExcel");
    },
    getSelectedValueManger(selectedValue) {
      this.selectedManagers = selectedValue;
    },

    getSelectedValueAdviser(selectedValue) {
      this.selectedAgents = selectedValue;
    },

    getSelectedValueCollaborator(selectedValue) {
      this.selectedCollaborators = selectedValue;
    },

    targetEndDate(endDate) {
      this.filterObj.endDate = endDate;
    },

    targetStartDate(startDate) {
      this.filterObj.startDate = startDate;
    },
    setDefaultValues() {
      // this.filterObj.endDate = this.$route.name == "rateAverage" ? this.today : "";
      // this.filterObj.startDate = this.$route.name == "rateAverage" ? this.today : "";
      // this.filterObj.endDate = /*this.$route.name == "rateAverage" || */ this.$route.name == "productivity-charts-v3"  ? this.today : "";
      // this.filterObj.startDate = /*this.$route.name == "rateAverage"|| */ this.$route.name == "productivity-charts-v3" ? this.today : "";

      // this.filterObj.status = '1';
      this.filterObj.status = (this.$route.name == "rateAverage" || this.$route.name == "productivity-charts-v3" || this.$route.name == "RequestCalls" || this.$route.name == "RequestsStatus" || this.$route.name == "requests-sources-charts" || this.$route.name == "admin-charts" || this.$route.name == "DailyReport") ? "استشاري قائم" : "الكل";
      // this.filterObj.status = (this.$route.name == "rateAverage" || this.$route.name == "productivity-charts" || this.$route.name == "RequestCalls" || this.$route.name == "RequestsStatus" || this.$route.name == "requests-sources-charts" || this.$route.name == "admin-charts" || this.$route.name == "RequestResourcesChart" || this.$route.name == "DailyReport") ? "استشاري قائم" : "";
      // this.filterObj.status = (this.$route.name == "rateAverage" || this.$route.name == "productivity-charts" || this.$route.name == "RequestCalls" || this.$route.name == "RequestsStatus" || this.$route.name == "requests-sources-charts" || this.$route.name == "admin-charts"  || this.$route.name == "DailyReport") ? "استشاري قائم" : "الكل";
    },

    getMangersAndAgents(role, status = "") {
      this.agentNames = [];
      let body = new FormData();

      body.append("role", role);

      if (status !== "") {
        body.append("status", status);
      }

      if (this.selectedManagers.length || localStorage["id"]) {
        const managerProperties = {};
        this.selectedManagers.forEach((item, index) => {
          body.append([`manager_id[${index}]`], item.id);
        });
        if (this.isSalesManager)
          body.append(
            [`manager_id[${this.selectedManagers.length}]`],
            localStorage["id"]
          );
      }
      Axios.post(
        `${this.$store.state.url}/employee/admin/filterUsers`,
        body,
        { headers: this.request_headers() }
      ).then((res) => {
        if (role === "manager") {
          this.managerNames = res["data"]["payload"];
          this.managerNames.map((item) => {
            item["text"] = item["name"];
            item["color"] = "red";
            return item;
          });
        } else {
          this.agentNames = res["data"]["payload"];
          this.agentNames.map((item) => {
            item["text"] = item["name"];
            item["color"] = "red";
            return item;
          });
        }
      });
    },

    get_collaborators() {
      axiosInstance
        .get(`${this.$store.state.url}/agent/Collaborators`)
        .then((response) => {
          this.collaborators = response.data.payload;
          this.collaborators.map((item) => {
            item["text"] = item["name"];
            item["color"] = "red";
            return item;
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.getting = false;
        });
    },
  },

  created() {
    this.getMangersAndAgents("manager");
    this.setDefaultValues();
    // this.handleFilter();
    this.getMangersAndAgents("agent", 1);
    this.get_collaborators();

  },
  mounted() {
    this.setDefaultValues();
    this.filterObj.dayNumber = 1;
    this.handleFilter();
    //  this.exportsToExcel();
  }
};
</script>

<style>
.filter label {
  font-size: 16px;
}

.filter .selectInput .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)>.v-input__control>.v-input__slot {
  background: #f3f5f7;
  margin-top: 0px;
}

.filter .v-text-field--outlined.v-input--dense .v-label {
  font-size: 16px;
  font-weight: 700;
  color: black;
}

.filter .v-input__slot {
  height: 48px;
}

.filter .v-btn:not(.v-btn--round).v-size--default {
  min-width: 40px !important;
}

.filter .v-text-field.v-text-field--solo .v-label {
  font-size: 16px;
  font-weight: 700;
  color: black;
}

.filter .v-input:not(.file-inp) .v-input__icon {
  visibility: visible !important;
  left: 0px;
  color: black;
}

.filter .selectInput .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)>.v-input__control>.v-input__slot {
  padding-bottom: 17px !important;
}

.filter .v-text-field.v-text-field--solo .v-label {
  top: 10px;
}
</style>
