<template>
    <div>
        <v-form>
            <v-row>
                <v-col cols="12" sm="12" md="12">
                    <v-radio-group v-model="filterType" @change="sendDataToParent">
                        <div style="display: flex">
                            <v-radio label="الملاحظة" value="الملاحظة"></v-radio>
                            <v-radio label="إسم العميل" value="إسم العميل" style="margin-right: 30px"></v-radio>
                        </div>
                    </v-radio-group>
                </v-col>

                <v-col cols="12" sm="12" md="12" style="margin-top: -15px">
                    <v-text-field :label="' جزء من ' + filterType" v-model="filterTypeParts" outlined dense
                        @blur="sendDataToParent">
                    </v-text-field>
                </v-col>

                <v-col cols="12" sm="12" md="12">
                    <v-autocomplete :items="['نعم', 'لا', 'لا يوجد']" label=" هل بالامكان البيع للعميل؟"
                        v-model="PossibleToSellToCustomer" outlined dense @change="sendDataToParent">
                        <template v-slot:item="{ item, attrs, on }">
                            <v-list-item v-bind="attrs" v-on="on">
                                <v-list-item-title :id="attrs['aria-labelledby']" v-text="item"></v-list-item-title>
                            </v-list-item>
                        </template>
                    </v-autocomplete>
                </v-col>

                <v-col cols="12" sm="12" md="12">
                    <SelectInput ref="selectInput1" @selectedValues="getSelectedValueManger"
                        :originalItems="getManagerNames" label="مدير المبيعات" />
                </v-col>

                <v-col cols="12" sm="12" md="12">
                    <SelectInput ref="selectInput24" @selectedValues="getFundingManagers"
                        :originalItems="getFundingManagersName" label="مدير التمويل" />
                </v-col>


                <v-col cols="12" sm="12" md="12" v-if="$route.name == 'administration_requests'">
                    <SelectInput ref="selectInput2" @selectedValues="getAgentBeforeSendingToAdministration"
                        :originalItems="getAgentsName" label="استشاري المبيعات" />
                </v-col>

                <v-col cols="8" sm="12" md="12" v-else>
                    <SelectInput ref="selectInput2" @selectedValues="getSelectedValueAgent"
                        :originalItems="getAgentsName" label="استشاري المبيعات" />
                </v-col>

                <v-col cols="12" sm="12" md="12">
                    <v-autocomplete :items="allStatus" label="الحالة" v-model="selectedStatusFilter"
                        @change="sendDataToParent" outlined dense>
                        <template v-slot:item="{ item, attrs, on }">
                            <v-list-item v-bind="attrs" v-on="on">
                                <v-list-item-title :id="attrs['aria-labelledby']" v-text="item"></v-list-item-title>
                            </v-list-item>
                        </template>
                    </v-autocomplete>
                </v-col>

                <v-col cols="12" sm="12" md="12">
                    <v-autocomplete :items="['الكل', 'نعم', 'لا']" label=" الموافقه علي الارشفة ؟"
                        v-model="selectedAcceptArchivedOrNot" outlined dense @change="sendDataToParent">
                        <template v-slot:item="{ item, attrs, on }">
                            <v-list-item v-bind="attrs" v-on="on">
                                <v-list-item-title :id="attrs['aria-labelledby']" v-text="item"></v-list-item-title>
                            </v-list-item>
                        </template>
                    </v-autocomplete>
                </v-col>

                <v-col cols="12" sm="12" md="12">
                    <v-autocomplete :items="['لا', 'نعم']" label="طلبات تحتوي علي مكالمات" @change="sendDataToParent"
                        v-model="isHasCalls" outlined dense>
                        <template v-slot:item="{ item, attrs, on }">
                            <v-list-item v-bind="attrs" v-on="on">
                                <v-list-item-title :id="attrs['aria-labelledby']" v-text="item"></v-list-item-title>
                            </v-list-item>
                        </template>
                    </v-autocomplete>
                </v-col>

                <v-col cols="12" sm="12" md="12">
                    <SelectInput ref="selectInput3" @selectedValues="getSelectedProductType"
                        :originalItems="productType" label="نوع المنتج" />
                </v-col>
                <v-col cols="12" sm="12" md="12">
                    <SelectInput ref="selectInput4" @selectedValues="getRequestStatus" :originalItems="requestTypeDrop"
                        label="حاله الطلب" />
                </v-col>

                <v-col cols="12" sm="12" md="12">
                    <v-autocomplete :items="noteType" label="حاله الملاحظه" @change="sendDataToParent"
                        v-model="getRequestsType" outlined dense>
                        <template v-slot:item="{ item, attrs, on }">
                            <v-list-item v-bind="attrs" v-on="on">
                                <v-list-item-title :id="attrs['aria-labelledby']" v-text="item"></v-list-item-title>
                            </v-list-item>
                        </template>
                    </v-autocomplete>
                </v-col>

                <v-col cols="12" sm="12" md="12">
                    <SelectInput ref="selectInput5" @selectedValues="getSourceWork" :originalItems="requestSource"
                        label="مصدر المعامله" />
                </v-col>

                <v-col cols="12" lg="12" md="12" class="pb-5 pt-0"
                    v-if="sourceWork.find((el) => { return el.id == 2 })">
                    <v-autocomplete placeholder="المتعاونين" label="المتعاونين" hide-details :items="collaborators"
                        class="mr-1" item-text="name" item-value="collaborator_id" @change="sendDataToParent"
                        v-model="selected_collaborators" outlined multiple dense chips deletable-chips color="primary"
                        append-icon></v-autocomplete>
                </v-col>

                <v-col cols="12" sm="12" md="12">
                    <SelectInput ref="selectInput6" @selectedValues="getTypeRequest" :originalItems="typeRequest"
                        label="نوع الطلب" />
                </v-col>

                <v-col cols="12" sm="12" md="12">
                    <SelectInput ref="selectInput7" @selectedValues="getRejectionList" :originalItems="rejection_lists"
                        label="أسباب الرفض" />
                </v-col>

                <v-col cols="12" sm="12" md="12">
                    <SelectInput ref="selectInput7" @selectedValues="getRequestDescription"
                        :originalItems="request_descriptions" label="وصف الطلب" />
                </v-col>



                <v-col cols="6" sm="6" md="6">
                    <PickerInput ref="pickerInput1" label="تاريخ الطلب من" @dateValue="saveDateFrom" />
                </v-col>

                <v-col cols="6" sm="6" md="6">
                    <PickerInput ref="pickerInput2" label="تاريخ الطلب الي" @dateValue="saveDateTo" />
                </v-col>

                <v-col cols="6" sm="6" md="6">
                    <PickerInput ref="pickerInput3" label="تاريخ الأفراغ من" @dateValue="saveCompleteDateFrom" />
                </v-col>

                <v-col cols="6" sm="6" md="6">
                    <PickerInput ref="pickerInput4" label="تاريخ الأفراع الي" @dateValue="saveCompleteDateTo" />
                </v-col>

                <v-col cols="12" lg="12" style="padding-top: 0px; margin-bottom: 15px">
                    <input type="checkbox" :checked="updatedDate" @change="handleToggle" />
                    <label for="vehicle1" style="margin-right: 10px" v-if="updatedDate">التي تم التحديث عليها</label>
                    <label for="vehicle1" style="margin-right: 10px" v-if="!updatedDate">تاريخ عدم التحديث</label>
                </v-col>

                <v-col cols="6" sm="6" md="6" v-if="updatedDate">
                    <PickerInput label="من تاريخ" @dateValue="saveUpdatedFrom" />
                </v-col>

                <v-col cols="6" sm="6" md="6" v-if="updatedDate">
                    <PickerInput label="الي تاريخ" @dateValue="saveUpdatedTo" ref="pickerInput5" />
                </v-col>

                <v-col cols="6" sm="6" md="6" v-if="!updatedDate">
                    <PickerInput label="من تاريخ" @dateValue="saveUnUpdatedTo" ref="pickerInput6" />
                </v-col>

                <v-col cols="12" sm="12" md="12">
                    <SelectInput ref="selectInput20" @selectedValues="getworkList" :originalItems="workList"
                        label="جهه العمل" />
                </v-col>

                <v-col cols="12" sm="12" md="12">
                    <SelectInput ref="selectInput8" @selectedValues="getSalaryList" :originalItems="salaryList"
                        label="جهه تزول الراتب" />
                </v-col>
                <v-col cols="12" sm="12" md="12">
                    <SelectInput ref="selectInput9" @selectedValues="getFundingSide" :originalItems="fundingSide"
                        label="جهه التمويل" />
                </v-col>

                <v-col cols="12" sm="12" md="12">
                    <SelectInput ref="selectInput21" @selectedValues="getMortgageCustomerNeeds"
                        :originalItems="customerNeeds" label="احتياجات العميل" />
                </v-col>

                <v-col cols="12" sm="12" md="12">
                    <SelectInput ref="selectInput22" @selectedValues="getMortgageCustomerObjections"
                        :originalItems="CustomerObjections" label="الاعتراضات والتحديات" />
                </v-col>

                <v-col cols="12" sm="12" md="12">
                    <SelectInput ref="selectInput23" @selectedValues="getMortgageObstacles"
                        :originalItems="CustomerObsatacles" label="المعوقات" />
                </v-col>

            </v-row>
        </v-form>
    </div>
</template>

<script>
import SelectInput from "@/components/Charts/SelectInput.vue";
import PickerInput from "@/components/AdminComponent/PickerInput.vue";
import { removeDuplicated } from "@/mixins/removeDublicated";
import { axiosInstance } from "@/axios/config";

export default {
    components: { PickerInput, SelectInput },
    data() {
        return {
            filterType: "إسم العميل",
            filterTypeParts: "",
            allStatus: ["الكل", "استشاري مؤرشف", "استشاري قائم"],
            typeRequest: [],
            allType: "",
            noteType: ["بها محتوي", "فارغه"],
            allMangerType: ["general", "gen"],
            allMangers: [],
            allAgents: [],
            allFundingManagers: [],
            selectedStatusFilter: "الكل",
            selectedAcceptArchivedOrNot: "الكل",
            selectedManger: [],
            selectedMang: [],
            selectAgent: [],
            AgentBeforeSendingToAdministration: [],
            fundingManagers: [],
            requestStatu: [],
            selectedRequestStatus: [],
            requestSource: [],
            collaborators: [],
            selected_collaborators: [],
            sourceWork: [],
            productType: [],
            selectedProductType: [],
            requestTypeDrop: [],
            selectBirthData: null,
            getRequestsType: "",
            selectedTypeRequest: [],
            requestDateFrom: "",
            requestDateTo: "",
            completeDateFrom: "",
            completeDateTo: "",
            updatedDate: false,
            updatedDateFrom: "",
            updatedDateTo: "",
            UnUpdatedDate: "",
            workList: [],
            selectedWorkList: [],
            customerNeeds: [],
            rejection_lists: [],
            request_descriptions: [],
            CustomerObjections: [],
            CustomerObsatacles: [],
            selectedCustomerNeeds: [],
            selectedRejection_lists: [],
            selectedRequest_descriptions: [],
            selectedCustomerObjections: [],
            selectedCustomerObsatacles: [],
            salaryList: [],
            selectedSalaryList: [],
            fundingSide: [],
            selectedFundingSide: [],
            isHasCalls: "",
            PossibleToSellToCustomer: "",
        };
    },

    computed: {
        getManagerNames() {
            return this.allMangers;
        },

        getAgentsName() {
            return this.allAgents;
        },

        getFundingManagersName() {
            return this.allFundingManagers;
        },

    },

    watch: {
        selectedStatusFilter(val) {
            if (val === "الكل") {
                this.getAllManger("manager");
                this.getAllAgents("agent", 1, this.selectedMang);
            } else if (val === "استشاري مؤرشف") {
                this.getAllAgents("agent", 0, this.selectedMang);
                this.getAllManger("manager ", 0);
            } else if (val === "استشاري قائم") {
                this.getAllAgents("agent", 1, this.selectedMang);
                this.getAllManger("manager ", 1);
            }
        },

        "selectedProductType": function (newVal, oldVal) {
            if (oldVal !== newVal) {
                this.get_rejection_list(newVal[0].id);
            }
        },

        "$store.state.rejection_list": {
            handler(newval, oldvalue) {
                this.selesAgentRefuseList = newval.map(item => ({
                    id: item.id,
                    text: item.title, // Renaming title to value
                    value: item.title, // Renaming title to value
                    color: "orange" // Renaming title to value
                }));

                console.log("this.selesAgentRefuseList", this.selesAgentRefuseList)
            },
        },
    },

    methods: {
        sendDataToParent() {
            this.$emit("send-request-data", {
                filterTypeParts: this.filterTypeParts,
                filterType: this.filterType,
                PossibleToSellToCustomer: this.PossibleToSellToCustomer,
                selectedMang: this.selectedMang,
                fundingManagers: this.fundingManagers,
                AgentBeforeSendingToAdministration: this.AgentBeforeSendingToAdministration,
                selectedStatusFilter: this.selectedStatusFilter,
                selectedAcceptArchivedOrNot: this.selectedAcceptArchivedOrNot,
                isHasCalls: this.isHasCalls,
                selectedProductType: this.selectedProductType,
                selectedRequestStatus: this.selectedRequestStatus,
                getRequestsType: this.getRequestsType,
                sourceWork: this.sourceWork,
                selectedTypeRequest: this.selectedTypeRequest,
                selected_collaborators: this.selected_collaborators,
                selectedRejection_lists: this.selectedRejection_lists,
                selectedRequest_descriptions: this.selectedRequest_descriptions,
                requestDateFrom: this.requestDateFrom,
                requestDateTo: this.requestDateTo,
                completeDateFrom: this.completeDateFrom,
                completeDateTo: this.completeDateTo,
                updatedDateFrom: this.updatedDateFrom,
                UnUpdatedDate: this.UnUpdatedDate,
                updatedDateTo: this.updatedDateTo,
                selectedWorkList: this.selectedWorkList,
                selectedSalaryList: this.selectedSalaryList,
                selectedFundingSide: this.selectedFundingSide,
                selectedCustomerNeeds: this.selectedCustomerNeeds,
                selectedCustomerObsatacles: this.selectedCustomerObsatacles,
                selectedCustomerObjections: this.selectedCustomerObjections,
                selectAgent: this.selectAgent,
            });
        },

        getFundingSide(val) {
            this.selectedFundingSide = val;
            this.sendDataToParent();
        },

        getSalaryList(val) {
            this.selectedSalaryList = val;
            this.sendDataToParent();
        },

        getworkList(val) {
            this.selectedWorkList = val;
            this.sendDataToParent();
        },

        getMortgageCustomerNeeds(val) {
            this.selectedCustomerNeeds = val;
            this.sendDataToParent();
        },

        getRejectionList(val) {
            this.selectedRejection_lists = val;
            this.sendDataToParent();
        },

        getRequestDescription(val) {
            this.selectedRequest_descriptions = val;
            this.sendDataToParent();
        },


        getMortgageObstacles(val) {
            this.selectedCustomerObsatacles = val;
            this.sendDataToParent();
        },

        getMortgageCustomerObjections(val) {
            this.selectedCustomerObjections = val;
            this.sendDataToParent();
        },

        saveUpdatedFrom(val) {
            this.updatedDateFrom = val;
            this.sendDataToParent();
        },

        saveUpdatedTo(val) {
            this.updatedDateTo = val;
            this.sendDataToParent();
        },

        saveUnUpdatedTo(val) {
            this.UnUpdatedDate = val;
            this.sendDataToParent();
        },

        handleToggle() {
            this.updatedDate = !this.updatedDate;
            this.sendDataToParent();
        },

        saveDateFrom(val) {
            this.requestDateFrom = val;
            this.sendDataToParent();
        },

        saveDateTo(val) {
            this.requestDateTo = val;
            this.sendDataToParent();
        },

        saveCompleteDateFrom(val) {
            this.completeDateFrom = val;
            this.sendDataToParent();
        },

        saveCompleteDateTo(val) {
            this.completeDateTo = val;
            this.sendDataToParent();
        },

        requestStatus() {
            axiosInstance.get("/agent/RequestStatus").then((response) => {
                this.requestTypeDrop = response.data.payload.map((item) => {
                    item["color"] = "red";
                    item["text"] = item["value"];
                    return item;
                });
            });
        },

        get_work_list() {
            this.workList = this.$store.state.works_list.map((item) => {
                item["text"] = item["value"];
                item["color"] = "red";
                return item;
            });
        },

        MortgageCustomerNeeds() {
            axiosInstance.get("/employee/admin/mortgage-settings-CustomerNeeds").then((res) => {
                this.customerNeeds = res.data.payload.map((item) => {
                    item["color"] = "red";
                    item["text"] = item["value"];
                    return item;
                });
            });

        },

        async get_rejection_list(productType) {
            let url = `/agent/Rejections/Reasons`;
            if (productType) {
                url = `/agent/Rejections/Reasons?productType=${productType}`
            }
            await axiosInstance.get(url).then((response) => {
                this.rejection_lists = response.data.payload.map((item) => {
                    item["color"] = "red";
                    item["text"] = item["title"];
                    return item;
                });

            });
        },

        async get_request_description() {
            let url = `/employee/admin/requestDescription`;
            await axiosInstance.post(url).then((response) => {
                this.request_descriptions = response.data.payload.data.map((item) => {
                    item["color"] = "red";
                    item["text"] = item["option"];
                    return item;
                });

            });
        },

        MortgageCustomerObjections() {
            axiosInstance.get("/employee/admin/mortgage-settings-CustomerObjections").then((res) => {
                this.CustomerObjections = res.data.payload.map((item) => {
                    item["color"] = "red";
                    item["text"] = item["value"];
                    return item;
                });
            });
        },

        MortgageCustomerObsatacles() {
            axiosInstance.get("/employee/admin/mortgage-settings-CustomerObsatacles").then((res) => {
                this.CustomerObsatacles = res.data.payload.map((item) => {
                    item["color"] = "red";
                    item["text"] = item["value"];
                    return item;
                });
            });
        },

        getRequestSource() {
            axiosInstance.get("/agent/Request/Sources").then((res) => {
                this.requestSource = res.data.payload.map((item) => {
                    item["color"] = "red";
                    item["text"] = item["value"];
                    return item;
                });
            });
        },

        get_salary_sources() {
            axiosInstance.get("/customer/SalarySources").then((res) => {
                this.salaryList = res.data.payload.map((item) => {
                    item["color"] = "red";
                    item["text"] = item["value"];
                    return item;
                });
            });
        },

        getRequestType() {
            this.requestTypeDrop = this.$store.state.request_statuses.map((item) => {
                item["color"] = "red";
                item["text"] = item["value"];
                return item;
            });
        },

        getSourceWork(val) {
            this.sourceWork = val;
            this.sendDataToParent();
            console.log("this.sourceWork", this.sourceWork, this.sourceWork.find((el) => { return el.id == 2 }));
        },
      
        getTypeRequestList() {
            let data = [
                {
                    color: "red",
                    value: "شراء",
                },
                {
                    color: "red",
                    value: "رهن",
                },
                {
                    color: "red",
                    value: "تساهيل",
                },
                {
                    color: "red",
                    value: "شراء-دفعة",
                },
                {
                    color: "red",
                    value: "رهن-شراء",
                },
            ];
            this.typeRequest = data.map((item) => {
                item["color"] = "red";
                item["text"] = item["value"];
                return item;
            });
        },

        getSelectedValueManger(val) {
            this.selectedMang = val;
            if (val.length && this.selectedStatusFilter === "استشاري مؤرشف") {
                this.getAllAgents("agent", 0, val);
            } else if (val.length && this.selectedStatusFilter === "استشاري قائم") {
                this.getAllAgents("agent", 1, val);
            } else if (val.length && this.selectedStatusFilter === "الكل") {
                this.getAllAgents("agent", 1, val);
            }
            this.sendDataToParent();
        },

        getTypeRequest(val) {
            this.selectedTypeRequest = val;
            this.sendDataToParent();
        },

        getSelectedValueAgent(val) {
            this.selectAgent = val;
            this.sendDataToParent();
        },

        getAgentBeforeSendingToAdministration(val) {
            this.AgentBeforeSendingToAdministration = val;
            this.sendDataToParent();
        },

        getFundingManagers(val) {
            this.fundingManagers = val;
            this.sendDataToParent();
        },

        getSelectedAgentType(val) {
            this.selectAgentType = val;
        },
        getSelectAgentRefused(val) {
            this.selectAgentRefused = val;
        },

        getRequestStatus(val) {
            this.selectedRequestStatus = val;
            this.sendDataToParent();
        },

        getSelectedMangerType(val) {
            this.selectedSalesManger = val;
        },

        getSelectedFundingType(val) {
            this.selectedFundingManger = val;
        },

        getMerteryType(val) {
            this.selectMortgageManger = val;
        },

        getGeneralManger(val) {
            this.selectGeneralManger = val;
        },

        getSelectedProductType(val) {
            this.selectedProductType = val;
            this.sendDataToParent();
        },

        getAllManger(role, status) {
            this.allMangers = [];
            let body = { role: role };
            if (status === 0) {
                body = {
                    status: status,
                    role: role,
                };
            }

            let url = "/employee/admin/filterUsers";
            axiosInstance.post(url, body).then((res) => {
                this.allMangers = res.data.payload;
                this.allMangers.map((item) => {
                    item["color"] = "red";
                    item["text"] = item["name"];
                    return item;
                });
            });
        },

        getAllFundingManagers(role, status) {
            this.allFundingManagers = [];
            let body = { role: role };
            if (status === 0) {
                body = {
                    status: status,
                    role: role,
                };
            }

            let url = "/employee/admin/filterUsers";
            axiosInstance.post(url, body).then((res) => {
                this.allFundingManagers = res.data.payload;
                this.allFundingManagers.map((item) => {
                    item["color"] = "red";
                    item["text"] = item["name"];
                    return item;
                });
            });
        },

        getAllAgents(role, status, val) {
            this.allAgents = [];
            let body = new FormData();
            body.append("role", role);
            if (status === 0 && !val) {
                body.append("status", status);
                body.append("role", role);
            } else if (status === 1 && !val) {
                body.append("status", status);
                body.append("role", role);
            }
            if (val) {
                val.forEach((item, index) => {
                    this.selectedManger.push(`'manager_id[${index}]': '${item["id"]}'`);
                });
            }

            let removeDublicate = removeDuplicated(this.selectedManger);

            if (removeDublicate.length) {
                val.forEach((item, index) => {
                    body.append(`manager_id[${index}]`, item["id"]);
                });
                body.append("status", status);
                body.append("role", role);
            }

            let url = "/employee/admin/filterUsers";
            axiosInstance.post(url, body).then((res) => {
                this.allAgents = res.data.payload;
                this.allAgents.map((item) => {
                    item["color"] = "red";
                    item["text"] = item["name"];
                    return item;
                });
            });
        },

        get_customer() {
            if (document.cookie !== "") {
                axiosInstance.get("/agent/RelatedCustomers").then((response) => {
                    if (this.$store.state.agent_customers) {
                        this.$store.state.agent_customers = response.data.payload;
                    }
                });
            }
        },

        get_funding_sources() {
            axiosInstance.get("/agent/Funding/Sources").then((response) => {
                this.fundingSide = response.data.payload.map((item) => {
                    item["color"] = "red";
                    item["text"] = item["value"];
                    return item;
                });
            });
        },

        get_collaborators() {
            this.getting = true;
            axiosInstance
                .get("/agent/Collaborators")
                .then((response) => {
                    this.collaborators = response.data.payload;
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    this.getting = false;
                });
        },

        getProductType() {
            let url = "/customer/SelectedProduct";
            axiosInstance.get(url).then((res) => {
                this.productType = res.data.payload.map((item) => {
                    item["color"] = "red";
                    item["text"] = item["value"];
                    return item;
                });
            });
        },
    },

    mounted() {
        this.getAllFundingManagers("fundingManager");
        this.getAllManger("manager");
        this.getAllAgents("agent");
        this.getProductType();
        this.requestStatus();
        this.getRequestSource();
        this.getRequestType();
        this.getTypeRequestList();
        this.get_work_list();
        this.MortgageCustomerNeeds();
        this.get_rejection_list();
        this.get_request_description();
        this.MortgageCustomerObjections();
        this.MortgageCustomerObsatacles();
        this.get_salary_sources();
        this.get_funding_sources();
        this.get_collaborators();
        this.selesAgentRefuseList = this.$store.state.rejection_list.map(item => ({
            id: item.id,
            text: item.title, // Renaming title to value
            value: item.title, // Renaming title to value
            color: "orange" // Renaming title to value
        }));
    },

};
</script>
