export function getClassificationTabData(store) {

    const { bodySearch, ClassificationTapData } = store.state;

    // Helper function to clear old keys from bodySearch
    const clearKeysByPrefix = (prefix) => {
        for (let key of bodySearch.keys()) {
            if (key.startsWith(prefix)) {
                bodySearch.delete(key);
            }
        }
    };

    // Helper function to update bodySearch with array values
    const updateBodySearchArray = (prefix, items) => {
        clearKeysByPrefix(prefix);
        items.forEach((item, index) => {
            bodySearch.set(`${prefix}[${index}]`, item["id"]);
        });
    };


    // Process agent classifications
    if (ClassificationTapData.selectAgentType.length > 0) {
        updateBodySearchArray('agent_classification', ClassificationTapData.selectAgentType);
    }

    if (ClassificationTapData.selectedSalesManger.length > 0) {
        updateBodySearchArray('sales_manager_classification', ClassificationTapData.selectedSalesManger);
    }

    if (ClassificationTapData.selectAgentRefused.length > 0) {
        updateBodySearchArray('rejected_ids', ClassificationTapData.selectAgentRefused);
    }

    if (ClassificationTapData.selectedFundingManger.length > 0) {
        updateBodySearchArray('funding_manager_classification', ClassificationTapData.selectedFundingManger);
    }

    if (ClassificationTapData.selectMortgageManger.length > 0) {
        updateBodySearchArray('mortgage_manager_classification', ClassificationTapData.selectMortgageManger);
    }

    if (ClassificationTapData.selectGeneralManger.length > 0) {
        updateBodySearchArray('general_manager_classification', ClassificationTapData.selectGeneralManger);
    }

    const classificationTypeMap = {
        "سلبي": 0,
        "ايجابي": 1,
        "بدون": 2,
    };

    if (Object.prototype.hasOwnProperty.call(classificationTypeMap, ClassificationTapData.allType)) {
        bodySearch.set("agent_positive_or_negative_classification", classificationTypeMap[ClassificationTapData.allType]);
    } else if (ClassificationTapData.allType === "جميع التصنيفات") {
        bodySearch.delete("agent_positive_or_negative_classification");
    }














    // const formDataKeys = Array.from(store.state.bodySearch.keys());
    // // Handle allType classification
    // const classificationTypeMap = {
    //     "سلبي": 0,
    //     "ايجابي": 1,
    //     "بدون": 2
    // };
    // if (!formDataKeys.includes("agent_positive_or_negative_classification") && store.state.ClassificationTapData.allType in classificationTypeMap) {
    //     store.state.bodySearch.append("agent_positive_or_negative_classification", classificationTypeMap[store.state.ClassificationTapData.allType]);
    // }

    // // Helper to handle arrays
    // const appendArrayItems = (dataArray, keyTemplate) => {
    //     if (dataArray.length > 0) {
    //         dataArray.forEach((item, index) => {
    //             const key = keyTemplate.replace("{index}", index);
    //             if (!formDataKeys.includes(key)) {
    //                 store.state.bodySearch.append(key, item["id"]);
    //             }
    //         });
    //     }
    // };

    // // Append agent classification types
    // appendArrayItems(store.state.ClassificationTapData.selectAgentType, "agent_classification[{index}]");
    // appendArrayItems(store.state.ClassificationTapData.selectAgentRefused, "rejected_ids[{index}]");
    // appendArrayItems(store.state.ClassificationTapData.selectedSalesManger, "sales_manager_classification[{index}]");
    // appendArrayItems(store.state.ClassificationTapData.selectedFundingManger, "funding_manager_classification[{index}]");
    // appendArrayItems(store.state.ClassificationTapData.selectMortgageManger, "mortgage_manager_classification[{index}]");
    // appendArrayItems(store.state.ClassificationTapData.selectGeneralManger, "general_manager_classification[{index}]");








    



    /*if (store.state.ClassificationTapData.allType === "سلبي") {
        store.state.bodySearch.append("agent_positive_or_negative_classification", 0);
    }

    if (store.state.ClassificationTapData.allType === "ايجابي") {
        store.state.bodySearch.append("agent_positive_or_negative_classification", 1);
    }

    if (store.state.ClassificationTapData.allType === "بدون") {
        store.state.bodySearch.append("agent_positive_or_negative_classification", 2);
    }

    if (store.state.ClassificationTapData.selectAgentType.length > 0) {
        store.state.ClassificationTapData.selectAgentType.forEach((item, index) => {
            store.state.bodySearch.append(`agent_classification[${index}]`, item["id"]);
        });
    }

    if (store.state.ClassificationTapData.selectAgentRefused.length > 0) {
        store.state.ClassificationTapData.selectAgentRefused.forEach((item, index) => {
            store.state.bodySearch.append(`rejected_ids[${index}]`, item["id"]);
        });
    }

    if (store.state.ClassificationTapData.selectedSalesManger.length > 0) {
        store.state.ClassificationTapData.selectedSalesManger.forEach((item, index) => {
            store.state.bodySearch.append(`sales_manager_classification[${index}]`, item["id"]);
        });
    }

    if (store.state.ClassificationTapData.selectedFundingManger.length > 0) {
        store.state.ClassificationTapData.selectedFundingManger.forEach((item, index) => {
            store.state.bodySearch.append(`funding_manager_classification[${index}]`, item["id"]);
        });
    }

    if (store.state.ClassificationTapData.selectMortgageManger.length > 0) {
        store.state.ClassificationTapData.selectMortgageManger.forEach((item, index) => {
            store.state.bodySearch.append(`mortgage_manager_classification[${index}]`, item["id"]);
        });
    }

    if (store.state.ClassificationTapData.selectGeneralManger.length > 0) {
        store.state.ClassificationTapData.selectGeneralManger.forEach((item, index) => {
            store.state.bodySearch.append(`general_manager_classification[${index}]`, item["id"]);
        });
    }*/
    
  }
  