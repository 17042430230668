<template>
    <div>
        <div v-if="$store.state.showSearchInputs">
            <div>
                <v-row>
                    <v-col cols="2" sm="2" md="2">
                        <v-text-field label="الهاتف" v-model="phoneNumber" @change="sendDataToParent()" outlined
                            v-numericOnly dense class="d-flex align-center custom-phone-background"></v-text-field>
                    </v-col>

                    <v-col cols="2" sm="2" md="2">
                        <SelectInput ref="selectInput1" @selectedValues="getSelectedValueManger"
                            :originalItems="getManagerNames" label="مدير المبيعات" />
                    </v-col>

                    <v-col cols="2" sm="2" md="2">
                        <SelectInput ref="selectInput2" @selectedValues="getSelectedValueAgent"
                            :originalItems="getAgentsName" label="استشاري المبيعات" />
                    </v-col>

                    <v-col cols="2" sm="2" md="2">
                        <v-autocomplete :items="allTypes" label="جميع التصنيفات" v-model="allType"
                            @change="sendDataToParent()" outlined dense class="custom-phone-background">
                            <template v-slot:item="{ item, attrs, on }">
                                <v-list-item v-bind="attrs" v-on="on">
                                    <v-list-item-title :id="attrs['aria-labelledby']" v-text="item"></v-list-item-title>
                                </v-list-item>
                            </template>
                        </v-autocomplete>
                    </v-col>

                    <v-col cols="2" sm="2" md="2">
                        <SelectInput ref="selectInput10" @selectedValues="getSelectedAgentType"
                            :originalItems="selesAgentType" label="تصنيف استشاري المبيعات" />
                    </v-col>

                    <v-col cols="2" sm="2" md="2">
                        <v-btn icon style="background-color: #451646; color: white;"  @click="startNewDesignSearch">
                            <v-icon>mdi-magnify</v-icon>
                        </v-btn>
                        <v-btn color="#4ea2a1" style="color: white;" class="mx-5 my-2"
                            @click="$store.state.showFilters = !$store.state.showFilters">
                            تصفية
                        </v-btn>
                    </v-col>
                </v-row>
            </div>
        </div>
    </div>
</template>


<script>
import SelectInput from "@/components/Charts/SelectInput.vue";
import { axiosInstance } from "@/axios/config";
import { removeDuplicated } from "@/mixins/removeDublicated";

export default {
    components: { SelectInput },
    data() {
        return {
            phoneNumber: "",
            selectedManger: [],
            selectedMang: [],
            allAgents: [],
            allMangers: [],
            selectAgent: [],
            allType: "",
            allTypes: ["جميع التصنيفات", "ايجابي", "سلبي", "بدون"],
            selectAgentType: [],
      selesAgentType: [],

        };
    },

    computed: {
        getManagerNames() {
            return this.allMangers;
        },

        getAgentsName() {
            return this.allAgents;
        },
    },

    methods: {

        startNewDesignSearch() {
            this.$emit('startNewDesignSearch');
        },
        sendDataToParent() {
            this.$emit("send-global-data", {
                phoneNumber: this.phoneNumber,
                selectAgent: this.selectAgent,
                selectedMang: this.selectedMang,
                allType: this.allType,
                selectAgentType: this.selectAgentType,
            });
        },

        getSelectedAgentType(val) {
            this.selectAgentType = val;
            this.sendDataToParent();
        },

        getSelectedValueManger(val) {
            this.selectedMang = val;
            this.getAllAgents("agent", 1, val);
            this.sendDataToParent();
        },

        getAllAgents(role, status, val) {
            this.allAgents = [];
            let body = new FormData();
            body.append("role", role);
            if (status === 0 && !val) {
                body.append("status", status);
                body.append("role", role);
            } else if (status === 1 && !val) {
                body.append("status", status);
                body.append("role", role);
            }
            if (val) {
                val.forEach((item, index) => {
                    this.selectedManger.push(`'manager_id[${index}]': '${item["id"]}'`);
                });
            }

            let removeDublicate = removeDuplicated(this.selectedManger);

            if (removeDublicate.length) {
                val.forEach((item, index) => {
                    body.append(`manager_id[${index}]`, item["id"]);
                });
                body.append("status", status);
                body.append("role", role);
            }

            let url = "/employee/admin/filterUsers";
            axiosInstance.post(url, body).then((res) => {
                this.allAgents = res.data.payload;
                this.allAgents.map((item) => {
                    item["color"] = "red";
                    item["text"] = item["name"];
                    return item;
                });
            });
        },

        getSelectedValueAgent(val) {
            this.selectAgent = val;
            this.sendDataToParent();
        },

        getAllManger(role, status) {
            this.allMangers = [];
            let body = { role: role };
            if (status === 0) {
                body = {
                    status: status,
                    role: role,
                };
            }

            let url = "/employee/admin/filterUsers";
            axiosInstance.post(url, body).then((res) => {
                this.allMangers = res.data.payload;
                this.allMangers.map((item) => {
                    item["color"] = "red";
                    item["text"] = item["name"];
                    return item;
                });
            });
        },

        getRequestClassification(val) {
            let url = `/agent/Request/Classification?type_of_classification=${val}`;
            axiosInstance.get(url).then((res) => {
                if (val === 1) {
                    this.selesMangerType = res.data.payload;
                    this.requestClass(this.selesMangerType);
                } else if (val === 2) {
                    this.fundingMangerType = res.data.payload;
                    this.requestClass(this.fundingMangerType);
                } else if (val === 3) {
                    this.marteryMangerType = res.data.payload;
                    this.requestClass(this.marteryMangerType);
                } else if (val === 4) {
                    this.genaralMangerType = res.data.payload;
                    this.requestClass(this.genaralMangerType);
                } else if (val === 5) {
                    this.qualityUserType = res.data.payload;
                    this.requestClass(this.qualityUserType);
                } else if (val === 0) {
                    this.selesAgentType = res.data.payload;
                    this.requestClass(this.selesAgentType);
                }
            });
        },

        requestClass(data) {
            data.map((item) => {
                item["color"] = "red";
                item["text"] = item["value"];
                return item;
            });
        },

    },

    mounted() {
        this.getAllManger("manager");
        this.getAllAgents("agent");
        this.getRequestClassification(0);
    }
};
</script>
