<template>
  <div>
    <v-row
      justify="center"
      class="mt-5 graphContent"
      style="margin: 0px; margin-bottom: -35px"
    >
      <DailyReportFilter @handleFilter="handleFilter" />
    </v-row>

    <div v-if="!this.$store.state.allLoading" style="margin-top: 40px">
      <table-component
        :showSelect="true"
        itemKey="id"
        :selectedRows.sync="selected_requests"
        :headers="headers"
        :pagination="this.$store.state.pagination"
        :limit="counterOfShow"
        :items="this.$store.state.tableData"
        :loading="loading"
        @refresh="getTableData"
        v-model="page"
        :sum_of_duplicated_register="sum_of_duplicated_register"
      >
      </table-component>
    </div>

    <div
      style="
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
      "
      v-else
    >
      <LoadingMore />
    </div>
    <v-snackbar
      v-model="active"
      color="#40BE72"
      timeout="-1"
      right
      bottom
      class="ml-4"
      elevation="24"
      transition="fade-transition"
    >
      <span class="font-weight-bold" style="font-size: 16px">{{ message }}</span>
      <template v-slot:action>
        <v-btn icon small @click="active = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <OrderRequestDialog />
   
  </div>
</template>

<script>
import LoadingMore from "@/components/Charts/LoadingMore.vue";
import OrderRequestDialog from "@/components/AdminComponent/OrderRequestDialog.vue";
import { axiosInstance } from "@/axios/config";
import DailyReportFilter from "./DailyReportSalesFilter.vue";

export default {
  components: {
    OrderRequestDialog,
    LoadingMore,
    DailyReportFilter
  },

  data() {
    return {
      body: {},
      allGetData: [],
      selected_requests: [],
      pagination: {
        limit: 3,
        count: this.$store.state.searchedCount,
      },
      page: 1,
      sum_of_duplicated_register:0,
      selectedRows: [],
      allData: [],
      active: false,
      message: "",
      showSelect: { default: false },
      copySupportRequests: [],
      loadMoreData: false,
      loading: false,
      selectedItems: [],
      headers: [
        { align: "center", value: "data-table-select", width: "5%" },
        { text: "الاستشاري", value: "الاستشاري", width: "170px" },
        { text: "عدد محاولات التسجيل", value: "عدد محاولات التسجيل", width: "170px" },
      ],
      counterOfShow: 10,
      getDataDone: false,
    };
  },

  watch: {
    page(val) {
      this.searchRequest(val, "table");
    },
    selected_requests(val) {
      this.selectedItems = val;
      this.$emit("requests", val);
    },

    "$store.state.rates_in_admin_count": {
      handler(val) {
        if (val > this.$store.state.redirect_count_of_rates && localStorage["role"] == "1") {
          this.$store.state.showAlertOfRatingDidntProccess = true;
          this.$router.push({ name: "salesManager_SMRateServices" });
        }
      },
      immediate: true
    },
    
  },

  methods: {
    reloadPage() {
      this.$store.state.allData = [];
      this.$store.state.tableData = [];
      this.allGetData = [];
      this.$store.state.followLoading = true;
      this.$store.state.isSearch = false;
      this.getAllRequests(1);
      this.$store.state.startElement = [];
    },

    handleFilter(val) {
      this.body = new FormData();
      if (val.startDate) {
        this.body.append("start_date", val.startDate);
      }

      if (val.endDate) {
        this.body.append("end_date", val.endDate);
      }

      if (val.status === "استشاري قائم") {
        this.body.append("status", 1);
      }

      if (val.status === "استشاري مؤرشف") {
        this.body.append("status", 0);
      }

      if (val.range) {
        this.body.append("range", val.range);
      }

      if (val.managerId.length) {
        const managerProperties = {};
        val.managerId.forEach((item, index) => {
          this.body.append([`manager_id[${index}]`], item.id);
        });
      }

      if (val.agentId.length) {
        const managerProperties = {};
        val.agentId.forEach((item, index) => {
          this.body.append([`adviser_id[${index}]`], item.id);
        });
      }

      if (val.dayNumber) {
        this.body.append("range", val.dayNumber);
      }
      this.searchRequest(1, "table");
      this.calculateSumOfDuplicatedRegister();
    },

    calculateSumOfDuplicatedRegister() {
      let url = `${this.$store.state.url}/employee/admin/getDuplicatedLogginWithAgentsSum`;
      axiosInstance.post(url, this.body, { headers: this.request_headers() }).then((res) => {
        this.sum_of_duplicated_register=res.data.payload;
      });
    },

    setTblData(res) {
      this.$store.state.tableData.push({
        id: res.id,
        isSelected: false,
        الاستشاري: res.name,
        "عدد محاولات التسجيل": res.totall_count,
      });
    },

    getTableData(page) {
      this.$store.state.tableData = [];
      this.loading = true;
      axiosInstance
        .post(`/employee/admin/getDuplicatedLogginWithAgents?page=${page}`, this.body, {
          headers: this.request_headers(),
        })
        .then((res) => {
          let response = res.data.payload.data.map((item) => {
            this.setTblData(item);
          });

          this.$store.state.pagination = {
            limit: 3,
            count: res.data.payload.count_of_agents,
          };
          this.loading = false;
        });
    },

    searchRequest(page, type) {
      console.log("enter");
      this.loading = true;
      if (type === "table") {
        this.$store.state.tableData = [];
      }
      let url = `/employee/admin/getDuplicatedLogginWithAgents?page=${page}`;
      axiosInstance
        .post(url, this.body, {
          headers: this.request_headers(),
        })
        .then((res) => {
          this.$store.state.pagination = {
            limit: 3,
            count: res.data.payload.count_of_agents,
          };
          this.loading = false;
          let response = res.data.payload.data.map((item) => {
            this.setTblData(item);
          });
        });
    },

    removeDublicate(array) {
      let uniqueArray = array.filter((item, index) => {
        return (
          index ===
          array.findIndex((obj) => {
            return obj.id === item.id;
          })
        );
      });
      this.$store.state.allData = uniqueArray;
    },

    getAllRequests(page) {
      if (
        this.$store.state.allData.length === 0 ||
        this.copySupportRequests.length === 0
      ) {
        this.$store.state.allLoading = true;
      }
      this.loadMoreData = true;
      axiosInstance
        .post(`/employee/admin/getDuplicatedLogginWithAgents?page=${page}`, this.body)
        .then((res) => {
          this.$store.state.pagination = {
            limit: 3,
            count: res.data.payload.count_of_agents,
          };
          let response = res.data.payload.data.map((item) => {
            this.setTblData(item);
            item["starChecked"] = false;
            return item;
          });

          this.allGetData.push(...response);
          this.removeDublicate(this.allGetData);
          this.copySupportRequests.push(...response);
        })
        .catch((err) => {
          this.$store.state.allLoading = false;
          this.loadMoreData = false;
        })
        .finally(() => {
          this.loadMoreData = false;
          this.$store.state.allLoading = false;
        });
    },

    handleChangeInput(row) {
      row["selected"] = !row["selected"];
      let findRow = this.selectedRows.findIndex((item) => item["id"] === row["id"]);
      if (findRow !== -1) {
        this.selectedRows.splice(findRow, 1);
      } else {
        this.selectedRows.push(row);
      }
    },
  },

  created() {
    this.$store.state.tableData = [];
    this.$store.state.back = false;
    this.$store.state.isSearch = false;
    this.body = new FormData();
    this.body.append("status", 1);
    this.getTableData(1);
    this.$store.state.startElement = [];
  },

  beforeMount(){
    this.calculateSumOfDuplicatedRegister();
  }
};
</script>

<style>
.noData {
  display: flex;
  justify-content: center;
  font-size: 22px;
  color: #3f0e40;
  margin-top: 70px;
  font-weight: bold;
}
.support_container {
  display: flex;
  text-align: center;
  justify-content: space-between;
  margin-top: 45px;
  margin-bottom: 20px;
}
</style>
