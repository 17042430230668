<template>
  <div class="notifs position-absolute">
    <div class="the-container mx-2 my-10">
      <div class="cont-dad">
        <h2 style="margin-bottom:1rem;">اضافة طلب اجازة</h2>
        <v-row>
            <v-col cols="12" lg="12" md="12" sm="12" xs="12" class="mb-1 py-0">
              <div class="mb-2">
                
                هل هو مدعوم
              </div>
              <v-radio-group
                row
                class="mr-n4 px-0"
                v-model="payload.period_or_day"
              >
                <v-radio value="day" label="يوم"></v-radio>
                <v-radio value="period" label="فترة"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" xs="12" class="mb-5 py-0">
              <div class="mb-2 d-flex justify-space-between align-items-center">
                <div>
                  <span v-if="payload.period_or_day == 'day'">تاريخ اليوم</span>
                  <span v-else>الفترة من</span>
                </div>
              </div>
              <v-text-field
                type="date"
                hide-details
                v-model="payload.from"
                class="mr-1"
                outlined
                dense
                append-icon
              >
                <template v-slot:append>
                  <svg
                    id="calendar"
                    xmlns="http://www.w3.org/2000/svg"
                    width="17.396"
                    height="16.989"
                    viewBox="0 0 17.396 16.989"
                    style="margin-bottom: -6px"
                  >
                    <path
                      id="Path_2784"
                      data-name="Path 2784"
                      d="M19.1,24.906H6.714a2.5,2.5,0,0,1-2.5-2.5V16.412a.626.626,0,0,1,1.252,0V22.4a1.252,1.252,0,0,0,1.252,1.252H19.1A1.252,1.252,0,0,0,20.354,22.4V11.814A1.252,1.252,0,0,0,19.1,10.562H6.714a1.252,1.252,0,0,0-1.252,1.252V14.28a.626.626,0,0,1-1.252,0V11.814a2.5,2.5,0,0,1,2.5-2.5H19.1a2.5,2.5,0,0,1,2.5,2.5V22.4A2.5,2.5,0,0,1,19.1,24.906Z"
                      transform="translate(-4.21 -7.917)"
                      fill="#6c757d"
                    />
                    <path
                      id="Path_2785"
                      data-name="Path 2785"
                      d="M18.476,11.849H4.836a.626.626,0,1,1,0-1.252h13.64a.626.626,0,0,1,0,1.252ZM9.23,8.9A.626.626,0,0,1,8.6,8.275V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,9.23,8.9Zm7.355,0a.626.626,0,0,1-.626-.626V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,16.585,8.9Z"
                      transform="translate(-4.21 -4.86)"
                      fill="#6c757d"
                    />
                  </svg>
                </template>
              </v-text-field>
            </v-col>
            <v-col v-if="payload.period_or_day != 'day' " cols="12" lg="3" md="4" sm="6" xs="12" class="mb-5 py-0">
              <div class="mb-2 d-flex justify-space-between align-items-center">
                <div>
                  <span>الفترة الي</span>
                </div>
              </div>
              <v-text-field
                type="date"
                hide-details
                v-model="payload.to"
                class="mr-1"
                outlined
                dense
                append-icon
              >
                <template v-slot:append>
                  <svg
                    id="calendar"
                    xmlns="http://www.w3.org/2000/svg"
                    width="17.396"
                    height="16.989"
                    viewBox="0 0 17.396 16.989"
                    style="margin-bottom: -6px"
                  >
                    <path
                      id="Path_2784"
                      data-name="Path 2784"
                      d="M19.1,24.906H6.714a2.5,2.5,0,0,1-2.5-2.5V16.412a.626.626,0,0,1,1.252,0V22.4a1.252,1.252,0,0,0,1.252,1.252H19.1A1.252,1.252,0,0,0,20.354,22.4V11.814A1.252,1.252,0,0,0,19.1,10.562H6.714a1.252,1.252,0,0,0-1.252,1.252V14.28a.626.626,0,0,1-1.252,0V11.814a2.5,2.5,0,0,1,2.5-2.5H19.1a2.5,2.5,0,0,1,2.5,2.5V22.4A2.5,2.5,0,0,1,19.1,24.906Z"
                      transform="translate(-4.21 -7.917)"
                      fill="#6c757d"
                    />
                    <path
                      id="Path_2785"
                      data-name="Path 2785"
                      d="M18.476,11.849H4.836a.626.626,0,1,1,0-1.252h13.64a.626.626,0,0,1,0,1.252ZM9.23,8.9A.626.626,0,0,1,8.6,8.275V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,9.23,8.9Zm7.355,0a.626.626,0,0,1-.626-.626V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,16.585,8.9Z"
                      transform="translate(-4.21 -4.86)"
                      fill="#6c757d"
                    />
                  </svg>
                </template>
              </v-text-field>
            </v-col>
            
            <!-- <v-col cols="12" lg="3" md="4" sm="6" xs="12" class="mb-5 py-0">
              <div class="mb-2">
                اختر البديل
              </div>
              <v-autocomplete
                :items="agent_list"
                hide-details
                v-model="payload.user_id"
                item-text="name"
                item-value="id"
                class="mr-1"
                outlined
                dense
              >
              </v-autocomplete>
            </v-col> -->
            
            <v-col cols="12" lg="12">
                <v-btn
                :color="$vuetify.theme.themes.light.dataActionBTN"
                style="font-size: 16px"
                class="pb-2 receive-btn"
            :loading="Loader"
                @click="addVacation"
                dark>
                    أضافة طلب اجازه
                    </v-btn>
            </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>


<script>
import Axios from "axios";
export default {
  data() {
    return {
      payload: {
        period_or_day:'day',
        from: '',
        to: '',
        user_id: '',
        alternative_user_id: 310
      },
      Loader: false,
      agent_list: [],
  
    };
  },
  methods: {
    
    async addVacation(uname, pass) {
      this.Loader = true;
      const data = this.payload;
      let url = ``

        url = `${this.$store.state.url}/salesmanager/add_vacation`
      await Axios.post(
        url,
        data,
        {
          headers: this.request_headers(),
        }
      )
      .then((res) => {
          this.showPopup("success", res.data.payload);
        })
        .then(() => {
            
          this.Loader = false;
            this.$router.push({name: 'salesManager_vacations'});


        })
        .catch((err) => {
          this.Loader = false;
          this.showPopup("error", err.response.data.message);
          this.verifing = false;
        });
    }
  },
  mounted() {
    Axios.get(
        `${this.$store.state.url}/sales/agents-statistics`,
        {
          headers: this.request_headers(),
        }
      ).then((response) => {
        this.agent_list = response.data.payload.data;
        this.loading = false;
      });
  },
};
</script>


<style lang="scss">
.notifs {
  .cont-dad {
    background: #ffffff;
    border: 1px solid #ebebeb;
    border-radius: 6px;
    color: #2c2c2c;
    padding: 9px 20px 11px 7px;
    margin-top: 20px;
    .upper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .right-side {
        display: flex;
        align-items: center;
        span {
          font-size: 15px;
          font-weight: 500;
        }
      }
    }
    p {
      margin: 0;
      margin-top: 6px;
    }
    h2 {
      margin: 0;
      font-weight: 900;
      font-size: 18px;
    }
  }
}
@media (max-width: 600px) {
  .notifs {
    .cont-dad {
      h2 {
        font-size: 14px;
      }
      span {
        font-size: 11px !important;
      }
      p {
        font-size: 13px;
      }
    }
  }
}
</style>
