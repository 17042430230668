<template>
    <div>
        <v-form style="margin-top: 20px">
            <v-row>
                <v-col cols="12" sm="12" md="12">
                    <v-autocomplete :items="allTypes" label="جميع التصنيفات" v-model="allType"
                        @change="sendDataToParent()" outlined dense>
                        <template v-slot:item="{ item, attrs, on }">
                            <v-list-item v-bind="attrs" v-on="on">
                                <v-list-item-title :id="attrs['aria-labelledby']" v-text="item"></v-list-item-title>
                            </v-list-item>
                        </template>
                    </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                    <SelectInput ref="selectInput10" @selectedValues="getSelectedAgentType"
                        :originalItems="selesAgentType" label="تصنيف استشاري المبيعات" />
                </v-col>
                <v-col cols="12" sm="12" md="12" v-if="selectAgentType.some(ele => ele.id === 16)">
                    <SelectInput ref="selectInput19" @selectedValues="getSelectAgentRefused"
                        :originalItems="selesAgentRefuseList" label="سبب الرفض" />
                </v-col>
                <v-col cols="12" sm="12" md="12">
                    <SelectInput ref="selectInput11" @selectedValues="getSelectedMangerType"
                        :originalItems="selesMangerType" label="تصنيف مدير المبيعات" />
                </v-col>

                <v-col cols="12" sm="12" md="12">
                    <SelectInput ref="selectInput12" @selectedValues="getSelectedFundingType"
                        :originalItems="fundingMangerType" label="تصنيف مشرف التمويل" />
                </v-col>
                <v-col cols="12" sm="12" md="12">
                    <SelectInput ref="selectInput13" @selectedValues="getMerteryType" :originalItems="marteryMangerType"
                        label="تصنيف مدير الرهن" />
                </v-col>

                <v-col cols="12" sm="12" md="12">
                    <SelectInput ref="selectInput14" @selectedValues="getGeneralManger"
                        :originalItems="genaralMangerType" label="تصنيف المدير العام" />
                </v-col>
            </v-row>
        </v-form>
    </div>
</template>


<script>
import SelectInput from "@/components/Charts/SelectInput.vue";
import { axiosInstance } from "@/axios/config";

export default {
    components: { SelectInput },
    data() {
        return {
            allType: "",
            selesAgentType: [],
            selectAgentType: [],
            selectAgentRefused: [],
            selectedSalesManger: [],
            selectedFundingManger: [],
            selectMortgageManger: [],
            selectGeneralManger: [],
            selesAgentRefuseList: [],
            allTypes: ["جميع التصنيفات", "ايجابي", "سلبي", "بدون"],
            selesMangerType: [],
            fundingMangerType: [],
            marteryMangerType: [],
            genaralMangerType: [],
        };
    },

    watch: {
        "$store.state.rejection_list": {
            handler(newval, oldvalue) {
                this.selesAgentRefuseList = newval.map(item => ({
                    id: item.id,
                    text: item.title, // Renaming title to value
                    value: item.title, // Renaming title to value
                    color: "orange" // Renaming title to value
                }));

                console.log("this.selesAgentRefuseList", this.selesAgentRefuseList)
            },
        },
    },

    methods: {

        sendDataToParent() {
            this.$emit("send-classification-data", {
                allType: this.allType,
                selectAgentType: this.selectAgentType,
                selectAgentRefused: this.selectAgentRefused,
                selectedSalesManger: this.selectedSalesManger,
                selectedFundingManger: this.selectedFundingManger,
                selectMortgageManger: this.selectMortgageManger,
                selectGeneralManger: this.selectGeneralManger,
            });
        },

        getRequestClassification(val) {
            let url = `/agent/Request/Classification?type_of_classification=${val}`;
            axiosInstance.get(url).then((res) => {
                if (val === 1) {
                    this.selesMangerType = res.data.payload;
                    this.requestClass(this.selesMangerType);
                } else if (val === 2) {
                    this.fundingMangerType = res.data.payload;
                    this.requestClass(this.fundingMangerType);
                } else if (val === 3) {
                    this.marteryMangerType = res.data.payload;
                    this.requestClass(this.marteryMangerType);
                } else if (val === 4) {
                    this.genaralMangerType = res.data.payload;
                    this.requestClass(this.genaralMangerType);
                } else if (val === 5) {
                    this.qualityUserType = res.data.payload;
                    this.requestClass(this.qualityUserType);
                } else if (val === 0) {
                    this.selesAgentType = res.data.payload;
                    this.requestClass(this.selesAgentType);
                }
            });
        },

        requestClass(data) {
            data.map((item) => {
                item["color"] = "red";
                item["text"] = item["value"];
                return item;
            });
        },

        getSelectedAgentType(val) {
            this.selectAgentType = val;
            this.sendDataToParent();
        },
        getSelectAgentRefused(val) {
            this.selectAgentRefused = val;
            this.sendDataToParent();
        },


        getSelectedMangerType(val) {
            this.selectedSalesManger = val;
            this.sendDataToParent();
        },

        getSelectedFundingType(val) {
            this.selectedFundingManger = val;
            this.sendDataToParent();
        },

        getMerteryType(val) {
            this.selectMortgageManger = val;
            this.sendDataToParent();
        },

        getGeneralManger(val) {
            this.selectGeneralManger = val;
            this.sendDataToParent();
        },

    },

    mounted() {
        this.getRequestClassification(0);
        this.getRequestClassification(1);
        this.getRequestClassification(2);
        this.getRequestClassification(3);
        this.getRequestClassification(4);
        this.getRequestClassification(5);
        this.selesAgentRefuseList = this.$store.state.rejection_list.map(item => ({
            id: item.id,
            text: item.title, // Renaming title to value
            value: item.title, // Renaming title to value
            color: "orange" // Renaming title to value
        }));
    },

};
</script>
