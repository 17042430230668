<template>
  <div>
    <v-card style="padding: 10px">
      <v-card-title>أسماء الكولسنتر</v-card-title>
      <v-container>
        <v-row>
          <v-col cols="12" sm="12" md="12" class="py-0">
            <div class="selectInput">
              <template>
                <v-container>
                  <v-combobox
                      v-model="tags"
                      label="أضافة أسماء الكولسنتر "
                      multiple
                      chips
                      clearable
                      @keydown.tab="addTag"
                      @keydown.enter="addTag"
                  >
                    <!-- Customize the appearance of the chips -->
                    <template v-slot:selection="{ attrs, item, select, selected }">
                      <v-chip
                          v-bind="attrs"
                          :input-value="selected"
                          close
                          @click="select"
                          @click:close="removeTag(item)"
                      >
                        {{ item }}
                      </v-chip>
                    </template>
                  </v-combobox>
                </v-container>
              </template>
            </div>

          </v-col>
        </v-row>
      </v-container>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue-darken-1" variant="text" @click="updateSetting">
          حفظ
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<style lang="scss">
.loadingMore {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}
.text__success {
  color: green;
}

.text__faild {
  color: red;
}
th.text-start.sortable {
  text-align: center !important;
}
i.v-icon.notranslate.mdi.mdi-dots-vertical.theme--dark {
  background: rgb(63, 14, 64);
  border-radius: 4px;
}
 .v-text-field {
   padding-top: 12px !important;
   margin-top: 4px;
 }
label.v-label.v-label--active.theme--light.primary--text {
  left: auto;
  position: absolute;
  top: 0;
  right: -11px;
}
</style>
<script>
import Axios from "axios";
import {axiosInstance} from "@/axios/config";
export default {
  name: "alertsIndex",
  components: {

  },
  data() {
    return {
      page: 1,
      loading: false,
      copySupportRequests: [],
      loadMoreData: false,
      loadingData: false,
      iconToggle: false,
      newTag: '', // Holds the value of the new tag input
      tags: [],    // Array to store the tags
    };
  },

  watch: {

  },

  methods: {
    //------------------------------------------------------------------------------
    // For Tags Task
    //------------------------------------------------------------------------------
    addTag(event) {
      event.preventDefault();
      const inputValue = this.$refs.combobox.internalSearch;
      if (inputValue.trim() !== '' && !this.tags.includes(inputValue.trim())) {
        this.tags.push(inputValue.trim());
        this.$refs.combobox.internalSearch = '';
      }
    },
    //------------------------------------------------------------------------------
    // Method to remove a tag
    //------------------------------------------------------------------------------
    removeTag(tag) {
      this.tags = this.tags.filter((t) => t !== tag);
    },
    //------------------------------------------------------------------------------
    // Get Settings With Key
    //------------------------------------------------------------------------------
    getSettingData() {
      Axios.get(`${this.$store.state.url}/employee/admin/call-center-names`, {
        headers: this.request_headers(),
      }).then((res) => {
        res.data.payload.map((item) => {
         // this.setTbData(item);
          this.tags.push(item)
        });
        this.loading = false;
      });
    },
    updateSetting() {
      const body = new FormData();
      console.log(this.users)
      body.append("users", this.tags);

      console.log(this.tags)

      axiosInstance
          .post(`/employee/admin/call-center-names/update`, body)
          .then((res) => {
            this.showPopup("success", res.data.message);
          })
          .catch((err) => {
            this.showPopup("error", err.response.data.message);
          });
    },
  },

  created() {
    this.getSettingData();
  },
};
</script>
