<template>
  <div style="box-shadow: 0 0 0 0 !important" class="adminGraph">
    <v-row justify="center" class="mt-5 graphContent" style="margin: 0px">
      <LatestReportFilter @filterDataMask="handleFilter" />
    </v-row>
    <div v-if="!getDataDone">
<!--      <SliderGroup
          :options="this.options"
          @removeRow="removeRow($event)"
          style="margin-bottom: 30px"
      />-->
      <table-component
          itemKey="id"
          :headers="tableHeaders"
          :formBody="body"
          :pagination="this.$store.state.pagination"
          :limit="$store.state.counterOfShow"
          :items="tableData"
          :loading="loading"
          v-model="page"
      >
        <template v-slot:[`item.action`]="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="#3F0E40" small depressed dark v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-horizontal</v-icon>
              </v-btn>
            </template>
            <v-list>
              <SingleProcess :item="item['request_id']" :idOfRate="item['id']" :rateRecord="item" @refreshAfterProccesses="refreshDataAfterProccesses"/>
            </v-list>
          </v-menu>
          <v-btn small depressed dark color="#3F0E40" :style="{
                        'margin': '0px 2px',
                    }"  @click="isProccessed(item.id)" v-if="item.is_processed == 0">
            <v-list-item-icon class="ml-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="16.153" height="16.007" viewBox="0 0 16.153 16.007">
                <g id="Icon_feather-check-circle" data-name="Icon feather-check-circle" transform="translate(-2.31 -2.338)">
                  <path id="Path_5859" data-name="Path 5859" d="M17.756,9.694v.679A7.378,7.378,0,1,1,13.381,3.63" transform="translate(0 0)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                  <path id="Path_5860" data-name="Path 5860" d="M23.091,6l-7.378,7.385L13.5,11.172" transform="translate(-5.336 -1.529)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                </g>
              </svg>
            </v-list-item-icon>
          </v-btn>


          <!--        <v-list-item @click="isProccessed" v-if="rateRecord.is_processed == 0">
                    <v-list-item-icon class="ml-2">
                      <ProcessedIcon />
                    </v-list-item-icon>
                    <v-list-item-title>تمت المعالجة</v-list-item-title>
                  </v-list-item>-->
        </template>
      </table-component>
    </div>
    <!-- chart -->
    <div style="box-shadow: 0 0 0 0 !important" class="adminGraph">
      <div v-if="!getDataDone">
        <div style="margin-top: 40px" v-if="!loading">
          <highcharts :options="chartOptions" style="height: 600px"></highcharts>
        </div>
      </div>
      <div v-else class="loader">
        <img src="../../../../assets/Comp-2.gif" />
      </div>
    </div>
    <v-snackbar
        v-model="active"
        color="#40BE72"
        timeout="-1"
        right
        bottom
        class="ml-4"
        elevation="24"
        transition="fade-transition"
    >
      <span class="font-weight-bold" style="font-size: 16px">{{ message }}</span>
      <template v-slot:action>
        <v-btn icon small @click="active = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <LatestReportFilter pageName="all" @filterDataMask="handleFilter" />

    <OrderRequestSection
        v-if="this.$store.state.openOrderRequestSection"
        style="margin-top: 50px"
    />

    <TasksRequestSection
        v-if="this.$store.state.openTasksRequestSection"
        style="margin-top: 50px"
    />

    <AddTaskForRequest
        v-if="this.$store.state.openAddTaskForRequest"
        style="margin-top: 50px"
    />

    <TasksDetailsRequestSection
        v-if="this.$store.state.openTasksDetailsRequestSection"
        style="margin-top: 50px"
    />
  </div>
</template>
<style>
.bg-primary {
  background-color: rgb(63, 14, 64) !important;
  color: #fff !important;
  border: 1px solid #ddd !important;
}
.bg-primary * {
  color: #fff !important;
}

.v-data-table__wrapper > table > thead > tr > th.dimensions {
  text-align: center;
}

.v-data-table-header th.height,
.v-data-table-header th.base,
.v-data-table-header th.volume {
  display: none;
}

.v-data-table tr td.dimensions {
  text-align: center;
}

.v-data-table tr td.height,
.v-data-table tr td.base,
.v-data-table tr td.volume {
  display: none;
}
</style>
<script>
import LatestReportFilter from "./LatestReportFilter.vue";
import SliderGroup from "@/components/Charts/SliderGroup";
import { Chart } from "highcharts-vue";
import Highcharts from "highcharts-vue";
import SingleProcess from "./SingleProcess.vue";
import OrderRequestSection from "@/components/AdminComponent/OrderRequestSection.vue";
import TasksRequestSection from "@/components/AdminComponent/TasksRequestSection.vue";
import AddTaskForRequest from "@/components/AdminComponent/AddTaskForRequest.vue";
import TasksDetailsRequestSection from "@/components/AdminComponent/TasksDetailsRequestSection.vue";

import Axios from "axios";

export default {
  components: {
    LatestReportFilter,  OrderRequestSection,highcharts: Chart,SingleProcess
  },

  data() {

    return {
      chartData: [],
      page: 1,
      loading: false,
      refreshDataAfterProccesses: false,
      copySupportRequests: [],
      loadMoreData: false,
      loadingData: false,
      selected_requests: [],
      iconToggle: false,
      getDataDone: false,
      tableHeaders: [
        { text: "تاريخ السحب", value: "تاريخ السحب" },
        { text: "من الإستشاري", value: "من الإستشاري" },
        { text: "إلى الإستشاري", value: "إلى الإستشاري" },
        { text: "العمليات", value: "action" },
        // { text: "التحكم", value: "التحكم" },
      ],
      chartOptions: {
        chart: {
          type: "column",
        },
        title: {
          text: "تقرير الطلبات الحرة للاستشاريين",
        },
        xAxis: {
          min: 0,
          title: {
            text: "الطلبات الحرة",
          },
          categories: [],
        },
        yAxis: {
          title: {
            text: "المجموع",
          },
        },
        tooltip: {
          headerFormat: "<b>{point.x}</b><br/>",
          pointFormat:
              '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
          shared: true,
        },
        plotOptions: {
          column: {
            dataLabels: {
              enabled: true,
            },
          },
        },
        series: [
          {
            name: "مجموع الطلبات الحرة التي سحبها الاستشاري",
            data: [],
          },
          {
            name: "مجموع الطلبات الحرة التي تم سحبها من الاستشاري",
            data: [],
          },
        ],
      },
    };
  },

  watch: {
    page(val) {
      this.getData(val, "loading");
    },
  selected_requests(val) {
    this.selectedItems = val;
    this.$emit("requests", val);
  },

  "$store.state.counterOfShow": {
      handler(newval, oldvalue) {
        if (newval !== oldvalue && !this.$store.state.admin_search_active) {
          this.getData(this.page,"getDataDone");
        }
      },
    },

    $route: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.$store.state.counterOfShow = 10;
        }
      },
    },

  },

  methods: {
    handleOrderRequest(item) {
      this.$router.push({
        name: "request_details",
        params: { id: item.request_id },
      });
    },
    handleOrdersRequest(item) {
      this.$router.push({
        name: "logs",
        params: { id: item.request_id },
      });
    },



    handleFilter(val) {
      console.log(val)
      this.body = new FormData();

      if (val.date_from) {
        this.body.append("date_from", val.date_from);
      }
      if (val.date_to) {
        this.body.append("date_to", val.date_to);
      }
      if (val.sales_from) {
        this.body.append("sales_from", val.sales_from);
      }
      if (val.sales_to) {
        this.body.append("sales_to", val.sales_to);
      }
      if (val.agent_from) {
        this.body.append("agent_from", val.agent_from);
      }
      if (val.agent_to) {
        this.body.append("agent_to", val.agent_to);
      }

      this.getData(1, "getDataDone");
    },

    getData(page, loader) {
      if (loader === "getDataDone") {
        this.getDataDone = true;
      } else if (loader === "loading") {
        this.loading = true;
      }
      this.options = [];
      this.tableData = [];
      let url = `${this.$store.state.url}/employee/latestReportFilter?page=${page}&per_page=${this.$store.state.counterOfShow}`;

      Axios.post(url, this.body, { headers: this.request_headers() }).then((res) => {
        this.setTableData(res.data.payload.data);
        this.setOptions();
        this.chartData = res.data.payload;
        this.$store.state.pagination = {
          limit: 3,
          count: res.data.payload.total_agents,
        };
        this.drawChart(res.data.payload.chart);
        this.loading = false;
        this.getDataDone = false;
      });
    },

    drawChart(data) {
      this.chartData = data;
      this.horizontalData = this.chartData.map((item) => item.name);
      this.verticalData1 = this.chartData.map((item) => item.countPulledFromAgent);
      this.verticalData2 = this.chartData.map((item) => item.countPulledByAgent);
      this.chartOptions.xAxis.categories = this.horizontalData;
      this.chartOptions.series[0].data = this.verticalData1;
      this.chartOptions.series[1].data = this.verticalData2;
      this.loading = false;
    },

    setOptions() {
      this.tableHeaders.map((item, index) => {
        if (item.text !== "الاستشاري" && item.text !== "التحكم") {
          this.options.push(item.text);
        }
      });
    },

    setTableData(data) {
      data.map((res) => {
        this.tableData.push({
          id: res.id,
          request_id: res.request_id,
          "تاريخ السحب": res.history_date_formated,
          "من الإستشاري": res.user_sent?.name,
          "إلى الإستشاري": res.user_received?.name,

        });
      });
    },
  },

  created() {
   // this.handleFilter({});
    this.getData(1, "getDataDone");
    this.$store.state.pagination = {};
  },
};
</script>
