<template>
  <div class="container">

    <v-dialog v-model="dialogOfQuestionLikeusers" persistent max-width="600px">
      <v-card>
        <v-card-title class="text-h6 font-weight-bold">
          <v-icon color="primary" class="mr-2">mdi-thumb-up</v-icon>
          {{ likeText }}
        </v-card-title>

        <v-divider></v-divider>

        <!-- Scrollable Content -->
        <v-card-text style="max-height: 400px; overflow-y: auto;" width="60%">
          <v-container>
            <v-row dense>
              <v-col v-for="user in likedUsers" :key="user.id" cols="12" sm="6" md="4" class="d-flex align-center mb-3">
                <v-avatar color="primary" size="50" class="mr-3">
                  <span style="color: white; font-weight: bold;">
                    {{ user.name.substring(0, 2).toUpperCase() }}
                  </span>
                </v-avatar>
                <div>
                  <p class="text-subtitle-2 font-weight-bold mb-1">
                    {{ user.name }}
                  </p>
                  <small class="text-caption grey--text">
                    {{ user.job_title || "No Job Title Provided" }}
                  </small>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="dialogOfQuestionLikeusers = false">
            <v-icon left>mdi-close</v-icon> إغلاق
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Posts List -->
    <div class="posts my-5">
      <div class="d-flex justify-end">
        <button class="btn btn-primary" @click="goToAllPosts"
          style="background-color: #4CAF50; color: white; padding: 10px 20px; border: none; border-radius: 5px; cursor: pointer; font-size: 16px; font-weight: bold; box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); transition: background-color 0.3s ease;">
          عرض كل المنشورات
        </button>
      </div>

      <div class="post">
        <h4>بواسطة: {{ singlePost.user?.name }}</h4>
        <span class="d-flex align-center justify-end" style="color:#9BB4CD;">{{ singlePost.created_at }}</span>
        <p class="font-weight-bold">{{ singlePost.question }}</p>
        <!-- Comments Section -->
        <div class="comments">

          <div class="post-actions d-flex align-center">
            <button class="toggle-comments" @click="toggleComments(singlePost.id)">
              التعليقات ({{ singlePost.comments.length }})
            </button>
            <div class="flex-grow-1"></div>

            <button class="likes" @click="listQuestionLikes(singlePost.id)" title="المعجبين">
              <span>(الإعجابات {{ singlePost.likes_count }} )</span>
            </button>

            <button class="likes" @click="likePost(singlePost.id)">
              <span :style="{ backgroundColor: singlePost.is_liked_by_user ? 'blue' : '' }">👍</span>
            </button>
          </div>

          <div>
            <div v-for="comment in singlePost.comments" :key="comment.id" class="comment">

              <div class="post-actions d-flex align-center">
                <p>{{ comment.answer }}</p>
                <div class="flex-grow-1"></div>
                <span class="d-flex align-center justify-end" style="color:#9BB4CD;">{{ comment.created_at }}</span>
              </div>

              <div class="post-actions d-flex align-center">
                <small>بواسطة: {{ comment.user?.name }}</small>
                <div class="flex-grow-1"></div>
                <button class="likes" @click="listCommentLikes(comment.id)" title="المعجبين">
                  <span>(الإعجابات {{ comment.likes_count }} )</span>
                </button>
                <button class="likes" @click="likeComment(comment.id)">
                  <!-- {{ comment.likes_count }}  -->
                  <span :style="{ backgroundColor: comment.is_liked_by_user ? 'blue' : '' }">👍</span>
                </button>
              </div>

            </div>

            <!-- Add Comment -->
            <div class="add-comment">
              <textarea v-model="newComment" placeholder="أضف تعليقًا..." rows="2">
              </textarea>
              <button @click="addComment(singlePost.id)">إرسال</button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { axiosInstance } from "@/axios/config";
export default {
  data() {
    return {
      activeTab: "all",
      singlePost: null,
      posts: [],
      newPost: "",
      expandedPostId: this.$route.params.id,
      newComment: "",
      pagination: {
        limit: 3,
        count: null,
      },
      currentPage: 1,

      dialogOfQuestionLikeusers: false,
      likedUsers: [],
      likeText:"",

    };
  },

  computed: {
    firstTwoCharFromUser() {
      return this.getInitials(window.localStorage.getItem("loggedIn_user_name"));
    },

    results() {
      return this.pagination.count;
    },

    pages() {
      return Math.ceil(this.pagination.count / this.pagination.limit);
    },

  },

  methods: {

    goToAllPosts() {
      this.$router.push({ name: 'QuestionAndAnswer2' }); // if agent
      // this.$router.push({ name: 'FundingQuestionAndAnswer' }); // if funding manager
      // this.$router.push({ name: 'AdminQuestionAndAnswer' }); // if admin
    },

    getInitials(fullName) {
      if (!fullName) return "";
      const nameParts = fullName.trim().split(" ");
      const firstTwo = nameParts.slice(0, 2);
      const initials = firstTwo.map((part) => part.charAt(0).toUpperCase()).join(" ");
      return initials;
    },

    async fetchSinglePosts() {
      const endpoint = `/agent/show-questionaires/${this.$route.params.id}`
      try {
        await axiosInstance.get(endpoint)
          .then((res) => {
            this.singlePost = res.data.payload;
          })
          .catch((erroe) => {
            console.error("Error fetching posts:", erroe);
          });
      } catch (error) {
        console.error("Error fetching posts:", error.response?.data?.message || error.message);
        this.showPopup("error", error.response?.data?.message || "خطأ أثناء جلب المنشور.");
      }
    },

    toggleComments(postId) {
      this.expandedPostId = this.expandedPostId === postId ? null : postId;
    },

    async likePost(postId) {
      try {
        await axiosInstance.get(
          `${this.$store.state.url}/employee/funding-manager/like-questionaire/${postId}`
        ).then((res) => {
          this.showPopup("success", res.data.message);
          this.fetchSinglePosts();
        })
          .catch((res) => {
            this.showPopup("error", res.response.data.message);
          });
      } catch (error) {
        console.error("Error liking post:", error);
      }
    },

    async likeComment(commentId) {
      try {
        await axiosInstance.get(
          `${this.$store.state.url}/employee/funding-manager/like-comment/${commentId}`
        ).then((res) => {
          this.showPopup("success", res.data.message);
          this.fetchSinglePosts();
        })
          .catch((res) => {
            this.showPopup("error", res.response.data.message);
          });
      } catch (error) {
        console.error("Error liking post:", error);
      }
    },

    async addComment(postId) {
      if (!this.newComment.trim()) return alert("يرجى كتابة تعليق قبل الإرسال.");
      try {
        await axiosInstance.post(
          `${this.$store.state.url}/employee/funding-manager/answer-questionaires/${postId}`,
          { answer: this.newComment }
        ).then((res) => {
          this.showPopup("success", res.data.message);
          this.newComment = "";
          this.fetchSinglePosts();
        })
          .catch((res) => {
            this.showPopup("error", res.response.data.message);
          });
      } catch (error) {
        console.error("Error adding comment:", error);
      }
    },

    async listQuestionLikes(questionId) {
      this.likeText = "المعجبين بالسؤال";
      // this.dialogOfQuestionLikeusers=true;
      try {
        await axiosInstance.get(
          `/employee/funding-manager/list-question-likes/${questionId}`
        ).then((res) => {
          this.likedUsers = res.data.payload.data;
          this.dialogOfQuestionLikeusers = true;
        })
          .catch((res) => {
            this.showPopup("error", res.response.data.message);
            this.dialogOfQuestionLikeusers = false;
          });
      } catch (error) {
        console.error("Error fetching likes:", error.response?.data || error);
      }
    },

    async listCommentLikes(commentId) {
      this.likeText = "المعجبين بالكومنت";
      // this.dialogOfQuestionLikeusers=true;
      try {
        await axiosInstance.get(
          `/employee/funding-manager/list-comment-likes/${commentId}`
        ).then((res) => {
          this.likedUsers = res.data.payload.data;
          this.dialogOfQuestionLikeusers = true;
        })
          .catch((res) => {
            this.showPopup("error", res.response.data.message);
            this.dialogOfQuestionLikeusers = false;
          });
      } catch (error) {
        console.error("Error fetching likes:", error.response?.data || error);
      }
    },

  },
  created() {
    this.fetchSinglePosts();
  },
};
</script>


<style scoped>
.container {
  padding: 20px;
  font-family: Arial, sans-serif;
}

/* Tabs */
.tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.tabs button {
  flex: 1;
  padding: 10px;
  text-align: center;
  border: 1px solid #ccc;
  background: #f9f9f9;
  cursor: pointer;
}

.tabs button.active {
  background: #3f0e40 !important;
  color: #fff;
}

/* New Post Template */
.new-post {
  margin-bottom: 20px;
}

.new-post textarea {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  background: white;

}

.textkdmskdmsk {
  /* width: 100%; */
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  /* margin-bottom: 10px; */
  background: white;
}

.new-post .submit-btn {
  float: left;
  padding: 8px 15px;
  background-color: #4caf50;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  margin-bottom: 50px;
}

.new-post .submit-btn:hover {
  background-color: #45a049;
}

/* Posts List */
.posts .post {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 15px;
  margin-top: 50px;
  margin-bottom: 10px;
  background: white;
}

.posts .post h3 {
  margin: 0 0 10px;
}

.likes {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.comments .toggle-comments {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  margin-bottom: 10px;
}

.comments .toggle-comments:hover {
  text-decoration: underline;
}

/* Comments */
.comment {
  padding: 10px;
  margin-bottom: 5px;
  border-bottom: 1px solid #ddd;
}

.add-comment textarea {
  width: 95%;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 8px;
  margin-bottom: 5px;
}

.add-comment button {
  float: left;
  padding: 5px 10px;
  background: #007bff;
  color: white;
  border: none;
  margin: 33px 0px 5px 0px;
  border-radius: 5px;
}

.add-comment button:hover {
  background: #0056b3;
}


/* Pagination container styles */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  gap: 10px;
}

/* Pagination buttons */
.pagination button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.pagination button:hover {
  background-color: #0056b3;
}

.pagination button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

/* Current page indicator */
.pagination span {
  font-size: 14px;
  color: #333;
  font-weight: bold;
}
</style>
