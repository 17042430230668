<template>
  <v-row justify="center">
    <v-dialog v-model="this.$store.state.FMCancelPaymentDialog" persistent width="624">
      <v-card>
        <v-card-title>
          <span class="text-h5" style="margin-bottom: 30px">تاكيد الغاء الدفعة؟</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" lg="12" md="12" sm="12" xs="12">
              <div class="mb-2">الملاحظة</div>
              <v-textarea
                hide-details
                style="background-color: white; border: 2px solid; padding: 5px"
                v-model="note"
                dense
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="$store.state.FMCancelPaymentDialog = false"
          >
            الغاء
          </v-btn>
          <v-btn color="blue-darken-1" variant="text" @click="cancelPrepayment">
            الغاء الدفعة
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Axios from "axios";
import { axiosInstance } from "@/axios/config";

export default {
  name: "FMCancelPaymentDialog",
  props: ["reqId"],

  data() {
    return {
      note: "",
    };
  },

  methods: {
    cancelPrepayment() {
      let req_id = "";
      req_id = parseInt(this.reqId);
      const formData = new FormData();
      formData.append("req_id", req_id);
      formData.append("note", this.note);
      axiosInstance.get(`/employee/funding-manager/cancelPrepayment/${req_id}`, formData, { headers: this.request_headers(), })
        .then((res) => {
          this.$store.state.FMCancelPaymentDialog = false;
          this.showPopup("success", res.data.message);
        })
        .catch((err) => {
          this.$store.state.FMCancelPaymentDialog = false;
          this.showPopup("error", err.response.data.message);
        });
    },
  },

};
</script>
