<template>
  <v-row justify="center">
    <v-dialog v-model="this.$store.state.FMApproveRequestDialog" persistent width="624">
      <v-card>
        <v-card-title>
          <h3>هل أنت متأكد من قبوله ؟</h3>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" lg="12" md="12" sm="12" xs="12">
              <h4 class="mb-3">الملاحظة</h4>
              <v-textarea outlined v-model="comment" dense></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pl-sm-and-down-2 excluded">
          <v-spacer></v-spacer>
          <v-btn color="#5A6268" variant="text" @click="$store.state.FMApproveRequestDialog = false">
            الغاء
          </v-btn>
          <v-btn color="primary" variant="text" @click="approve_request">
            قبول
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Axios from "axios";
import { axiosInstance } from "@/axios/config";

export default {
  name: "FMApproveRequestDialog",
  props: ["reqId"],
  data() {
    return {
      comment: "",
    };
  },
  methods: {
    approve_request() {
      let req_id = "";
      req_id = parseInt(this.reqId);
      const formData = new FormData();
      formData.append("comment", this.comment);
      axiosInstance.post(`/employee/funding-manager/approveMorPurRequest/${req_id}`, formData, { headers: this.request_headers(), })
        .then((res) => {
          this.$store.state.FMApproveRequestDialog = false;
          this.showPopup("success", res.data.message);
        })
        .catch((err) => {
          this.$store.state.FMApproveRequestDialog = false;
          this.showPopup("error", err.response.data.message);
        });
    },
  },
};
</script>