<template>
  <v-form>
    <v-row>
      <div style="width: 70%; display: flex">
        <v-col cols="12" sm="4" md="4" >
          <label class="mr-2">من تاريخ</label>
          <PickerInput ref="targetStartDate" label="من تاريخ" @dateValue="targetStartDate($event)" />
        </v-col>

        <v-col cols="12" sm="4" md="4">
          <label class="mr-2">الي تاريخ</label>
          <PickerInput ref="targetEndDate" label="الي تاريخ" @dateValue="targetEndDate($event)" />
        </v-col>

        <v-col cols="12" sm="6" md="4" >
          <label > الحالة</label>
          <v-autocomplete
              :items="allStatus"
              label="الحالة"
              style="margin-top: 15px;"
              v-model="filterObj.status"
              outlined
              dense
          >
            <template v-slot:item="{ item, attrs, on }">
              <v-list-item v-bind="attrs" v-on="on">
                <v-list-item-title
                    :id="attrs['aria-labelledby']"
                    v-text="item"
                ></v-list-item-title>
              </v-list-item>
            </template>
          </v-autocomplete>
        </v-col>
      </div>

      <div style="width: 25.5%; display: flex;margin-top: 37px">
        <div class="button" style="background: white !important">
          <input
              type="radio"
              id="a15"
              name="check-substitution-2"
              :checked="filterObj.range === ''"
              @change="filterObj.range = $event.target.value"
              value=""
          />
          <label class="btn btn-default" for="a25">مسح</label>
        </div>
        <div class="button" style="background: white !important">

          <input
              type="radio"
              id="a25"
              name="check-substitution-2"
              :checked="filterObj.range === 'all'"
              @change="filterObj.range = $event.target.value;"
              value="all"
          />
          <label class="btn btn-default" for="a25">الكل</label>
        </div>
        <div class="button" style="background: white !important">
          <input
              type="radio"
              id="a50"
              name="check-substitution-2"
              :checked="filterObj.range === 'day'"
              @change="filterObj.range = $event.target.value"
              value="day"
          />
          <label class="btn btn-default" for="a50">اليوم</label>
        </div>
        <div class="button" style="background: white !important">
          <input
              type="radio"
              id="a75"
              name="check-substitution-2"
              :checked="filterObj.range === 'week'"
              @change="filterObj.range = $event.target.value"
              value="week"
          />
          <label class="btn btn-default" for="a75">الاسبوع</label>
        </div>
      </div>
      <div
          style="
                  height: 40px;
                  width: 3%;
                  line-height: 2;
                  background: rgb(243, 245, 254);
                  margin-top: 12px;
                  border-radius: 4px;
                  text-align: center;
                "
      >
        <v-img
            style="width: 20px; height: 25px;margin-top: 37px"
            id="logo"
            contain
            src="@/assets/calendar.svg"
        />
      </div>
    </v-row>
    <v-row style="margin: -5px; margin-bottom: -20px">
      <div style="width: 70%; display: flex;">
        <v-col cols="12" sm="6" md="6">
          <SelectInput
              @selectedValues="getSelectedValueManger"
              :originalItems="managerNames"
              label="اسم المدير"
          />
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <SelectInput
              style="margin-top: -50px"
              @selectedValues="getSelectedValueAdviser"
              :originalItems="agentNames"
              label="اسم الاستشاري"
          />
        </v-col>
      </div>
      <div style="width: 30%; display: flex; margin-top: -10px">
        <v-col cols="12" sm="6" md="4">
          <v-text-field
              label="عدد الايام"
              v-model="filterObj.dayNumber"
              outlined
              dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="8">
          <v-btn
              depressed
              color="primary"
              style="width: 100%"
              @click="handleFilter()"
          >
            بحث
          </v-btn>
        </v-col>
      </div>
    </v-row>
  </v-form>
</template>

<script>
// import { SearchIconBlack } from "@/assets/adminIcons/searchIconBlack";
import SelectInput from "@/components/Charts/SelectInput.vue";
import PickerInput from "@/components/Charts/PickerInput.vue";
import Axios from "axios";
import {axiosInstance} from "@/axios/config";

export default {
  /*SearchIconBlack,*/
  components: {  SelectInput, PickerInput },
  data() {
    return {
      allStatus: ["الكل", "استشاري مؤرشف", "استشاري قائم"],
      selectedAgents: [],
      selectedManagers: [],
      managerNames: [],
      agentNames: [],
      selectedManager: [],
      mangers: [],
      agents: [],
      status: null,
      filterObj: {
        endDate: "",
        startDate: "",
        status: "الكل",
        range: "",
        dayNumber: "",
        managerId: [],
        agentId: [],
      },
    };
  },

  watch: {
    selectedManagers(val) {
      this.selectedManager = val;
      this.filterObj.managerId = val;
      this.getMangersAndAgents("agent", this.status);
    },

    selectedAgents(val) {
      this.filterObj.agentId = val;
    },

    "filterObj.status": function (newStatus, oldStatus) {
      this.status = newStatus;
      switch (newStatus) {
        case "استشاري قائم":
          this.getMangersAndAgents("manager", 1);
          this.getMangersAndAgents("agent", 1);
          break;
        case "استشاري مؤرشف":
          this.getMangersAndAgents("manager", 0);
          this.getMangersAndAgents("agent", 0);
          break;
        case "الكل":
          this.getMangersAndAgents("manager");
          this.getMangersAndAgents("agent");
          break;
        default:
          break;
      }
    },
  },

  methods: {
    handleFilter() {
      this.$emit("handleFilter", this.filterObj);
    },
    getSelectedValueManger(selectedValue) {
      this.selectedManagers = selectedValue;
    },

    getSelectedValueAdviser(selectedValue) {
      this.selectedAgents = selectedValue;
    },

    targetEndDate(endDate) {
      this.filterObj.endDate = endDate;
    },

    targetStartDate(startDate) {
      this.filterObj.startDate = startDate;
    },

    getMangersAndAgents(role, status = "") {
      this.agentNames = [];
      let body = new FormData();

      body.append("role", role);

      // if (status !== "" && status) {
      if (status !== "") {
        body.append("status", status);
      }

      if (this.selectedManagers.length) {
        const managerProperties = {};

        this.selectedManagers.forEach((item, index) => {
          body.append([`manager_id[${index}]`], item.id);
        });
      }

      axiosInstance.post(
        '/employee/admin/filterUsers',
        body,
        { headers: this.request_headers() }
      ).then((res) => {
        if (role === "manager") {
          this.managerNames = res["data"]["payload"];
          this.managerNames.map((item) => {
            item["text"] = item["name"];
            item["color"] = "red";
            return item;
          });
        } else {
          this.agentNames = res["data"]["payload"];
          this.agentNames.map((item) => {
            item["text"] = item["name"];
            item["color"] = "red";
            return item;
          });
        }
      });
    },
  },

  created() {
    this.getMangersAndAgents("manager");
    this.getMangersAndAgents("agent");
  },
  mounted(){
    this.filterObj.status = "استشاري قائم";
      this.$emit("handleFilter", this.filterObj);
  }
};
</script>
