<template>
  <v-container class="py-4" fluid>
    <!-- Statistics Header -->
    <v-row>
      <v-col cols="12">
        <v-card outlined class="pa-4">
          <v-row dense>
            <v-col cols="2" v-for="stat in statistics" :key="stat.title">
              <router-link :to="{name: stat.to}" style="text-decoration: none;">
              <v-card outlined class="d-flex flex-column align-center py-4">
                <v-icon large color="primary" class="mb-2">{{ stat.icon }}</v-icon>
                <div class="text-h6 font-weight-bold">{{ stat.value }}</div>
                <div class="text-subtitle-2 grey--text">{{ stat.title }}</div>
              </v-card>
              </router-link>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <!-- Detailed Metrics Section -->
    <v-row dense v-if="0">
      <v-col cols="4" v-for="metric in metrics" :key="metric.title">
        <router-link :to="{name: metric.to}">
          <v-card outlined class="pa-4">
            <div class="d-flex justify-space-between align-center">
              <span class="text-h6 font-weight-bold">{{ metric.title }}</span>
              <span class="text-caption grey--text">{{ metric.subtitle }}</span>
            </div>
            <div class="text-h4 font-weight-bold my-2">{{ metric.value }}</div>
            <div class="text-caption" :class="metric.change > 0 ? 'text-success' : 'text-error'">
              {{ metric.change }}% {{ metric.change > 0 ? '▲' : '▼' }}
            </div>
            <v-progress-linear :value="metric.progress" height="6" rounded color="primary"></v-progress-linear>
          </v-card>

        </router-link>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { axiosInstance } from "@/axios/config";
export default {
  data() {
    return {
      statisticsData: {
        all_count: 0,
        recieved_count: 0,
        f_report_count: 0,
        notify_count: 0,
      },
      // Statistics summary (icons, titles, values)
      statistics: [
        { icon: "mdi-format-list-group", title: "جميع الطلبات", value: "" },
        { icon: "mdi-format-list-checks", title: "الطلبات المستلمة", value: "" },
        { icon: "mdi-layers", title: "التقارير المالية", value: "" },
        { icon: "mdi-bell", title: "الإشعارات", value: "" },
        { icon: "mdi-chat", title: "الدعم", value: "__" },
        { icon: "mdi-account", title: "حسابي", value: "__" },
      ],
      // Detailed metrics (cards with progress bars)
      metrics: [
        {
          title: "Orders",
          subtitle: "Last Week",
          value: "124k",
          change: 12.6,
          progress: 65,
        },
        {
          title: "Sales",
          subtitle: "Last Year",
          value: "175k",
          change: -16.2,
          progress: 50,
        },
        {
          title: "Profit",
          subtitle: "Last Month",
          value: "624k",
          change: 8.24,
          progress: 85,
        },
        {
          title: "Sessions",
          subtitle: "This Month",
          value: "45.1k",
          change: 12.6,
          progress: 75,
        },
        {
          title: "Expenses",
          subtitle: "This Month",
          value: "82.5k",
          change: 78,
          progress: 78,
        },
        {
          title: "Impression",
          subtitle: "Expenses",
          value: "26.1k",
          change: -24.5,
          progress: 42,
        },
      ],
    };
  },
  mounted(){
    axiosInstance
          .post(`funding-manager/get-home`, {}, { headers: this.request_headers() })
          .then((res) => {
            this.statisticsData = res.data.payload;
            this.$store.state.funding_home_info = this.statisticsData;
            this.statistics= [
              { icon: "mdi-format-list-group", to: "fundingManagerAll", title: "جميع الطلبات", value: this.statisticsData.all_count },
              { icon: "mdi-format-list-checks", to: "fundingManagerRecieved", title: "الطلبات المستلمة", value: this.statisticsData.recieved_count },
              { icon: "mdi-layers", to: "fundingManagerFinanceReport", title: "التقارير المالية", value: this.statisticsData.f_report_count },
              { icon: "mdi-bell", to: "fundingManager_TheNotifc", title: "الإشعارات", value: this.statisticsData.notify_count },
              { icon: "mdi-chat", to: "agent-support", title: "الدعم", value: "__" },
              { icon: "mdi-account", to: "funding_profile", title: "حسابي", value: "__" },
            ];
            console.log(res);
            // this.loading = false;
          });
  }
};
</script>

<style scoped>
.text-success {
  color: #4caf50;
}
.text-error {
  color: #f44336;
}
</style>
