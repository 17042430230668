import Vue from "vue";
import Router from "vue-router";
import {adminRoutes} from "@/routes/admin.router";
import {authRouter} from "@/routes/auth.router";
import {agentRouter} from "@/routes/agent.router";
import {qualityRouter} from "@/routes/quality.router";
import {salesManagerRouter} from "@/routes/salesManager.router";
import {fundingManagerRouter} from "@/routes/fundingManager.router";
import {commonRouter} from "@/routes/common.router";
Vue.use(Router);

// let pathesArray = JSON.parse(localStorage.getItem('hidden_baskets'));
// const filteredAgentRoutes = agentRouter.filter(route => !pathesArray?.includes(route.path));

const router = new Router({
  mode: "history",
  routes: [
      ...authRouter,
      ...adminRoutes,
      ...agentRouter,
      // ...filteredAgentRoutes,
      ...salesManagerRouter,
      ...fundingManagerRouter,
      ...qualityRouter,
      ...commonRouter
  ],
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeResolve((to, from, next) => {
  Vue.nextTick(() => {
    document.title = to.meta.page_name || "شركة الوساطة العقارية";
  });

  if (to.name === 'all') {
    const userRole = localStorage['role'];

    if (userRole) {
      if (userRole == 7) {
        next('/admin');
      } else if (userRole == 1) {
        next("/salesManager");
      } else if (userRole == 2) {
        next("/funding-manager");
        // next('/salesManager/requests/all');
      } else if (userRole == 5) {
        next('/quality/requests/all');
      }
    }
  }
  
  //cookie
  const theCookie = document.cookie
    .split(";")
    .map((coki) => coki.split("="))
    .reduce(
      (acc, [key, val]) => ({
        ...acc,
        [key.trim()]: decodeURIComponent(val),
      }),
      {}
    )["wsataJwt"];
  next();
  if (theCookie) {
    to.path == "/login" ? next("/") : next();
  } else next("/login");
});

export default router;
