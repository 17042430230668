<template>
  <div style="box-shadow: 0 0 0 0 !important" class="request__Classification">
    <v-row justify="center" class="mb-3 mt-5" style="margin: 0px">
      <v-expansion-panels accordion>
        <v-expansion-panel>
          <v-expansion-panel-header style="font-weight: 600; padding: 20px"
            ><span style="position: absolute; bottom: 15px"
              ><v-img
                style="width: 20px; height: 25px"
                id="logo"
                contain
                src="@/assets/Group.svg"
            /></span>
            <span style="margin-right: 30px">فلتر البحث</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <hr
              style="
                'height': 0px !important;
                'margin-bottom': 20px;
                'color': #e3e2e3;
              "
            />
            <template>
              <v-form>
                <v-row style="margin-bottom: -30px">
                  <v-col cols="12" lg="2" md="2" sm="6" xs="12" class="mb-5 py-0">
                    <div class="mb-2 d-flex justify-space-between align-items-center">
                      <div>
                        <span>من تاريخ</span>
                      </div>
                    </div>
                    <v-text-field
                      type="date"
                      hide-details
                      class="mr-1"
                      v-model="filterData.start_date"
                      outlined
                      dense
                      append-icon
                    >
                      <template v-slot:append>
                        <svg
                          id="calendar"
                          xmlns="http://www.w3.org/2000/svg"
                          width="17.396"
                          height="16.989"
                          viewBox="0 0 17.396 16.989"
                          style="margin-bottom: -6px"
                        >
                          <path
                            id="Path_2784"
                            data-name="Path 2784"
                            d="M19.1,24.906H6.714a2.5,2.5,0,0,1-2.5-2.5V16.412a.626.626,0,0,1,1.252,0V22.4a1.252,1.252,0,0,0,1.252,1.252H19.1A1.252,1.252,0,0,0,20.354,22.4V11.814A1.252,1.252,0,0,0,19.1,10.562H6.714a1.252,1.252,0,0,0-1.252,1.252V14.28a.626.626,0,0,1-1.252,0V11.814a2.5,2.5,0,0,1,2.5-2.5H19.1a2.5,2.5,0,0,1,2.5,2.5V22.4A2.5,2.5,0,0,1,19.1,24.906Z"
                            transform="translate(-4.21 -7.917)"
                            fill="#6c757d"
                          />
                          <path
                            id="Path_2785"
                            data-name="Path 2785"
                            d="M18.476,11.849H4.836a.626.626,0,1,1,0-1.252h13.64a.626.626,0,0,1,0,1.252ZM9.23,8.9A.626.626,0,0,1,8.6,8.275V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,9.23,8.9Zm7.355,0a.626.626,0,0,1-.626-.626V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,16.585,8.9Z"
                            transform="translate(-4.21 -4.86)"
                            fill="#6c757d"
                          />
                        </svg>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" lg="2" md="2" sm="6" xs="12" class="mb-5 py-0">
                    <div class="mb-2 d-flex justify-space-between align-items-center">
                      <div>
                        <span>الي تاريخ</span>
                      </div>
                    </div>
                    <v-text-field
                      v-model="filterData.end_date"
                      type="date"
                      hide-details
                      class="mr-1"
                      outlined
                      dense
                      append-icon
                    >
                      <template v-slot:append>
                        <svg
                          id="calendar"
                          xmlns="http://www.w3.org/2000/svg"
                          width="17.396"
                          height="16.989"
                          viewBox="0 0 17.396 16.989"
                          style="margin-bottom: -6px"
                        >
                          <path
                            id="Path_2784"
                            data-name="Path 2784"
                            d="M19.1,24.906H6.714a2.5,2.5,0,0,1-2.5-2.5V16.412a.626.626,0,0,1,1.252,0V22.4a1.252,1.252,0,0,0,1.252,1.252H19.1A1.252,1.252,0,0,0,20.354,22.4V11.814A1.252,1.252,0,0,0,19.1,10.562H6.714a1.252,1.252,0,0,0-1.252,1.252V14.28a.626.626,0,0,1-1.252,0V11.814a2.5,2.5,0,0,1,2.5-2.5H19.1a2.5,2.5,0,0,1,2.5,2.5V22.4A2.5,2.5,0,0,1,19.1,24.906Z"
                            transform="translate(-4.21 -7.917)"
                            fill="#6c757d"
                          />
                          <path
                            id="Path_2785"
                            data-name="Path 2785"
                            d="M18.476,11.849H4.836a.626.626,0,1,1,0-1.252h13.64a.626.626,0,0,1,0,1.252ZM9.23,8.9A.626.626,0,0,1,8.6,8.275V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,9.23,8.9Zm7.355,0a.626.626,0,0,1-.626-.626V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,16.585,8.9Z"
                            transform="translate(-4.21 -4.86)"
                            fill="#6c757d"
                          />
                        </svg>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" lg="2" md="2" class="pb-5 pt-0">
                    <div class="mb-2 d-flex justify-space-between align-items-center">
                      <span>الحالة </span>
                    </div>
                    <v-autocomplete
                      v-model="filterData.status"
                      chips
                      deletable-chips
                      color="primary"
                      :items="[
                        { id: 1, value: 'استشاري قائم' },
                        { id: 2, value: 'استشاري غير نشط' },
                        { id: 3, value: 'الكل' },
                      ]"
                      hide-details
                      item-text="value"
                      item-value="id"
                      outlined
                      dense
                      append-icon
                    >
                      <template v-slot:append>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="11.003"
                          height="6.291"
                          viewBox="0 0 11.003 6.291"
                          class="mt-3"
                        >
                          <path
                            id="Icon_ionic-ios-arrow-back"
                            data-name="Icon ionic-ios-arrow-back"
                            d="M1.9,5.5,6.06,1.34A.786.786,0,0,0,4.946.23L.229,4.943A.785.785,0,0,0,.206,6.028l4.737,4.746a.786.786,0,0,0,1.114-1.11Z"
                            transform="translate(0 6.291) rotate(-90)"
                            fill="#2c2c2c"
                          />
                        </svg>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="4" lg="4" class="pb-5 pt-0">
                    <div class="mb-2 d-flex justify-space-between align-items-center">
                      <span>إسم الاستشاري</span>
                    </div>
                    <v-autocomplete
                      v-model="filterData.agents"
                      multiple
                      chips
                      deletable-chips
                      color="primary"
                      :items="$store.state.salesmanager_agents"
                      hide-details
                      item-text="name"
                      item-value="id"
                      outlined
                      append-icon
                      dense
                    >
                      <template v-slot:append>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="11.003"
                          height="6.291"
                          viewBox="0 0 11.003 6.291"
                          class="mt-3"
                        >
                          <path
                            id="Icon_ionic-ios-arrow-back"
                            data-name="Icon ionic-ios-arrow-back"
                            d="M1.9,5.5,6.06,1.34A.786.786,0,0,0,4.946.23L.229,4.943A.785.785,0,0,0,.206,6.028l4.737,4.746a.786.786,0,0,0,1.114-1.11Z"
                            transform="translate(0 6.291) rotate(-90)"
                            fill="#2c2c2c"
                          />
                        </svg>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="2" lg="2" class="pb-5 pt-0">
                    <div class="mb-2 d-flex justify-space-between align-items-center">
                      <span>عدد الايام </span>
                    </div>
                    <v-text-field v-model="filterData.days" hide-details outlined dense>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-btn
                      depressed
                      color="primary"
                      style="width: 100%"
                      @click="getAllUsers()"
                    >
                      بحث
                    </v-btn>
                    <br />
                  </v-col>
                </v-row>
              </v-form>
            </template>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
    <div v-if="!loading">
      <v-sheet class="my-5">
        <v-label>عرض : </v-label>
        <v-slide-group show-arrows v-model="model" multiple>
          <v-slide-item
            v-for="n in headerTagOptions"
            :key="n.key"
            v-slot="{ active, toggle }"
          >
            <v-btn
              class="mx-2"
              :input-value="!active"
              active-class="btn-theme-style white--text"
              @click="
                removeRow(n.key);
                toggle();
              "
              style="
                margin-top: 7px;
                border: 1px solid #3f0e40;
                background: #f2f5ff;
                color: #3f0e40;
                font-weight: bold;
                box-shadow: 0 0 0 0;
              "
            >
              {{ n.value }}
            </v-btn>
          </v-slide-item>
        </v-slide-group>
      </v-sheet>

      <v-card
        style="
          border-radius: 4px !important;
          border: none !important;
          margin-bottom: 30px;
        "
      >
        <!-- <v-card-title> التقرير اليومي </v-card-title> -->
        <v-row>
          <v-col cols="12">
            <div style="display: flex; justify-content: center">
              <v-btn
                @click="getAllData('classifications')"
                class="btn-theme-style white--text mx-2"
                :disabled="currentChart == 'classifications'"
              >
                تقرير تصنيفات الطلب
              </v-btn>
              <v-btn
                @click="getAllData('requests')"
                class="btn-theme-style white--text mx-2"
                :disabled="currentChart == 'requests'"
              >
                تقرير سلال الطلب
              </v-btn>
              <v-btn
                @click="getAllData('statuses')"
                class="btn-theme-style white--text mx-2"
                :disabled="currentChart == 'statuses'"
              >
                تقرير حالات الطلب
              </v-btn>
            </div>
          </v-col>
        </v-row>
        <v-layout column>
          <v-flex md6>
            <v-data-table
              :headers="getHeaders"
              :items="tableData"
              item-key="report_pdf"
              style="margin: 20px"
            >
              <template v-slot:[`item.التقرير`]="{ item }">
                <p @click="test(item)" style="text-align: center; margin-top: 10px">
                  <a :href="item.التقرير"
                    ><img
                      src="../../assets/document-download-icon.svg"
                      width="20px"
                      height="30px"
                  /></a>
                </p>
              </template>
            </v-data-table>
          </v-flex>
          <div class="text-center"></div>
        </v-layout>
      </v-card>
      <v-row>
        <v-col cols="12" style="padding: 2rem">
          <highcharts
            class="hc"
            style="width: 100%"
            :options="chartOptions"
            ref="chart"
          ></highcharts>
        </v-col>
      </v-row>
    </div>
    <div
      v-else
      style="
        width: 100%;
        position: absolute;
        height: 100%;
        background-color: white;
        z-index: 999;
        display: flex;
        justify-content: center;
      "
    >
      جاري التحميل...
    </div>
  </div>
</template>

<style lang="scss">
.loader {
  width: 100%;
  text-align: center;
  height: 100%;
}

.v-expansion-panel-header__icon {
  background-color: #3a5de6;
  border-radius: 3px;
}

.v-expansion-panel-header__icon .v-icon {
  color: white !important;
}

.theme--light.v-sheet .v-slide-group {
  height: 50px;
  overflow: hidden;
  border-radius: 5px;
}

.v-slide-group__prev .v-icon.v-icon,
.v-slide-group__next .v-icon.v-icon {
  color: #5271eb;
  background: #d8defb;
  height: 100%;
  border-radius: 0px 4px 4px 0px;

  margin-right: -25px;
}

.v-slide-group__next .v-icon.v-icon {
  border-radius: 4px 0px 0px 4px;
  margin-left: -53px;
}

#container {
  height: 400px;
}

.highcharts-figure,
.highcharts-data-table table {
  min-width: 310px;
  max-width: 800px;
  margin: 1em auto;
}

hr {
  height: 1px !important;
  margin-bottom: 30px;
}

.container {
  padding: 0px;
  margin-top: 10px;
}

.highcharts-data-table table {
  font-family: Verdana, sans-serif;
  border-collapse: collapse;
  border: 1px solid #ebebeb;
  margin: 10px auto;
  text-align: center;
  width: 100%;
  max-width: 500px;
}

.highcharts-data-table caption {
  padding: 1em 0;
  font-size: 1.2em;
  color: #555;
}

.highcharts-data-table th {
  font-weight: 600;
  padding: 0.5em;
}

.highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
  padding: 0.5em;
}

.highcharts-data-table thead tr,
.highcharts-data-table tr:nth-child(even) {
  background: #f8f8f8;
}

.highcharts-data-table tr:hover {
  background: #f1f7ff;
}

.highcharts-container {
  border-radius: 4px;
}

.request__Classification .v-expansion-panel-content__wrap .v-input__slot {
  outline: 1px solid #dee2e7;
}

.v-input:not(.file-inp) {
  .v-input__icon {
    position: absolute;
    left: 10px;
    color: #91979c;
    visibility: hidden;
  }
}
.button {
  float: left;
  margin: 0 5px 0 0;
  width: 110px;
  height: 40px;
  position: relative;
  background: white !important;

  background: white !important;
  text-align: center;
  border-radius: 4px;
  margin: 12px;
}

.button label,
.button input {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  outline: 1px solid #9e9e9e;
  border-radius: 4px;
}

.button input[type="radio"] {
  opacity: 0.011;
  background-color: white !important;
  z-index: 100;
}

.button input[type="radio"]:checked + label {
  background: #efb802 !important;
  border-radius: 4px;
  color: white;
  outline: none;
}

.button label {
  cursor: pointer;
  z-index: 90;
  line-height: 1.8em;
  color: black;
}

.v-application .button {
  background: white !important;
}
.btn-theme-style {
  background-color: #3f0e40 !important;
  color: #fff;
}
</style>

<script>
import { Chart } from "highcharts-vue";
import Highcharts from "highcharts-vue";
import Axios from "axios";
import SelectInput from "@/components/Charts/SelectInput";

export default {
  data() {
    return {
      loading: true,
      chart_data: [],
      chart_headers: [],
      showntableData: [],
      temptableData: [],
      headerTagOptions: [],
      allSelectedAdviser: [],
      active: null,
      allStatus: ["الكل", "استشاري مؤرشف", "استشاري قائم"],
      check: false,
      model: null,
      allData: [],
      allHeader: [],
      tableData: [],
      allOptions: [],
      filterData: {
        start_date: "",
        end_date: "",
        agents: [],
        status: "",
        days: "",
      },
      currentChart: "requests",
      //   stackData: [],
      //   archiveArr: [],
      //   complitedArr: [],
      //   followedArr: [],
      //   remmberArr: [],
      //   moveRequerstFrom: [],
      //   openedRequest: [],
      //   receivedBasket: [],
      //   receivedTask: [],
      //   sendBasket: [],
      //   startBasketArr: [],
      //   totalReceivedArr: [],
      //   updatedRequestArr: [],
      //   responseNameArr: [],
      //   radio: "all",
      page: 1,
      //   selectedStatus: "",
      //   status: null,
      //   mangerId: null,
      //   adviserId: null,
      getDataDone: true,
      paginationLength: null,
      //   mangerSelected: [],
      /*
    chartOptions: {
        series: [
          {
            data: [1, 2, 3]
          }
        ]
      }
    */
      chartOptions: {
        chart: {
          type: "line",
        },
        title: {
          text: "chart name ",
          align: "right",
        },
        xAxis: {
          categories: [],
        },
        // yAxis: {
        //   min: 0,
        // },
        tooltip: {
          headerFormat: "<b>{point.x}</b><br/>",
          pointFormat: "{series.name}: {point.y}<br/>المجموع: {point.stackTotal}",
        },
        // plotOptions: {
        //   column: {
        //     stacking: "normal",
        //     dataLabels: {
        //       enabled: true,
        //     },
        //   },
        // },
        series: [],
      },
    };
  },

  components: {
    highcharts: Chart,
    // SelectInput,
  },
  mounted() {
    this.getAllData("requests");
    this.getAllUsers(1);
  },

  computed: {
    getHeaders() {
      let testArr = [{ text: "الاستشاري", value: "name" }];
      this.temptableData.map((res) =>
        testArr.push({
          text: res.value,
          value: res.key,
        })
      );
      return testArr;
    },

    getAllOptions() {
      let optionsArray = [...this.allOptions];
      return optionsArray;
    },
    returnAllData() {
      return this.allData;
    },
  },
  watch: {
    pageFilter(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getAllUsers(newVal);
      }
    },
    selectedStatusFilter(val) {
      this.active = val;

      if (val === "استشاري قائم") {
        this.getAllManagerAndAgentsNames("agent", 1);
      } else if (val === "استشاري مؤرشف") {
        this.getAllManagerAndAgentsNames("agent", 0);
      } else {
        this.getAllManagerAndAgentsNames("agent");
      }
    },
  },
  methods: {
    removeRow(headerValue) {
      this.check = !this.check;

      let item = this.temptableData.filter((item) => item.key === headerValue);
      if (item.length > 0) {
        this.temptableData = this.temptableData.filter(
          (item) => item.key !== headerValue
        );
      } else {
        item = this.headerTagOptions.filter((item) => item.key === headerValue);
        this.temptableData.unshift(item[0]);
      }
    },

    filter(item, queryText, itemText) {
      if (item.header) return false;

      const hasValue = (val) => (val != null ? val : "");

      const text = hasValue(itemText);
      const query = hasValue(queryText);

      return text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) > -1;
    },

    setChartData2(names, data) {
      this.chartOptions.xAxis.categories = names;
      this.chartOptions.series = data;
    },
    // setChartData(name) {
    //   this.responseNameArr.push(res.name),
    //     (this.chartOptions["xAxis"]["categories"] = this.responseNameArr),
    //     this.completes.push(res.completes),
    //     (this.chartOptions["series"][0]["data"] = this.completes),
    //     this.archived.push(res.archived),
    //     (this.chartOptions["series"][1]["data"] = this.archived),
    //     this.following.push(res.following),
    //     (this.chartOptions["series"][2]["data"] = this.following),
    //     this.star.push(res.star),
    //     (this.chartOptions["series"][2]["data"] = this.star),
    //     this.received.push(res.received),
    //     (this.chartOptions["series"][2]["data"] = this.received);
    // },

    getAllData(type) {
      this.getDataDone = false;
      let endPoint = ``;
      this.currentChart = type;
      this.loading = true;
      if (type == "requests") {
        endPoint = `${this.$store.state.url}/sales/request-baskets-chart`;
      } else if (type == "classifications") {
        endPoint = `${this.$store.state.url}/sales/request-classifications-chart`;
      } else {
        endPoint = `${this.$store.state.url}/sales/request-source-chart`;
      }
      Axios.post(endPoint, this.filterData, { headers: this.request_headers() })
        .then((res) => {
          this.allData = res.data.payload.data;
          this.tableData = this.allData;
          this.paginationLength = Math.ceil(this.allData.length / 10);
          this.chart_data = res.data.payload.data;
          const obj = res.data.payload.headers;
          this.chart_headers = res.data.payload.headers;
          let testArr = [];
          let testArr2 = [];
          const keys = Object.entries(this.chart_headers);
          keys.forEach(([key, value]) => {
            testArr.push({
              value: value,
              key: key,
            });
            testArr2.push({
              value: value,
              key: key,
              active: true,
            });
          });
          const agents = [];
          const agents_s = [];
          // get names of agents and values
          this.allData.map((item) => {
            agents.push(item.name);
            const keys2 = Object.entries(item);
            let row_data = [];
          });
          Object.entries(this.chart_headers).forEach(([key, value]) => {
            let row_data = [];
            this.allData.map((item) => {
              Object.entries(item).forEach(([key2, value2]) => {
                if (key2 == key) {
                  row_data.push(value2);
                }
              });
            });
            //
            agents_s.push({
              name: value,
              data: row_data,
            });
          });
          this.setChartData2(agents, agents_s);
          this.temptableData = testArr;
          this.headerTagOptions = testArr2;
          this.loading = false;
        })
        .catch(console.error);
    },

    getAllUsers(pageNum) {
      this.getDataDone = false;
      this.arNames = [];
      if (this.inputNumberDays !== null) {
        this.radio = this.inputNumberDays;
      }

      let bodyFormData = new FormData();
      bodyFormData.append("role", 0);
      if (this.date) {
        bodyFormData.append("start_date", this.date);
      }

      if (this.date1) {
        bodyFormData.append("end_date", this.date1);
      }

      if (this.radio) {
        bodyFormData.append("range", this.radio);
      }

      // if (this.allSelectedMangers.length) {
      //   this.allSelectedMangers.forEach((item, index) => {
      //     bodyFormData.append(`manager_id[${index}]`, item["id"]);
      //   });
      // }

      // if (this.allSelectedAdviser.length) {
      //   this.allSelectedAdviser.forEach((item, index) => {
      //     bodyFormData.append(`adviser_id[${index}]`, item["id"]);
      //   });
      // }

      if (this.active === "استشاري مؤرشف") {
        bodyFormData.append("status", 0);
      } else if (this.active === "استشاري قائم") {
        bodyFormData.append("status", 1);
      }

      Axios.post(
        `${this.$store.state.url}/employee/admin/getAllUsers?per_page=1000`,
        bodyFormData,
        {
          headers: {
            "x-api-key":
              "WFKvB4Vjr8v5BVmxY7CDQ6ZMAU8DSHSBFKCMVpkfd5hhZyKTvnV3uh5XwxSwhnfeUbptZ7Z4XFJCR4vKXeHWGHTZ6djsYXjdAatf",
            Authorization: `Bearer ${localStorage.token}`,
          },
        }
      )
        .then((res) => {
          this.allData = res.data.payload.data;
          const element = res["data"]["payload"]["data"][0];
          if (element) {
            this.fillArabicNames(element);
          }
          this.tableData = [];
          this.responseNameArr = [];
          this.archiveArr = [];
          this.complitedArr = [];
          this.followedArr = [];
          this.remmberArr = [];
          this.moveRequerstFrom = [];
          this.openedRequest = [];
          this.receivedBasket = [];
          this.receivedTask = [];
          this.sendBasket = [];
          this.startBasketArr = [];
          this.totalReceivedArr = [];
          this.updatedRequestArr = [];
          this.paginationLength = Math.ceil(res.data.payload.total_agents / 10);

          this.getDataDone = true;

          this.allData.map((item) => {
            this.setTblData(item);
            this.setChartData(item);
          });
        })
        .catch(console.error);
    },
  },
};
</script>
