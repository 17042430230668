<template>
  <page-component style="position: relative" class="agent-data-page">
    <template #content>
      <!-- eslint-disable -->
      <!-- بيانات العميل -->
      <v-sheet>
        <!-- <span class="font-weight-bold" style="display: inline">بيانات العقار</span> -->
        <v-form class="mt-5 mb-5" :disabled="!for_agent">
          <v-card flat>
            <v-layout
              row
              wrap
              style="background-color: #f3f5f7"
              class="userProfile__container"
            >
              <v-col cols="12" lg="4" md="4" sm="6" xs="12" class="px-2 py-1">
                <v-card class="pa-3 position-relative userProfile__card">
                  <v-col cols="12" sm="12">
                    <div style="display: flex" class="input__group">
                      <div>
                        <img
                          src="@/assets/icons/material-history.svg"
                          alt="image"
                          style="margin-top: 5px; margin-left: 5px"
                          @click="show_Field_log('realName')"
                        />
                        اسم المالك
                      </div>
                      <div class="horizontal__line"></div>
                      <div>
                        <v-text-field
                          type="text"
                          hide-details
                          style="
                            background-color: #fbfbfb;
                            border-right: #fbfbfb;
                            margin-top: -20px;
                          "
                          v-model="agent.real_estate.name"
                          ref="customerName"
                        />
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" sm="12">
                    <div style="display: flex" class="input__group">
                      <div>
                        <img
                          src="@/assets/icons/material-history.svg"
                          alt="image"
                          style="margin-top: 5px; margin-left: 5px"
                          @click="show_Field_log('realMobile')"
                        />
                        جوال المالك
                      </div>
                      <div class="horizontal__line"></div>
                      <div>
                        <v-text-field
                          type="text"
                          hide-details
                          style="
                            background-color: #fbfbfb;
                            border-right: #fbfbfb;
                            margin-top: -20px;
                          "
                          v-model="agent.real_estate.mobile"
                          v-numericOnly
                        />
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" sm="12">
                    <div style="display: flex; align-items: center" class="input__group">
                      <div>
                        <img
                          src="@/assets/icons/material-history.svg"
                          alt="image"
                          style="margin-top: 5px; margin-left: 5px"
                          @click="show_Field_log('realCity')"
                        />
                        المدينه
                      </div>
                      <div class="horizontal__line"></div>

                      <v-autocomplete
                        :items="$store.state.cities"
                        hide-details
                        v-model="agent.real_estate.city"
                        item-text="value"
                        item-value="id"
                        style="border: none !important"
                        class="mr-1"
                        outlined
                        dense
                      >
                      </v-autocomplete>
                      <img src="@/assets/icons/down-arrow.svg" />
                    </div>
                  </v-col>

                  <v-col cols="12" sm="12">
                    <div style="display: flex" class="input__group">
                      <div>
                        <img
                          src="@/assets/icons/material-history.svg"
                          alt="image"
                          style="margin-top: 5px; margin-left: 5px"
                          @click="show_Field_log('realRegion')"
                        />
                        الحي
                      </div>
                      <div class="horizontal__line"></div>
                      <div>
                        <v-text-field
                          type="text"
                          hide-details
                          style="
                            background-color: #fbfbfb;
                            border-right: #fbfbfb;
                            margin-top: -20px;
                          "
                          v-model="agent.real_estate.region"
                        />
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" sm="12">
                    <div style="display: flex" class="input__group">
                      <div>
                        <img
                          src="@/assets/icons/material-history.svg"
                          alt="image"
                          style="margin-top: 5px; margin-left: 5px"
                          @click="show_Field_log('realPursuit')"
                        />
                        السعي
                      </div>
                      <div class="horizontal__line"></div>
                      <div>
                        <v-text-field
                          type="text"
                          hide-details
                          style="
                            background-color: #fbfbfb;
                            border-right: #fbfbfb;
                            margin-top: -20px;
                          "
                          v-model="agent.real_estate.pursuit"
                        />
                      </div>
                    </div>
                  </v-col>
                </v-card>
              </v-col>

              <v-col cols="12" lg="4" md="4" sm="6" xs="12" class="px-2 py-1">
                <v-card class="pa-3 position-relative userProfile__card">
                  <v-col cols="12" sm="12">
                    <div style="display: flex; align-items: center" class="input__group">
                      <div>
                        <img
                          src="@/assets/icons/material-history.svg"
                          alt="image"
                          style="margin-top: 5px; margin-left: 5px"
                          @click="show_Field_log('realStatus')"
                        />
                        حالة العقار
                      </div>
                      <div class="horizontal__line"></div>

                      <v-autocomplete
                        :items="['مكتمل', 'عظم']"
                        hide-details
                        v-model="agent.real_estate.status"
                        item-text="value"
                        item-value="id"
                        style="border: none !important"
                        class="mr-1"
                        outlined
                        dense
                      >
                      </v-autocomplete>
                      <img src="@/assets/icons/down-arrow.svg" />
                    </div>
                  </v-col>

                  <v-col cols="12" sm="12">
                    <div style="display: flex" class="input__group">
                      <div>
                        <img
                          src="@/assets/icons/material-history.svg"
                          alt="image"
                          style="margin-top: 5px; margin-left: 5px"
                          @click="show_Field_log('realAge')"
                        />
                        عمر العقار
                      </div>
                      <div class="horizontal__line"></div>
                      <div>
                        <v-text-field
                          type="text"
                          hide-details
                          style="
                            background-color: #fbfbfb;
                            border-right: #fbfbfb;
                            margin-top: -20px;
                          "
                          v-model="agent.real_estate.age"
                          v-numericOnly
                        />
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" sm="12">
                    <div style="display: flex; align-items: center" class="input__group">
                      <div>
                        <img
                          src="@/assets/icons/material-history.svg"
                          alt="image"
                          style="margin-top: 5px; margin-left: 5px"
                          @click="show_Field_log('residence_type')"
                        />
                        المسكن
                      </div>
                      <div class="horizontal__line"></div>

                      <v-autocomplete
                        :items="[
                          { text: 'مسكن أول', value: 1 },
                          { text: 'مسكن ثاني', value: 2 },
                        ]"
                        hide-details
                        v-model="agent.real_estate.residence_type"
                        item-text="text"
                        item-value="value"
                        style="border: none !important"
                        class="mr-1"
                        outlined
                        dense
                      >
                      </v-autocomplete>
                      <img src="@/assets/icons/down-arrow.svg" />
                    </div>
                  </v-col>

                  <v-col cols="12" sm="12">
                    <div style="display: flex; align-items: center" class="input__group">
                      <div>
                        <img
                          src="@/assets/icons/material-history.svg"
                          alt="image"
                          style="margin-top: 5px; margin-left: 5px"
                          @click="show_Field_log('realType')"
                        />
                        نوع العقار
                      </div>
                      <div class="horizontal__line"></div>

                      <v-autocomplete
                        :items="estate_types"
                        hide-details
                        v-model="agent.real_estate.type"
                        item-text="value"
                        item-value="id"
                        style="border: none !important"
                        class="mr-1"
                        outlined
                        dense
                      >
                      </v-autocomplete>
                      <img src="@/assets/icons/down-arrow.svg" />
                    </div>
                  </v-col>

                  <v-col cols="12" sm="12">
                    <div style="display: flex" class="input__group">
                      <div>
                        <img
                          src="@/assets/icons/material-history.svg"
                          alt="image"
                          style="margin-top: 5px; margin-left: 5px"
                          @click="show_Field_log('realCost')"
                        />
                        قيمة العقار
                      </div>
                      <div class="horizontal__line"></div>
                      <div>
                        <v-text-field
                          type="text"
                          hide-details
                          style="
                            background-color: #fbfbfb;
                            border-right: #fbfbfb;
                            margin-top: -20px;
                          "
                          v-model="agent.real_estate.cost"
                          v-numericOnly
                        />
                      </div>
                    </div>
                  </v-col>
                </v-card>
              </v-col>

              <v-col cols="12" lg="4" md="4" sm="6" xs="12" class="px-2 py-1">
                <v-card class="pa-3 position-relative userProfile__card">
                  <v-col cols="12" sm="12">
                    <div style="display: flex; align-items: center" class="input__group">
                      <div>
                        <img
                          src="@/assets/icons/material-history.svg"
                          alt="image"
                          style="margin-top: 5px; margin-left: 5px"
                          @click="show_Field_log('project_id')"
                        />
                        اختر المشروع العقاري
                      </div>
                      <div class="horizontal__line"></div>

                      <v-autocomplete
                        :items="$store.state.projects"
                        hide-details
                        v-model="agent.real_estate.project_id"
                        item-text="title"
                        item-value="id"
                        style="border: none !important"
                        class="mr-1"
                        outlined
                        dense
                      >
                      </v-autocomplete>
                      <img src="@/assets/icons/down-arrow.svg" />
                    </div>
                  </v-col>

                  <div class="switch__container">
                    <div>
                      <img
                        src="@/assets/icons/material-history.svg"
                        alt="image"
                        style="margin-left: 5px"
                        @click="show_Field_log('owning_property')"
                      />
                      هل يمتلك العميل عقار؟
                    </div>
                    <div>
                      <v-radio-group row v-model="agent.real_estate.owning_property">
                        <v-radio :value="1" label="نعم"></v-radio>
                        <v-radio :value="0" label="لا"></v-radio>
                      </v-radio-group>
                    </div>
                  </div>
                  <hr style="border: 0.5px solid #38414a26; margin-bottom: 0px" />

                  <div class="switch__container">
                    <div>
                      <img
                        src="@/assets/icons/material-history.svg"
                        alt="image"
                        style="margin-left: 5px"
                        @click="show_Field_log('realhasprop')"
                      />
                      هل وجد عقار؟
                    </div>
                    <div>
                      <v-radio-group row v-model="agent.real_estate.has_property">
                        <v-radio :value="1" label="نعم"></v-radio>
                        <v-radio :value="0" label="لا"></v-radio>
                      </v-radio-group>
                    </div>
                  </div>
                  <hr style="border: 0.5px solid #38414a26; margin-bottom: 0px" />

                  <div class="switch__container">
                    <div>
                      <img
                        src="@/assets/icons/material-history.svg"
                        alt="image"
                        style="margin-left: 5px"
                        @click="show_Field_log('realeva')"
                      />
                      هل تم تقييم العقار؟
                    </div>
                    <div>
                      <v-radio-group row v-model="agent.real_estate.evaluated">
                        <v-radio :value="1" label="نعم"></v-radio>
                        <v-radio :value="0" label="لا"></v-radio>
                      </v-radio-group>
                    </div>
                  </div>
                  <hr style="border: 0.5px solid #38414a26; margin-bottom: 0px" />

                  <div class="switch__container">
                    <div>
                      <img
                        src="@/assets/icons/material-history.svg"
                        alt="image"
                        style="margin-left: 5px"
                        @click="show_Field_log('realten')"
                      />
                      هل يوجد مستأجرون؟
                    </div>
                    <div>
                      <v-radio-group row v-model="agent.real_estate.tenant">
                        <v-radio :value="1" label="نعم"></v-radio>
                        <v-radio :value="0" label="لا"></v-radio>
                      </v-radio-group>
                    </div>
                  </div>
                  <hr style="border: 0.5px solid #38414a26; margin-bottom: 0px" />

                  <div class="switch__container">
                    <div>
                      <img
                        src="@/assets/icons/material-history.svg"
                        alt="image"
                        style="margin-left: 5px"
                        @click="show_Field_log('realmor')"
                      />
                      هل العقار مرهون؟
                    </div>
                    <div>
                      <v-radio-group row v-model="agent.real_estate.mortgage">
                        <v-radio :value="1" label="نعم"></v-radio>
                        <v-radio :value="0" label="لا"></v-radio>
                      </v-radio-group>
                    </div>
                  </div>
                </v-card>
              </v-col>
            </v-layout>
          </v-card>
          <!-- <v-row>
            <v-col cols="12" lg="3" md="4" sm="6" xs="12" class="py-0 mb-5">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('realName')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                اسم المالك
              </div>
              <v-text-field
                color=""
                hide-details
                v-model="agent.real_estate.name"
                class="mr-1"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" xs="12" class="py-0 mb-5">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('realMobile')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                جوال المالك
              </div>
              <v-text-field
                hide-details
                v-model="agent.real_estate.mobile"
                class="mr-1"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" xs="12" class="py-0 mb-5">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('realCity')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                المدينه
              </div>
              <v-autocomplete
                :items="$store.state.cities"
                hide-details
                v-model="agent.real_estate.city"
                item-text="value"
                item-value="id"
                class="mr-1"
                outlined
                dense
              />
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" xs="12" class="py-0 mb-5">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('realRegion')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                الحي
              </div>
              <v-text-field
                hide-details
                v-model="agent.real_estate.region"
                class="mr-1"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" xs="12" class="py-0 mb-5">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('realPursuit')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                السعي
              </div>
              <v-text-field
                hide-details
                v-model="agent.real_estate.pursuit"
                class="mr-1"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" xs="12" class="py-0 mb-5">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('realStatus')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                حالة العقار
              </div>
              <v-autocomplete
                hide-details
                :items="['مكتمل', 'عظم']"
                v-model="agent.real_estate.status"
                class="mr-1"
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" xs="12" class="py-0 mb-5">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('realAge')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                عمر العقار
              </div>
              <v-text-field
                hide-details
                v-model="agent.real_estate.age"
                class="mr-1"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" xs="12" class="py-0 mb-5">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('residence_type')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                المسكن
              </div>
              <v-autocomplete
                :items="[
                  { text: 'مسكن أول', value: 1 },
                  { text: 'مسكن ثاني', value: 2 },
                ]"
                item-text="text"
                item-value="value"
                hide-details
                v-model="agent.real_estate.residence_type"
                class="mr-1"
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" xs="12" class="py-0 mb-1">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('realType')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                نوع العقار
              </div>
              <v-autocomplete
                hide-details
                :items="estate_types"
                item-text="value"
                item-value="id"
                v-model="agent.real_estate.type"
                class="mr-1"
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" xs="12" class="py-0 mb-1">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('realCost')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                قيمة العقار
              </div>
              <v-text-field
                hide-details
                v-model="agent.real_estate.cost"
                class="mr-1"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" xs="12" class="py-0 mb-1">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('owning_property')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                هل يمتلك العميل عقار؟
              </div>
              <v-radio-group
                row
                v-model="agent.real_estate.owning_property"
                class="mr-n4 px-0"
              >
                <v-radio :value="1" label="نعم"></v-radio>
                <v-radio :value="0" label="لا"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" xs="12" class="py-0 mb-1">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('realhasprop')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                هل وجد عقار؟
              </div>
              <v-radio-group
                row
                v-model="agent.real_estate.has_property"
                class="mr-n4 px-0"
              >
                <v-radio :value="1" label="نعم"></v-radio>
                <v-radio :value="0" label="لا"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" xs="12" class="py-0 mb-5">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('realeva')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                هل تم تقييم العقار؟
              </div>
              <v-radio-group row v-model="agent.real_estate.evaluated" class="mr-n4 px-0">
                <v-radio :value="1" label="نعم"></v-radio>
                <v-radio :value="0" label="لا"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" xs="12" class="py-0 mb-5">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('realten')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                هل يوجد مستأجرون؟
              </div>
              <v-radio-group row v-model="agent.real_estate.tenant" class="mr-n4 px-0">
                <v-radio :value="1" label="نعم"></v-radio>
                <v-radio :value="0" label="لا"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" xs="12" class="py-0 mb-5">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('realmor')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                هل العقار مرهون؟
              </div>
              <v-radio-group row v-model="agent.real_estate.mortgage" class="mr-n4 px-0">
                <v-radio :value="1" label="نعم"></v-radio>
                <v-radio :value="0" label="لا"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row> -->
        </v-form>
      </v-sheet>
    </template>
  </page-component>
</template>

<script>
import Axios from "axios";
export default {
  props: ["agent"],
  data() {
    return {
      date: {
        birth_date: "",
        birth_date_higri: "",
      },
      estate_types: [],
      birth_date: true,
    };
  },
  created() {
    Axios.get(`${this.$store.state.url}/agent/RealEstate/Types`, {
      headers: this.request_headers(),
    }).then((res) => {
      this.estate_types = res.data.payload;
    });
  },
  computed: {
    for_agent() {
      return (
        this.$store.state.request_info.statusReq == 3 ||
        this.$store.state.request_info.statusReq == 5 ||
        this.$store.state.request_info.statusReq == 7 ||
        this.$store.state.request_info.statusReq == 10
      );
    },
  },
  watch: {
    agent: {
      handler(val) {
        if (val) {
          this.$emit("update:agent", val);
          this.$store.state.request_info = val;
          this.date.birth_date = val.customer.birth_date;
          this.date.birth_date_higri = val.customer.birth_date_higri;
          val.customer.work = parseInt(val.customer.work);
          this.setBreadCrumbs(["الرئيسية", "الطلبات", val.customer.name]);
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
