<template>
  <div>


    <v-list-item @click="convertRequest">
      <v-list-item-icon class="ml-2">
        <ConvertRequestIcon />
      </v-list-item-icon>
      <v-list-item-title>تحويل الطلب</v-list-item-title>
    </v-list-item>

    <v-list-item @click="getRequestCalls(item)">
      <v-list-item-icon class="ml-3">
        <RequestCallsIcon />
      </v-list-item-icon>
      <v-list-item-title>المكالمات</v-list-item-title>
    </v-list-item>

    <v-list-item @click="toOrderRequest(item)">
      <v-list-item-icon class="ml-2">
        <OrderHistory />
      </v-list-item-icon>
      <v-list-item-title>سجل الطلب</v-list-item-title>
    </v-list-item>

  </div>
</template>

<script>
import {ConvertRequestIcon} from "@/assets/adminIcons/convertRequest";
import {OrderHistory} from "@/assets/adminIcons/orderHistory";
import Axios from "axios";
import { RequestCallsIcon } from "@/assets/adminIcons/requestCalls";

export default {
  name: "SingleProcess",
  props: ['item'],
  components: { ConvertRequestIcon, OrderHistory,RequestCallsIcon},


  methods: {

    toOrderRequest(item) {
      this.$store.state.orderRequestId = item;
      if (this.$store.state.showCards) {
        this.$store.state.orderRequestDialog = true;
      } else {
        this.$store.state.openOrderRequestSection = true;
      }
    },

    getRequestCalls(item){
      this.$store.state.idOfRequestCalls = item;
      this.$store.state.openCallsDialog = true;
    },


    convertRequest() {
      this.getUserAdmin();
      this.$store.state.openDialog = true;
      this.$store.state.selectId = this.item
    },

    getUserAdmin() {
      let url = `${this.$store.state.url}/v2/employee/sales-agent-users`
      Axios.get(url, {headers: this.request_headers()}).then(res => {
        this.$store.state.allStatusAgent = res.data.payload
      })
    },
  },

}
</script>