<template>
  <div>
    <AlertsForms @filterData="handleFilter" @alertIsAdded="getTableData"/>
    <div v-if="!this.$store.state.loadingData">
      <table-component
          :showSelect="false"
          itemKey="id"
          :headers="headers"
          :pagination="this.$store.state.pagination"
          :limit="10"
          :items="this.$store.state.tableData"
          :loading="loading"
          @refresh="getTableData"
          v-model="page"
      >
        <template v-slot:[`item.action`]="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  color="transparent"
                  small
                  depressed
                  dark
                  v-bind="attrs"
                  v-on="on"
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="handleShowAlert(item.id)">
                <v-list-item-icon class="ml-1">
                 <img
                      src="@/assets/icons/titcketIcon.svg"
                      alt="image"
                  />
                </v-list-item-icon>
                <v-list-item-title>أستعراض  </v-list-item-title>
              </v-list-item>
              <v-list-item @click="handleUserStatus(item)" v-if="item.status == 'غير نشط'">
                <v-list-item-icon class="ml-3">
                  <img
                      style="width: 25px;"
                      src="@/assets/icons/unlock.svg"
                      alt="image"
                  />
                </v-list-item-icon>
                <v-list-item-title>تنشيط</v-list-item-title>
              </v-list-item>

              <v-list-item @click="handleUserStatus(item)" v-if="item.status == 'نشط'">
                <v-list-item-icon class="ml-3">
                  <img
                      style="width: 25px;"
                      src="@/assets/icons/lock.svg"
                      alt="image"
                  />
                </v-list-item-icon>
                <v-list-item-title>عدم تنشيط</v-list-item-title>
              </v-list-item>
              <v-list-item @click="openAlert(item.id)">
                <v-list-item-icon class="ml-1">
                  <img src="./assets/3.svg" alt="edit" style="    width: 17px;" />

<!--                  <img
                      src="@/assets/icons/titcketIcon.svg"
                      alt="image"
                  />-->
                </v-list-item-icon>
                <v-list-item-title>تعديل  </v-list-item-title>
              </v-list-item>
              <v-list-item @click="DeleteAlert(item.id)">
                <v-list-item-title>
                  <v-icon class="ml-1" size="20">mdi-trash-can</v-icon>
                  حذف
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <span
              :class="{
              text__success: item['status'] === 'نشط',
              text__faild: item['status'] !== 'نشط',
            }"
          >{{ item.status }}</span
          >
        </template>
<!--
       <template slot="item.attachment" slot-scope="props">
          <template v-if="props.item.attachment != 'لا يوجد مرفق'">
            <div v-if="isImage(props.item.attachment)">
            <img
                :src="props.item.attachment"
                alt="Image"
                style="width: 50px; height: 50px;"
            />
            </div>
            <div v-else v-html="`<a href='${props.item.attachment}' download>تحميل الملف</a>`"></div>

          </template>
          <span v-else>لا يوجد مرفق </span>

        </template>-->
      </table-component>
    </div>
    <div class="loadingMore" v-else>
      <LoadingMore />
    </div>

  </div>
</template>

<style lang="scss">
.loadingMore {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}
.text__success {
  color: green;
}

.text__faild {
  color: red;
}
th.text-start.sortable {
  text-align: center !important;
}
i.v-icon.notranslate.mdi.mdi-dots-vertical.theme--dark {
  background: rgb(63, 14, 64);
  border-radius: 4px;
}
</style>

<script>
import Axios from "axios";
import AlertsForms from "./AlertsForms.vue";
export default {
  name: "alertsIndex",
  components: {
    AlertsForms,
  },
  data() {
    return {
      page: 1,
      loading: false,
      copySupportRequests: [],
      loadMoreData: false,
      loadingData: false,
      iconToggle: false,
      headers: [
        { text: "تاريخ الانشاء", value: "تاريخ الانشاء" , width: "15%"},

        { text: "المحتوي", value: "المحتوي", width: "35%"},
        /*
                { text: "الملف", value: "attachment" , width: "10%"},
        */
        { text: "تاريخ التنبيه", value: "التاريخ" , width: "15%"},
        { text: "عدد المشاهدين", value: "عدد المشاهدين" , width: "15%"},
        { text: "الحالة", value: "status" , width: "10%"},
        { text: "العمليات", value: "action", width: "10%" },
      ],
    };
  },

  watch: {
    page(val) {
      this.getTableData(val);
    },
  },

  methods: {
    isImage(url) {
      if (!url) return false;
      const fileExtension = url.split('.').pop().toLowerCase();
      return ['jpg', 'jpeg', 'png', 'gif', 'bmp'].includes(fileExtension);
    },
    handleUserStatus(item) {
      let url = `${this.$store.state.url}/employee/admin/alerts/updateAlertStatus/${item.id}`;
      Axios.get(url, { headers: this.request_headers() })
          .then((res) => {
            this.showPopup("success", res.data.message);
            this.getTableData();
          })
          .catch((err) => {
            let error = { err };
            this.showPopup("error", error.err.response.data.message);
          });
    },
    setTbData(items) {
      const fileUrl = items.attachment; // Assuming items.image contains the URL to the file
      const fileExtension = fileUrl.split('.').pop().toLowerCase();
      const isImage = ['jpg', 'jpeg', 'png', 'gif', 'bmp'].includes(fileExtension);
      const FilePath =isImage
         ? `<img src="${fileUrl}" alt="Image" style="width: 50px; height: 50px;"/>`
         : `<a href="${fileUrl}" download>تحميل الملف</a>`;
      this.$store.state.tableData.push({
        id: items.id,
        "المحتوي": items.content,
        "attachment" :  items.has_attachment ? FilePath: "لا يوجد مرفق",
        "التاريخ": items.end_at?? "لا يوجد",
        "تاريخ الانشاء": items.created_at,
        "عدد المشاهدين": items.seen_count,

        "status": items.status == 1 ? "نشط": "غير نشط",
      });
    },

    openAlert(id){
      // The last Task
      this.$store.state.open_edit_dialog_alert = true;
      this.$store.state.id_of_alert = id;
    },
    handleShowAlert(id){
      // The last Task
      this.$store.state.open_edit_dialog_alert = true;
      this.$store.state.id_of_alert = id;
      this.$router.push({
        name: "alert-seen",
        params: { id: id },
      });
    },

    DeleteAlert(id){
      Axios.get(`${this.$store.state.url}/employee/admin/alerts/deleteAlert/${id}`,{headers: this.request_headers(),})
          .then((res) => {
            this.showPopup("success", res.data.message);
            this.getTableData(this.page);
          })
          .catch((err) => {
            this.showPopup("error", err.response.data.message);
          })
    },

    getTableData(count) {
      this.$store.state.tableData = [];
      this.loading = true;
      Axios.get(`${this.$store.state.url}/employee/admin/alerts/all?page=${count}`, {
        headers: this.request_headers(),
      }).then((res) => {
        console.log(res.data.payload);

        res.data.payload.data.map((item) => {
          this.setTbData(item);
        });

        this.$store.state.pagination = {
          limit: 3,
          count: res.data.payload.count_of_alerts,
        };

        this.loading = false;
      });
    },


    handleFilter(val) {
      this.$store.state.tableData = [];
      val.data.map((res) => {
        this.setTbData(res);
      });
    },

  },

  created() {
    this.$store.state.tableData = [];
    this.getTableData(1);
  },
};
</script>
