<template>
    <div>
        <v-form style="margin-top: 20px">
            <v-row>

                <v-col cols="12" sm="6" md="6">
                    <PickerInput ref="pickerInput8" label="تاريخ الدخول من" @dateValue="saveRecievedDateFromReport" />
                </v-col>

                <v-col cols="12" sm="6" md="6">
                    <PickerInput ref="pickerInput9" label="تاريخ الدخول الي" @dateValue="saveRecievedDateToReport" />
                </v-col>

                <v-col cols="12" sm="6" md="6">
                    <PickerInput ref="pickerInput10" label="تاريخ الخروج من" @dateValue="saveExitDateFromReport" />
                </v-col>

                <v-col cols="12" sm="6" md="6">
                    <PickerInput ref="pickerInput11" label="تاريخ الخروج الي" @dateValue="saveExitDateToReport" />
                </v-col>

            </v-row>
        </v-form>
    </div>
</template>


<script>
import PickerInput from "@/components/AdminComponent/PickerInput.vue";

export default {
    components: { PickerInput },
    data() {
        return {
            ExitDateFromReport: "",
            ExitDateToReport: "",
            RecievedDateFromReport: "",
            RecievedDateToReport: "",
        };
    },

    methods: {
        sendDataToParent() {
            this.$emit("send-fundingDuration-data", {
                ExitDateFromReport: this.ExitDateFromReport,
                ExitDateToReport: this.ExitDateToReport,
                RecievedDateFromReport: this.RecievedDateFromReport,
                RecievedDateToReport: this.RecievedDateToReport,
            });
        },

        saveExitDateFromReport(val) {
            this.ExitDateFromReport = val;
            this.sendDataToParent(val);
        },
        saveExitDateToReport(val) {
            this.ExitDateToReport = val;
            this.sendDataToParent(val);
        },
        saveRecievedDateFromReport(val) {
            this.RecievedDateFromReport = val;
            this.sendDataToParent(val);
        },
        saveRecievedDateToReport(val) {
            this.RecievedDateToReport = val;
            this.sendDataToParent(val);
        },
    },
};
</script>
