<template>
  <v-card elevation="1" class="main__table">
    <v-data-table
      :headers="myHeaders"
      :items="items"
      :item-key="itemKey"
      :single-select="singleSelect"
      :loading-text="loadingText"
      :dense="dense"
      :no-data-text="noDataText"
      :page.sync="page"
      :items-per-page="limit"
      :custom-filter="customFilter"
      :show-select="showSelect"
      :loading="loading"
      @click:row="rowClickEvent"
      hide-default-footer
      :options.sync="optionsObj"
      :sortBy.sync="sortAr"
      v-model="selectedItems"
      mobile-breakpoint="990"
      :item-class="itemRowBackground"
      :hide-default-header="isProductivity() || isMortgage()"
    >

      <template v-slot:header v-if="returnProductivityV3 || returnMortgageV3">
        <thead>
        <tr>
          <th
              v-for="header in myHeaders"
              :key="header.value"
              :style="{ textAlign: header.align, width: header.width }"
          >
            <template v-if="header.tooltip">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">{{ header.text }}</span>
                </template>
                <span class="custom-tooltip ">{{ header.tooltip }}</span>
              </v-tooltip>
            </template>
            <template v-else>
              <span>{{ header.text }}</span>
            </template>

          </th>
        </tr>
        </thead>
      </template>
      <template slot="top" slot-scope="scope">
        <slot name="top" v-bind="scope" />
      </template>
      <!-- eslint-disable-next-line -->
      <template v-for="field in headers" :slot="slotName(field)" slot-scope="scope">
        <slot class="ma-0 pa-0 font-weight-bold" :name="slotName(field)" v-bind="scope">{{
          scope.item[field.value]
        }}</slot>
      </template>

      <template slot="top" slot-scope="scope">
        <slot name="top" v-bind="scope" />
      </template>
      <!-- eslint-disable-next-line -->
      <template v-for="field in headers" :slot="slotName(field)" slot-scope="scope">
        <slot class="ma-0 pa-0 font-weight-bold" :name="slotName(field)" v-bind="scope"
          >{{ scope.item[field.value] }}
        </slot>
      </template>

      <!-- <template v-slot:footer>
        <v-divider />

        <v-toolbar flat class="table-toolbar">
          <v-btn icon color="primary" class="mr-3" width="35px" @click="refresh">
            <v-icon style="color: white !important">mdi-refresh</v-icon>
          </v-btn>
          <p style="margin: 0 12px 7px 12px">عدد الصفوف في الصفحة الواحدة</p>

          <v-text-field
            outlined
            v-model="$store.state.counterOfShow"
            dense
            width="10"
            height="35"
            color="purple"
            class="pruple-border"
            style="min-width: unset; max-width: 60px !important"
          ></v-text-field>
          <span class="font-italic mr-2" style="position: absolute; right: 50%">
            صفحه {{ page }}
            <span v-if="pagination != null"
              >من {{ pages }}, إجمالي البيانات {{ results }}</span
            >
          </span>
          <v-spacer></v-spacer>
          <div style="margin: 0 0 20px 20px">
            <v-btn
              text
              small
              color="primary"
              :disabled="page == 1 || loading"
              @click="page--"
            >
              <v-icon left style="color: white !important; margin-right: 5px"
                >mdi-arrow-right</v-icon
              >
            </v-btn>
            <v-btn
              text
              small
              color="primary"
              :disabled="
                loading || pagination ? page == pages : false || items.length < limit
              "
              @click="page++"
            >
              <v-icon right style="margin-left: 5px; color: white !important"
                >mdi-arrow-left</v-icon
              >
            </v-btn>
          </div>
        </v-toolbar>
      </template> -->

      <template v-slot:footer>
        <v-divider />
        <v-toolbar flat class="table-toolbar">
          <div style="display: flex; align-items: center; position: absolute;">
            <v-pagination
              v-model="page"
              :length="pages"
              :total-visible="8"
              @input="handlePageChange"
              color="primary"
              square
    
            ></v-pagination>
            <v-text-field
              outlined
              :value="tempCounterOfShow"
              @input="updateTempCounterOfShow"
              @blur="updateCounterOfShow"
              dense
              width="10"
              height="25"
              color="purple"
              class="pruple-border"
              style="min-width: unset; max-width: 60px !important; right: 2%; margin-top: 10px;"
            ></v-text-field>
          </div>
          <span class="font-italic mr-2" style="display: flex; align-items: center; position: absolute;left: 2%;">
            صفحه {{ page }}
            <span v-if="pagination != null"
              >من {{ pages }}, إجمالي البيانات {{ results }}</span
            >
          </span>
        </v-toolbar>
      </template>
      
      <!-- eslint-disable -->
      <template v-slot:item.data-table-select="{ item, isSelected, select }">
        <v-simple-checkbox
          :value="isSelected"
          @input="select($event)"
        ></v-simple-checkbox>
      </template>

      <!-- <template v-slot:[`body.append`]="{ items }" v-if="returnDailyReportPlace">
        <tr>
          <td style="margin-right: 17px">#</td>
          <td style="margin-right: 17px">المجموع</td>
          <td
            v-if="
              headers.findIndex((res) => res.value === 'مجموع الطلبات المستلمة') !== -1
            "
          >
            {{ calculateTotal(items, "مجموع الطلبات المستلمة") }}
          </td>
          <td v-if="headers.findIndex((res) => res.value === 'المستلمة') !== -1">
            {{ calculateTotal(items, "المستلمة") }}
          </td>
          <td v-if="headers.findIndex((res) => res.value === 'المميزة') !== -1">
            {{ calculateTotal(items, "المميزة") }}
          </td>
          <td v-if="headers.findIndex((res) => res.value === 'المتابعة') !== -1">
            {{ calculateTotal(items, "المتابعة") }}
          </td>
          <td v-if="headers.findIndex((res) => res.value === 'المؤرشفة') !== -1">
            {{ calculateTotal(items, "المؤرشفة") }}
          </td>
          <td v-if="headers.findIndex((res) => res.value === 'المرسلة') !== -1">
            {{ calculateTotal(items, "المرسلة") }}
          </td>
          <td v-if="headers.findIndex((res) => res.value === 'المكتملة') !== -1">
            {{ calculateTotal(items, "المكتملة") }}
          </td>
          <td v-if="headers.findIndex((res) => res.value === 'المحدث عليها') !== -1">
            {{ calculateTotal(items, "المحدث عليها") }}
          </td>
          <td v-if="headers.findIndex((res) => res.value === 'تم فتحها') !== -1">
            {{ calculateTotal(items, "تم فتحها") }}
          </td>
          <td v-if="headers.findIndex((res) => res.value === 'المهام المستلمة') !== -1">
            {{ calculateTotal(items, "المهام المستلمة") }}
          </td>
          <td v-if="headers.findIndex((res) => res.value === 'المهام المرسلة') !== -1">
            {{ calculateTotal(items, "المهام المرسلة") }}
          </td>
          <td v-if="headers.findIndex((res) => res.value === 'التذكيرات') !== -1">
            {{ calculateTotal(items, "التذكيرات") }}
          </td>
          <td
            v-if="headers.findIndex((res) => res.value === 'الطلبات المحولة منه') !== -1"
          >
            {{ calculateTotal(items, "الطلبات المحولة منه") }}
          </td>
          <td
            v-if="headers.findIndex((res) => res.value === 'الطلبات المحولة إليه') !== -1"
          >
            {{ calculateTotal(items, "الطلبات المحولة إليه") }}
          </td>
          <td v-if="headers.findIndex((res) => res.value === 'المستقبلة - المحولة منه') !== -1">
            {{ calculateTotal(items, "المستقبلة - المحولة منه") }}
          </td>
          <td></td>
        </tr>
      </template> -->

      <template  :v-sticky="1" v-slot:[`body.append`]="{ items }" v-if="returnProductivity">
        <tr style="font-weight: bold">
          <td class="td text-center11" style="margin-right: 17px">المجموع</td>
          <td class="td text-center11" v-if="headers.findIndex((res) => res.value === 'المستقبلة') !== -1">{{ dataVal.total_recived_request_sum }}</td>
          <td class="td text-center11" v-if="headers.findIndex((res) => res.value === 'قبول أولي') !== -1">{{ dataVal.followed_basket_possible_to_sell_sum }}</td>
          <td class="td text-center11" v-if="headers.findIndex((res) => res.value === 'المتابعه') !== -1">{{ dataVal.possible_to_sell_to_customer_sum }}</td>
          <td class="td text-center11" v-if="headers.findIndex((res) => res.value === '%المتابعه') !== -1">{{ dataVal.followed_basket_possible_to_sell_percentage_sum }}</td>
          <td class="td text-center11" v-if="headers.findIndex((res) => res.value === 'مرفوعه مبيعات') !== -1">{{ dataVal.sent_basket_sum }}</td>
          <td class="td text-center11" v-if="headers.findIndex((res) => res.value === 'مرفوضه مبيعات') !== -1">{{ dataVal.sent_basket_reject_sum }}</td>
          <td class="td text-center11" v-if="headers.findIndex((res) => res.value === '%مبيعات') !== -1">{{ dataVal.sent_basket_percentage_sum }}</td>
          <td class="td text-center11" v-if="headers.findIndex((res) => res.value === 'مرفوعه تمويل') !== -1">{{ dataVal.sent_basket_funding_sum }}</td>
          <td class="td text-center11" v-if="headers.findIndex((res) => res.value === 'مرفوضة تمويل') !== -1">{{ dataVal.sent_basket_funding_reject_sum }}</td>
          <td class="td text-center11" v-if="headers.findIndex((res) => res.value === 'قائم تمويل') !== -1">{{ dataVal.waiting_fm_status_sum }}</td>
          <td class="td text-center11" v-if="headers.findIndex((res) => res.value === '%تمويل') !== -1">{{ dataVal.sent_basket_mortgage_send_basket_percentage_sum }}</td>
          <td class="td text-center11" v-if="headers.findIndex((res) => res.value === 'مرفوعه رهن') !== -1">{{ dataVal.sent_basket_mortgage_sum }}</td>
          <td class="td text-center11" v-if="headers.findIndex((res) => res.value === 'مرفوضة رهن') !== -1">{{ dataVal.sent_basket_mortgage_reject_sum }}</td>
          <td class="td text-center11" v-if="headers.findIndex((res) => res.value === 'قائم رهن') !== -1">{{ dataVal.waiting_mm_status_sum }}</td>
          <td class="td text-center11" v-if="headers.findIndex((res) => res.value === '%رهن') !== -1">{{ dataVal.sent_basket_funding_send_basket_percentage_sum }}</td>
          <td class="td text-center11" v-if="headers.findIndex((res) => res.value === 'مفرغة') !== -1">{{ dataVal.completed_request_sum }}</td>
          <td class="td text-center11" v-if="headers.findIndex((res) => res.value === '%مفرغة') !== -1">{{ dataVal.completed_request_percentage_sum }}</td>
        </tr>
      </template>
      <template  :v-sticky="1" v-slot:[`body.append`]="{ items }" v-if="returnRequestsStatusReport">
        <tr style="font-weight: bold">
          <td class="td text-center11" style="margin-right: 17px">#</td>
          <td class="td text-center11" style="margin-right: 17px">المجموع</td>
          <td class="td text-center11" v-if="headers.findIndex((res) => res.value === 'status_0') !== -1">{{ dataVal.status_0 }}</td>
          <td class="td text-center11" v-if="headers.findIndex((res) => res.value === 'status_1') !== -1">{{ dataVal.status_1 }}</td>
          <td class="td text-center11" v-if="headers.findIndex((res) => res.value === 'status_2') !== -1">{{ dataVal.status_2 }}</td>
          <td class="td text-center11" v-if="headers.findIndex((res) => res.value === 'status_3') !== -1">{{ dataVal.status_3 }}</td>
          <td class="td text-center11" v-if="headers.findIndex((res) => res.value === 'status_4') !== -1">{{ dataVal.status_4 }}</td>
          <td class="td text-center11" v-if="headers.findIndex((res) => res.value === 'status_6') !== -1">{{ dataVal.status_6 }}</td>
          <td class="td text-center11" v-if="headers.findIndex((res) => res.value === 'status_7') !== -1">{{ dataVal.status_7 }}</td>
          <td class="td text-center11" v-if="headers.findIndex((res) => res.value === 'status_9') !== -1">{{ dataVal.status_9 }}</td>
          <td class="td text-center11" v-if="headers.findIndex((res) => res.value === 'status_10') !== -1">{{ dataVal.status_10 }}</td>
          <td class="td text-center11" v-if="headers.findIndex((res) => res.value === 'status_12') !== -1">{{ dataVal.status_12 }}</td>
          <td class="td text-center11" v-if="headers.findIndex((res) => res.value === 'status_13') !== -1">{{ dataVal.status_13 }}</td>
          <td class="td text-center11" v-if="headers.findIndex((res) => res.value === 'status_15') !== -1">{{ dataVal.status_15 }}</td>
          <td class="td text-center11" v-if="headers.findIndex((res) => res.value === 'status_16') !== -1">{{ dataVal.status_16 }}</td>
          <td class="td text-center11" v-if="headers.findIndex((res) => res.value === 'status_17') !== -1">{{ dataVal.status_17 }}</td>
          <td class="td text-center11" v-if="headers.findIndex((res) => res.value === 'status_19') !== -1">{{ dataVal.status_19 }}</td>
          <td class="td text-center11" v-if="headers.findIndex((res) => res.value === 'status_24') !== -1">{{ dataVal.status_24 }}</td>
          <td class="td text-center11" v-if="headers.findIndex((res) => res.value === 'status_28') !== -1">{{ dataVal.status_28 }}</td>
          <td class="td text-center11" v-if="headers.findIndex((res) => res.value === 'status_29') !== -1">{{ dataVal.status_29 }}</td>
        </tr>
      </template>
      <template  :v-sticky="1" v-slot:[`body.append`]="{ items }" v-else-if="returnCallsReport">
        <tr style="font-weight: bold">
          <td class="td text-center11" style="margin-right: 17px">#</td>
          <td class="td text-center11" style="margin-right: 17px">المجموع</td>
          <td class="td text-center11" v-if="headers.findIndex((res) => res.value == 'المكالمات الصادرة') !== -1">{{ dataVal.ongoing_count }}</td>
          <td class="td text-center11" v-if="headers.findIndex((res) => res.value == 'مدة الصادرة') !== -1">{{ dataVal.ongoing_sum }}</td>
          <td class="td text-center11" v-if="headers.findIndex((res) => res.value == 'المكالمات الواردة') !== -1">{{ dataVal.incoming_count }}</td>
          <td class="td text-center11" v-if="headers.findIndex((res) => res.value == 'مدة الواردة') !== -1">{{ dataVal.incoming_sum }}</td>
        </tr>
      </template>
      <template  :v-sticky="1" v-slot:[`body.append`]="{ items }" v-else-if="returnDailyReport">
        <tr style="font-weight: bold">
          <td class="td text-center11" style="margin-right: 17px">#</td>
          <td class="td text-center11" style="margin-right: 17px">المجموع</td>
          <td class="td text-center11" v-if="headers.findIndex((res) => res.value == 'مجموع الطلبات المستلمة') !== -1">{{ dataVal.total_received_request }}</td>
          <td class="td text-center11" v-if="headers.findIndex((res) => res.value == 'المستلمة') !== -1">{{ dataVal.received_basket }}</td>
          <td class="td text-center11" v-if="headers.findIndex((res) => res.value == 'المميزة') !== -1">{{ dataVal.star_basket }}</td>
          <td class="td text-center11" v-if="headers.findIndex((res) => res.value == 'المتابعة') !== -1">{{ dataVal.followed_basket }}</td>
          <td class="td text-center11" v-if="headers.findIndex((res) => res.value == 'المؤرشفة') !== -1">{{ dataVal.archived_basket }}</td>
          <td class="td text-center11" v-if="headers.findIndex((res) => res.value == 'المرسلة') !== -1">{{ dataVal.sent_basket }}</td>
          <td class="td text-center11" v-if="headers.findIndex((res) => res.value == 'المكتملة') !== -1">{{ dataVal.completed_request }}</td>
          <td class="td text-center11" v-if="headers.findIndex((res) => res.value == 'المحدث عليها') !== -1">{{ dataVal.updated_request }}</td>
          <td class="td text-center11" v-if="headers.findIndex((res) => res.value == 'تم فتحها') !== -1">{{ dataVal.opened_request }}</td>
          <td class="td text-center11" v-if="headers.findIndex((res) => res.value == 'المهام المستلمة') !== -1">{{ dataVal.received_task }}</td>
          <td class="td text-center11" v-if="headers.findIndex((res) => res.value == 'المهام المرسلة') !== -1">{{ dataVal.replayed_task }}</td>
          <td class="td text-center11" v-if="headers.findIndex((res) => res.value == 'التذكيرات') !== -1">{{ dataVal.missed_reminders }}</td>
          <td class="td text-center11" v-if="headers.findIndex((res) => res.value == 'الطلبات المحولة منه') !== -1">{{ dataVal.move_request_from }}</td>
          <td class="td text-center11" v-if="headers.findIndex((res) => res.value == 'الطلبات المحولة إليه') !== -1">{{ dataVal.move_request_to }}</td>
          <td class="td text-center11" v-if="headers.findIndex((res) => res.value == 'المستقبلة - المحولة منه') !== -1">{{ dataVal.total_received_moved_request_arr }}</td>
          <td class="td text-center11" v-if="headers.findIndex((res) => res.value == 'التحكم') !== -1"></td>
        </tr>
      </template>

      <template  :v-sticky="1" v-slot:[`body.append`]="{ items }" v-else-if="returnDuplicatedRegister">
        <tr style="font-weight: bold;">
          <td class="td text-right" colspan="2" style="padding-right: 80px;">المجموع</td>
          <td class="td text-right" v-if="headers.findIndex((res) => res.value === 'عدد محاولات التسجيل')">{{ sum_of_duplicated_register }}</td>
        </tr>
      </template>

      <template  :v-sticky="1" v-slot:[`body.prepend`]="{ items }" v-else-if="returnRequestSources">
       <tr style="font-weight: bold">
         <td class="td text-center11" v-for="(val,key) in sum_of_each_source"  v-if="headers.findIndex((res) => res.value === key) !== -1">{{ val }}</td>
       </tr>
        <tr>
          <td class="td text-center11" v-for="(dub,key) in duplicatedLogin"  v-if="headers.findIndex((res) => res.value === key) !== -1">{{ dub }}</td>
        </tr>
      </template>

      <template  :v-sticky="1" v-slot:[`body.append`]="{ items }" v-else-if="returnFundingDurationRequests">
        <tr style="font-weight: bold;">
          <td class="td text-right" colspan="2" style="padding-right: 80px;">مجموع المدة بالايام</td>
          <td class="td text-right" style="padding-right: 80px;">#</td>
          <td class="td text-right" style="padding-right: 80px;">#</td>
          <td class="td text-right" style="padding-right: 80px;">#</td>
          <td class="td text-right" style="padding-right: 75px;" v-if="headers.findIndex((res) => res.value === 'المدة بالايام')">{{ sum_of_funding_duration_average }}</td>
          <td class="td text-right" style="padding-right: 80px;">#</td>
          <td class="td text-right" style="padding-right: 80px;">#</td>
        </tr>
      </template>
      <template  v-slot:[`body.append`]="{ items }"  v-else-if="returnProductivityV3">
        <tr style="font-weight: bold">
          <td class="td text-center" style="margin-right: 17px">المجموع</td>
          <td class="td text-center" v-if="headers.findIndex((res) => res.value === 'المستقبلة') !== -1">{{ dataVal.total_recived_request_sum }}</td>
          <td class="td text-center" v-if="headers.findIndex((res) => res.value === 'قبول أولي') !== -1">{{ dataVal.followed_basket_possible_to_sell_sum }}</td>
          <td class="td text-center" v-if="headers.findIndex((res) => res.value === 'المتابعه') !== -1">{{ dataVal.possible_to_sell_to_customer_sum }}</td>
          <td class="td text-center" v-if="headers.findIndex((res) => res.value === '%المتابعه') !== -1">{{ dataVal.followed_basket_possible_to_sell_percentage_sum }}</td>
          <td class="td text-center" v-if="headers.findIndex((res) => res.value === 'مرفوعه مبيعات') !== -1">{{ dataVal.sent_basket_sum }}</td>
          <td class="td text-center" v-if="headers.findIndex((res) => res.value === 'مرفوضه مبيعات') !== -1">{{ dataVal.sent_basket_reject_sum }}</td>
          <td class="td text-center" v-if="headers.findIndex((res) => res.value === '%مبيعات') !== -1">{{ dataVal.sent_basket_percentage_sum }}</td>
          <td class="td text-center" v-if="headers.findIndex((res) => res.value === 'مرفوعه تمويل') !== -1">{{ dataVal.sent_basket_funding_sum }}</td>
          <td class="td text-center" v-if="headers.findIndex((res) => res.value === 'مرفوضة تمويل') !== -1">{{ dataVal.sent_basket_funding_reject_sum }}</td>
          <td class="td text-center" v-if="headers.findIndex((res) => res.value === 'قائم تمويل') !== -1">{{ dataVal.waiting_fm_status_sum }}</td>
          <td class="td text-center" v-if="headers.findIndex((res) => res.value === '%تمويل') !== -1">{{ dataVal.sent_basket_mortgage_send_basket_percentage_sum }}</td>
          <td class="td text-center" v-if="headers.findIndex((res) => res.value === 'مرفوعه رهن') !== -1">{{ dataVal.sent_basket_mortgage_sum }}</td>
          <td class="td text-center" v-if="headers.findIndex((res) => res.value === 'مرفوضة رهن') !== -1">{{ dataVal.sent_basket_mortgage_reject_sum }}</td>
          <td class="td text-center" v-if="headers.findIndex((res) => res.value === 'قائم رهن') !== -1">{{ dataVal.waiting_mm_status_sum }}</td>
          <td class="td text-center" v-if="headers.findIndex((res) => res.value === '%رهن') !== -1">{{ dataVal.sent_basket_funding_send_basket_percentage_sum }}</td>
          <td class="td text-center" v-if="headers.findIndex((res) => res.value === 'مفرغة') !== -1">{{ dataVal.completed_request_sum }}</td>
          <td class="td text-center" v-if="headers.findIndex((res) => res.value === '%مفرغة') !== -1">{{ dataVal.completed_request_percentage_sum }}</td>
        </tr>
      </template>
      <template  v-slot:[`body.append`]="{ items }"  v-else-if="returnMortgageV3">
        <tr style="font-weight: bold">
          <td class="td text-center" style="margin-right: 17px">المجموع</td>
          <td class="td text-center" v-if="headers.findIndex((res) => res.value === 'مرفوعه رهن') !== -1">{{ dataValMort.sent_basket_mortgage_sum }}</td>
          <td class="td text-center" v-if="headers.findIndex((res) => res.value === 'مرفوضة رهن') !== -1">{{ dataValMort.sent_basket_mortgage_reject_sum }}</td>
          <td class="td text-center" v-if="headers.findIndex((res) => res.value === 'قائم رهن') !== -1">{{ dataValMort.waiting_mm_status_sum }}</td>
        </tr>
      </template>
      <template v-slot:[`body.append`]="{ items }" v-else-if="returnDailyReportPlace">
        <tr>
          <td style="margin-right: 17px">#</td>
          <td style="margin-right: 17px">المجموع</td>
          <td
            v-if="
              headers.findIndex((res) => res.value === 'مجموع الطلبات المستلمة') !== -1
            "
          >
            {{ calculateTotal(items, "مجموع الطلبات المستلمة") }}
          </td>
          <td v-if="headers.findIndex((res) => res.value === 'المستلمة') !== -1">
            {{ calculateTotal(items, "المستلمة") }}
          </td>
          <td v-if="headers.findIndex((res) => res.value === 'المميزة') !== -1">
            {{ calculateTotal(items, "المميزة") }}
          </td>
          <td v-if="headers.findIndex((res) => res.value === 'المتابعة') !== -1">
            {{ calculateTotal(items, "المتابعة") }}
          </td>
          <td v-if="headers.findIndex((res) => res.value === 'المؤرشفة') !== -1">
            {{ calculateTotal(items, "المؤرشفة") }}
          </td>
          <td v-if="headers.findIndex((res) => res.value === 'المرسلة') !== -1">
            {{ calculateTotal(items, "المرسلة") }}
          </td>
          <td v-if="headers.findIndex((res) => res.value === 'المكتملة') !== -1">
            {{ calculateTotal(items, "المكتملة") }}
          </td>
          <td v-if="headers.findIndex((res) => res.value === 'المحدث عليها') !== -1">
            {{ calculateTotal(items, "المحدث عليها") }}
          </td>
          <td v-if="headers.findIndex((res) => res.value === 'تم فتحها') !== -1">
            {{ calculateTotal(items, "تم فتحها") }}
          </td>
          <td v-if="headers.findIndex((res) => res.value === 'المهام المستلمة') !== -1">
            {{ calculateTotal(items, "المهام المستلمة") }}
          </td>
          <td v-if="headers.findIndex((res) => res.value === 'المهام المرسلة') !== -1">
            {{ calculateTotal(items, "المهام المرسلة") }}
          </td>
          <td v-if="headers.findIndex((res) => res.value === 'التذكيرات') !== -1">
            {{ calculateTotal(items, "التذكيرات") }}
          </td>
          <td
            v-if="headers.findIndex((res) => res.value === 'الطلبات المحولة منه') !== -1"
          >
            {{ calculateTotal(items, "الطلبات المحولة منه") }}
          </td>
          <td
            v-if="headers.findIndex((res) => res.value === 'الطلبات المحولة إليه') !== -1"
          >
            {{ calculateTotal(items, "الطلبات المحولة إليه") }}
          </td>
          <td v-if="headers.findIndex((res) => res.value === 'المستقبلة - المحولة منه') !== -1">
            {{ calculateTotal(items, "المستقبلة - المحولة منه") }}
          </td>
          <td></td>
        </tr>
      </template> 

      <template  :v-sticky="1" v-slot:[`body.append`]="{ items }" v-else-if="returnRequestSourcesChart">
        <tr style="font-weight: bold;">
          <td class="td text-right">المجموع</td>
          <td class="td text-right" v-if="headers.findIndex((res) => res.value === 'مجموع الايجابي')">{{ requestSourceData.positive }}</td>
          <td class="td text-right" v-if="headers.findIndex((res) => res.value === 'نسبة الايجابي')">#</td>
          <td class="td text-right" v-if="headers.findIndex((res) => res.value === 'مجموع السلبي')">{{ requestSourceData.negative }}</td>
          <td class="td text-right" v-if="headers.findIndex((res) => res.value === 'نسبة السلبي')">#</td>
          <td class="td text-right" v-if="headers.findIndex((res) => res.value === 'مجموع بدون')">{{ requestSourceData.null }}</td>
          <td class="td text-right" v-if="headers.findIndex((res) => res.value === 'مجموع المفرغ')">{{ requestSourceData.completed }}</td>
          <td class="td text-right" v-if="headers.findIndex((res) => res.value === 'المجموع')">{{ requestSourceData.positive+requestSourceData.negative+requestSourceData.null+requestSourceData.completed  }}</td>
        </tr>
      </template>

    </v-data-table>
  </v-card>
</template>
<style>
/* Tooltip custom styling */

.v-tooltip {
  max-width: 250px; /* Set a maximum width for the tooltip */
  white-space: normal; /* Allow text wrapping */
}
.custom-tooltip {

}
</style>
<script>
import Axios from "axios";
import ProductivityReportFilter from "@/views/admin/Charts/ProductivityReportFilter";
import { EventBus } from '@/mixins/eventBus.js';

export default {
  name: "TableComponent",
  props: {
    dense: { default: false },
    pagination: { default: null },
    sum_of_duplicated_register: { default: null },
    sum_of_funding_duration_average: { default: null },
    requestSourceData: { default: null },
    value: { default: 1 },
    limit: { default: 12 },
    headers: { required: true },
    formBody: { default: undefined },
    itemKey: { default: undefined },
    singleSelect: { default: false },
    showSelect: { default: false },
    items: { required: true },
    loading: { default: false },
    children: { type: Array },
    rowClickEvent: {
      type: Function,
      default: () => {},
    },
    loadingText: { default: "جاري تحميل البيانات ..." },
    noDataText: { default: "لا توجد بيانات" },
    selectedRows: { type: Array },
    customFilter: {
      default: () => {},
    },

    options: { required: false },
    sortBy: { required: false },
  },
  data() {
    return {
      tempCounterOfShow: this.limit,
      duplicatedLogin: [],
      selectedItems: [],
      sum_of_each_source: [],
      getSumProductivity: [],
      body: {},
      tooltipVisible: false,
      tooltipContent: "",
      tooltipStyle: {
        position: "absolute",
        top: "0px",
        left: "0px",
      },
      dataValMort: {
        sent_basket_mortgage_sum: 0,
        sent_basket_mortgage_reject_sum: 0,
        waiting_mm_status_sum: 0,
      },
      dataVal: {
        total_recived_request_sum: 0,
        followed_basket_possible_to_sell_sum: 0,
        followed_basket_possible_to_sell_percentage_sum: 0,
        completed_request_sum: 0,
        completed_request_percentage_sum: 0,
        waiting_mm_status_sum: 0,
        waiting_fm_status_sum: 0,
        possible_to_sell_to_customer_sum: 0,

        sent_basket_sum: 0,
        sent_basket_reject_sum: 0,
        sent_basket_reject_percentage_sum: 0,
        sent_basket_percentage_sum: 0,

        sent_basket_funding_sum: 0,
        sent_basket_funding_reject_sum: 0,
        sent_basket_funding_reject_percentage_sum: 0,
        sent_basket_funding_percentage_sum: 0,

        sent_basket_mortgage_sum: 0,
        sent_basket_funding_send_basket_percentage_sum: 0,
        sent_basket_mortgage_send_basket_percentage_sum: 0,
        sent_basket_mortgage_reject_sum: 0,
        sent_basket_mortgage_percentage_sum: 0,
        sent_basket_mortgage_reject_percentage_sum: 0,
      },

      newOrderId: null,
    };
  },
  watch: {
    "$store.state.orderRequestId": {
      // immediate: true,
      handler(newOrderId, oldOrderId) {
        if (newOrderId) {
          this.itemRowBackground(this.newOrderId);
        }
      },
    },
    selectedItems(newlySelectedRows) {
      this.$emit("update:selectedRows", newlySelectedRows);
    },
    page(val) {
      this.$emit("update:page", val);
    },
    limit() {
      if (this.pagination != null) {
        return this.pagination.limit;
      }
      return 10;
    },
    "$store.state.tasksRequestId": {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.itemRowBackground(this.newOrderId);
        }
      },
    },

    "$store.state.tasksDetailsRequestId": {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.itemRowBackground(this.newOrderId);
        }
      },
    },

    "$store.state.calls_search_body": {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.CallReportSum();
        }
      },
    },
    "$store.state.daily_report_search_body": {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.DailyReportSum();
        }
      },
    },
  },
  computed: {
    returnDailyReportPlace() {
      return (
        window.location.pathname === "/salesManager/chart/dailyPerformance" ||
        window.location.pathname === "/admin/charts"
      );
    },
    returnProductivity() {
      return (
        window.location.pathname === "/admin/charts/productivity" ||
        window.location.pathname === "/salesManager/charts/productivity-report"
      );
    },
    returnRequestsStatusReport() {
      return (
        window.location.pathname === "/admin/charts/requests-status"
      );
    },
    returnCallsReport() {
      return (
        window.location.pathname === "/admin/settings/request-calls"
      );
    },
    returnDailyReport() {
      return (
        window.location.pathname === "/admin/charts/dailyPerformance"
      );
    },
    returnProductivityV3() {
      return (
        window.location.pathname === "/admin/charts/productivity-v3" ||
        window.location.pathname === "/salesManager/charts/productivity-v3"
      );
    },
    returnMortgageV3() {
      return (
        window.location.pathname === "/admin/charts/mortgage-report"
      );
    },
    returnMortgageReport() {
      return (
          window.location.pathname === "/admin/charts/mortgage-report"
      );
    },
    returnRequestSourcesSales() {
      return (
        window.location.pathname === "/salesManager/charts/requests-sources"
      );
    },
    returnRequestSources() {
      return (
        window.location.pathname === "/admin/charts/requests-sources"
      );
    },

    returnDuplicatedRegister() {
      return (
        window.location.pathname === "/salesManager/chart/duplicated-register"
      );
    },

    returnFundingDurationRequests() {
      return (
        window.location.pathname === "/admin/requests/fundingDurationRequests"
      );
    },

    returnRequestSourcesChart() {
      return (
        window.location.pathname === "/admin/charts/request-sources"
      );
    },

    page: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    sortAr: {
      get() {
        return this.sortBy;
      },
      set(val) {
        this.$emit("update:sortBy", val);
      },
    },
    optionsObj: {
      get() {
        return this.options;
      },
      set(val) {
        this.$emit("update:options", val);
      },
    },
    results() {
      return this.pagination.count;
    },
    pages() {
      return Math.ceil(parseInt(this.pagination?.count) / parseInt(this.limit)) || 1;
      // return Math.ceil(parseInt(this.pagination['count']) / parseInt(this.limit)) || 1
    },
    myHeaders: {
      get: function () {
        let headers = [];
        headers = this.headers;
        !this.headers.some((item) => item.value === "data-table-select") &&
        this.showSelect
          ? headers.unshift({
              align: "start",
              value: "data-table-select",
              width: "5%",
            })
          : "";
        return headers;
      },
    },
  },
  methods: {
    resetSelectedItems(){
      this.selectedItems = [];
    },
    handlePageChange(value) {
      this.page = value;
    },

    isProductivity() {
      return (
          window.location.pathname === "/admin/charts/productivity-v3"||
          window.location.pathname === "/salesManager/charts/productivity-v3"
      );
    },
    isMortgage() {
      return (
          window.location.pathname === "/admin/charts/mortgage-report"
      );
    },
    showTooltip(event, content) {
      this.tooltipVisible = true;
      this.tooltipContent = content;
      this.tooltipStyle = {
        position: "absolute",
        top: `${event.clientY + 10}px`,
        left: `${event.clientX + 10}px`,
      };
    },
    hideTooltip() {
      this.tooltipVisible = false;
      this.tooltipContent = "";
    },
    calculateSumSources() {
      let url = `${this.$store.state.url}/employee/admin/request-sources-sums`;
      Axios.post(url, this.formBody, { headers: this.request_headers() }).then((res) => {

        this.sum_of_each_source=res.data.payload.sum_of_each_source;
        this.duplicatedLogin=res.data.payload.duplicatedLogin;
      });
    },
    calculateSumV3() {
      let url = `${this.$store.state.url}/employee/admin/getProductivitySum`;
      Axios.post(url, this.formBody, { headers: this.request_headers() }).then((res) => {
        this.dataVal.total_recived_request_sum =
            res.data.payload.total_recived_request_sum;
        this.dataVal.sent_basket_sum = res.data.payload.sent_basket_sum;
        this.dataVal.completed_request_sum = res.data.payload.completed_request_sum;
        this.dataVal.sent_basket_mortgage_sum = res.data.payload.sent_basket_mortgage_sum;
        this.dataVal.sent_basket_reject_sum = res.data.payload.sent_basket_reject_sum;
        this.dataVal.sent_basket_funding_reject_sum =
            res.data.payload.sent_basket_funding_reject_sum;
        this.dataVal.sent_basket_funding_sum = res.data.payload.sent_basket_funding_sum;
        this.dataVal.waiting_mm_status_sum = res.data.payload.waiting_mm_status_sum;
        this.dataVal.waiting_fm_status_sum = res.data.payload.waiting_fm_status_sum;
        this.dataVal.possible_to_sell_to_customer_sum = res.data.payload.possible_to_sell_to_customer_sum;
        this.dataVal.sent_basket_mortgage_reject_sum =
            res.data.payload.sent_basket_mortgage_reject_sum;
        this.dataVal.followed_basket_possible_to_sell_sum =
            res.data.payload.followed_basket_possible_to_sell_sum;
        // ----------------------------------------------
        // Percentages
        // ----------------------------------------------
        this.dataVal.followed_basket_possible_to_sell_percentage_sum =
            res.data.payload.followed_basket_possible_to_sell_percentage_sum;

        this.dataVal.sent_basket_percentage_sum =
            res.data.payload.sent_basket_percentage_sum;

        this.dataVal.sent_basket_funding_percentage_sum =
            res.data.payload.sent_basket_funding_percentage_sum;

        this.dataVal.sent_basket_mortgage_percentage_sum =
            res.data.payload.sent_basket_mortgage_percentage_sum;

        this.dataVal.sent_basket_reject_percentage_sum =
            res.data.payload.sent_basket_reject_percentage_sum;

        this.dataVal.sent_basket_funding_reject_percentage_sum =
            res.data.payload.sent_basket_funding_reject_percentage_sum;

        this.dataVal.sent_basket_mortgage_reject_percentage_sum =
            res.data.payload.sent_basket_mortgage_reject_percentage_sum;

        this.dataVal.sent_basket_funding_send_basket_percentage_sum =
            res.data.payload.sent_basket_funding_send_basket_percentage_sum;

        this.dataVal.sent_basket_mortgage_send_basket_percentage_sum =
            res.data.payload.sent_basket_mortgage_send_basket_percentage_sum;

        this.dataVal.completed_request_percentage_sum =
            res.data.payload.completed_request_percentage_sum;
      });
    },
    RequestsStatusReportSum() {
      let url = `${this.$store.state.url}/employee/admin/requests-status-report-total`;
      Axios.post(url, this.$store.state.requests_status_search_body, { headers: this.request_headers() }).then((res) => {
        this.dataVal = res.data.payload;
      });
    },
    CallReportSum() {
      let url = `${this.$store.state.url}/employee/admin/getCallsWithAgents-report-total`;
      Axios.post(url, this.$store.state.calls_search_body, { headers: this.request_headers() }).then((res) => {
        this.dataVal = res.data.payload;
      });
    },
    DailyReportSum() {
      let url = `${this.$store.state.url}/employee/admin/getAllUsers-sum`;
      Axios.post(url, this.$store.state.daily_report_search_body, { headers: this.request_headers() }).then((res) => {
        this.dataVal = res.data.payload;
      });
    },
    calculateMortgageSum() {
      let url = `${this.$store.state.url}/employee/admin/getProductivitySum`;
      Axios.post(url, this.formBody, { headers: this.request_headers() }).then((res) => {

        this.dataValMort.sent_basket_mortgage_sum = res.data.payload.sent_basket_mortgage_sum;
        this.dataValMort.sent_basket_mortgage_reject_sum = res.data.payload.sent_basket_mortgage_reject_sum;
        this.dataValMort.waiting_mm_status_sum = res.data.payload.waiting_mm_status_sum;
        this.dataValMort.sent_basket_mortgage_percentage_sum = res.data.payload.sent_basket_mortgage_percentage_sum;
      });
    },

    calculateTotal(items, column) {
      let total = 0;
      for (const item of items) {
        total += item[column];
      }
      return total;
    },
    itemRowBackground(res) {
      this.newOrderId = res;
      const isStatusOpenRequest =
          res.status === "طلب تم فتحه" &&
          !res.comment &&
          !res.class_id_agent_text &&
          window.location.pathname.includes("/agent");

      const isMatchingPath =
          (res.request?.id === this.$store.state.orderRequestId ||
              res.id === this.$store.state.orderRequestId ||
              res.request_id === this.$store.state.orderRequestId) &&
          (window.location.pathname.includes("/admin") ||
              window.location.pathname.includes("/salesManager")) &&
          (this.$store.state.openOrderRequestSection ||
              this.$store.state.orderRequestDialog);

      const isMatchingRequestTasksPath =
          (res.request?.id === this.$store.state.tasksRequestId ||
              res.id === this.$store.state.tasksRequestId ||
              res.request_id === this.$store.state.tasksRequestId) &&
          window.location.pathname.includes("/admin") &&
          this.$store.state.openTasksRequestSection;

      const isMatchingRequestTasksDetailsPath =
          (res.request?.id === this.$store.state.tasksDetailsRequestId ||
              res.id === this.$store.state.tasksDetailsRequestId ||
              res.request_id === this.$store.state.tasksDetailsRequestId) &&
          window.location.pathname.includes("/admin") &&
          this.$store.state.openTasksDetailsRequestSection;

      if (
          isStatusOpenRequest ||
          isMatchingPath ||
          isMatchingRequestTasksPath ||
          isMatchingRequestTasksDetailsPath
      ) {
        return "openRequest";
      }
    },
    slotName(field) {
      return `item.${field.value}`;
    },
    refresh() {
      this.page = 1;
      this.$emit("refresh");
    },

    updateTempCounterOfShow(newValue) {
      this.tempCounterOfShow = Number(newValue);
    },
    updateCounterOfShow() {
      this.$store.state.counterOfShow = this.tempCounterOfShow;
    }
    
  },
  created() {
    EventBus.$on('EmptySelectedRequests', this.resetSelectedItems);
    this.page = 1;
    if ( window.location.pathname === "/admin/charts/requests-sources" ) {
      this.calculateSumSources();
    }
    // this.calculateSum();
    // this.calculateMortgageSum();
    // this.calculateSumV3();
    // this.calculateSum();
    if ( window.location.pathname === "/admin/charts/mortgage-report" ) {
      this.calculateMortgageSum();
    }
    if ( window.location.pathname === "/admin/charts/productivity-v3" || window.location.pathname === "/salesManager/charts/productivity-v3") {
      this.calculateSumV3();
    }
    if ( window.location.pathname === "/admin/charts/requests-status" ) {
      this.RequestsStatusReportSum();
    }
    if ( window.location.pathname === "/admin/settings/request-calls" ) {
      this.CallReportSum();
    }
    if ( window.location.pathname === "/admin/charts/dailyPerformance" ) {
      this.DailyReportSum();
    }
    
  },
};
</script>
<style lang="scss">
.openRequest {
  background-color: #f5c4c6 !important;
}

.oddRowcolor {
  background: #e3dce3 !important;
}

.v-application .elevation-1 {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 12px 24px #12263f08 !important;
  border: 1px solid #e2e2e4 !important;
  border-radius: 3px !important;
}

.printing {
  box-shadow: 0px 12px 24px #12263f08 !important;
  border-radius: 3px !important;
  justify-content: flex-end !important;
}

.v-menu__content {
  box-shadow: 0px 10px 25px #00000026 !important;
}

.v-card {
  border-radius: unset !important;
  border: 1px solid #c9ccd2 !important;
  box-shadow: unset !important;
}

.v-data-table th {
  font-weight: bolder !important;
  font-size: 16px !important;
  color: #444444 !important;
  vertical-align: top !important;
  padding-top: 7px !important;
  background-color: #f8f9fa;
  letter-spacing: 0px;
}

.v-data-table td {
  letter-spacing: 0px;
  font-weight: 500;
  color: #5d5d5d;
  font-size: 16px !important;
}

.v-data-table tr:nth-of-type(even) {
  border-color: #f8f9fa !important;
  font-size: 16px;
  background-color: #f8f9fa;
}

.v-data-table th .v-icon {
  margin-top: -3px !important;
  margin-right: 7px !important;
  font-size: 18px !important;
}

.v-data-table__wrapper {
  border-top: 1px solid #e9e9e9;
  border-right: 1px solid #e9e9e9;
  border-left: 1px solid #e9e9e9;
}

.v-data-table thead tr:last-child > th {
  border-bottom: thin solid #e9e9e9 !important;
}

.v-data-table tr td {
  border-bottom: 1px solid #e9e9e9 !important;
}

.v-data-table tr:nth-of-type(even) {
  background-color: #f8f9fa;
}

.v-data-table tr td:last-child,
.v-data-table tr th:last-child {
  border-right: 1px solid #e2e2e2;
  /*background-color: #ffffff;
*/
  i {
    font-size: 34px;
  }

  button {
    padding: 0 !important;
  }
}

.v-data-table tr th:last-child {
  background-color: #f8f9fa;
}

@media (max-width: 990px) {
  .v-data-table > .v-data-table__wrapper .v-data-table__mobile-row {
    border-bottom: 1px solid #d1d1d1;
    padding: 17px;
  }

  .v-data-table tr td:last-child,
  .v-data-table tr th:last-child {
    border-right: 1px solid #e2e2e2;
    background-color: transparent;
  }
}

@media (max-width: 500px) {
  .v-card > *:last-child:not(.v-btn):not(.v-chip):not(.v-avatar):not(.excluded) {
    padding: 0 !important;
  }
  .v-application--is-rtl .v-data-table__mobile-row__header,
  .v-application--is-rtl .v-data-table__mobile-row__cell {
    font-size: 14px;
    margin-bottom: 10px;
  }
}

.main__table
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr
  > th {
  background: rgb(63, 14, 64);
  color: white !important;
  border-right: 1px solid white;
  border-bottom: 1px solid rgb(63, 14, 64) !important;
  height: 72px;
}

.main__table .v-data-table-header__icon {
  display: none;
}

.main__table .v-data-table th .v-icon {
  color: white;
}

.main__table .v-data-table__wrapper {
  border-radius: 12px 12px 0 0;
}

.main__table .v-data-table th .v-icon {
  width: 50px;
  height: 20px;
  background: white;
  border-radius: 3px;
}

.main__table .v-data-table tr:nth-of-type(even) {
  background-color: #e3dce3;
}

.main__table .v-input--selection-controls__input .v-icon {
  color: purple;
}

.table-toolbar .v-btn:not(.v-btn--round).v-size--small {
  color: white !important;
  margin-left: -5px;
}

.table-toolbar .v-btn__content {
  background: rgb(63, 14, 64);
  width: 36px;
  height: 36px;
  border-radius: 6px;
}

.main__table .v-input__slot {
  min-height: 30px !important;
  margin-top: 20px;
  border: 1px solid rgb(63, 14, 64);
}

.main__table .v-text-field--outlined.v-input--is-focused fieldset {
  height: 30px !important;
  border: none;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:not(.v-data-table__mobile-row),
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  font-family: "Material Design Icons";

  span {
    font-family: "Material Design Icons";
  }
}
</style>
