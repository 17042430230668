var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-simple-table',{staticClass:"mt-2 mb-4 pl-3 pr-3"},[_c('tr',[_c('td',{staticClass:"px-2 py-2",attrs:{"colspan":"2"}},[_c('h5',{staticClass:"pa-0 requestTitle",staticStyle:{"color":"#3f0f41","font-size":"16px","font-weight":"bold"}},[_vm._v(" معلومات التنبيه ")])])]),_c('tr',[_c('td',{staticClass:"px-2 py-1"},[_c('span',{staticClass:"req-type",staticStyle:{"color":"#545a69","font-size":"16px"}},[_c('b',[_vm._v(" محتوي التنبيه")])])]),_c('td',{staticClass:"px-2 py-1",attrs:{"width":"85%"}},[_vm._v(_vm._s(this.alert.content))])]),_c('tr',[_c('td',{staticClass:"px-2 py-1"},[_c('span',{staticClass:"req-type",staticStyle:{"color":"#545a69","font-size":"16px"}},[_c('b',[_vm._v(" الحالة ")])])]),_c('td',{staticClass:"px-2 py-1",attrs:{"width":"85%"}},[_c('span',{staticClass:"req-status spanRequestType",style:(`${
                  this.alert.status === 0
                    ? `background-color: #FE705B`
                    :this.alert.status === 1
                    ? `background-color: #41BF72`

                    : `background-color: #FE705B`
                }`)},[_vm._v(_vm._s(this.alert.status == 1 ? 'نشط' : 'غير نشط')+" ")])])]),_c('tr',[_c('td',{staticClass:"px-2 py-1"},[_c('span',{staticClass:"req-type",staticStyle:{"color":"#545a69","font-size":"16px"}},[_c('b',[_vm._v(" التاريخ (هل تم ارساله)")])])]),_c('td',{staticClass:"px-2 py-1",attrs:{"width":"85%"}},[_c('span',{staticClass:"req-status spanRequestType",style:(`${
                  this.alert.is_send === 'no'
                    ? `background-color: #FE705B`
                    :this.alert.is_send === 'yes'
                    ? `background-color: #41BF72`

                    : `background-color: #FE705B`
                }`)},[_vm._v(_vm._s(this.alert.is_send === 'yes' ? 'تم ارساله' : 'لم يتم ارساله')+" ")]),_c('b',{staticClass:"mx-2"},[_vm._v(" ( "+_vm._s(this.alert.end_at)+" )")])])]),_c('tr',[_c('td',{staticClass:"px-2 py-1"},[_c('span',{staticClass:"req-type",staticStyle:{"color":"#545a69","font-size":"16px"}},[_c('b',[_vm._v(" عدد المشاهدين ")])])]),_c('td',{staticClass:"px-2 py-1",attrs:{"width":"85%"}},[_vm._v(_vm._s(this.seen_count))])])]),(!this.$store.state.loadingData)?_c('div',[_c('table-component',{attrs:{"showSelect":false,"itemKey":"id","headers":_vm.headers,"pagination":this.$store.state.pagination,"limit":10,"items":this.$store.state.tableData,"loading":_vm.loading},on:{"refresh":_vm.getTableData},scopedSlots:_vm._u([{key:`item.action`,fn:function({ item }){return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"#40BE72","small":"","depressed":"","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.handleShowAlert(item.id)}}},[_c('v-list-item-icon',{staticClass:"ml-1"},[_c('img',{attrs:{"src":require("@/assets/icons/titcketIcon.svg"),"alt":"image"}})]),_c('v-list-item-title',[_vm._v("أستعراض ")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.openAlert(item.id)}}},[_c('v-list-item-icon',{staticClass:"ml-1"},[_c('img',{staticStyle:{"width":"17px"},attrs:{"src":require("./assets/3.svg"),"alt":"edit"}})]),_c('v-list-item-title',[_vm._v("تعديل ")])],1)],1)],1)]}},{key:`item.status`,fn:function({ item }){return [_c('span',{class:{
            text__success: item['status'] === 'نشط',
            text__faild: item['status'] !== 'نشط',
          }},[_vm._v(_vm._s(item.status))])]}}],null,true),model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1):_c('div',{staticClass:"loadingMore"},[_c('LoadingMore')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }