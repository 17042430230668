<template>
  <div class="container">
    <!-- Tabs -->
    <!-- <div class="tabs">
      <button 
        :class="{ active: activeTab === 'all' }" 
        @click="fetchPosts('all')">الجميع
      </button>
      <button 
        :class="{ active: activeTab === 'mine' }" 
        @click="fetchPosts('mine')">أسئلتي
      </button>
    </div> -->

    <!-- Add New Questionaire -->
    <!-- <v-card>
      <v-card-text>
        <v-row>
          <v-col cols="1">
            <v-avatar color="primary" size="50">
              <span style="color: white !important;">{{ firstTwoCharFromUser }}</span>
            </v-avatar>
          </v-col>
          <v-col cols="11">
            <v-textarea
              placeholder="قم بكتابة محتواك....."
              v-model="newPost"
              rows="2"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="d-flex align-center justify-end">
        <v-btn color="success" class="mx-5 my-1 font-weight-bold" style="border-radius: 15px;" @click="submitPost">نشر</v-btn>
      </v-card-actions>
      
    </v-card> -->

    <!-- Posts List -->

    <v-dialog v-model="dialogOfQuestionLikeusers" persistent max-width="600px">
      <v-card>
        <v-card-title class="text-h6 font-weight-bold">
          <v-icon color="primary" class="mr-2">mdi-thumb-up</v-icon>
          {{ likeText }}
        </v-card-title>

        <v-divider></v-divider>

        <!-- Scrollable Content -->
        <v-card-text style="max-height: 400px; overflow-y: auto;"  width="60%">
          <v-container>
            <v-row dense>
              <v-col 
                v-for="user in likedUsers" 
                :key="user.id" 
                cols="12" 
                sm="6" 
                md="4"
                class="d-flex align-center mb-3"
              >
                <v-avatar color="primary" size="50" class="mr-3">
                  <span style="color: white; font-weight: bold;">
                    {{ user.name.substring(0, 2).toUpperCase() }}
                  </span>
                </v-avatar>
                <div>
                  <p class="text-subtitle-2 font-weight-bold mb-1">
                    {{ user.name }}
                  </p>
                  <small class="text-caption grey--text">
                    {{ user.job_title || "No Job Title Provided" }}
                  </small>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="dialogOfQuestionLikeusers = false">
            <v-icon left>mdi-close</v-icon> إغلاق
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>




    <v-expand-transition>
    <v-row dense v-show="$store.state.q_a_search_section">
              <v-col cols="10">
                <v-text-field
                v-model="keywords"
                solo
                placeholder="كلمات البحث"
                >

                </v-text-field>
              </v-col>
              <v-col cols="2">
                <v-btn
                color="primary"
                @click="fetchPosts('all')"
                style="margin: 24px;">
                  بحث
                </v-btn>
              </v-col>
            </v-row>
            </v-expand-transition>

    <div class="posts my-5">
      <div 
        v-for="post in posts" 
        :key="post.id" 
        class="post">
        <!--<h4>بواسطة: {{ post.user.name }}</h4>-->
        <!-- <span class="d-flex align-center justify-end" style="color:#9BB4CD;">{{ post.created_at }}</span> -->
        <span class="d-flex align-center justify-end" style="color:#9BB4CD;">
          <button class="likes" @click="listQuestionLikes(post.id)" title="المعجبين" style="margin-bottom: 0; font-size: 14px;">
            
              <span><img src="../../../assets/s_like.svg" alt="" srcset="">  {{ post.likes_count }} |</span>
            </button>  {{ post.created_at }}
        </span>
        <p class="font-weight-bold">{{ post.question }}</p>
        <!-- Comments Section -->
        <div class="comments">

          <div class="post-actions d-flex align-center">
            <button class="toggle-comments" style="color: #5c325e;" @click="toggleComments(post.id)">
              <img src="../../../assets/comment.svg" alt="" srcset="">
              التعليقات ({{ post.comments.length }})
            </button>
            <div class="flex-grow-1"></div>

            <!-- <button class="likes" @click="listQuestionLikes(post.id)" title="المعجبين">
              <span>(الإعجابات {{ post.likes_count }} )</span>
            </button> -->

            <button class="likes" @click="likePost(post.id)">
              <!-- {{ post.likes_count }}  -->
              
              <span >
                <img v-if="post.is_liked_by_user" src="../../../assets/like.svg" alt="" srcset="">
                <img v-else src="../../../assets/n_like.svg" alt="" srcset="">
              </span>
            </button>
          </div>

          <div v-if="expandedPostId == post.id">
            <div 
              v-for="comment in post.comments" 
              :key="comment.id" 
              class="comment">

              <div class="post-actions d-flex align-center">
                <p>{{ comment.answer }}</p>
                <div class="flex-grow-1"></div>
                <!-- <span class="d-flex align-center justify-end" style="color:#9BB4CD;">{{ comment.created_at }}</span> -->
                <span class="d-flex align-center justify-end" style="color:#9BB4CD;">
                  <button class="likes" @click="listCommentLikes(comment.id)" title="المعجبين" style="margin-bottom: 0; font-size: 14px;">
                    
                      <span><img src="../../../assets/s_like.svg" alt="" srcset="">  {{ comment.likes_count }} |</span>
                  </button>  {{ comment.created_at }}
                </span>
              </div>

              <div class="post-actions d-flex align-center">
                <small>بواسطة: {{ comment.user.name }}</small>
                <div class="flex-grow-1"></div>

                <!-- <button class="likes" @click="listCommentLikes(comment.id)" title="المعجبين">
                  <span>(الإعجابات {{ comment.likes_count }} )</span>
                </button> -->

                <button class="likes" @click="likeComment(comment.id)">
                  <!-- {{ comment.likes_count }}  -->
                  <span>
                    <img v-if="comment.is_liked_by_user" src="../../../assets/like.svg" alt="" srcset="">
                    <img v-else src="../../../assets/n_like.svg" alt="" srcset="">
                  </span>
                </button>
              </div>

            </div>

            <!-- Add Comment -->
            <div class="add-comment" v-if="canSendComment">
              <textarea 
                v-model="newComment" 
                placeholder="أضف تعليقًا..." 
                rows="2">
              </textarea>
              <button  elevation="2" style="color: #fff; background-color: #3f0e40;"  @click="addComment(post.id)">إرسال</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Pagination -->
    <div class="pagination">
      <button 
      style=" padding: 0; background: none; "
        :disabled="currentPage === 1" 
        @click="changePage(currentPage - 1)">
        <img v-if="currentPage === 1" src="../../../assets/prev_d.svg" srcset="">
        <img v-else src="../../../assets/prev.svg" srcset="">
      </button>
      <span>الصفحة {{ currentPage }} من {{ pages }} الاجمالي {{ results }}</span>
      <button 
      style=" padding: 0; background: none; "
        :disabled="currentPage === pages" 
        @click="changePage(currentPage + 1)">
        <img v-if="currentPage === pages" src="../../../assets/next_d.svg" srcset="">
        <img v-else src="../../../assets/next.svg" srcset="">
      </button>
    </div>

  </div>
</template>

<script>
import { axiosInstance } from "@/axios/config";
export default {
  data() {
    return {
      activeTab: "all",
      posts: [],
      newPost: "", 
      keywords:"",
      expandedPostId: null,
      newComment: "",
      pagination: {
        limit: 5,
        count: null,
      }, 
      currentPage: 1,
      allowedUsersForAnswers : [],

      dialogOfQuestionLikeusers:false,
      likedUsers:[],
      likeText:"",

    };
  },

  computed: {
    firstTwoCharFromUser() {
      return this.getInitials(window.localStorage.getItem("loggedIn_user_name"));
    },

    results() {
      return this.pagination.count;
    },

    pages() {
      return Math.ceil(this.pagination.count / this.pagination.limit);
    },

    canSendComment() {
      const theCookie1 = document.cookie

      .split(";")
      .map((coki) => coki.split("="))
      .reduce(
        (acc, [key, val]) => ({
          ...acc,
          [key.trim()]: decodeURIComponent(val),
        }),
        {}
      )["wsataJwt"];
      let authUserId = '';

      if (theCookie1 !== undefined ) {
        authUserId = JSON.parse(theCookie1).agent_id;
      }
      return this.allowedUsersForAnswers.some(user => user.id == authUserId);
    },

  },

  methods: {

    async getAllowedUsersForAnswers() {
      await axiosInstance.get("/agent/list-allowedUserForAnswer",{headers: this.request_headers(),})
      .then((response) => {
        this.allowedUsersForAnswers = response.data.payload;
      });
    },

    getInitials(fullName) {
      if (!fullName) return ""; 
      const nameParts = fullName.trim().split(" ");
      const firstTwo = nameParts.slice(0, 2);
      const initials = firstTwo.map((part) => part.charAt(0).toUpperCase()).join(" ");
      return initials;
    },

    async fetchPosts(type, page = 1) {
      this.activeTab = type; 
      this.currentPage = page; 
      const endpoint = `${this.$store.state.url}/employee/funding-manager/list-questionaires?per_page=${this.pagination.limit}&page=${this.currentPage}`

      try {
        const response = await axiosInstance.get(endpoint)
        .then((res) => {
          this.posts = res.data.payload.data; 
          this.pagination.count = res.data.payload.count;
        })
        .catch((erroe) => {
          console.error("Error fetching posts:", erroe);
        });
      } catch (error) {
        console.error("Error fetching posts:", error.response?.data?.message || error.message);
        this.showPopup("error", error.response?.data?.message || "خطأ أثناء جلب المنشورات.");
      }
    },

    changePage(newPage) {
      if (newPage < 1 || newPage > this.pages) return; 
      this.currentPage = newPage; 
      this.fetchPosts(this.activeTab, this.currentPage); 
    },

    async submitPost() {
      await axiosInstance.post(
        `${this.$store.state.url}/agent/create-questionaires`,
        { question: this.newPost }
      ).then((res) => {
        this.showPopup("success", res.data.message);
        this.newPost = "";
        this.fetchPosts(this.activeTab, this.currentPage); 
      })
      .catch((res) => {
        this.showPopup("error",res.response.data.message);
      });
    },

    toggleComments(postId) {
      this.expandedPostId = this.expandedPostId == postId ? null : postId;
      // console.log("this.expandedPostId", this.expandedPostId);
    },

    async likePost(postId) {
      try {
        await axiosInstance.get(
          `${this.$store.state.url}/employee/funding-manager/like-questionaire/${postId}`
        ).then((res) => {
          this.showPopup("success", res.data.message);
          this.fetchPosts(this.activeTab, this.currentPage); 
        })
        .catch((res) => {
          this.showPopup("error",res.response.data.message);
        });
      } catch (error) {
        console.error("Error liking post:", error);
      }
    },

    async likeComment(commentId) {
      try {
        await axiosInstance.get(
          `${this.$store.state.url}/employee/funding-manager/like-comment/${commentId}`
        ).then((res) => {
          this.showPopup("success", res.data.message);
          this.fetchPosts(this.activeTab, this.currentPage); 
        })
        .catch((res) => {
          this.showPopup("error",res.response.data.message);
        });
      } catch (error) {
        console.error("Error liking post:", error);
      }
    },

    async addComment(postId) {
      if (!this.newComment.trim()) return alert("يرجى كتابة تعليق قبل الإرسال.");
      try {
        await axiosInstance.post(
          `${this.$store.state.url}/employee/funding-manager/answer-questionaires/${postId}`,
          { answer: this.newComment }
        ).then((res) => {
          this.showPopup("success", res.data.message);
          this.newComment = "";
          this.fetchPosts(this.activeTab, this.currentPage); 
        })
        .catch((res) => {
          this.showPopup("error",res.response.data.message);
        });
      } catch (error) {
        console.error("Error adding comment:", error);
      }
    },

    async listQuestionLikes(questionId) {
      this.likeText = "المعجبين بالسؤال";
      // this.dialogOfQuestionLikeusers=true;
      try {
        await axiosInstance.get(
          `/employee/funding-manager/list-question-likes/${questionId}`
        ).then((res) => {
          this.likedUsers = res.data.payload.data;
          this.dialogOfQuestionLikeusers = true;
        })
        .catch((res) => {
          this.showPopup("error",res.response.data.message);
          this.dialogOfQuestionLikeusers = false;
        });
      } catch (error) {
        console.error("Error fetching likes:", error.response?.data || error);
      }
    },

    async listCommentLikes(commentId) {
      this.likeText = "المعجبين بالكومنت";
      // this.dialogOfQuestionLikeusers=true;
      try {
        await axiosInstance.get(
          `/employee/funding-manager/list-comment-likes/${commentId}`
        ).then((res) => {
          this.likedUsers = res.data.payload.data;
          this.dialogOfQuestionLikeusers = true;
        })
        .catch((res) => {
          this.showPopup("error",res.response.data.message);
          this.dialogOfQuestionLikeusers = false;
        });
      } catch (error) {
        console.error("Error fetching likes:", error.response?.data || error);
      }
    },

  },
  created() {
    this.getAllowedUsersForAnswers();
    this.fetchPosts("all");
  },
};
</script>


<style scoped>
.container {
  padding: 20px;
  font-family: Arial, sans-serif;
}

/* Tabs */
.tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.tabs button {
  flex: 1;
  padding: 10px;
  text-align: center;
  border: 1px solid #ccc;
  background: #f9f9f9;
  cursor: pointer;
}
.tabs button.active {
  background: #3f0e40 !important;
  color: #fff;
}

/* New Post Template */
.new-post {
  margin-bottom: 20px;
}
.new-post textarea {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  background: white;

}

.textkdmskdmsk{
  /* width: 100%; */
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  /* margin-bottom: 10px; */
  background: white;
}
.new-post .submit-btn {
  float: left;
  padding: 8px 15px;
  background-color: #4caf50;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  margin-bottom: 50px;
}
.new-post .submit-btn:hover {
  background-color: #45a049;
}

/* Posts List */
.posts .post {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 15px;
  margin-top: 50px;
  margin-bottom: 10px;
  background: white;
}
.posts .post h3 {
  margin: 0 0 10px;
}
.likes {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.comments .toggle-comments {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  margin-bottom: 10px;
}
.comments .toggle-comments:hover {
  text-decoration: underline;
}

/* Comments */
.comment {
  padding: 10px;
  margin-bottom: 5px;
  border-bottom: 1px solid #ddd;
}
.add-comment textarea {
  width: 95%;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 8px;
  margin-bottom: 5px;
}
.add-comment button {
  float: left;
  padding: 5px 10px;
  background: #007bff;
  color: white;
  border: none;
  margin: 33px 0px 5px 0px;
  border-radius: 5px;
}
.add-comment button:hover {
  background: #0056b3;
}


/* Pagination container styles */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  gap: 10px;
}

/* Pagination buttons */
.pagination button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.pagination button:hover {
  background-color: #0056b3;
}

.pagination button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

/* Current page indicator */
.pagination span {
  font-size: 14px;
  color: #333;
  font-weight: bold;
}

.likes-popup {
  text-align: center;
}

.likes-popup p {
  margin: 0;
}

.v-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

</style>
