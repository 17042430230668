<template>
  <v-container>
    <v-row class="mt-7 pa-7 white rounded-lg">
      <v-col class="py-0" cols="12">
        <p class="font-weight-bold">بيـاناتي</p>
      </v-col>
      <v-col cols="12" sm="6">
        <p>اسم المستخدم</p>
        <v-text-field outlined dense v-model="user.name"></v-text-field>
        <p>كلمة المرور</p>
        <v-text-field type="password" outlined dense  v-model="user.password"></v-text-field>
      </v-col>
      <v-col cols="12" sm="6">
        <p>البريد الالكتروني</p>
        <v-text-field outlined dense v-model="user.email"></v-text-field>
        <p class="mt-3">اللغة</p>
        <v-select class="my-0" :items="items" solo v-model="user.locale" item-text="value" item-value="id"></v-select>
      </v-col>

      <v-col cols="6" sm="6">
        <p>صورة المستخدم</p>
        <v-file-input
          chips
          label="قم باختيار الصورة"
          outlined
          v-model="userImage"
          accept="image/*"
        ></v-file-input>
      </v-col>

      <v-col cols="6" sm="6">
        <p> صورة المستخدم قبل التعديل</p>
        <img :src="user.avatar" alt="" width="100%" height="200">
      </v-col>
      <v-col cols="12" style="display: none;">
        <form name="form01" id="form01">
          <label>Total: <input name="total" type="range" min="5" max="20" value="20"/></label>
          <label>Score: <input name="score" type="range" min="0" max="20" value="15"/></label>
        </form>
        <svg viewBox="0 0 100 100" width="200" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <mask id="mask01">
              <circle id="c1" cx="50" cy="50" r="40" fill="none" stroke="white" stroke-width="10" stroke-dasharray="95 5" pathLength="1000"/>
            </mask>
          </defs>
          <g transform="rotate(-90 50 50)">
          <circle mask="url(#mask01)" cx="50" cy="50" r="40" fill="none" stroke="Gainsboro" stroke-width="10" />
          <circle id="c2" mask="url(#mask01)" cx="50" cy="50" r="40" fill="none" stroke="DarkTurquoise" stroke-width="10" stroke-dasharray="500 1000" pathLength="1000" />
          </g>
          <text id="t0" x="50" y="50" style="font-size: 10px;" dominant-baseline="middle" text-anchor="middle" font-family="sans-serif" fill="DarkTurquoise">
            agent name
          </text>
          <text id="t1" x="50" y="70" dominant-baseline="middle" text-anchor="middle" font-family="sans-serif" fill="DarkTurquoise">
            15/20</text>
        </svg>
      </v-col>


      <v-col class="py-0" cols="12">
        <v-btn class="primary px-6" @click="updateProfile">تحديث بياناتي</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Axios from "axios";
export default {
  name: "ProfilePage",
  data() {
    return {
      value: 35,
      items: [
      {
        id: 'ar',
        value: 'العربية'
      },
      {
        id: 'en',
        value: 'الانجليزية'
      }
      ],
      user:{
        name: '',
        email: '',
        password: '',
        locale: '',
        avatar:'',
      },
      userImage:'',
    };
  },
  mounted() {
    this.getProfile();
    const c1 = document.getElementById('c1');
const c2 = document.getElementById('c2');
const t1 = document.getElementById('t1');

const update = (total, score) => {
  c1.setAttribute('stroke-dasharray', `${(1000-total*5)/total} 5`);
  c2.setAttribute('stroke-dasharray', `${1000/total*score} 1000`);
  t1.textContent = `${score}/${total}`;
};

document.forms.form01.total.addEventListener('change', e => {
  let total = parseInt(e.target.value);
  let score = parseInt(e.target.form.score.value);
  e.target.form.score.setAttribute('max', total);
  if (score >= total) score = total;
  update(total, score);
});

document.forms.form01.score.addEventListener('change', e => {
  let total = parseInt(e.target.form.total.value);
  let score = parseInt(e.target.value);
  update(total, score);
});
let total = 20;
  let score = 15;
  if (score >= total) score = total;
  update(total, score);
  },
  methods: {
    async getProfile(){
      await Axios.get(
        `${this.$store.state.url}/employee/profile`,
        {
          headers: this.request_headers(),
        }
      ).then((response) => {
        this.user = response.data.payload;
      });
    },
    async updateProfile(){

      const body = new FormData();
      body.append(`name`, this.user.name);
      body.append("email", this.user.email);
      body.append("locale", this.user.locale);
      body.append("avatar", this.userImage);

      if(this.user.password != null && this.user.password.length >= 6){
        body.append("password", this.user.password);
      }
     

      await Axios.post(
        `${this.$store.state.url}/employee/update-profile`, this.user,
        {
          headers: this.request_headers(),
        }
      ).then(async (response) => {
        this.showPopup("success", response.data.message);
        await this.getProfile();
        localStorage.setItem("avatar", this.user.avatar);
        localStorage.setItem("loggedIn_user_name", this.user.name);
        window.location.reload();
      }).catch((err) => {
        this.showPopup("error", err.response.data.message);
      });
    }
  },
};
</script>

<style lang="scss">
  .v-input__control{
    min-height: 40px !important;
  }
  // circle:first-child{
  //   stroke-dasharray: 8 1;
  // }
  #form01 {
  display: flex;
  flex-direction: column;
}
</style>
