<template>
  <div>
    <v-btn
      @click="dialog = true"
      fab
      class="mx-2 pa-0 genaralIcons"
      small
      style="left: 20px"
      :color="$vuetify.theme.themes.light.BtnBKColor"
      depressed
      width="35"
      height="35"
    >
      <SearchIcon />
    </v-btn>

    <v-dialog v-model="dialog" persistent width="60%">
      <v-card style="padding: 10px">
        <v-card-title>البحث</v-card-title>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <PickerInput label="تاريخ الطلب من" @dateValue="handleDateFrom" />
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <PickerInput label="تاريخ الطلب الي" @dateValue="handleDateTo" />
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-autocomplete
                :items="allMangers"
                :item-title="allMangers.name"
                label="مدير المبيعات من"
                v-model="allMangerFrom"
                item-text="name"
                return-object
                outlined
                dense
              >
                <template v-slot:item="{ item, attrs, on }">
                  <v-list-item v-bind="attrs" v-on="on">
                    <v-list-item-title
                      :id="attrs['aria-labelledby']"
                      v-text="item.name"
                    ></v-list-item-title>
                  </v-list-item>
                </template>
              </v-autocomplete>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-autocomplete
                :items="allMangers"
                :item-title="allMangers.name"
                label=" مدير المبيعات الي"
                v-model="allMangerTo"
                item-text="name"
                return-object
                outlined
                dense
              >
                <template v-slot:item="{ item, attrs, on }">
                  <v-list-item v-bind="attrs" v-on="on">
                    <v-list-item-title
                      :id="attrs['aria-labelledby']"
                      v-text="item.name"
                    ></v-list-item-title>
                  </v-list-item>
                </template>
              </v-autocomplete>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-autocomplete
                :items="allSalesFromArr"
                :item-title="allSalesFromArr.name"
                label=" استشاري المبيعات من"
                v-model="allSalesFrom"
                item-text="name"
                return-object
                outlined
                dense
              >
                <template v-slot:item="{ item, attrs, on }">
                  <v-list-item v-bind="attrs" v-on="on">
                    <v-list-item-title
                      :id="attrs['aria-labelledby']"
                      v-text="item.name"
                    ></v-list-item-title>
                  </v-list-item>
                </template>
              </v-autocomplete>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-autocomplete
                :items="allSalesToArr"
                :item-title="allSalesToArr.name"
                label=" استشاري المبيعات الي"
                v-model="allSalesTo"
                item-text="name"
                return-object
                outlined
                dense
              >
                <template v-slot:item="{ item, attrs, on }">
                  <v-list-item v-bind="attrs" v-on="on">
                    <v-list-item-title
                      :id="attrs['aria-labelledby']"
                      v-text="item.name"
                    ></v-list-item-title>
                  </v-list-item>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-container>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue-darken-1" variant="text" @click="dialog = false">
            الغاء
          </v-btn>
          <v-btn color="blue-darken-1" variant="text" @click="handleSearch"> بحث </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup>
import { ref, onMounted, defineEmits, watch } from "vue";
import { SearchIcon } from "@/assets/adminIcons/searchIcon";
import PickerInput from "@/components/Charts/PickerInput.vue";
import { axiosInstance } from "@/axios/config";

const emit = defineEmits(["filterDataMask"]);

const dialog = ref(false);
const dateFrom = ref("");
const handleDateFrom = (val) => {
  dateFrom.value = val;
};
const dateTo = ref("");
const handleDateTo = (val) => {
  dateTo.value = val;
};

const allMangers = ref([]);
const getMangers = () => {
  axiosInstance.post(`/employee/admin/filterUsers`, { role: "manager" }).then((res) => {
    allMangers.value = res.data.payload;
  });
};

const allMangerFrom = ref(null);
const allMangerTo = ref(null);

watch(allMangerFrom, (current) => {
  getSales(current, "from");
});

watch(allMangerTo, (current) => {
  getSales(current, "to");
});

const allSalesFromArr = ref([]);
const allSalesToArr = ref([]);

const getSales = (val, type) => {
  let body = new FormData();
  body.append("role", "agent");

  if (val) {
    body.append(`manager_id[0]`, val["id"]);
  }
  axiosInstance.post(`/employee/admin/filterUsers`, body).then((res) => {
    if (type === "from") {
      allSalesFromArr.value = res.data.payload;
    } else if (type === "to") {
      allSalesToArr.value = res.data.payload;
    } else {
      allSalesFromArr.value = res.data.payload;
      allSalesToArr.value = res.data.payload;
    }
  });
};

const allSalesFrom = ref(null);
const allSalesTo = ref(null);

const handleSearch = () => {
  dialog.value = false;
  let searchObj = {
    date_from: dateFrom.value,
    date_to: dateTo.value,
    sales_from: allMangerFrom.value?.id,
    sales_to: allMangerTo.value?.id,
    agent_from: allSalesFrom.value?.id,
    agent_to: allSalesTo.value?.id,
  };
  const body = new FormData();
  for (let key in searchObj) {
    if (
      searchObj[key] !== "" &&
      searchObj[key] !== null &&
      searchObj[key] !== undefined
    ) {
      body.append(key, searchObj[key]);
    }
  }
  emit("filterDataMask", searchObj);

};

onMounted(() => {
  getMangers();
  getSales();
});
</script>

<style>
.genaralIcons {
  position: absolute;
  top: -50px;
  min-width: unset;
  z-index: 5;
}
</style>
