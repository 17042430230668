export function getFundingDurationTabData(store) {



    if (store.state.FundingDurationTapData.ExitDateFromReport) {
        store.state.bodySearch.set("ExitDateFromReport", store.state.FundingDurationTapData.ExitDateFromReport);
    }else{
        store.state.bodySearch.delete("ExitDateFromReport");
    }

    if (store.state.FundingDurationTapData.ExitDateToReport) {
        store.state.bodySearch.set("ExitDateToReport", store.state.FundingDurationTapData.ExitDateToReport);
    }else{
        store.state.bodySearch.delete("ExitDateToReport");
    }

    if (store.state.FundingDurationTapData.RecievedDateFromReport) {
        store.state.bodySearch.set("RecievedDateFromReport", store.state.FundingDurationTapData.RecievedDateFromReport);
    }else{
        store.state.bodySearch.delete("RecievedDateFromReport");
    }

    if (store.state.FundingDurationTapData.RecievedDateToReport) {
        store.state.bodySearch.set("RecievedDateToReport", store.state.FundingDurationTapData.RecievedDateToReport);
    }else{
        store.state.bodySearch.delete("RecievedDateToReport");
    }



    // List of fields to handle
    // const fields = [
    //     "ExitDateFromReport",
    //     "ExitDateToReport",
    //     "RecievedDateFromReport",
    //     "RecievedDateToReport",
    // ];

    // // Process each field
    // fields.forEach((field) => {
    //     const value = store.state.FundingDurationTapData[field];
    //     if (value) {
    //         store.state.bodySearch.append(field, value);
    //     } else {
    //         store.state.bodySearch.delete(field);
    //     }
    // });


    

    /*if (store.state.FundingDurationTapData.ExitDateFromReport) {
        store.state.bodySearch.append("ExitDateFromReport", store.state.FundingDurationTapData.ExitDateFromReport);
    }else{
        store.state.bodySearch.delete("ExitDateFromReport");
    }

    if (store.state.FundingDurationTapData.ExitDateToReport) {
        store.state.bodySearch.append("ExitDateToReport", store.state.FundingDurationTapData.ExitDateToReport);
    }else{
        store.state.bodySearch.delete("ExitDateToReport");
    }

    if (store.state.FundingDurationTapData.RecievedDateFromReport) {
        store.state.bodySearch.append("RecievedDateFromReport", store.state.FundingDurationTapData.RecievedDateFromReport);
    }else{
        store.state.bodySearch.delete("RecievedDateFromReport");
    }

    if (store.state.FundingDurationTapData.RecievedDateToReport) {
        store.state.bodySearch.append("RecievedDateToReport", store.state.FundingDurationTapData.RecievedDateToReport);
    }else{
        store.state.bodySearch.delete("RecievedDateToReport");
    }*/
  
  }