<template>
  <v-container>
    <v-row class="mt-7 pa-7 white rounded-lg">
      <v-col class="py-0" cols="12">
        <p class="font-weight-bold">اعدادات (منتدى الوساطة)</p>
      </v-col>

      <v-col cols="12" sm="12">
        <h4>المستخدمين المخول لهم الرد علي الاسئلة</h4>
        <v-row class="white rounded-lg">
          <v-col cols="12">
            <v-col cols="12">
              <div class="selectInput">
                <v-combobox
                  label="المستخدمين المخول لهم الرد علي الاسئلة"
                  class="my-0"
                  :items="AllUsersForAnswer"
                  v-model="allowedUsersForAnswers"
                  item-text="name"
                  item-value="id"
                  multiple
                  solo
                >
                  <template v-slot:selection="{ attrs, item, parent, selected }">
                    <v-chip
                      v-if="item === Object(item)"
                      v-bind="attrs"
                      :color="`${item.color} lighten-3`"
                      :input-value="selected"
                      label
                      small
                    >
                      <span class="pr-2">
                        {{ item.name }}
                      </span>
                      <v-icon small @click="parent.selectItem(item)"> $delete </v-icon>
                    </v-chip>
                  </template>
                </v-combobox>
              </div>
            </v-col>
          </v-col>
        </v-row>
        <v-col
          cols="12"
          style="
            margin-top: 30px;
            margin-bottom: 15px;
            padding-top: 0;
            padding-bottom: 0;
            height: 0;
          "
        >
          <hr style="margin: 0; padding: 0" />
        </v-col>
        <v-col class="py-5 text-left" cols="12">
          <v-btn class="primary px-6" @click="updateAllowedUserForAnswer">تحديث </v-btn>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { axiosInstance } from "@/axios/config";
export default {
  name: "RequestSetting",
  data() {
    return {
      AllUsersForAnswer : [],
      allowedUsersForAnswers : [],
    };
  },
  mounted() {
    this.getAllowedUsersForAnswers();
    this.getAllUsersForAnswers();
  },
  methods: {
    async getAllowedUsersForAnswers() {
      await axiosInstance.get("/agent/list-allowedUserForAnswer",{headers: this.request_headers(),})
      .then((response) => {
        this.allowedUsersForAnswers = response.data.payload;
      });
    },

    getAllUsersForAnswers() {
      axiosInstance
        .get(`/agent/list-listAllUserForAnswer`)
        .then((res) => {
          this.AllUsersForAnswer = res.data.payload;
          this.AllUsersForAnswer.map((item) => {
            item["color"] = "red";
            item["text"] = item["name"];
            return item;
          });
        })
        .catch((error) => {
          console.log(error.message);
        });
    },

    async updateAllowedUserForAnswer() {
      let body = new FormData();
      if (this.allowedUsersForAnswers.length > 0) {
        this.allowedUsersForAnswers.forEach((item, index) => {
          if(item != null){
            body.append(`usersIds[${index}]`, item['id']);
          }
        });
      }
      await axiosInstance.post(`/agent/toggle-allowedUserForAnswer`,body,{headers: this.request_headers(),})
      .then((response) => {
        this.getAllowedUsersForAnswers();
        this.showPopup("success", response.data.message);
      });
    },
  },
};
</script>

<style lang="scss">
.v-input__control {
  min-height: 40px !important;
}
</style>
