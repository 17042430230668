export function getRequestTabData(store) {

    const { bodySearch, RequestTapData } = store.state;
    // Helper function to clear old keys from bodySearch
    const clearKeysByPrefix = (prefix) => {
        for (let key of bodySearch.keys()) {
            if (key.startsWith(prefix)) {
                bodySearch.delete(key);
            }
        }
    };

    // Helper function to update bodySearch with array values
    const updateBodySearchArray = (prefix, items,value="id") => {
        clearKeysByPrefix(prefix);
        items.forEach((item, index) => {
            bodySearch.set(`${prefix}[${index}]`, item[value]);
        });
    };


    if (store.state.RequestTapData.filterTypeParts) {
        store.state.bodySearch.set("text_name", store.state.RequestTapData.filterTypeParts);
    }else{
        store.state.bodySearch.delete("text_name");
    }

    if (RequestTapData.selectedMang.length > 0) {
        updateBodySearchArray('sales_manager_ids', RequestTapData.selectedMang);
    }

    const PossibleToSellToCustomerMap = {
        "نعم": 1,
        "لا": 0,
        "لا يوجد": 2,
    };

    if (Object.prototype.hasOwnProperty.call(PossibleToSellToCustomerMap, RequestTapData.PossibleToSellToCustomer)) {
        bodySearch.set("RequestPossibleToSellToCustomer", PossibleToSellToCustomerMap[RequestTapData.PossibleToSellToCustomer]);
    }

    if (store.state.RequestTapData.selectAgent.length > 0) {
        updateBodySearchArray('agent_name', store.state.RequestTapData.selectAgent);
    }

    if (store.state.RequestTapData.fundingManagers.length > 0) {
        updateBodySearchArray('FundingManager', store.state.RequestTapData.fundingManagers);
    }

    if (store.state.RequestTapData.AgentBeforeSendingToAdministration.length > 0) {
        updateBodySearchArray('AgentBeforeSendingToAdministration', store.state.RequestTapData.AgentBeforeSendingToAdministration);
    }

    if (store.state.RequestTapData.selectedProductType.length > 0) {
        updateBodySearchArray('request_product_type', store.state.RequestTapData.selectedProductType);
    }

    if (store.state.RequestTapData.selectedRequestStatus.length > 0) {
        updateBodySearchArray('request_status', store.state.RequestTapData.selectedRequestStatus,"status_id");
    }

    if (store.state.RequestTapData.sourceWork.length > 0) {
        updateBodySearchArray('request_source', store.state.RequestTapData.sourceWork);
    }

    if (store.state.RequestTapData.sourceWork.find((el) => {return el.id == 2}) && store.state.RequestTapData.selected_collaborators.length > 0) {
        updateBodySearchArray('collaborator_name', store.state.RequestTapData.collaborator_name);
    }


    if (store.state.RequestTapData.selectedTypeRequest.length > 0) {
        updateBodySearchArray('request_types', store.state.RequestTapData.selectedTypeRequest,"value");
    }


    if (store.state.RequestTapData.selectedRejection_lists.length > 0) {
        updateBodySearchArray('AgentRejectionReason', store.state.RequestTapData.selectedRejection_lists);
    }

    if (store.state.RequestTapData.selectedRequest_descriptions.length > 0) {
        updateBodySearchArray('RequestDescription', store.state.RequestTapData.selectedRequest_descriptions);
    }

    if (store.state.RequestTapData.selectedWorkList.length > 0) {
        updateBodySearchArray('work_source', store.state.RequestTapData.selectedWorkList);
    }

    if (store.state.RequestTapData.selectedCustomerNeeds.length > 0) {
        updateBodySearchArray('MortgageCustomerNeeds', store.state.RequestTapData.selectedCustomerNeeds);
    }

    if (store.state.RequestTapData.selectedSalaryList.length > 0) {
        updateBodySearchArray('SalaryType', store.state.RequestTapData.selectedSalaryList);
    }

    if (store.state.RequestTapData.selectAgent.length > 0) {
        updateBodySearchArray('agent_name', store.state.RequestTapData.selectAgent);
    }

    const AgentTypeMap = {
        "استشاري قائم": 1,
        "استشاري مؤرشف": 0,
    };

    if (Object.prototype.hasOwnProperty.call(AgentTypeMap, RequestTapData.selectedStatusFilter)) {
        bodySearch.set("agent_active_or_archived_classification", AgentTypeMap[RequestTapData.selectedStatusFilter]);
    }

    const acceptedorarchivedTypeMap = {
        "نعم": 1,
        "لا": 0,
    };

    if (Object.prototype.hasOwnProperty.call(acceptedorarchivedTypeMap, RequestTapData.selectedAcceptArchivedOrNot)) {
        bodySearch.set("ApproveForQualityArchivedOrNot", acceptedorarchivedTypeMap[RequestTapData.selectedAcceptArchivedOrNot]);
    }

    const hascallTypeMap = {
        "نعم": 1,
        "لا": 0,
    };

    if (Object.prototype.hasOwnProperty.call(hascallTypeMap, RequestTapData.isHasCalls)) {
        bodySearch.set("request_has_calls", hascallTypeMap[RequestTapData.isHasCalls]);
    }


    const getrequestTypeMap = {
        "فارغه": 0,
        "بها محتوي": 1,
    };

    if (Object.prototype.hasOwnProperty.call(getrequestTypeMap, RequestTapData.getRequestsType)) {
        bodySearch.set("note_status", getrequestTypeMap[RequestTapData.getRequestsType]);
    }


    
    if (store.state.RequestTapData.requestDateFrom) {
        store.state.bodySearch.set("request_date_from", store.state.RequestTapData.requestDateFrom);
    }else{
        store.state.bodySearch.delete("request_date_from");
    }


    if (store.state.RequestTapData.requestDateTo) {
        store.state.bodySearch.set("request_date_to", store.state.RequestTapData.requestDateTo);
    }else{
        store.state.bodySearch.delete("request_date_to");
    }

    if (store.state.RequestTapData.completeDateFrom) {
        store.state.bodySearch.set("complete_date[0]", store.state.RequestTapData.completeDateFrom);
    }else{
        store.state.bodySearch.delete("complete_date[0]");
    }

    if (store.state.RequestTapData.completeDateTo) {
        store.state.bodySearch.set("complete_date[1]", store.state.RequestTapData.completeDateTo);
    }else{
        store.state.bodySearch.delete("complete_date[1]");
    }
    

    if (store.state.RequestTapData.updatedDateFrom) {
        store.state.bodySearch.set("request_date_updated_from", store.state.RequestTapData.updatedDateFrom);
    }else{
        store.state.bodySearch.delete("request_date_updated_from");
    }

    if (store.state.RequestTapData.updatedDateTo) {
        store.state.bodySearch.set("request_date_updated_to", store.state.RequestTapData.updatedDateTo);
    }else{
        store.state.bodySearch.delete("request_date_updated_to");
    }

    if (store.state.RequestTapData.UnUpdatedDate) {
        store.state.bodySearch.set("request_date_did_not_updated_from", store.state.RequestTapData.UnUpdatedDate);
    }else{
        store.state.bodySearch.delete("request_date_did_not_updated_from");
    }
  

    if (store.state.RequestTapData.selectedFundingSide.length > 0) {
        updateBodySearchArray('funding_source', store.state.RequestTapData.selectedFundingSide);
    }
    if (store.state.RequestTapData.selectedCustomerNeeds.length > 0) {
        updateBodySearchArray('MortgageCustomerNeeds', store.state.RequestTapData.selectedCustomerNeeds);
    }
    if (store.state.RequestTapData.selectedCustomerObjections.length > 0) {
        updateBodySearchArray('MortgageCustomerObjections', store.state.RequestTapData.selectedCustomerObjections);
    }
    if (store.state.RequestTapData.selectedCustomerObsatacles.length > 0) {
        updateBodySearchArray('MortgageCustomerObsatacles', store.state.RequestTapData.selectedCustomerObsatacles);
    }

 


    



  





    // const formDataKeys = Array.from(store.state.bodySearch.keys());
    // if (store.state.RequestTapData.filterTypeParts) {
    //     const key = "text_name";
    //     if (!formDataKeys.includes(key)) {
    //         store.state.bodySearch.append(key, store.state.RequestTapData.filterTypeParts);
    //     }
    // }
    
    // if (store.state.RequestTapData.PossibleToSellToCustomer === "نعم") {
    //     const key = "RequestPossibleToSellToCustomer";
    //     if (!formDataKeys.includes(key)) {
    //         store.state.bodySearch.append(key, 1);
    //     }
    // }

    
    // if (store.state.RequestTapData.PossibleToSellToCustomer === "لا") {
    //     const key = "RequestPossibleToSellToCustomer";
    //     if (!formDataKeys.includes(key)) {
    //         store.state.bodySearch.append(key, 0);
    //     }
    // }
    
    // if (store.state.RequestTapData.PossibleToSellToCustomer === "لا يوجد") {
    //     const key = "RequestPossibleToSellToCustomer";
    //     if (!formDataKeys.includes(key)) {
    //         store.state.bodySearch.append(key, 2);
    //     }
    // }
    
    // if (store.state.RequestTapData.selectedMang.length > 0) {
    //     store.state.RequestTapData.selectedMang.forEach((item, index) => {
    //         const key = `sales_manager_ids[${index}]`;
    //         if (!formDataKeys.includes(key)) {
    //             store.state.bodySearch.append(key, item["id"]);
    //         }
    //     });
    // }
    
    // if (store.state.RequestTapData.fundingManagers.length > 0) {
    //     store.state.RequestTapData.fundingManagers.forEach((item, index) => {
    //         const key = `FundingManager[${index}]`;
    //         if (!formDataKeys.includes(key)) {
    //             store.state.bodySearch.append(key, item["id"]);
    //         }
    //     });
    // }
    
    // if (store.state.RequestTapData.AgentBeforeSendingToAdministration.length > 0) {
    //     store.state.RequestTapData.AgentBeforeSendingToAdministration.forEach((item, index) => {
    //         const key = `AgentBeforeSendingToAdministration[${index}]`;
    //         if (!formDataKeys.includes(key)) {
    //             store.state.bodySearch.append(key, item["id"]);
    //         }
    //     });
    // }
    
    // if (store.state.RequestTapData.selectedStatusFilter === "استشاري قائم") {
    //     const key = "agent_active_or_archived_classification";
    //     if (!formDataKeys.includes(key)) {
    //         store.state.bodySearch.append(key, 1);
    //     }
    // }
    
    // if (store.state.RequestTapData.selectedStatusFilter === "استشاري مؤرشف") {
    //     const key = "agent_active_or_archived_classification";
    //     if (!formDataKeys.includes(key)) {
    //         store.state.bodySearch.append(key, 0);
    //     }
    // }
    
    // if (store.state.RequestTapData.selectedAcceptArchivedOrNot === "نعم") {
    //     const key = "ApproveForQualityArchivedOrNot";
    //     if (!formDataKeys.includes(key)) {
    //         store.state.bodySearch.append(key, 1);
    //     }
    // }
    
    // if (store.state.RequestTapData.selectedAcceptArchivedOrNot === "لا") {
    //     const key = "ApproveForQualityArchivedOrNot";
    //     if (!formDataKeys.includes(key)) {
    //         store.state.bodySearch.append(key, 0);
    //     }
    // }
    
    // if (store.state.RequestTapData.isHasCalls === "نعم") {
    //     const key = "request_has_calls";
    //     if (!formDataKeys.includes(key)) {
    //         store.state.bodySearch.append(key, 1);
    //     }
    // }
    
    // if (store.state.RequestTapData.isHasCalls === "لا") {
    //     const key = "request_has_calls";
    //     if (!formDataKeys.includes(key)) {
    //         store.state.bodySearch.append(key, 0);
    //     }
    // }
    
    // if (store.state.RequestTapData.selectedProductType.length > 0) {
    //     store.state.RequestTapData.selectedProductType.forEach((item, index) => {
    //         const key = `request_product_type[${index}]`;
    //         if (!formDataKeys.includes(key)) {
    //             store.state.bodySearch.append(key, item["id"]);
    //         }
    //     });
    // }
    
    // if (store.state.RequestTapData.selectedRequestStatus.length > 0) {
    //     store.state.RequestTapData.selectedRequestStatus.forEach((item, index) => {
    //         const key = `request_status[${index}]`;
    //         if (!formDataKeys.includes(key)) {
    //             store.state.bodySearch.append(key, item["status_id"]);
    //         }
    //     });
    // }
    
    // if (store.state.RequestTapData.getRequestsType === "فارغه") {
    //     const key = "note_status";
    //     if (!formDataKeys.includes(key)) {
    //         store.state.bodySearch.append(key, 0);
    //     }
    // }
    
    // if (store.state.RequestTapData.getRequestsType === "بها محتوي") {
    //     const key = "note_status";
    //     if (!formDataKeys.includes(key)) {
    //         store.state.bodySearch.append(key, 1);
    //     }
    // }
    
    // if (store.state.RequestTapData.sourceWork.length > 0) {
    //     store.state.RequestTapData.sourceWork.forEach((item, index) => {
    //         const key = `request_source[${index}]`;
    //         if (!formDataKeys.includes(key)) {
    //             store.state.bodySearch.append(key, item["id"]);
    //         }
    //     });
    // }
    
    // if (store.state.RequestTapData.selectedTypeRequest.length > 0) {
    //     store.state.RequestTapData.selectedTypeRequest.forEach((item, index) => {
    //         const key = `request_types[${index}]`;
    //         if (!formDataKeys.includes(key)) {
    //             store.state.bodySearch.append(key, item["value"]);
    //         }
    //     });
    // }
    
    // if (store.state.RequestTapData.sourceWork.find((el) => {return el.id == 2}) && store.state.RequestTapData.selected_collaborators.length > 0) {
    //     store.state.RequestTapData.selected_collaborators.forEach((item, index) => {
    //         const key = `collaborator_name[${index}]`;
    //         if (!formDataKeys.includes(key)) {
    //             store.state.bodySearch.append(key, item);
    //         }
    //     });
    // }
    
    // if (store.state.RequestTapData.selectedRejection_lists.length > 0) {
    //     store.state.RequestTapData.selectedRejection_lists.forEach((item, index) => {
    //         const key = `AgentRejectionReason[${index}]`;
    //         if (!formDataKeys.includes(key)) {
    //             store.state.bodySearch.append(key, item["id"]);
    //         }
    //     });
    // }
    
    // if (store.state.RequestTapData.selectedRequest_descriptions.length > 0) {
    //     store.state.RequestTapData.selectedRequest_descriptions.forEach((item, index) => {
    //         const key = `RequestDescription[${index}]`;
    //         if (!formDataKeys.includes(key)) {
    //             store.state.bodySearch.append(key, item["id"]);
    //         }
    //     });
    // }
    
    // if (store.state.RequestTapData.requestDateFrom) {
    //     const key = "request_date_from";
    //     if (!formDataKeys.includes(key)) {
    //         store.state.bodySearch.append(key, store.state.RequestTapData.requestDateFrom);
    //     }
    // }
    
    // if (store.state.RequestTapData.requestDateTo) {
    //     const key = "request_date_to";
    //     if (!formDataKeys.includes(key)) {
    //         store.state.bodySearch.append(key, store.state.RequestTapData.requestDateTo);
    //     } else {
    //         store.state.bodySearch.delete("request_date_to");
    //     }
    // }
    
    // if (store.state.RequestTapData.completeDateFrom) {
    //     const key = "complete_date[0]";
    //     if (!formDataKeys.includes(key)) {
    //         store.state.bodySearch.append(key, store.state.RequestTapData.completeDateFrom);
    //     } else {
    //         store.state.bodySearch.delete("complete_date[0]");
    //     }
    // }
    
    // if (store.state.RequestTapData.completeDateTo) {
    //     const key = "complete_date[1]";
    //     if (!formDataKeys.includes(key)) {
    //         store.state.bodySearch.append(key, store.state.RequestTapData.completeDateTo);
    //     } else {
    //         store.state.bodySearch.delete("complete_date[1]");
    //     }
    // }
    
    // if (store.state.RequestTapData.updatedDateFrom) {
    //     const key = "request_date_updated_from";
    //     if (!formDataKeys.includes(key)) {
    //         store.state.bodySearch.append(key, store.state.RequestTapData.updatedDateFrom);
    //     } else {
    //         store.state.bodySearch.delete("request_date_updated_from");
    //     }
    // }
    
    // if (store.state.RequestTapData.updatedDateTo) {
    //     const key = "request_date_updated_to";
    //     if (!formDataKeys.includes(key)) {
    //         store.state.bodySearch.append(key, store.state.RequestTapData.updatedDateTo);
    //     } else {
    //         store.state.bodySearch.delete("request_date_updated_to");
    //     }
    // }
    
    // if (store.state.RequestTapData.UnUpdatedDate) {
    //     const key = "request_date_did_not_updated_from";
    //     if (!formDataKeys.includes(key)) {
    //         store.state.bodySearch.append(key, store.state.RequestTapData.UnUpdatedDate);
    //     } else {
    //         store.state.bodySearch.delete("request_date_did_not_updated_from");
    //     }
    // }
    
    // if (store.state.RequestTapData.selectedWorkList.length > 0) {
    //     store.state.RequestTapData.selectedWorkList.forEach((item, index) => {
    //         const key = `work_source[${index}]`;
    //         if (!formDataKeys.includes(key)) {
    //             store.state.bodySearch.append(key, item["id"]);
    //         }
    //     });
    // }
    
    // if (store.state.RequestTapData.selectedCustomerNeeds.length > 0) {
    //     store.state.RequestTapData.selectedCustomerNeeds.forEach((item, index) => {
    //         const key = `MortgageCustomerNeeds[${index}]`;
    //         if (!formDataKeys.includes(key)) {
    //             store.state.bodySearch.append(key, item["id"]);
    //         }
    //     });
    // }
    
    // if (store.state.RequestTapData.selectedSalaryType.length > 0) {
    //     store.state.RequestTapData.selectedSalaryType.forEach((item, index) => {
    //         const key = `SalaryType[${index}]`;
    //         if (!formDataKeys.includes(key)) {
    //             store.state.bodySearch.append(key, item["id"]);
    //         }
    //     });
    // }

    
    // if (store.state.RequestTapData.selectAgent.length > 0) {
    //     store.state.RequestTapData.selectAgent.forEach((item, index) => {
    //         const key = `agent_name[${index}]`;
    //         if (!formDataKeys.includes(key)) {
    //             store.state.bodySearch.append(key, item["id"]);
    //         }
    //     });
    // }
    
    
    




    
    
    
    
    
    
    /*if (store.state.RequestTapData.filterTypeParts) {
        store.state.bodySearch.append("text_name", store.state.RequestTapData.filterTypeParts);
    }

    if (store.state.RequestTapData.PossibleToSellToCustomer === "نعم") {
        store.state.bodySearch.append("RequestPossibleToSellToCustomer", 1);
    }

    if (store.state.RequestTapData.PossibleToSellToCustomer === "لا") {
        store.state.bodySearch.append("RequestPossibleToSellToCustomer", 0);
    }

    if (store.state.RequestTapData.PossibleToSellToCustomer === "لا يوجد") {
        store.state.bodySearch.append("RequestPossibleToSellToCustomer", 2);
    }

    if (store.state.RequestTapData.selectedMang.length > 0) {
        store.state.RequestTapData.selectedMang.forEach((item, index) => {
            store.state.bodySearch.append(`sales_manager_ids[${index}]`, item["id"]);
        });
    }

    if (store.state.RequestTapData.fundingManagers.length > 0) {
        store.state.RequestTapData.fundingManagers.forEach((item, index) => {
            store.state.bodySearch.append(`FundingManager[${index}]`, item["id"]);
        });
    }

    if (store.state.RequestTapData.AgentBeforeSendingToAdministration.length > 0) {
        store.state.RequestTapData.AgentBeforeSendingToAdministration.forEach((item, index) => {
            store.state.bodySearch.append(`AgentBeforeSendingToAdministration[${index}]`, item["id"]);
        });
    }

    if (store.state.RequestTapData.selectedStatusFilter === "استشاري قائم") {
        store.state.bodySearch.append("agent_active_or_archived_classification", 1);
    }

    if (store.state.RequestTapData.selectedStatusFilter === "استشاري مؤرشف") {
        store.state.bodySearch.append("agent_active_or_archived_classification", 0);
    }

    if (store.state.RequestTapData.selectedAcceptArchivedOrNot === "نعم") {
        store.state.bodySearch.append("ApproveForQualityArchivedOrNot", 1);
    }

    if (store.state.RequestTapData.selectedAcceptArchivedOrNot === "لا") {
        store.state.bodySearch.append("ApproveForQualityArchivedOrNot", 0);
    }

    if (store.state.RequestTapData.isHasCalls === "نعم") {
        store.state.bodySearch.append("request_has_calls", 1);
    }

    if (store.state.RequestTapData.isHasCalls === "لا") {
        store.state.bodySearch.append("request_has_calls", 0);
    }


    if (store.state.RequestTapData.selectedProductType.length > 0) {
        store.state.RequestTapData.selectedProductType.forEach((item, index) => {
            store.state.bodySearch.append(`request_product_type[${index}]`, item["id"]);
        });
    }

    if (store.state.RequestTapData.selectedRequestStatus.length > 0) {
        store.state.RequestTapData.selectedRequestStatus.forEach((item, index) => {
            store.state.bodySearch.append(`request_status[${index}]`, item["status_id"]);
        });
    }

    if (store.state.RequestTapData.getRequestsType === "فارغه") {
        store.state.bodySearch.append("note_status", 0);
    }

    if (store.state.RequestTapData.getRequestsType === "بها محتوي") {
        store.state.bodySearch.append("note_status", 1);
    }

    if (store.state.RequestTapData.sourceWork.length > 0) {
        store.state.RequestTapData.sourceWork.forEach((item, index) => {
            store.state.bodySearch.append(`request_source[${index}]`, item["id"]);
        });
    }   

    if (store.state.RequestTapData.selectedTypeRequest.length > 0) {
        store.state.RequestTapData.selectedTypeRequest.forEach((item, index) => {
            store.state.bodySearch.append(`request_types[${index}]`, item["store.state.RequestTapDataue"]);
        });
    }

    if (store.state.RequestTapData.sourceWork.find((el) => {return el.id == 2} ) && store.state.RequestTapData.selected_collaborators.length > 0) {
        store.state.RequestTapData.selected_collaborators.forEach((item, index) => {
            store.state.bodySearch.append(`collaborator_name[${index}]`, item);
        });
    }

    if (store.state.RequestTapData.selectedRejection_lists.length > 0) {
        store.state.RequestTapData.selectedRejection_lists.forEach((item, index) => {
            store.state.bodySearch.append(`AgentRejectionReason[${index}]`, item["id"]);
        });
    }


    if (store.state.RequestTapData.selectedRequest_descriptions.length > 0) {
        store.state.RequestTapData.selectedRequest_descriptions.forEach((item, index) => {
            store.state.bodySearch.append(`RequestDescription[${index}]`, item["id"]);
        });
    }

    if (store.state.RequestTapData.requestDateFrom) {
        store.state.bodySearch.append("request_date_from", store.state.RequestTapData.requestDateFrom);
    }

    if (store.state.RequestTapData.requestDateTo) {
        store.state.bodySearch.append("request_date_to", store.state.RequestTapData.requestDateTo);
    } else {
        store.state.bodySearch.delete("request_date_to");
    }

    if (store.state.RequestTapData.completeDateFrom) {
        store.state.bodySearch.append("complete_date[0]", store.state.RequestTapData.completeDateFrom);
    } else {
        store.state.bodySearch.delete("complete_date[0]");
    }

    if (store.state.RequestTapData.completeDateTo) {
        store.state.bodySearch.append("complete_date[1]", store.state.RequestTapData.completeDateTo);
    } else {
        store.state.bodySearch.delete("complete_date[1]");
    }  

    if (store.state.RequestTapData.updatedDateFrom) {
        store.state.bodySearch.append("request_date_updated_from", store.state.RequestTapData.updatedDateFrom);
    } else {
        store.state.bodySearch.delete("request_date_updated_from");
    }

    if (store.state.RequestTapData.updatedDateTo) {
        store.state.bodySearch.append("request_date_updated_to", store.state.RequestTapData.updatedDateTo);
    } else {
        store.state.bodySearch.delete("request_date_updated_to");
    }

    if (store.state.RequestTapData.UnUpdatedDate) {
        store.state.bodySearch.append("request_date_did_not_updated_from", store.state.RequestTapData.UnUpdatedDate);
    } else {
        store.state.bodySearch.delete("request_date_did_not_updated_from");
    }

    if (store.state.RequestTapData.selectedWorkList.length > 0) {
        store.state.RequestTapData.selectedWorkList.forEach((item, index) => {
            store.state.bodySearch.append(`work_source[${index}]`, item["id"]);
        });
    }

    if (store.state.RequestTapData.selectedCustomerNeeds.length > 0) {
        store.state.RequestTapData.selectedCustomerNeeds.forEach((item, index) => {
            store.state.bodySearch.append(`MortgageCustomerNeeds[${index}]`, item["id"]);
        });
    }

    if (store.state.RequestTapData.selectedSalaryList.length > 0) {
        store.state.RequestTapData.selectedSalaryList.forEach((item, index) => {
            store.state.bodySearch.append(`salary_source[${index}]`, item["id"]);
        });
    }

    if (store.state.RequestTapData.selectedFundingSide.length > 0) {
        store.state.RequestTapData.selectedFundingSide.forEach((item, index) => {
            store.state.bodySearch.append(`funding_source[${index}]`, item["id"]);
        });
    }

    if (store.state.RequestTapData.selectedCustomerNeeds.length > 0) {
        store.state.RequestTapData.selectedCustomerNeeds.forEach((item, index) => {
            store.state.bodySearch.append(`MortgageCustomerNeeds[${index}]`, item["id"]);
        });
    }

    if (store.state.RequestTapData.selectedCustomerObjections.length > 0) {
        store.state.RequestTapData.selectedCustomerObjections.forEach((item, index) => {
            store.state.bodySearch.append(`MortgageCustomerObjections[${index}]`, item["id"]);
        });
    }

    if (store.state.RequestTapData.selectedCustomerObsatacles.length > 0) {
        store.state.RequestTapData.selectedCustomerObsatacles.forEach((item, index) => {
            store.state.bodySearch.append(`MortgageCustomerObsatacles[${index}]`, item["id"]);
        });
    }

    if (store.state.RequestTapData.selectAgent.length > 0) {
        store.state.RequestTapData.selectAgent.forEach((item, index) => {
            store.state.bodySearch.append(`agent_name[${index}]`, item["id"]);
        });
    }*/

}