<template>
  <div>
    <HeaderIcons
      :selectedItems="selectedItems"
      type="moveLoadTableData"
      @getData="reloadPage"
    />

    <div v-if="!this.$store.state.allLoading" style="margin-top: 40px">
     
      <div class="table__view">
        <table-component
          :showSelect="true"
          itemKey="id"
          :selectedRows.sync="selected_requests"
          :headers="headers"
          :pagination="this.$store.state.pagination"
          :limit="$store.state.counterOfShow"
          :items="this.$store.state.tableData"
          :loading="loading"
          @refresh="getTableData"
          v-model="page"
        >
          <template v-slot:[`item.action`]="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="white" small depressed dark v-bind="attrs" v-on="on">
                  <v-icon style="background: rgb(63, 14, 64); border-radius: 4px"
                    >mdi-dots-vertical</v-icon
                  >
                </v-btn>
              </template>
              <v-list>
                <AllSingleProcess :item="item" @getUsersName="getUsersName" />
              </v-list>
            </v-menu>
          </template>

          <template slot="item.real_estate.type" slot-scope="props">
            <span>{{ props.item?.real_estate?.type_text ?? '' }}</span>
            <v-icon @click="changeRequestProperty(props.item, props.item.real_estate.type, 'property_type')"
              color="blue darken-2"
            >
              mdi-pencil
            </v-icon>
          </template>
          <template slot="item.real_estate.city" slot-scope="props">
            <span>{{ props.item?.real_estate?.realCity ?? '' }}</span>
            <v-icon @click="changeRequestProperty(props.item, props.item.real_estate.city, 'fun_city')"
              color="blue darken-2"
            >
              mdi-pencil
            </v-icon>
          </template>
          <template slot="item.fm_comment" slot-scope="props">
            <span v-if="!props.item?.fm_comment"></span>
            <v-tooltip bottom v-else>
              <template v-slot:activator="{ on }">
                <span v-on="on">{{
                  props.item?.fm_comment.substring(0, 25) + "..."
                }}</span>
              </template>
              <div class="font-weight-bold">{{ props.item?.fm_comment }}</div>
            </v-tooltip>
            <!-- <span>{{ props.item?.fm_comment.substring(0, 25) + "..." }}</span> -->
            <v-icon @click="changeRequestProperty(props.item, props.item.fm_comment, 'comment')"
              color="blue darken-2"
            >
              mdi-pencil
            </v-icon>
          </template>
          <template slot="item.real_estate.pursuit" slot-scope="props">
            <span>{{ props.item?.real_estate?.pursuit ?? '' }}</span>
            <v-icon @click="changeRequestProperty(props.item, props.item?.real_estate?.pursuit, 'pursuit')"
              color="blue darken-2"
            >
              mdi-pencil
            </v-icon>
          </template>
          <template slot="item.funding.funding_source" slot-scope="props">
            <span>{{ props.item?.funding?.funding_source_text ?? '' }}</span>
            <v-icon @click="changeRequestProperty(props.item, props.item.funding.funding_source, 'fun_source')"
              color="blue darken-2"
            >
              mdi-pencil
            </v-icon>
          </template>
          <template slot="item.class_id_fm" slot-scope="props">
            <span>{{ props.item?.class_id_fm_text ?? '' }}</span>
            <v-icon @click="changeRequestProperty(props.item, props.item.class_id_fm, 'fun_class')"
              color="blue darken-2"
            >
              mdi-pencil
            </v-icon>
          </template>
          
          <template slot="item.empBank" slot-scope="props">
            <span>{{ props.item?.empBank ?? '' }}</span>
            <v-icon @click="changeRequestProperty(props.item, props.item.empBank, 'empBank')"
              color="blue darken-2"
            >
              mdi-pencil
            </v-icon>
          </template>
          
          <template slot="item.reqNoBank" slot-scope="props">
            <span>{{ props.item?.reqNoBank ?? '' }}</span>
            <v-icon @click="changeRequestProperty(props.item, props.item.reqNoBank, 'reqNoBank')"
              color="blue darken-2"
            >
              mdi-pencil
            </v-icon>
          </template>
          <template slot="item.quality_notes" slot-scope="props">
            <span v-if="!props.item.quality_notes"></span>
            <v-tooltip bottom v-else>
              <template v-slot:activator="{ on }">
                <span v-on="on">{{
                  props.item.quality_notes.length > 7
                    ? props.item.quality_notes.substring(0, 7) + "..."
                    : props.item.quality_notes
                }}</span>
              </template>
              <div class="font-weight-bold">{{ props.item.quality_notes }}</div>
            </v-tooltip>
          </template>

          <template slot="item.source" slot-scope="props">
            <span v-if="!props.item.source"></span>
            <v-tooltip bottom v-else>
              <template v-slot:activator="{ on }">
                <span v-on="on">{{
                  props.item.source.length > 5
                    ? props.item.source.substring(0, 5) + "..."
                    : props.item.source
                }}</span>
              </template>
              <div class="font-weight-bold">{{ props.item.source }}</div>
            </v-tooltip>
          </template>

          <template slot="item.type" slot-scope="props">
            <span v-if="props.item.type !== 'لا_يوجد'">{{ props.item.type }}</span>
            <span v-else style="color: red">{{ props.item.type }}</span>
          </template>
          <template slot="item.customer.name" slot-scope="props">
            <span >{{ props.item.customer.name }}</span>
          </template>
          <template slot="item.real_estate.name" slot-scope="props">
            <span >{{ props.item.real_estate.name }}</span>
          </template>
          <template slot="item.real_estate.mobile" slot-scope="props">
            <span >{{ props.item.real_estate.mobile }}</span>
          </template>
          <template slot="item.customer.mobile" slot-scope="props">
            <span >{{ props.item.customer.mobile }}</span>
          </template>
          <template slot="item.user.name" slot-scope="props">
            <span >{{ props.item.user.name }}</span>
          </template>

          <template slot="item.status" slot-scope="props">
            <span v-if="props.item.status?.includes('مرفوض ')" class="faild__request">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <span v-on="on">{{
                    props.item.status.length > 7
                      ? props.item.status.substring(0, 7) + ".."
                      : props.item.status
                  }}</span>
                </template>
                <div class="font-weight-bold">{{ props.item.status }}</div>
              </v-tooltip>
            </span>
            <span
              v-else-if="
                props.item.status?.includes('طلب جديد') ||
                props.item.status?.includes('طلب تم فتحه')
              "
              class="new__request"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <span v-on="on">{{
                    props.item.status.length > 8
                      ? props.item.status.substring(0, 8) + ".."
                      : props.item.status
                  }}</span>
                </template>
                <div class="font-weight-bold">{{ props.item.status }}</div>
              </v-tooltip>
            </span>

            <span
              v-else-if="
                props.item.status?.includes('مؤرشف') ||
                props.item.status?.includes('مكتمل')
              "
              class="archive__request"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <span v-on="on">{{
                    props.item.status.length > 7
                      ? props.item.status.substring(0, 7) + ".."
                      : props.item.status
                  }}</span>
                </template>
                <div class="font-weight-bold">{{ props.item.status }}</div>
              </v-tooltip>
            </span>
            <span v-else class="anotherTypes__request">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <span v-on="on">{{
                    props.item.status.length > 8
                      ? props.item.status.substring(0, 8) + ".."
                      : props.item.status
                  }}</span>
                </template>
                <div class="font-weight-bold">{{ props.item.status }}</div>
              </v-tooltip>
            </span>
          </template>

          <template slot="item.agent_notes" slot-scope="props">
            <span v-if="!props.item.agent_notes"></span>
            <v-tooltip bottom v-else>
              <template v-slot:activator="{ on }">
                <span v-on="on">{{
                  props.item.agent_notes.length > 7
                    ? props.item.agent_notes.substring(0, 7) + "..."
                    : props.item.agent_notes
                }}</span>
              </template>
              <div class="font-weight-bold">{{ props.item.agent_notes }}</div>
            </v-tooltip>
          </template>
        </table-component>
        <OrderRequestSection
          v-if="this.$store.state.openOrderRequestSection"
          style="margin-top: 50px"
        />
      </div>
    </div>
    <div
      style="
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
      "
      v-else
    >
      <LoadingMore />
    </div>
    <v-snackbar
      v-model="active"
      color="#40BE72"
      timeout="-1"
      right
      bottom
      class="ml-4"
      elevation="24"
      transition="fade-transition"
    >
      <span class="font-weight-bold" style="font-size: 16px">{{ message }}</span>
      <template v-slot:action>
        <v-btn icon small @click="active = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <FundingManagerFilterDialog pageName="all" @fireSearch="searchRequest"/>
    <OrderRequestDialog />
    <CallsDialog />
    <!-- change property type dailoge -->
    <v-dialog
      v-model="property_dialog"
      width="500"
    >

      <v-card>
        <v-card-title class="text-h6 grey lighten-2">
          {{property_dailog_title}}
        </v-card-title>

        <v-row style="padding: 10px;">
            <v-col cols="12" sm="12"  v-if="modal_type == 'property_type'" >
              <div style="display: flex;align-items: center;border: 1px solid;padding: 6px;" class="input__group">
                <div>
                  نوع العقار
                </div>
                <div class="horizontal__line"></div>
                <v-autocomplete
                  :items="property_type_list"
                  hide-details
                  v-model="new_property_type"
                  item-text="value"
                  item-value="id"
                  style="border: none !important"
                  class="mr-1"
                  outlined
                  dense
                >
                </v-autocomplete>
                <img src="@/assets/icons/down-arrow.svg" />
              </div>
            </v-col>
            <v-col cols="12" sm="12"  v-if="modal_type == 'fun_city'" >
              <div style="display: flex;align-items: center;border: 1px solid;padding: 6px;" class="input__group">
                <div>
                  المدينة
                </div>
                <div class="horizontal__line"></div>
                <v-autocomplete
                  :items="cities_list"
                  hide-details
                  v-model="new_city"
                  item-text="value"
                  item-value="id"
                  style="border: none !important"
                  class="mr-1"
                  outlined
                  dense
                >
                </v-autocomplete>
                <img src="@/assets/icons/down-arrow.svg" />
              </div>
            </v-col>
            <v-col cols="12" sm="12"  v-if="modal_type == 'fun_source'" >
              <div style="display: flex;align-items: center;border: 1px solid;padding: 6px;" class="input__group">
                <div>
                  جهة التمويل
                </div>
                <div class="horizontal__line"></div>
                <v-autocomplete
                  :items="fun_source_list"
                  hide-details
                  v-model="new_fun_source"
                  item-text="value"
                  item-value="id"
                  style="border: none !important"
                  class="mr-1"
                  outlined
                  dense
                >
                </v-autocomplete>
                <img src="@/assets/icons/down-arrow.svg" />
              </div>
            </v-col>
            <v-col cols="12" sm="12"  v-if="modal_type == 'fun_class'">
              <div style="display: flex;align-items: center;border: 1px solid;padding: 6px;" class="input__group">
                <div>
                  تصنيف المعاملة
                </div>
                <div class="horizontal__line"></div>
                <v-autocomplete
                  :items="fun_class_list"
                  hide-details
                  v-model="new_fun_class"
                  item-text="value"
                  item-value="id"
                  style="border: none !important"
                  class="mr-1"
                  outlined
                  dense
                >
                </v-autocomplete>
                <img src="@/assets/icons/down-arrow.svg" />
              </div>
            </v-col>
            <v-col cols="12" sm="12" v-if="modal_type == 'comment'" >
              <div style="display: flex;align-items: center;border: 1px solid;padding: 6px;" class="input__group">
                <div>
                  الملاحظة
                </div>
                <div class="horizontal__line"></div>
                
                <v-textarea
                  hide-details
                  v-model="req_comment"
                  class="mr-1"
                  outlined
                  dense
                >
                </v-textarea>
              </div>
            </v-col>
            <v-col cols="12" sm="12" v-if="modal_type == 'pursuit'" >
              <div style="display: flex;align-items: center;border: 1px solid;padding: 6px;" class="input__group">
                <div>
                  السعي
                </div>
                <div class="horizontal__line"></div>
                
                <v-text-field
                  hide-details
                  v-model="new_pursuit"
                  class="mr-1"
                  outlined
                  dense
                >
                </v-text-field>
              </div>
            </v-col>
            <v-col cols="12" sm="12" v-if="modal_type == 'reqNoBank'" >
              <div style="display: flex;align-items: center;border: 1px solid;padding: 6px;" class="input__group">
                <div>
                  رقم طلب البنك
                </div>
                <div class="horizontal__line"></div>
                
                <v-text-field
                  hide-details
                  v-model="new_reqNoBank"
                  class="mr-1"
                  outlined
                  dense
                >
                </v-text-field>
              </div>
            </v-col>
            <v-col cols="12" sm="12" v-if="modal_type == 'empBank'" >
              <div style="display: flex;align-items: center;border: 1px solid;padding: 6px;" class="input__group">
                <div>
                  موظف البنك
                </div>
                <div class="horizontal__line"></div>
                
                <v-text-field
                  hide-details
                  v-model="new_empBank"
                  class="mr-1"
                  outlined
                  dense
                >
                </v-text-field>
              </div>
            </v-col>
        </v-row>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="updatePropertyType()"
          >
            حفظ
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="property_dialog = false"
          >
            الغاء
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- change property type dailoge -->
  </div>
</template>

<script>
// import RequestsCard from "@/components/Charts/RequestsCard.vue";
import LoadingMore from "@/components/Charts/LoadingMore.vue";
import HeaderIcons from "@/views/funding_manager/Requests/HeaderIcons.vue";
// import AdminDialog from "@/components/AdminComponent/AdminDialog.vue";
// import AdministrationDialog from "@/components/AdminComponent/AdministrationDialog.vue";
import FundingManagerFilterDialog from "@/views/funding_manager/Requests/FundingManagerFilterDialog.vue";
import CallsDialog from "@/components/Charts/CallsDialog.vue";
import { formatDate } from "@/mixins/formatDate";
import { SourceValue } from "@/mixins/sourceValue";
import OrderRequestDialog from "@/components/AdminComponent/OrderRequestDialog.vue";
import OrderRequestSection from "@/components/AdminComponent/OrderRequestSection.vue";
import AllSingleProcess from "@/views/funding_manager/Requests/AllSingleProcess.vue";
import { axiosInstance } from "@/axios/config";
// import DefaultView from "@/components/Base/DefaultView.vue";

export default {
  name: "AdminSupport",
  components: {
    OrderRequestDialog,
    OrderRequestSection,
    FundingManagerFilterDialog,
    // AdminDialog,
    // AdministrationDialog,
    // RequestsCard,
    LoadingMore,
    HeaderIcons,
    AllSingleProcess,
    CallsDialog,
    // DefaultView,
  },

  data() {
    return {
      property_type_list: [],
      fun_class_list: [],
      fun_source_list: [],
      cities_list: [],
      allGetData: [],
      customers: [],
      property_dialog: false,
      property_dailog_title:'',
      edit_property_req_id:'',
      new_property_type:'',
      new_city:'',
      new_fun_class:'',
      new_reqNoBank:'',
      new_empBank:'',
      new_fun_source:'',
      req_comment:'',
      modal_type:'',
      search:'',
      selected_requests: [],
      pagination: {
        limit: 3,
        count: this.$store.state.searchedCount,
      },
      page: 1,
      selectedRows: [],
      allData: [],
      active: false,
      message: "",
      showSelect: { default: false },
      copySupportRequests: [],
      loadMoreData: false,
      count: null,
      loading: false,
      selectId: null,
      allStatus: [],
      label: "",
      selectedItems: [],
      countPage: 1,
      listenToFreezeAction: this.$store.state.freezeActionIsDone,
      headers: [
        { text: "السعي", value: "real_estate.pursuit", width: "107px", align: "center" },
        { text: "الرهن", value: "type", align: "center" },
        {
          text: "عميل",
          value: "customer.name",
          align: "center",
        },
        { text: "الجوال", value: "customer.mobile", align: "center" },
        { text: "نوع العقار", value: "real_estate.type", align: "center" },
        { text: "المدينة", value: "real_estate.city", width: "108px", align: "center" },
        {
          text: "إسم المالك",
          value: "real_estate.name",
          align: "center",
        },
        {
          text: "جوال المالك ",
          value: "real_estate.mobile",
          align: "center",
        },
        {
          text: "جهة نزول الراتب",
          value: "customers.salary_id",
          width: "110px",
          align: "center",
        },
        {
          text: "جهة التمويل",
          value: "funding.funding_source",
          width: "110px",
          align: "center",
        },
        {
          text: "موظف البنك",
          value: "empBank",
          width: "107px",
          align: "center",
        },
        {
          text: "رقم طلب البنك",
          value: "reqNoBank",
          align: "center",
        },
        {
          text: "مصدر المعاملة",
          value: "source_text",
          width: "107px",
          align: "center",
        },
        {
          text: "نوع الطلب",
          value: "type",
          width: "107px",
          align: "center",
        },
        {
          text: "استشاري المبيعات",
          value: "user.name",
          width: "107px",
          align: "center",
        },
        {
          text: "تاريخ الإستلام",
          value: "recived_date_report",
          width: "107px",
          align: "center",
        },
        {
          text: "تصنيف المعاملة",
          value: "class_id_fm",
          width: "107px",
          align: "center",
        },
        {
          text: "الملاحظة",
          value: "fm_comment",
          width: "107px",
          align: "center",
        },
        { text: "العمليات", value: "action", align: "center" },
      ],
    };
  },

  watch: {
    page(val) {
      if (this.$store.state.isSearch === false && this.search == null) {
        this.getTableData(val);
      } else {
        this.searchRequest(val, "table");
      }
    },

    selected_requests(val) {
      this.selectedItems = val;
      this.$emit("requests", val);
    },

    "$store.state.counterOfShow": {
      handler(newval, oldvalue) {
        if (newval !== oldvalue && !this.$store.state.admin_search_active) {
          this.getTableData(this.page);
        }
      },
    },

    $route: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.$store.state.counterOfShow = 10;
        }
      },
    },
  },

  beforeDestroy() {
    this.$store.state.openOrderRequestSection = false;
  },

  methods: {
    getUsersName(users, label, requestId) {
      this.allStatus = users;
      this.label = label;
      this.selectId = requestId;
    },
    updatePropertyType(){
      let req_id = this.edit_property_req_id;
      let vl = this.new_property_type;
      // update and refresh table new_property_type
      let data = {
        reqType: this.new_property_type
      };
      let url = '';
      switch (this.modal_type) {
        case 'property_type':
            url = `/employee/funding-manager/save-realType-value/${req_id}`;
            data = {
              reqType: this.new_property_type
            };
            break;
        case 'pursuit':
            url = `/employee/funding-manager/save-realPursuit-value/${req_id}`;
            data = {
              new_pursuit: this.new_pursuit
            };
            break;
        case 'fun_source':
            url = `/employee/funding-manager/save-funSource-value/${req_id}`;
            data = {
              funSour: this.new_fun_source
            };
            break;
        case 'fun_class':
            url = `/employee/funding-manager/save-class-value/${req_id}`;
            data = {
              reqClass: this.new_fun_class
            };
            break;
        case 'reqNoBank':
            url = `/employee/funding-manager/save-reqNoBank-value/${req_id}`;
            data = {
              reqNoBank: this.new_reqNoBank
            };
            break;
        case 'empBank':
            url = `/employee/funding-manager/save-empBank-value/${req_id}`;
            data = {
              empBank: this.new_empBank
            };
            break;
        case 'fun_city':
            url = `/employee/funding-manager/save-city-value/${req_id}`;
            data = {
              realcity: this.new_city
            };
          break;
        case 'comment':
            url = `/employee/funding-manager/save-comment-value/${req_id}`;
            data = {
              reqComm: this.req_comment
            };
          break;
      
        default:
          break;
      }
      axiosInstance
        .post(url,data, {
          headers: this.request_headers(),
        })
        .then((res) => {
          // res.data.payload.data.map((item) => {
          //   this.setTblData(item);
          // }); 
          const item = this.$store.state.tableData.find(item => item.id === req_id);
          if (item) {
            item.real_estate.type = res.data.payload.real_estate.type;
            item.real_estate.type_text = res.data.payload.real_estate.type_text;
            item.real_estate.city = res.data.payload.real_estate.city;
            item.real_estate.pursuit = res.data.payload.real_estate.pursuit;
            item.real_estate.realCity = res.data.payload.real_estate.realCity;
            item.funding.funding_source = res.data.payload.funding.funding_source;
            item.funding.funding_source_text = res.data.payload.funding.funding_source_text;
            item.class_id_fm = res.data.payload.class_id_fm;
            item.class_id_fm_text = res.data.payload.class_id_fm_text;
            item.fm_comment = res.data.payload.fm_comment;
            item.reqNoBank = res.data.payload.reqNoBank;
            item.empBank = res.data.payload.empBank;
          }
          console.log(res.data.payload.real_estate.type_text);
          this.loading = false;
        });
        this.property_dialog = false;
    },

    handleBack() {
      this.$store.state.allData = [];
      this.$store.state.tableData = [];
      this.allGetData = [];
      this.$store.state.followLoading = true;
      this.$store.state.isSearch = false;
      this.$store.state.back = false;
      this.getLoadTableData(1);
    },

    changeRequestProperty(req, old_val, type) {
      console.log('item_', req);
      this.modal_type = type;
      switch (type) {
        case 'property_type':
            this.new_property_type = old_val;
            break;
        case 'pursuit':
            this.new_pursuit = old_val;
            break;
        case 'fun_source':
            this.new_fun_source = old_val;
            break;
        case 'fun_class':
            this.new_fun_class = old_val;
            break;
        case 'reqNoBank':
            this.new_reqNoBank = old_val;
            break;
        case 'empBank':
            this.new_empBank = old_val;
            break;
        case 'fun_city':
            this.new_city = old_val;
          break;
        case 'comment':
            this.req_comment = old_val;
          break;
      
        default:
          break;
      }
      this.property_dialog = true;
      this.edit_property_req_id = req.id;
      this.property_dailog_title = req.customer.name + ` ${req.customer.mobile}` ;
    },
    reloadPage() {
      this.$store.state.allData = [];
      this.$store.state.tableData = [];
      this.allGetData = [];
      this.$store.state.followLoading = true;
      this.$store.state.isSearch = false;
      this.getLoadTableData(1);
      this.$store.state.startElement = [];
    },

    setTblData(res) {
      // console.log("res", res);
      this.$store.state.tableData.push(res);
      /*this.$store.state.tableData.push({
        id: res.id,
        isSelected: false,
        "رقم الطلب": res.id,
        created_at: res.created_at ? formatDate(res.created_at) : "",
        type: res.type || "لا_يوجد",
        "استشاري المبيعات": res.user?.name,
        عميل: res.customer.name,
        الجوال: res.customer.mobile,
        status: res.status,
        source: SourceValue(res.source),
        "تصنيف الاستشاري": res.class_id_agent_text,
        agent_notes: res.agent_notes,
        quality_notes: res.quality_notes,
        agent_date: res.agent_date ? formatDate(res.agent_date) : "",
        "هل تم استلامه من قبل الجوده":
          (res.class_id_quality != null || res.quacomment != null) ? "نعم" :  "لا",
          // res.user?.allow_recived && res.user?.allow_recived === 0 ? "لا" : "نعم",
        updated_at: res.updated_at ? formatDate(res.updated_at) : "",
        العمليات: res.sent_basket?.count,
      });*/
    },

    getTableData(count) {
      this.$store.state.tableData = [];
      this.loading = true;
      const pageNumber = Number(this.$store.state.counterOfShow);
      axiosInstance
        .get(`/v2/employee/Requests?type=underpage&page=${count}&per_page=${pageNumber}`, {
          headers: this.request_headers(),
        })
        .then((res) => {
          res.data.payload.data.map((item) => {
            this.setTblData(item);   
          });
          this.loading = false;
        });
    },

    handlePageNumber(val) {
      if (this.$store.state.isSearch === false) {
        this.getLoadTableData(val);
      } else {
        this.searchRequest(val);
      }
    },

    searchRequest(page, type = 'table') {
      this.loading = true;

        this.$store.state.tableData = [];
        this.$store.state.bodySearch.delete("page");
        this.$store.state.bodySearch.append("page", page);

        let url = `/v2/employee/Requests/search?type=underpage&per_page=${this.$store.state.counterOfShow}`;
        axiosInstance
          .post(url, this.$store.state.bodySearch, { headers: this.request_headers() })
          .then((res) => {
          this.$store.state.pagination = {
            limit: 3,
            count: res.data.payload.count_of_underpage,
          };
          let response = res.data.payload.data.map((item) => {
            this.setTblData(item);
            item["starChecked"] = false;
            return item;
          });

          this.allGetData.push(...response);
          this.removeDublicate(this.allGetData);
          this.copySupportRequests.push(...response);
        })
        .catch((err) => {
          this.$store.state.allLoading = false;
          this.loadMoreData = false;
        })
        .finally(() => {
          this.loadMoreData = false;
          this.$store.state.allLoading = false;
        });
    },

    getLoadTableData(page) {
      if (
        this.$store.state.allData.length === 0 ||
        this.copySupportRequests.length === 0
      ) {
        this.$store.state.allLoading = true;
      }
      this.loadMoreData = true;
      axiosInstance
        .get(
          `/v2/employee/Requests?type=underpage&page=${page}&per_page=${this.$store.state.counterOfShow}`
        )
        .then((res) => {
          this.$store.state.pagination = {
            limit: 3,
            count: res.data.payload.count_of_underpage,
          };
          let response = res.data.payload.data.map((item) => {
            this.setTblData(item);
            item["starChecked"] = false;
            return item;
          });

          this.allGetData.push(...response);
          this.removeDublicate(this.allGetData);
          this.copySupportRequests.push(...response);
        })
        .catch((err) => {
          this.$store.state.allLoading = false;
          this.loadMoreData = false;
        })
        .finally(() => {
          this.loadMoreData = false;
          this.$store.state.allLoading = false;
        });
    },

  },
  created() {
    this.$store.state.tableData = [];
    this.$store.state.back = false;
    this.$store.state.isSearch = false;
    this.$store.state.bodySearch = new FormData();
    this.getLoadTableData(1);
    this.allLoading = false;
    this.$store.state.startElement = [];
    axiosInstance.get(`/RealTypes`, {
          headers: this.request_headers(),
        })
        .then((res) => {
          this.property_type_list = res.data.payload;
          console.log("RealTypes", res.data.payload);
        });
    axiosInstance.get(`/cities`, {
          headers: this.request_headers(),
        })
        .then((res) => {
          this.cities_list = res.data.payload;
          console.log("cities", res.data.payload);
        });
    axiosInstance.get(`/agent/Funding/Sources`, {
          headers: this.request_headers(),
        })
        .then((res) => {
          this.fun_source_list = res.data.payload;
          console.log("fun_source_list", res.data.payload);
        });
    axiosInstance.get(`/employee/funding-manager/get-funding-manager-classification`, {
          headers: this.request_headers(),
        })
        .then((res) => {
          this.fun_class_list = res.data.payload;
          console.log("fun_class_list", res.data.payload);
        });
  },
};
</script>

<style>
.button__back {
  width: 70px;
  height: 40px;
  border-radius: 5px;
  margin: 50px;
  color: white;
  background-color: #441545;
  direction: ltr;
}
.noData {
  display: flex;
  justify-content: center;
  font-size: 22px;
  color: #3f0e40;
  margin-top: 70px;
  font-weight: bold;
}

.faild__request {
  border: 1px solid red;
  color: red;
  display: inline-block;
  padding: 0 3px 3px 3px;
  border-radius: 4px;
}

.archive__request {
  border: 1px solid #dbb622;
  color: #dbb622;
  display: inline-block;
  padding: 0 3px 3px 3px;
  border-radius: 4px;
}

.anotherTypes__request {
  border: 1px solid #ff7b1c;
  color: #ff7b1c;
  display: inline-block;
  padding: 0 3px 3px 3px;
  border-radius: 4px;
}

.new__request {
  border: 1px solid green;
  color: green;
  padding: 0 4px 4px 4px;
  border-radius: 4px;
}

.table__view .v-btn:not(.v-btn--round).v-size--small {
  min-width: auto !important;
}
</style>
