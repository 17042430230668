<template>
    <div>
        <v-form style="margin-top: 20px">
            <v-row>
                <v-col cols="12" sm="12" md="12">
                    <SelectInput ref="selectInput16" @selectedValues="getQuality" :originalItems="quality"
                        label="الجوده" />
                </v-col>
                <v-col cols="12" sm="6" md="6">
                    <v-autocomplete :items="['لا', 'نعم']" label="استلامه من قبل الجوده" @change="sendDataToParent()"
                        v-model="resiveFromQuality" outlined dense>
                        <template v-slot:item="{ item, attrs, on }">
                            <v-list-item v-bind="attrs" v-on="on">
                                <v-list-item-title :id="attrs['aria-labelledby']" v-text="item"></v-list-item-title>
                            </v-list-item>
                        </template>
                    </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                    <v-autocomplete :items="['لا', 'نعم']" label="ارشفه من قبل الجوده" @change="sendDataToParent()"
                        v-model="qualityArchive" outlined dense>
                        <template v-slot:item="{ item, attrs, on }">
                            <v-list-item v-bind="attrs" v-on="on">
                                <v-list-item-title :id="attrs['aria-labelledby']" v-text="item"></v-list-item-title>
                            </v-list-item>
                        </template>
                    </v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                    <SelectInput ref="selectInput17" @selectedValues="getQualityType" :originalItems="qualityStatus"
                        label="حاله الطلب عند الجوده" />
                </v-col>

                <v-col cols="12" sm="6" md="6">
                    <SelectInput ref="selectInput18" @selectedValues="getVarityQuality" :originalItems="qualityUserType"
                        label="تصنيف الجوده" />
                </v-col>
            </v-row>
        </v-form>
    </div>
</template>


<script>
import SelectInput from "@/components/Charts/SelectInput.vue";
import { axiosInstance } from "@/axios/config";

export default {
    components: { SelectInput },
    data() {
        return {
            qualityUserType: [],
            quality: [],
            selectedQualityUsers: [],
            resiveFromQuality: "",
            qualityArchive: "",
            selectedVarityQuality: [],
            qualityStatus: [],
            selectedQualityStatus: [],
        };
    },

    methods: {
        sendDataToParent() {
            this.$emit("send-quality-data", {
                selectedQualityStatus: this.selectedQualityStatus,
                selectedQualityUsers: this.selectedQualityUsers,
                resiveFromQuality: this.resiveFromQuality,
                qualityArchive: this.qualityArchive,
                selectedVarityQuality: this.selectedVarityQuality,
            });
        },

        getQualityType(val) {
            this.selectedQualityStatus = val;
            this.sendDataToParent();
        },

        getVarityQuality(val) {
            this.selectedVarityQuality = val;
            this.sendDataToParent();
        },
        getQuality(val) {
            this.selectedQualityUsers = val;
            this.sendDataToParent();
        },

        requestClass(data) {
            data.map((item) => {
                item["color"] = "red";
                item["text"] = item["value"];
                return item;
            });
        },

        getRequestClassification(val) {
            let url = `/agent/Request/Classification?type_of_classification=${val}`;
            axiosInstance.get(url).then((res) => {
                if (val === 1) {
                    this.selesMangerType = res.data.payload;
                    this.requestClass(this.selesMangerType);
                } else if (val === 2) {
                    this.fundingMangerType = res.data.payload;
                    this.requestClass(this.fundingMangerType);
                } else if (val === 3) {
                    this.marteryMangerType = res.data.payload;
                    this.requestClass(this.marteryMangerType);
                } else if (val === 4) {
                    this.genaralMangerType = res.data.payload;
                    this.requestClass(this.genaralMangerType);
                } else if (val === 5) {
                    this.qualityUserType = res.data.payload;
                    this.requestClass(this.qualityUserType);
                } else if (val === 0) {
                    this.selesAgentType = res.data.payload;
                    this.requestClass(this.selesAgentType);
                }
            });
        },

        getQualityStatus() {
            let url = "/employee/quality/quality-request/request/status";
            axiosInstance.get(url).then((res) => {
                this.qualityStatus = res.data.payload.map((item) => {
                    item["color"] = "red";
                    item["text"] = item["status_value"];
                    return item;
                });
            });
        },

        getSupAdmin() {
            let url = "/v2/employee/quality-users";
            axiosInstance.get(url).then((res) => {
                this.quality = res.data.payload.map((item) => {
                    item["color"] = "red";
                    item["text"] = item["name_for_admin"];
                    return item;
                });
            });
        },

    },

    mounted() {
        this.getRequestClassification(5);
        this.getQualityStatus();
        this.getSupAdmin();
    },

};
</script>
