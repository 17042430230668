<template>
  <v-row justify="center">
    <v-dialog v-model="$store.state.turnuserDialog" persistent width="1024">
      <v-card>
        <v-card-title>
          <span>تحويل الطلب</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6">
                <input
                  type="checkbox"
                  id="unActive"
                  name="unActive"
                  @change="handleChecked"
                  value="غير نشط"
                  :checked="mangerChecked"
                />
                <label for="unActive"> غير نشط </label><br />
              </v-col>
              <v-col cols="12" sm="6">
                <input
                  type="checkbox"
                  id="active"
                  name="active"
                  value="نشط"
                  :checked="agentChecked"
                  @change="handleCheckedAgent"
                />
                <label for="unActive"> نشط</label><br />
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <SelectInput
                  @selectedValues="getSelectedValueManger"
                  :originalItems="getManagerNames"
                  label="مدير المبيعات"
                />
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <SelectInput
                  @selectedValues="getSelectedValueAgent"
                  :originalItems="getAgentsName"
                  label="استشاري المبيعات"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="dialog = $store.state.turnuserDialog = false"
          >
            الغاء
          </v-btn>
          <v-btn color="blue-darken-1" variant="text" @click="handleChangeUser">
            تحويل
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="active"
      :color="color"
      timeout="-1"
      right
      bottom
      class="ml-4"
      elevation="24"
      transition="fade-transition"
    >
      <div>
        <span class="font-weight-bold" style="font-size: 16px">{{ message }}</span>
      </div>
      <template v-slot:action>
        <v-btn icon small @click="active = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-row>
</template>

<script>
import SelectInput from "@/components/Charts/SelectInput.vue";

import { removeDuplicated } from "@/mixins/removeDublicated";
import { axiosInstance } from "@/axios/config";

export default {
  components: { SelectInput },
  props: ["selectedItems", "type", "isRateTransfer"],
  data: () => ({
    dialog: false,
    allMangers: [],
    allAgents: [],
    mangerChecked: false,
    agentChecked: false,
    selectedManger: [],
    selectValue: [],
    selectedAgent: [],
    AllSelectedTable: [],
    active: false,
    message: "",
    color: "",
  }),
  computed: {
    getManagerNames() {
      return this.allMangers;
    },

    getAgentsName() {
      return this.allAgents;
    },
  },

  watch: {
    mangerChecked(val) {
      if (val === false) {
        this.getAllManger("manager", 1);
      } else if (val === true) {
        this.getAllManger("manager", 0);
      }
    },

    agentChecked(val) {
      if (val === false) {
        this.getAllAgents("agent", 0, this.selectValue);
      } else if (val === true) {
        this.getAllAgents("agent", 1, this.selectValue);
      }
    },
  },

  mounted() {
    this.getAllAgents("agent");
    this.getAllManger("manager");
  },

  methods: {
    handleChangeUser() {
      this.$store.state.turnuserDialog = false;
      let body = new FormData();
      let url = `/v2/employee/Requests/${this.type}`;

      if (window.location.pathname === "/admin/requests/need-to-turn-new") {
        url = `/v2/employee/Requests/moveNeedToBeTurnedQualityReqNew`;
      }

      if (this.isRateTransfer === true) {
        url = `/v2/employee/Requests/moveAllRequests`;
      }

      this.selectedAgent.length &&
        this.selectedAgent.forEach((item, index) => {
          body.append(`agents_ids[${index}]`, item["id"]);
        });

      this.selectedMangerValue != null && this.selectedMangerValue.length &&
        this.selectedMangerValue.forEach((item, index) => {
          body.append(`manager_ids[${index}]`, item["id"]);
        });

      this.selectedItems.length &&
        this.selectedItems.forEach((item, index) => {
          if (this.isRateTransfer === true) {
            body.append(`request_ids[${index}]`, item["request_id"]);
          } else {
            body.append(`request_ids[${index}]`, item["id"]);
          }
        });
      this.$store.state.startElement.length &&
        this.$store.state.startElement.forEach((item, index) => {
          body.append(`request_ids[${index}]`, item["id"]);
        });

      axiosInstance
        .post(url, body)
        .then((res) => {
          this.active = true;
          this.message = res.data.message;
          this.color = "green";
          this.$store.state.reloaddata_after_action = {
                action: 'admin_need_action_new',
                date: new Date,
                view_type: 'table',
              };
          this.$emit("reloadPage");
          
        })
        .catch((err) => {
          this.active = true;
          let error = { err };
          let message = "";
          if (Array.isArray(error.err.response.data.message)) {
            message = error.err.response.data.message[0];
          } else {
            message = error.err.response.data.message;
          }
          this.message = message;
          this.color = "red";
        });
    },

    getSelectedValueAgent(val) {
      this.selectedAgent = val;
    },

    getSelectedValueManger(val) {
      this.selectedMangerValue = val;
      if (this.agentChecked === false) {
        this.getAllAgents("agent", 0, val);
      } else if (this.agentChecked === true) {
        this.getAllAgents("agent", 1, val);
      }
    },

    handleChecked() {
      this.mangerChecked = !this.mangerChecked;
    },

    handleCheckedAgent() {
      this.agentChecked = !this.agentChecked;
    },

    getAllManger(role, status) {
      this.allMangers = [];
      let body = { role: role };
      if (status === 0) {
        body = {
          status: status,
          role: role,
        };
      }

      let url = "/employee/admin/filterUsers";
      axiosInstance.post(url, body).then((res) => {
        this.allMangers = res.data.payload;
        this.allMangers.map((item) => {
          item["color"] = "red";
          item["text"] = item["name"];
          return item;
        });
      });
    },

    getAllAgents(role, status, val) {
      this.allAgents = [];
      let body = new FormData();
      body.append("role", role);
      if (status === 0 && !val) {
        body.append("status", status);
        body.append("role", role);
      } else if (status === 1 && !val) {
        body.append("status", status);
        body.append("role", role);
      }

      this.selectValue = val;
      if (val) {
        val.forEach((item, index) => {
          this.selectedManger.push(`'manager_id[${index}]': '${item["id"]}'`);
        });
      }

      let removeDublicate = removeDuplicated(this.selectedManger);

      if (removeDublicate.length) {
        val.forEach((item, index) => {
          body.append(`manager_id[${index}]`, item["id"]);
        });
        body.append("status", status);
        body.append("role", role);
      }

      let url = "/employee/admin/filterUsers";
      axiosInstance.post(url, body).then((res) => {
        this.allAgents = res.data.payload;
        this.allAgents.map((item) => {
          item["color"] = "red";
          item["text"] = item["name"];
          return item;
        });
      });
    },
  },
};
</script>
