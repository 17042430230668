export function getQualityTabData(store) {

    if(store.state.QualityTapData.selectedQualityStatus.length > 0) {
        store.state.QualityTapData.selectedQualityStatus.forEach((item, index) => {
            store.state.bodySearch.set(`quality_status_of_request[${index}]`, item["id"]);
        });
    }else{
        store.state.bodySearch.delete('quality_status_of_request');
    }

    if (store.state.QualityTapData.selectedQualityUsers.length > 0) {
        store.state.QualityTapData.selectedQualityUsers.forEach((item, index) => {
            store.state.bodySearch.set(`quality_of_request[${index}]`, item["id"]);
        });
    }else{
        store.state.bodySearch.delete('quality_of_request');
    }

    if (store.state.QualityTapData.resiveFromQuality === "نعم") {
        store.state.bodySearch.set("quality_request_received", 1);
    }else if (store.state.QualityTapData.resiveFromQuality === "لا") {
        store.state.bodySearch.set("quality_request_received", 0);
    }else{
        store.state.bodySearch.delete('quality_request_received');
    }

    if (store.state.QualityTapData.qualityArchive === "نعم") {
        store.state.bodySearch.set("request_archived_by_quality", 1);
    }else if (store.state.QualityTapData.qualityArchive === "لا") {
        store.state.bodySearch.set("request_archived_by_quality", 0);
    }else{
        store.state.bodySearch.delete('request_archived_by_quality');
    }


    if (store.state.QualityTapData.selectedVarityQuality.length > 0) {
        store.state.QualityTapData.selectedVarityQuality.forEach((item, index) => {
            store.state.bodySearch.set(`quality_classification_of_request[${index}]`, item["id"]);
        });
    }else{
        store.state.bodySearch.delete('quality_classification_of_request');
    }




    // const formDataKeys = Array.from(store.state.bodySearch.keys());

    // Handle selectedQualityUsers
    // if (store.state.QualityTapData.selectedQualityUsers.length > 0) {
    //     store.state.QualityTapData.selectedQualityUsers.forEach((item, index) => {
    //         const key = `quality_of_request[${index}]`;
    //         if (!formDataKeys.includes(key)) {
    //             store.state.bodySearch.append(key, item["id"]);
    //         }
    //     });
    // }

    // // Handle selectedQualityStatus
    // if (store.state.QualityTapData.selectedQualityStatus.length > 0) {
    //     store.state.QualityTapData.selectedQualityStatus.forEach((item, index) => {
    //         const key = `quality_status_of_request[${index}]`;
    //         if (!formDataKeys.includes(key)) {
    //             store.state.bodySearch.append(key, item["id"]);
    //         }
    //     });
    // }

    // // Handle resiveFromQuality
    // if (!formDataKeys.includes("quality_request_received")) {
    //     const value = store.state.QualityTapData.resiveFromQuality === "نعم" ? 1 : 0;
    //     store.state.bodySearch.append("quality_request_received", value);
    // }

    // // Handle qualityArchive
    // if (!formDataKeys.includes("request_archived_by_quality")) {
    //     const value = store.state.QualityTapData.qualityArchive === "نعم" ? 1 : 0;
    //     store.state.bodySearch.append("request_archived_by_quality", value);
    // }

    // // Handle selectedVarityQuality
    // if (store.state.QualityTapData.selectedVarityQuality.length > 0) {
    //     store.state.QualityTapData.selectedVarityQuality.forEach((item, index) => {
    //         const key = `quality_classification_of_request[${index}]`;
    //         if (!formDataKeys.includes(key)) {
    //             store.state.bodySearch.append(key, item["id"]);
    //         }
    //     });
    // }


    /*if (store.state.QualityTapData.selectedQualityStatus.length > 0) {
        store.state.QualityTapData.selectedQualityStatus.forEach((item, index) => {
            store.state.bodySearch.append(`quality_status_of_request[${index}]`, item["id"]);
        });
    }

    if (store.state.QualityTapData.selectedQualityUsers.length > 0) {
        store.state.QualityTapData.selectedQualityUsers.forEach((item, index) => {
            store.state.bodySearch.append(`quality_of_request[${index}]`, item["id"]);
        });
    }

    if (store.state.QualityTapData.resiveFromQuality === "نعم") {
        store.state.bodySearch.append("quality_request_received", 1);
    }

    if (store.state.QualityTapData.resiveFromQuality === "لا") {
        store.state.bodySearch.append("quality_request_received", 0);
    }

    if (store.state.QualityTapData.qualityArchive === "نعم") {
        store.state.bodySearch.append("request_archived_by_quality", 1);
    }

    if (store.state.QualityTapData.qualityArchive === "لا") {
        store.state.bodySearch.append("request_archived_by_quality", 0);
    }


    if (store.state.QualityTapData.selectedVarityQuality.length > 0) {
        store.state.QualityTapData.selectedVarityQuality.forEach((item, index) => {
            store.state.bodySearch.append(`quality_classification_of_request[${index}]`, item["id"]);
        });
    }*/

  }