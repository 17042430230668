<template>
    <div>
        <v-form style="margin-top: 20px">
            <v-row>
                <v-col cols="12" sm="6" md="6">
                    <v-text-field label="الهاتف" v-model="phoneNumber" @change="sendDataToParent()" outlined
                        v-numericOnly dense></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                    <v-autocomplete :items="['غير سعودي', 'سعودي']" label="الجنسية" @change="sendDataToParent()"
                        v-model="selectedNationality" outlined dense>
                        <template v-slot:item="{ item, attrs, on }">
                            <v-list-item v-bind="attrs" v-on="on">
                                <v-list-item-title :id="attrs['aria-labelledby']" v-text="item"></v-list-item-title>
                            </v-list-item>
                        </template>
                    </v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                    <v-autocomplete :items="['شركة', 'مؤسسة', 'فرد']" label="لديه" @change="sendDataToParent()"
                        v-model="selectedYouHave" outlined dense>
                        <template v-slot:item="{ item, attrs, on }">
                            <v-list-item v-bind="attrs" v-on="on">
                                <v-list-item-title :id="attrs['aria-labelledby']" v-text="item"></v-list-item-title>
                            </v-list-item>
                        </template>
                    </v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                    <v-autocomplete :items="['نقاط بيع', 'قوائم مالية', 'لا يوجد']" label="مصدر الدخل"
                        v-model="selectedSourceOfIncome" outlined dense @change="sendDataToParent()">
                        <template v-slot:item="{ item, attrs, on }">
                            <v-list-item v-bind="attrs" v-on="on">
                                <v-list-item-title :id="attrs['aria-labelledby']" v-text="item"></v-list-item-title>
                            </v-list-item>
                        </template>
                    </v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                    <v-autocomplete :items="['نعم', 'لا']" label="هل لديه راتب" @change="sendDataToParent()"
                        v-model="selectedHaveSalary" outlined dense>
                        <template v-slot:item="{ item, attrs, on }">
                            <v-list-item v-bind="attrs" v-on="on">
                                <v-list-item-title :id="attrs['aria-labelledby']" v-text="item"></v-list-item-title>
                            </v-list-item>
                        </template>
                    </v-autocomplete>
                </v-col>


                <v-col cols="12" sm="6" md="6">
                    <PickerInput ref="pickerInput7" label="تاريخ الميلاد" @dateValue="saveBirthDate" />
                </v-col>

                <v-col cols="12" sm="6" md="6">
                    <v-text-field label="راتب العميل من" v-model="salaryFrom" @change="sendDataToParent()" outlined
                        dense></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                    <v-text-field label="راتب العميل الي" v-model="salaryTo" @change="sendDataToParent()" outlined
                        dense></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                    <SelectInput ref="selectInput15" @selectedValues="getSelectedRegionValue"
                        :originalItems="selectedRegion" label="منطقه العميل" />
                </v-col>

                <v-col cols="12" sm="6" md="6">
                    <v-autocomplete :items="['لا', 'نعم']" label="هل وجد عقار" @change="sendDataToParent()"
                        v-model="isHasProperty" outlined dense>
                        <template v-slot:item="{ item, attrs, on }">
                            <v-list-item v-bind="attrs" v-on="on">
                                <v-list-item-title :id="attrs['aria-labelledby']" v-text="item"></v-list-item-title>
                            </v-list-item>
                        </template>
                    </v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                    <v-autocomplete :items="['لا', 'نعم']" label="تم تحميل التطبيق" @change="sendDataToParent()"
                        v-model="isAplicationInstal" outlined dense>
                        <template v-slot:item="{ item, attrs, on }">
                            <v-list-item v-bind="attrs" v-on="on">
                                <v-list-item-title :id="attrs['aria-labelledby']" v-text="item"></v-list-item-title>
                            </v-list-item>
                        </template>
                    </v-autocomplete>
                </v-col>
            </v-row>
        </v-form>
    </div>
</template>


<script>
import SelectInput from "@/components/Charts/SelectInput.vue";
import PickerInput from "@/components/AdminComponent/PickerInput.vue";
import { axiosInstance } from "@/axios/config";

export default {
    components: { PickerInput, SelectInput },
    data() {
        return {
            phoneNumber: "",
            selectedRegion: [],
            selectedNationality: "",
            selectedYouHave: "",
            selectedSourceOfIncome: "",
            selectedHaveSalary: "",
            salaryFrom: "",
            salaryTo: "",
            birthDate: "",
            selectedRegionList: [],
            isHasProperty: "",
            isAplicationInstal: "",
        };
    },

    methods: {
        sendDataToParent() {
            this.$emit("send-customer-data", {
                selectedNationality: this.selectedNationality,
                selectedYouHave: this.selectedYouHave,
                selectedSourceOfIncome: this.selectedSourceOfIncome,
                selectedHaveSalary: this.selectedHaveSalary,
                phoneNumber: this.phoneNumber,
                salaryFrom: this.salaryFrom,
                salaryTo: this.salaryTo,
                birthDate: this.birthDate,
                selectedRegionList: this.selectedRegionList,
                isHasProperty: this.isHasProperty,
                isAplicationInstal: this.isAplicationInstal,
            });
        },

        saveBirthDate(val) {
            this.birthDate = val;
            this.sendDataToParent()
        },

        getSelectedRegionValue(val) {
            this.selectedRegionList = val;
            this.sendDataToParent()
        },

        getSelectedRegion() {
            axiosInstance.get("/agent/regions").then((res) => {
                this.selectedRegion = res.data.payload.map((item) => {
                    item["color"] = "red";
                    item["text"] = item["region_ip"];
                    return item;
                });
            });
        },
    },

    mounted() {
        this.getSelectedRegion();
    }
};
</script>
