<template>
  <page-component :loading="loading" v-if="$store.state.cardsStyle">
    <template #content>
      <div class="cards mb-5 selected-slider">
        <v-sheet class="mx-auto mb-7 vSheetContainer" v-if="$store.state.elements.length">
          <v-slide-group multiple show-arrows>
            <v-slide-item v-for="(person, i) in $store.state.elements" :key="i">
              <v-chip
                outlined
                :color="staredBadgeSelected == person.id ? '#3b5de7' : '#F6F7FA'"
                class="my-0 py-0 mr-3 vChipLoadingIcon"
              >
                <img src="@/assets/icons/chipStar.svg" />

                <span @click="filterCards(person)">
                  {{ person.customer?.name }}
                </span>
                <v-icon class="exitIcon" @click="deleteSelectedPerson(i, person)"
                  >mdi-close</v-icon
                >
              </v-chip>
            </v-slide-item>
            <v-slide-item v-if="staredBadgeSelected != 0">
              <v-chip
                outlined
                color="#3b5de7"
                style="background-color: #3b5de7 !important"
                @click="get_requests"
                class="my-0 py-0 mr-3 vChipLoadingIcon"
              >
                تراجع
              </v-chip>
            </v-slide-item>
          </v-slide-group>
        </v-sheet>

        <v-row
          class="px-2 mx-1 vRowToShowData"
          style="display: inline-block; width: 100%"
        >
          <v-col
            class="app no-requests font-weight-bold"
            style="font-size: 36px"
            cols="12"
            v-if="!tableData.length"
          >
            لا توجد طلبات
          </v-col>

          <v-card flat>
            <v-layout row wrap>
              <v-col
                cols="12"
                lg="3"
                md="4"
                sm="6"
                xs="12"
                v-for="(item, i) in tableData"
                :key="i"
                class="px-2 py-1"
              >
                <v-card class="pa-3 position-relative" style="height: 100%">
                  <span
                    class="right-color requestType"
                    :style="`${
                      item.status == `طلب تم فتحه`
                        ? `background-color:#40BE72`
                        : item.status == `طلب جديد`
                        ? `background-color:#3B5DE7`
                        : item.status == `مؤرشف لدى استشاري المبيعات`
                        ? `background-color:#017E84`
                        : item.status == `طلب مرفوض`
                        ? `background-color: indianred`
                        : item.status == `في انتظار موافقة مدير المبيعات`
                        ? `background-color: #FF715B`
                        : `background-color:black`
                    }`"
                  >
                  </span>
                  <div class="card-head d-flex justify-space-between align-center">
                    <v-card-title class="pa-0 requestTitle">
                      {{ item.customer?.name }}
                    </v-card-title>
                    <div class="icons">
                      <v-btn
                        v-if="$route.name == 'all' || $route.name == 'following'"
                        @click="select_element(item)"
                        width="30"
                        height="30"
                        color="transparent"
                        depressed
                        fab
                      >
                        <v-icon
                          size="30"
                          :color="in_selected_elements(item) ? 'primary' : '#999898'"
                        >
                          {{
                            in_selected_elements(item)
                              ? "mdi-hexagram"
                              : "mdi-crop-square"
                          }}
                        </v-icon>
                      </v-btn>
                      <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            width="30"
                            height="30"
                            color="transparent"
                            depressed
                            fab
                            light
                            v-bind="attrs"
                            v-on="on"
                            style="margin-left: -10px"
                          >
                            <img src="@/assets/icons/eyeIcon.svg" />
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item @click="to_request_details(item)">
                            <v-list-item-icon class="ml-3">
                              <img src="@/assets/icons/titcketIcon.svg" />
                            </v-list-item-icon>
                            <v-list-item-title>فتح</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </div>
                  </div>
                  <div
                    class="req-status-parent mt-1 d-flex justify-space-between align-center"
                  >
                    <span class="req-type" style="color: #444b5a; font-size: 15px">
                      {{ item.type || "لايوجد نوع للطلب" }}
                    </span>
                    <span
                      class="req-status spanRequestType"
                      :style="`${
                        item.status == `طلب تم فتحه`
                          ? `background-color:#40BE72`
                          : item.status == `طلب جديد`
                          ? `background-color:#3B5DE7`
                          : item.status == `مؤرشف لدى استشاري المبيعات`
                          ? `background-color:#017E84`
                          : item.status == `طلب مرفوض`
                          ? `background-color: indianred`
                          : item.status == `في انتظار موافقة مدير المبيعات`
                          ? `background-color: #FF715B`
                          : `background-color:black`
                      }`"
                      >{{ item.status }}
                    </span>
                  </div>
                  <div
                    class="req-status-parent mt-1 d-flex justify-space-between align-center"
                  >
                    <span class="req-type" style="color: #444b5a; font-size: 15px">
                      استشاري المبيعات
                    </span>
                    <span class="req-status">
                      {{ item.user.name }}
                    </span>
                  </div>

                  <div
                    class="req-date-parent my-2 d-flex justify-space-between align-center"
                  >
                    <span class="req-date">
                      {{ item.agent_date.split(" ")[0] }}
                    </span>
                    <span class="req-status" :style="`color: #444B5A ; font-size: 15px;`">
                      {{ item.class_id_agent_text || "لايوجد تصنيف معاملة" }}
                    </span>
                  </div>
                  <div
                    class="req-date-parent my-2 d-flex justify-space-between align-center"
                  >
                    <span class="req-date"> هل تم استلامة من قبل الجودة </span>
                    <span class="req-status" :style="`color: #444B5A ; font-size: 15px;`">
                      {{ item.class_id_quality ? "نعم" : "لا" }}
                    </span>
                  </div>
                  <hr style="height: 1px !important; opacity: 0.3" class="mb-0" />
                  <div class="desc parent">
                    <v-tooltip bottom color="#451646">
                      <template v-slot:activator="{ on }">
                        <v-card-text v-on="on" class="pt-2 px-0">
                          {{
                            item.agent_notes
                              ? item.agent_notes.length > 50
                                ? item.agent_notes.substring(0, 50) + "..."
                                : item.agent_notes
                              : "لايوجد ملاحظات"
                          }}
                        </v-card-text>
                      </template>
                      <div style="font-size: 14px; background-color: #451646">
                        {{ item.agent_notes || "لايوجد ملاحظات" }}
                      </div>
                    </v-tooltip>
                  </div>
                </v-card>
              </v-col>
            </v-layout>

            <v-card v-intersect="infiniteScrolling"></v-card>
          </v-card>
        </v-row>
      </div>
    </template>
    <template #pagination v-if="tableData.length > 0">
      <v-row>
        <v-col v-if="reloadScroll">
          <LoadingMore />
        </v-col>
        <v-col cols="12" sm="2" v-if="!reloadScroll">
          <v-chip
            outlined
            color="primary"
            class="font-weight-bold my-0 py-0"
            v-if="!loading"
          >
            الإجمالي : {{ pagination["count"] }}
          </v-chip>
        </v-col>
      </v-row>
    </template>
  </page-component>
</template>

<script>
import Axios from "axios";
import LoadingMore from "../../Charts/LoadingMore.vue";
export default {
  name: "CardsStyle",
  data() {
    return {
      tableData: [],
      loading: false,
      CurrentRole: 0,
      totalCount: 0,
      page: 1,
      pages: 1,
      pagination: {
        count: 0,
        limit: 12,
      },
      reloadScroll: false,
      stopLoadingData: false,
      staredBadgeSelected: 0,
    };
  },
  props: {
    endPoint: {
      required: true,
    },
  },
  comments: { LoadingMore },
  async created() {
    await this.get_requests();
  },
  computed: {
    techSupport() {
      if (
        localStorage["isAdmin"] ||
        (localStorage["role"] && localStorage["role"] == "1")
      ) {
        return true;
      } else {
        return false;
      }
    },
  },

  methods: {
    in_selected_elements(item) {
      return this.$store.state.elements.some((el) => {
        return el.id == item.id;
      });
    },
    select_element(item) {
      if (this.in_selected_elements(item)) {
        // this.$store.state.elements = this.$store.state.elements.filter((el) => {
        //   return el.id != item.id;
        // });
        // localhost:8002/api/v2/employee/ConvertRequestToFollowedOrStared?type_of_convert=2
        Axios.post(
          `${this.$store.state.url}/v2/employee/ConvertRequestToFollowedOrStared?type_of_convert=1`,
          { request_ids: [item.id] },
          { headers: this.request_headers() }
        )
          .then((response) => {
            //
            this.$store.state.elements = this.$store.state.elements.filter((el) => {
              return el.id != item.id;
            });
          })
          .catch((err) => {
            this.showPopup("error", err.response.data.message);
          });
      } else {
        // this.$store.state.elements.push(item);
        Axios.post(
          `${this.$store.state.url}/v2/employee/ConvertRequestToFollowedOrStared?type_of_convert=1`,
          { request_ids: [item.id] },
          { headers: this.request_headers() }
        )
          .then((response) => {
            //
            this.$store.state.elements.push(item);
          })
          .catch((err) => {
            this.showPopup("error", err.response.data.message);
          });
      }
    },
    async get_requests(page = 1) {
      this.staredBadgeSelected = 0;
      this.stopLoadingData = false;
      if (this.page == 1) {
        this.$store.state.elements = [];
      }
      let method = {};
      switch (this.$route.name) {
        case "SMEAllRequests":
          (method.type = "all"), (method.pagination_count = "count");
          break;
        case "SMERecievedRequests":
          (method.type = "received"), (method.pagination_count = "count");
          break;
        case "SMEFollowRequests":
          (method.type = "followed"), (method.pagination_count = "count");
          break;
        case "SMECompletedRequests":
          (method.type = "completed"), (method.pagination_count = "count");
          break;
        case "SMEArchievedRequests":
          (method.type = "archived"), (method.pagination_count = "count");
          break;
        case "SMESpecialRequests":
          (method.type = "stared"), (method.pagination_count = "count");
          break;
      }
      this.loading = true;
      await Axios.get(
        `${this.endPoint}?type=${method.type}&page=${this.page}&per_page=12`,
        { headers: this.request_headers() }
      )
        .then((response) => {
          this.totalCount = response.data.payload.count;
          this.tableData = response.data.payload.data;
          // is_stared
          let Arr = this.tableData.filter((el) => {
            return el.is_stared == 1;
          });
          Arr.forEach((item) => {
            this.$store.state.elements.push(item);
          });
          this.pagination.count = response.data.payload.count || 1;
          this.pages = Math.ceil(parseInt(this.pagination["count"]) / 12);
        })
        .catch((err) => {
          this.showPopup("error", err.response.data.message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    infiniteScrolling(entries, observer, isIntersecting) {
      if (this.stopLoadingData == true) {
        return;
      }
      setTimeout(() => {
        let method = {};
        switch (this.$route.name) {
          case "SMEAllRequests":
            (method.type = "all"), (method.pagination_count = "count");
            break;
          case "SMERecievedRequests":
            (method.type = "received"), (method.pagination_count = "count");
            break;
          case "SMEFollowRequests":
            (method.type = "followed"), (method.pagination_count = "count");
            break;
          case "SMECompletedRequests":
            (method.type = "completed"), (method.pagination_count = "count");
            break;
          case "SMEArchievedRequests":
            (method.type = "archived"), (method.pagination_count = "count");
            break;
          case "SMESpecialRequests":
            (method.type = "stared"), (method.pagination_count = "count");
            break;
        }
        this.reloadScroll = true;
        this.page++;
        Axios.get(`${this.endPoint}?type=${method.type}&page=${this.page}&per_page=12`, {
          headers: this.request_headers(),
        })
          .then((response) => {
            if (response.data.payload.data.length > 1) {
              this.tableData.push(...response.data.payload.data);
              // is_stared
              let Arr = response.data.payload.data.filter((el) => {
                return el.is_stared == 1;
              });
              Arr.forEach((item) => {
                this.$store.state.elements.push(item);
              });
            }
            this.reloadScroll = false;
          })
          .catch((err) => {
            console.log(err);
          });
      }, 100);
    },
    move_extra_request(item) {
      Axios.get(
        `${this.$store.state.url}/agent/MoveAdditionalRequest/${item.id}`,
        {
          headers: this.request_headers(),
        }
      )
        .then(() => {
          this.showPopup("success", "تم سحب الطلب بنجاح");
          this.get_extra_requests();
        })
        .catch((err) => {
          this.showPopup("error", err.response.data.message);
        });
    },
    to_tickets_page(item) {
      if (this.CurrentRole == "1") {
        this.$router.push({ name: "salesManager_tickets", params: { id: item.id } });
      } else {
        this.$router.push({ name: "tickets", params: { id: item.id } });
      }
    },
    to_request_details(item) {
      if (this.CurrentRole == "1") {
        this.$router.push({
          name: "salesManager_request_details",
          params: { id: item.id },
        });
      } else {
        this.$router.push({
          name: "request_details",
          params: { id: item.id },
        });
      }
    },
    to_chat_page(item) {
      this.$router.push({
        name: "chat_component",
        query: {
          customer_name: item.customer.name,
          customer_id: item.customer.id,
          request_id: item.id,
        },
      });
    },
    deleteSelectedPerson(index, person) {
      Axios.post(
        `${this.$store.state.url}/v2/employee/ConvertRequestToFollowedOrStared?type_of_convert=1`,
        { request_ids: [person.id] },
        { headers: this.request_headers() }
      )
        .then((response) => {
          //
          this.$store.state.elements.splice(index, 1);
        })
        .catch((err) => {
          this.showPopup("error", err.response.data.message);
        });
    },
    filterCards(person) {
      Axios.post(
        `${this.endPoint}/search?type=all`,
        { customer_phone: person.customer.mobile, page: 1 },
        { headers: this.request_headers() }
      )
        .then((response) => {
          if (response.data.payload.data.length > 0) {
            this.totalCount = 1;
            this.reloadScroll = false;
            this.page = 1;
            this.stopLoadingData = true;
            this.tableData = response.data.payload.data;
          }
          this.staredBadgeSelected = person.id;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.CurrentRole = window.localStorage.getItem("role");
    this.$root.$on("REFRESHCARDDATA", () => {
      this.get_requests();
    });
  },
  components: { LoadingMore },
};
</script>
<style lang="scss">
.spanRequestType {
  font-size: 13px;
  padding: 0px 5px 5px;
  color: white;
}

.vRowToShowData .requestTitle {
  font-size: 15px;
  font-weight: bold;
}

.vRowToShowData .requestType {
  position: absolute;
  width: 6px;
  height: 101.2%;
  top: -1px;
  right: -6px;
}

.vRowToShowData {
  padding-top: 11px !important;
}

.vChipLoadingIcon .exitIcon {
  font-size: 13px;
  position: absolute;
  left: 3px;
  top: 4px;
  cursor: pointer;
}

.vChipLoadingIcon img {
  margin-left: 5px;
}

.vChipLoadingIcon {
  clip-path: unset !important;
  border-radius: 5px !important;
  height: 36px;
  flex-shrink: 0;
  padding-left: 30px !important;
}

.vSheetContainer {
  height: 52px;
  overflow: hidden;
  border-radius: 5px;
  max-width: 100% !important;
  margin-top: 30px;
}

.app {
  display: grid;
  place-items: center;
  background-color: transparent;
  height: 50vh;
}

.no-requests {
  flex: 1;
  overflow-y: hidden;
}

.v-chip.v-chip.v-chip--outlined.v-chip.v-chip {
  border-top-right-radius: unset !important;
  border-bottom-right-radius: unset !important;
  clip-path: polygon(0% 50%, 10% 0%, 100% 0%, 100% 100%, 10% 100%, 0% 50%) !important;
  background-color: #3f0e40 !important;
  color: white !important;
  padding-left: 20px !important;
}

.v-btn {
  &:focus {
    &:before {
      background-color: white !important;
    }
  }
}

.selected-slider {
  .v-slide-group__content {
    align-items: center !important;
  }

  .theme--light.v-icon.v-icon:not(.mdi-close) {
    background-color: #f2f2f2 !important;
    border-color: #f2f2f2 !important;
    font-size: 40px;
    color: black;
  }
}
</style>
