<template>
    <v-alert
        v-model="alert"
      color="primary"
      dark
      sticky
      right
      style="position:sticky; bottom:3px;"
      icon="mdi-bullhorn"
      border="left"
      prominent
    >
    {{ text }}
      <v-btn
            color="info"
            outlined
            @click="Readed"
          >
            عُلم
          </v-btn>
    </v-alert>
  </template>
  
  <script>
  import Axios from "axios";
  export default {
    name: 'PopupComponent',
    data() {
      return {
        alert: false,
        text: "",
        link: null,
        copy: null,
        announcementId: 0
      };
    },
    mounted() {
        Axios.get(
          `${this.$store.state.url}/home-info`,
          {
            headers: this.request_headers(),
          }
        )
          .then((res) => {
              this.alert = true;
            this.text = res.data.payload.announcement.content;
            this.announcementId = res.data.payload.announcement.id;
          })

          .catch((err) => {
            // this.alert = false;
          });
    },
    methods: {
        Readed() {
        Axios.get(
          `${this.$store.state.url}/employee/announcements/mark-as-seen/${this.announcementId}`,
          {
            headers: this.request_headers(),
          }
        )
          .then((res) => {
              this.alert = false;
          })

          .catch((err) => {
            // this.alert = false;
          });
      },
    },
  };
  </script>
  