<template>
  <div>
    <div class="vRowToShowData">
      <v-card flat>
        <v-layout row wrap style="background-color: #f3f5f7">
          <v-col
            cols="12"
            lg="3"
            md="4"
            sm="6"
            xs="12"
            class="px-2 py-1"
            style="padding-bottom: 45px !important"
          >
            <v-card class="pa-3 position-relative cardContainer">
              <span
                class="right-color requestType"
                :style="returnBorder()"
              ></span>
              <div class="card-head d-flex justify-space-between align-center">
                <v-card-title class="pa-0 cardTitle">
                  {{ CardData.customer.name }}
                </v-card-title>

                <div class="icons">
                  <!-- <input class="star ml-3" type="checkbox" :checked="!item.starChecked" @change="starToggle(CardData.id)"> -->
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        height="30"
                        color="transparent"
                        depressed
                        fab
                        light
                        v-bind="attrs"
                        v-on="on"
                        style="margin-left: -10px"
                      >
                        <img src="@/assets/icons/eyeIcon.svg" />
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item @click="to_request_details()">
                        <v-list-item-icon class="ml-3">
                          <img
                            src="@/assets/icons/titcketIcon.svg"
                            alt="image"
                          />
                        </v-list-item-icon>
                        <v-list-item-title> فتح الطلب </v-list-item-title>
                      </v-list-item>

                      <router-link
                        style="text-decoration: none"
                        target="_blank"
                        :to="{
                          name: 'request_details',
                          params: { id: CardData.id },
                        }"
                      >
                        <v-list-item>
                          <v-list-item-icon class="ml-3">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18.528"
                              height="15.111"
                              viewBox="0 0 13.528 10.111"
                            >
                              <g
                                id="Icon_feather-eye-dark"
                                data-name="Icon feather-eye-dark"
                                transform="translate(-1 -5.5)"
                              >
                                <path
                                  id="Path_4040"
                                  data-name="Path 4040"
                                  d="M1.5,10.556S3.778,6,7.764,6s6.264,4.556,6.264,4.556-2.278,4.556-6.264,4.556S1.5,10.556,1.5,10.556Z"
                                  fill="none"
                                  stroke="#000"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="1"
                                />
                                <path
                                  id="Path_4041"
                                  data-name="Path 4041"
                                  d="M16.917,15.208A1.708,1.708,0,1,1,15.208,13.5,1.708,1.708,0,0,1,16.917,15.208Z"
                                  transform="translate(-7.444 -4.653)"
                                  fill="none"
                                  stroke="#000"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="1"
                                />
                              </g>
                            </svg>
                          </v-list-item-icon>
                          <v-list-item-title>
                            فتح في تاب جديد</v-list-item-title
                          >
                        </v-list-item>
                      </router-link>

                      <router-link
                        style="text-decoration: none; display: flex"
                        :to="{ path: `/admin/tickets-details/${CardData.id}` }"
                      >
                        <v-list-item>
                          <v-list-item-icon class="ml-2">
                            <TicketIcon />
                          </v-list-item-icon>
                          <v-list-item-title>التذاكر</v-list-item-title>
                        </v-list-item>
                      </router-link>

                      <v-list-item @click="toOrderRequest">
                        <v-list-item-icon class="ml-2">
                          <OrderHistory />
                        </v-list-item-icon>
                        <v-list-item-title>سجل الطلب</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </div>

              <div
                class="req-status-parent mt-1 d-flex justify-space-between align-center"
              >
                <span class="req-type" style="color: #545a69; font-size: 16px">
                  {{ this.CardData.type || "لا يوجد نوع طلب" }}
                </span>
                <span
                  class="req-status spanRequestType"
                  :style="returnBorder()"
                >
                  {{ this.CardData.status }}
                </span>
              </div>

              <div
                class="req-status-parent mt-1 d-flex justify-space-between align-center"
              >
                <span class="req-type" style="color: #545a69; font-size: 16px">
                  الاستشاري
                </span>
                <span>
                  {{ this.CardData.user?.name || "لا يوجد" }}
                </span>
              </div>

              <div
                class="req-date-parent my-2 d-flex justify-space-between align-center"
              >
                <span class="req-date" style="color: #545a69">
                  {{ this.formatDate(this.CardData.created_at) }}
                </span>
                <span
                  class="req-status"
                  :style="`color: #074C74 ; font-size: 14px;`"
                >
                  {{ this.CardData.class_id_agent_text }}
                </span>
              </div>

              <hr class="lineStyle" />
              <div class="desc parent">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-card-text
                      v-on="on"
                      class="pt-2 px-0"
                      style="color: #545a69"
                    >
                      {{ CardData?.agent_notes || "لايوجد ملاحظات" }}
                    </v-card-text>
                  </template>
                </v-tooltip>
              </div>
            </v-card>
          </v-col>

          <v-col
            v-if="CardData.personalRequest"
            cols="12"
            lg="3"
            md="4"
            sm="6"
            xs="12"
            class="px-2 py-1"
            style="padding-bottom: 45px !important"
          >
            <v-card class="pa-3 position-relative cardContainer">
              <span
                class="right-color requestType"
                :style="returnBorder()"
              ></span>
              <div class="card-head d-flex justify-space-between align-center">
                <v-card-title class="pa-0 cardTitle">
                  {{ CardData.customer?.name }}
                </v-card-title>

                <div class="icons">
                  <!-- <input class="star ml-3" type="checkbox" :checked="!item.starChecked" @change="starToggle(CardData.id)"> -->
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        height="30"
                        color="transparent"
                        depressed
                        fab
                        light
                        v-bind="attrs"
                        v-on="on"
                        style="margin-left: -10px"
                      >
                        <img src="@/assets/icons/eyeIcon.svg" />
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item @click="to_personal_or_main_request_details(CardData.personalRequest.id)">
                        <v-list-item-icon class="ml-3">
                          <img
                            src="@/assets/icons/titcketIcon.svg"
                            alt="image"
                          />
                        </v-list-item-icon>
                        <v-list-item-title> فتح الطلب </v-list-item-title>
                      </v-list-item>

                      <router-link
                        style="text-decoration: none"
                        target="_blank"
                        :to="{
                          name: 'request_details',
                          params: { id: CardData.personalRequest.id },
                        }"
                      >
                        <v-list-item>
                          <v-list-item-icon class="ml-3">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18.528"
                              height="15.111"
                              viewBox="0 0 13.528 10.111"
                            >
                              <g
                                id="Icon_feather-eye-dark"
                                data-name="Icon feather-eye-dark"
                                transform="translate(-1 -5.5)"
                              >
                                <path
                                  id="Path_4040"
                                  data-name="Path 4040"
                                  d="M1.5,10.556S3.778,6,7.764,6s6.264,4.556,6.264,4.556-2.278,4.556-6.264,4.556S1.5,10.556,1.5,10.556Z"
                                  fill="none"
                                  stroke="#000"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="1"
                                />
                                <path
                                  id="Path_4041"
                                  data-name="Path 4041"
                                  d="M16.917,15.208A1.708,1.708,0,1,1,15.208,13.5,1.708,1.708,0,0,1,16.917,15.208Z"
                                  transform="translate(-7.444 -4.653)"
                                  fill="none"
                                  stroke="#000"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="1"
                                />
                              </g>
                            </svg>
                          </v-list-item-icon>
                          <v-list-item-title>
                            فتح في تاب جديد</v-list-item-title
                          >
                        </v-list-item>
                      </router-link>

                      <router-link
                        style="text-decoration: none; display: flex"
                        :to="{ path: `/admin/tickets-details/${CardData.personalRequest.id}` }"
                      >
                        <v-list-item>
                          <v-list-item-icon class="ml-2">
                            <TicketIcon />
                          </v-list-item-icon>
                          <v-list-item-title>التذاكر</v-list-item-title>
                        </v-list-item>
                      </router-link>

                      <v-list-item @click="to_personal_or_main_order_request(CardData.personalRequest.id)">
                        <v-list-item-icon class="ml-2">
                          <OrderHistory />
                        </v-list-item-icon>
                        <v-list-item-title>سجل الطلب</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </div>

              <div
                class="req-status-parent mt-1 d-flex justify-space-between align-center"
              >
                <span class="req-type" style="color: #545a69; font-size: 16px">
                  {{ this.CardData.personalRequest.request_type || "لا يوجد نوع طلب" }}
                </span>
                <span
                  class="req-status spanRequestType"
                  :style="returnBorder()"
                >
                  {{ this.CardData.personalRequest.request_status }}
                </span>
              </div>

              <div
                class="req-status-parent mt-1 d-flex justify-space-between align-center"
              >
                <span class="req-type" style="color: #545a69; font-size: 16px">
                  الاستشاري
                </span>
                <span>
                  {{ this.CardData.personalRequest.user?.name || "لا يوجد" }}
                </span>
              </div>

              <div
                class="req-date-parent my-2 d-flex justify-space-between align-center"
              >
                <span class="req-date" style="color: #545a69">
                  {{ this.formatDate(this.CardData.personalRequest.request_date) }}
                </span>
                <span
                  class="req-status"
                  :style="`color: #074C74 ; font-size: 14px;`"
                >
                  {{ this.CardData.personalRequest.class_id_agent_text }}
                </span>
              </div>

              <hr class="lineStyle" />
              <div class="desc parent">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-card-text
                      v-on="on"
                      class="pt-2 px-0"
                      style="color: #545a69"
                    >
                      {{ CardData.personalRequest.note || "لايوجد ملاحظات" }}
                    </v-card-text>
                  </template>
                </v-tooltip>
              </div>
            </v-card>
          </v-col>

          <v-col
            v-if="CardData.mainRequest"
            cols="12"
            lg="3"
            md="4"
            sm="6"
            xs="12"
            class="px-2 py-1"
            style="padding-bottom: 45px !important"
          >
            <v-card class="pa-3 position-relative cardContainer">
              <span
                class="right-color requestType"
                :style="returnBorder()"
              ></span>
              <div class="card-head d-flex justify-space-between align-center">
                <v-card-title class="pa-0 cardTitle">
                  {{ CardData.customer?.name }}
                </v-card-title>

                <div class="icons">
                  <!-- <input class="star ml-3" type="checkbox" :checked="!item.starChecked" @change="starToggle(CardData.id)"> -->
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        height="30"
                        color="transparent"
                        depressed
                        fab
                        light
                        v-bind="attrs"
                        v-on="on"
                        style="margin-left: -10px"
                      >
                        <img src="@/assets/icons/eyeIcon.svg" />
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item @click="to_personal_or_main_request_details(CardData.mainRequest.id)">
                        <v-list-item-icon class="ml-3">
                          <img
                            src="@/assets/icons/titcketIcon.svg"
                            alt="image"
                          />
                        </v-list-item-icon>
                        <v-list-item-title> فتح الطلب </v-list-item-title>
                      </v-list-item>

                      <router-link
                        style="text-decoration: none"
                        target="_blank"
                        :to="{
                          name: 'request_details',
                          params: { id: CardData.mainRequest.id },
                        }"
                      >
                        <v-list-item>
                          <v-list-item-icon class="ml-3">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18.528"
                              height="15.111"
                              viewBox="0 0 13.528 10.111"
                            >
                              <g
                                id="Icon_feather-eye-dark"
                                data-name="Icon feather-eye-dark"
                                transform="translate(-1 -5.5)"
                              >
                                <path
                                  id="Path_4040"
                                  data-name="Path 4040"
                                  d="M1.5,10.556S3.778,6,7.764,6s6.264,4.556,6.264,4.556-2.278,4.556-6.264,4.556S1.5,10.556,1.5,10.556Z"
                                  fill="none"
                                  stroke="#000"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="1"
                                />
                                <path
                                  id="Path_4041"
                                  data-name="Path 4041"
                                  d="M16.917,15.208A1.708,1.708,0,1,1,15.208,13.5,1.708,1.708,0,0,1,16.917,15.208Z"
                                  transform="translate(-7.444 -4.653)"
                                  fill="none"
                                  stroke="#000"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="1"
                                />
                              </g>
                            </svg>
                          </v-list-item-icon>
                          <v-list-item-title>
                            فتح في تاب جديد</v-list-item-title
                          >
                        </v-list-item>
                      </router-link>

                      <router-link
                        style="text-decoration: none; display: flex"
                        :to="{ path: `/admin/tickets-details/${CardData.mainRequest.id}` }"
                      >
                        <v-list-item>
                          <v-list-item-icon class="ml-2">
                            <TicketIcon />
                          </v-list-item-icon>
                          <v-list-item-title>التذاكر</v-list-item-title>
                        </v-list-item>
                      </router-link>

                      <v-list-item @click="to_personal_or_main_order_request(CardData.mainRequest.id)">
                        <v-list-item-icon class="ml-2">
                          <OrderHistory />
                        </v-list-item-icon>
                        <v-list-item-title>سجل الطلب</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </div>

              <div
                class="req-status-parent mt-1 d-flex justify-space-between align-center"
              >
                <span class="req-type" style="color: #545a69; font-size: 16px">
                  {{ this.CardData.mainRequest.request_type || "لا يوجد نوع طلب" }}
                </span>
                <span
                  class="req-status spanRequestType"
                  :style="returnBorder()"
                >
                  {{ this.CardData.mainRequest.request_status }}
                </span>
              </div>

              <div
                class="req-status-parent mt-1 d-flex justify-space-between align-center"
              >
                <span class="req-type" style="color: #545a69; font-size: 16px">
                  الاستشاري
                </span>
                <span>
                  {{ this.CardData.mainRequest.user?.name || "لا يوجد" }}
                </span>
              </div>

              <div
                class="req-date-parent my-2 d-flex justify-space-between align-center"
              >
                <span class="req-date" style="color: #545a69">
                  {{ this.formatDate(this.CardData.mainRequest.request_date) }}
                </span>
                <span
                  class="req-status"
                  :style="`color: #074C74 ; font-size: 14px;`"
                >
                  {{ this.CardData.mainRequest.class_id_agent_text }}
                </span>
              </div>

              <hr class="lineStyle" />
              <div class="desc parent">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-card-text
                      v-on="on"
                      class="pt-2 px-0"
                      style="color: #545a69"
                    >
                      {{ CardData.mainRequest.note || "لايوجد ملاحظات" }}
                    </v-card-text>
                  </template>
                </v-tooltip>
              </div>
            </v-card>
          </v-col>

          <v-col
            cols="12"
            lg="3"
            md="4"
            sm="6"
            xs="12"
            class="px-2 py-1"
            style="padding-bottom: 45px !important"
            v-for="(item, i) in CardData.helpDesks"
            :key="i.created_at"
          >
            <v-card
              class="pa-3 position-relative cardContainer"
            >
              <div class="card__title__Support">طلب دعم فني</div>

              <span
                class="right-color requestType"
                :style="returnBorder(item)"
              ></span>
              <div class="card-head d-flex justify-space-between align-center">
                <v-card-title class="pa-0 cardTitle">
                  {{ item.name }}
                </v-card-title>

                <div class="icons">
                  <input
                    class="star ml-3"
                    type="checkbox"
                    :checked="!item.starChecked"
                    @change="starToggle(item.id)"
                  />
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        height="30"
                        color="transparent"
                        depressed
                        fab
                        light
                        v-bind="attrs"
                        v-on="on"
                        style="margin-left: -10px"
                      >
                        <img src="@/assets/icons/eyeIcon.svg" />
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item
                        @click="to_request_details(item.id)"
                        v-if="item.has_request === 'Yes'"
                      >
                        <v-list-item-icon class="ml-3">
                          <img
                            src="@/assets/icons/titcketIcon.svg"
                            alt="image"
                          />
                        </v-list-item-icon>
                        <v-list-item-title> فتح الطلب </v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="to_supportRequest_details(item.id)">
                        <v-list-item-icon class="ml-3">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17.599"
                            height="17.599"
                            viewBox="0 0 17.599 17.599"
                          >
                            <path
                              id="support"
                              d="M20.746,12.41v-.463a6.947,6.947,0,1,0-13.894,0v.463A1.852,1.852,0,0,0,5,14.262v2.779a1.852,1.852,0,0,0,1.852,1.852h.083a1.389,1.389,0,0,0,2.7-.463V12.873a1.389,1.389,0,0,0-1.852-1.311,6.021,6.021,0,0,1,12,0,1.389,1.389,0,0,0-1.815,1.311v5.557a1.389,1.389,0,0,0,1.389,1.389h.153a3.242,3.242,0,0,1-2.932,1.852H15.189v-.463A1.389,1.389,0,1,0,13.8,22.6h2.779a4.168,4.168,0,0,0,4.14-3.7h.028A1.852,1.852,0,0,0,22.6,17.041V14.262A1.852,1.852,0,0,0,20.746,12.41ZM5.926,17.041V14.262a.926.926,0,0,1,.926-.926v4.631A.926.926,0,0,1,5.926,17.041ZM8.242,12.41a.463.463,0,0,1,.463.463v5.557a.463.463,0,1,1-.926,0V12.873A.463.463,0,0,1,8.242,12.41ZM13.8,21.672a.463.463,0,1,1,.463-.463v.463Zm5.557-2.779a.463.463,0,0,1-.463-.463V12.873a.463.463,0,0,1,.926,0v5.557A.463.463,0,0,1,19.357,18.894Zm2.316-1.852a.926.926,0,0,1-.926.926V13.336a.926.926,0,0,1,.926.926Z"
                              transform="translate(-5 -5)"
                            />
                          </svg>
                        </v-list-item-icon>
                        <v-list-item-title>
                          <router-link
                            :to="{ path: `/admin/help_desk/${item.parent_id ? item.parent_id : item.id}` }"
                            style="text-decoration: none"
                          >
                            فتح طلب الدعم الفني
                          </router-link>
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </div>

              <div
                class="req-status-parent mt-1 d-flex justify-space-between align-center"
              >
                <span class="req-type" style="color: #017e84; font-size: 16px">
                  حالة الطلب
                </span>
                <span
                  class="req-status spanRequestType"
                  :style="returnBorder(item)"
                >
                  {{ returnStatusValue(item.status) }}
                </span>
              </div>

              <div
                class="req-date-parent my-2 d-flex justify-space-between align-center"
              >
                <span class="req-date" style="color: #eeb902">
                  تاريخ الطلب
                </span>
                <span class="req-status letterStyle">
                  {{ item.created_at }}
                </span>
              </div>
              <div
                class="req-date-parent my-2 d-flex justify-space-between align-center"
              >
                <span class="req-date" style="color: #428dfd"> الرد </span>
                <span class="req-status letterStyle">
                  {{ item.replay || "لا يوجد" }}
                </span>
              </div>
              <div class="desc parent">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <div style="color: #40be72">الوصف</div>
                    <v-card-text
                      v-on="on"
                      class="pt-2 px-0"
                      style="color: #545a69"
                    >
                      {{ item.descrebtion || "لايوجد ملاحظات" }}
                    </v-card-text>
                  </template>
                </v-tooltip>
              </div>
            </v-card>
          </v-col>

          <v-col
            cols="12"
            lg="3"
            md="4"
            sm="6"
            xs="12"
            class="px-2 py-1"
            style="padding-bottom: 45px !important"
            v-for="(item, i) in CardData.rates"
            :key="i"
          >
            <v-card class="pa-3 position-relative cardContainer">
              <div class="card__title__Support">طلبـات التقييم</div>
              <span
                class="right-color requestType"
                :style="returnBorder(item)"
              ></span>
              <div class="card-head d-flex justify-space-between align-center">
                <v-card-title class="pa-0 cardTitle">
                  {{ item.user?.name }}
                </v-card-title>

                <div class="icons">
                  <input
                    class="star ml-3"
                    type="checkbox"
                    :checked="!item.starChecked"
                    @change="starToggle(item.id)"
                  />
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        height="30"
                        color="transparent"
                        depressed
                        fab
                        light
                        v-bind="attrs"
                        v-on="on"
                        style="margin-left: -10px"
                      >
                        <img src="@/assets/icons/eyeIcon.svg" />
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item @click="to_request_details()">
                        <v-list-item-icon class="ml-3">
                          <img
                            src="@/assets/icons/titcketIcon.svg"
                            alt="image"
                          />
                        </v-list-item-icon>
                        <v-list-item-title> فتح الطلب </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </div>

              <div
                class="req-status-parent mt-1 d-flex justify-space-between align-center"
              >
                <span
                  class="req-type"
                  style="color: #017e84; font-size: 16px; font-weight: bold"
                >
                  عدد النجوم
                </span>
                <span style="color: #545a69; margin-left: 10px">
                  {{ item.stars }}
                </span>
              </div>

              <div
                class="req-date-parent my-2 d-flex justify-space-between align-center"
              >
                <span class="req-date" style="color: #eeb902">
                  تاريخ الطلب
                </span>
                <span
                  class="req-status letterStyle"
                  style="margin-left: 10px; color: #000000; font-weight: bold"
                >
                  {{ item.created_at }}
                </span>
              </div>

              <div class="desc parent">
                <v-tooltip bottom>
                  <!-- <template v-slot:activator="{ on }">
                    <div style="color: #40be72">الملاحظات</div>
                    <v-card-text
                      v-on="on"
                      class="pt-2 px-0"
                      style="color: #545a69"
                    >
                      {{ item.request.note || "لايوجد ملاحظات" }}
                    </v-card-text>
                  </template> -->

                  
                  <template v-slot:activator="{ on }">
                    <div style="color: #40be72">تعقيب العميل</div>
                    <v-card-text
                      v-on="on"
                      class="pt-2 px-0"
                      style="color: #545a69"
                    >
                      {{ item.comments || "لايوجد تعقيب" }}
                    </v-card-text>
                  </template>
                </v-tooltip>
              </div>
            </v-card>
          </v-col>


          <!-- Tickets data  -->
          <v-col
            cols="12"
            lg="3"
            md="4"
            sm="6"
            xs="12"
            class="px-2 py-1"
            style="padding-bottom: 45px !important"
            v-for="(item, i) in CardData.tasks"
            :key="i.created_at"
          >
            <v-card
              class="pa-3 position-relative cardContainer"
            >
              <div class="card__title__Support">التذاكر</div>

              <span
                class="right-color requestType"
                :style="returnBorder(item)"
              ></span>
              <div class="card-head d-flex justify-space-between align-center">
                <v-card-title class="pa-0 cardTitle">
                  {{ item.user?.name }}
                </v-card-title>

                <div class="icons">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        height="30"
                        color="transparent"
                        depressed
                        fab
                        light
                        v-bind="attrs"
                        v-on="on"
                        style="margin-left: -10px"
                      >
                        <img src="@/assets/icons/eyeIcon.svg" />
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item
                        @click="to_request_details(item.req_id)"
                      >
                        <v-list-item-icon class="ml-3">
                          <img
                            src="@/assets/icons/titcketIcon.svg"
                            alt="image"
                          />
                        </v-list-item-icon>
                        <v-list-item-title> فتح الطلب </v-list-item-title>
                      </v-list-item>

                      <v-list-item
                        @click="to_admin_tickets_details(item.id)"
                      >
                        <v-list-item-icon class="ml-3">
                          <img
                            src="@/assets/icons/titcketIcon.svg"
                            alt="image"
                          />
                        </v-list-item-icon>
                        <v-list-item-title> فتح التذكرة </v-list-item-title>
                      </v-list-item>

                    </v-list>
                  </v-menu>
                </div>
              </div>

              <div
                class="req-status-parent mt-1 d-flex justify-space-between align-center"
              >
                <span class="req-type" style="font-size: 16px">
                  مرسل التذكرة
                </span>
                <span
                  class="req-status spanRequestType"
                  style="background-color: #481a49;"
                >
                  {{ item.user?.name }}
                </span>
              </div>

              <div
                class="req-status-parent mt-1 d-flex justify-space-between align-center"
              >
                <span class="req-type" style="font-size: 16px">
                  مستلم التذكرة
                </span>
                <span
                  class="req-status spanRequestType"
                  style="background-color: #481a49;"
                >
                  {{ item.receive?.name }}
                </span>
              </div>


              <div
                class="req-status-parent mt-1 d-flex justify-space-between align-center"
              >
                <span class="req-type" style="color: #017e84; font-size: 16px">
                  حالة التذكرة
                </span>
                <span
                  class="req-status spanRequestType"
                  :style="returnBorder(item)"
                >
                  {{ item.status }}
                </span>
              </div>

              <div
                class="req-date-parent my-2 d-flex justify-space-between align-center"
              >
                <span class="req-date" style="color: #eeb902">
                  تاريخ التذكرة
                </span>
                <span class="req-status letterStyle">
                  {{ item.created_at }}
                </span>
              </div>
              <hr class="lineStyle" />
            
              <div class="desc parent">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <div style="color: #40be72">المحتوي</div>
                    <v-card-text
                      v-on="on"
                      class="pt-2 px-0"
                      style="color: #545a69"
                    >
                      {{ item.task_content[0].content || "لايوجد ملاحظات" }}
                    </v-card-text>
                  </template>
                </v-tooltip>
              </div>
            </v-card>
          </v-col>

          <!-- end Tickets data -->

        </v-layout>
      </v-card>
    </div>
    <OrderRequestSection
      v-if="this.$store.state.openOrderRequestSection"
      style="margin-top: 50px"
    />
  </div>
</template>

<style>
.card__title__Support {
  color: #2c2c2c;
  font-size: 16px;
  position: absolute;
  top: -50px;
  font-weight: bold;
}
</style>
<script>
import { TicketIcon } from "@/assets/adminIcons/ticketIcon";
import OrderRequestSection from "@/components/AdminComponent/OrderRequestSection.vue";
import { OrderHistory } from "@/assets/adminIcons/orderHistory";

export default {
  name: "FilterCard",
  props: ["CardData"],
  components: { TicketIcon, OrderRequestSection, OrderHistory },
  mounted() {
    this.cpCardData = structuredClone(this.CardData);
    this.$store.state.openOrderRequestSection = false;

  },
  data() {
    return {
      startElement: [],
      cpCardData: [],
      page: 1,
    };
  },

  methods: {
    formatDate(date) {
      if (!date) return "-";
      const d = new Date(date);
      const month = (d.getMonth() + 1).toString().padStart(2, "0");
      const day = d.getDate().toString().padStart(2, "0");
      const year = d.getFullYear().toString();
      return [year, month, day].join("-");
    },
    emitObjects(val) {
      this.$emit("pageCount", val);
    },

    toOrderRequest() {
      // show request history section
      this.$store.state.orderRequestId = this.CardData.id;
      this.$store.state.openOrderRequestSection = true;
    },

    returnBorder() {
      if (this.CardData.statusReq === 0) {
        return `background-color: #017F85`;
      } else if (this.CardData.statusReq === 1) {
        return `background-color: #41BF72`;
      } else if (this.CardData.statusReq === 2) {
        return `background-color: #DEB523`;
      } else {
        return `background-color: #FE705B`;
      }
    },

    infiniteScrolling(entries, observer, isIntersecting) {
      setTimeout(() => {
        this.page++;
        this.$emit("pageCount", this.page);
      }, 100);
    },

    to_supportRequest_details(item) {
      this.$router.push({
        name: "help_desk",
        params: { id: item },
      });
    },

    to_admin_tickets_details(item) {
      this.$router.push({
        name: "admin_tickets_details",
        params: { ticeket: item },
      });
    },

    

    to_request_details() {
      this.$router.push({
        name: "request_details",
        params: { id: this.CardData.id },
      });
    },

    to_personal_or_main_request_details(id) {
      this.$router.push({
        name: "request_details",
        params: { id: id },
      });
    },

    to_personal_or_main_order_request(id) {
      // show request history section
      this.$store.state.orderRequestId = id;
      this.$store.state.openOrderRequestSection = true;
    },


    returnStatusValue(status) {
      if (status == 0) {
        return "جديد";
      } else if (status == 1) {
        return "تم فتحه";
      } else if (status == 2) {
        return "مكتمل";
      } else {
        return "مرفوض";
      }
    },
    //   starToggle(id) {

    //       if (this.cpCardData.id == id) {
    //         ele.starChecked = !this.cpCardData.starChecked;
    //         if (this.cpCardData.starChecked) {
    //           this.startElement.push(this.cpCardData);
    //         } else {
    //           let targetItem = this.startElement.findIndex(item => item.id === ele.id);
    //           if (targetItem !== -1) {
    //             this.startElement.splice(targetItem, 1);
    //           }

    //         }
    //         return this.cpCardData;
    //       }
    //       return this.cpCardData;

    //   },
    deleteSelectedPerson(index, person) {
      this.starToggle(person.id);
    },
  },
};
</script>

<style lang="scss">
.lineStyle {
  border: 1px solid #000000;
  opacity: 0.1;
  margin-bottom: 0px;
}

.letterStyle {
  color: #074c74;
  font-size: 14px;
}

.starContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  background: #984abe;
  color: white;
  border-radius: 4px;
  padding: 15px;
}

.cardContainer {
  height: 100%;
  background-color: #f4f2f4;
  border: 1px solid #d4d5d5;
  position: relative;
}

.star {
  visibility: hidden;
  font-size: 30px;
  cursor: pointer;
  color: purple;
}

.star:before {
  content: "\2605";
  position: absolute;
  visibility: visible;
  top: 0px;
  color: purple;
}

.star:checked:before {
  content: "\2606";
  position: absolute;
  top: 0px;
}

.spanRequestType {
  font-size: 13px;
  padding: 0px 5px 5px;
  color: white;
}

.vRowToShowData .cardTitle {
  color: #3f0f41;
  font-size: 16px;
  font-weight: bold;
}

.vRowToShowData .requestType {
  position: absolute;
  width: 6px;
  height: 101.2%;
  top: -1px;
  right: -6px;
}

.vRowToShowData {
  padding-top: 11px !important;
}

.vChipLoadingIcon .exitIcon {
  font-size: 13px;
  position: absolute;
  left: 3px;
  top: 4px;
  cursor: pointer;
}

.vChipLoadingIcon img {
  margin-left: 5px;
}

.vChipLoadingIcon {
  clip-path: unset !important;
  border-radius: 5px !important;
  height: 36px;
  flex-shrink: 0;
  padding-left: 30px !important;
}

.vSheetContainer {
  height: 52px;
  overflow: hidden;
  border-radius: 5px;
  max-width: 100% !important;
  margin-top: 30px;
}

.app {
  display: grid;
  place-items: center;
  background-color: transparent;
  height: 50vh;
}

.no-requests {
  flex: 1;
  overflow-y: hidden;
}

.v-chip.v-chip.v-chip--outlined.v-chip.v-chip {
  border-top-right-radius: unset !important;
  border-bottom-right-radius: unset !important;
  clip-path: polygon(
    0% 50%,
    10% 0%,
    100% 0%,
    100% 100%,
    10% 100%,
    0% 50%
  ) !important;
  background-color: #3f0e40 !important;
  color: white !important;
  padding-left: 20px !important;
}

.v-btn {
  &:focus {
    &:before {
      background-color: white !important;
    }
  }
}

.selected-slider {
  .v-slide-group__content {
    align-items: center !important;
  }

  .theme--light.v-icon.v-icon:not(.mdi-close) {
    background-color: #f2f2f2 !important;
    border-color: #f2f2f2 !important;
    font-size: 40px;
    color: black;
  }
}
</style>
