<template>
  <div>
    <!--    <AlertsForms @filterData="handleFilter" @alertIsAdded="getTableData"/>-->
    <v-simple-table class="mt-2 mb-4 pl-3 pr-3">
      <tr>
        <td class="px-2 py-2" colspan="2"><h5 class="pa-0 requestTitle" style="color: #3f0f41; font-size: 16px; font-weight: bold">
          معلومات التنبيه
        </h5></td>
      </tr>
      <tr>
        <td class="px-2 py-1"> <span class="req-type" style="color: #545a69; font-size: 16px">
                <b>  محتوي التنبيه</b>
                </span></td>
        <td class="px-2 py-1" width="85%">{{this.alert.content}}</td>
      </tr>
      <tr>
        <td class="px-2 py-1"> <span class="req-type" style="color: #545a69; font-size: 16px">
                <b>  الحالة </b>
                </span></td>
        <td class="px-2 py-1" width="85%">
              <span
                  class="req-status spanRequestType"
                  :style="`${
                    this.alert.status === 0
                      ? `background-color: #FE705B`
                      :this.alert.status === 1
                      ? `background-color: #41BF72`

                      : `background-color: #FE705B`
                  }`">{{this.alert.status == 1 ? 'نشط' : 'غير نشط'}}
              </span>
        </td>
      </tr>
      <tr>
        <td class="px-2 py-1"> <span class="req-type" style="color: #545a69; font-size: 16px">
                <b>   التاريخ (هل تم ارساله)</b>
                </span></td>
        <td class="px-2 py-1" width="85%">
          <span
              class="req-status spanRequestType"
              :style="`${
                    this.alert.is_send === 'no'
                      ? `background-color: #FE705B`
                      :this.alert.is_send === 'yes'
                      ? `background-color: #41BF72`

                      : `background-color: #FE705B`
                  }`">{{this.alert.is_send === 'yes' ? 'تم ارساله' : 'لم يتم ارساله'}}
              </span>
          <b class="mx-2">  ( {{this.alert.end_at}} )</b>

        </td>
      </tr>
      <tr>
        <td class="px-2 py-1"> <span class="req-type" style="color: #545a69; font-size: 16px">
                <b>   عدد المشاهدين </b>
                </span></td>
        <td class="px-2 py-1" width="85%">{{this.seen_count}}</td>
      </tr>
    </v-simple-table>

    <div v-if="!this.$store.state.loadingData">
      <table-component
          :showSelect="false"
          itemKey="id"
          :headers="headers"
          :pagination="this.$store.state.pagination"
          :limit="10"
          :items="this.$store.state.tableData"
          :loading="loading"
          @refresh="getTableData"
          v-model="page"
      >
        <template v-slot:[`item.action`]="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  color="#40BE72"
                  small
                  depressed
                  dark
                  v-bind="attrs"
                  v-on="on"
              >
                <v-icon>mdi-dots-horizontal</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="handleShowAlert(item.id)">
                <v-list-item-icon class="ml-1">
                  <img
                      src="@/assets/icons/titcketIcon.svg"
                      alt="image"
                  />
                </v-list-item-icon>
                <v-list-item-title>أستعراض  </v-list-item-title>
              </v-list-item>
              <v-list-item @click="openAlert(item.id)">
                <v-list-item-icon class="ml-1">
                  <img src="./assets/3.svg" alt="edit" style="    width: 17px;" />

                  <!--                  <img
                                        src="@/assets/icons/titcketIcon.svg"
                                        alt="image"
                                    />-->
                </v-list-item-icon>
                <v-list-item-title>تعديل  </v-list-item-title>
              </v-list-item>

            </v-list>
          </v-menu>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <span
              :class="{
              text__success: item['status'] === 'نشط',
              text__faild: item['status'] !== 'نشط',
            }"
          >{{ item.status }}</span
          >
        </template>
        <!--
               <template slot="item.attachment" slot-scope="props">
                  <template v-if="props.item.attachment != 'لا يوجد مرفق'">
                    <div v-if="isImage(props.item.attachment)">
                    <img
                        :src="props.item.attachment"
                        alt="Image"
                        style="width: 50px; height: 50px;"
                    />
                    </div>
                    <div v-else v-html="`<a href='${props.item.attachment}' download>تحميل الملف</a>`"></div>

                  </template>
                  <span v-else>لا يوجد مرفق </span>

                </template>-->
      </table-component>
    </div>
    <div class="loadingMore" v-else>
      <LoadingMore />
    </div>

  </div>
</template>

<style lang="scss">
.loadingMore {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}
.text__success {
  color: green;
}

.text__faild {
  color: red;
}
th.text-start.sortable {
  text-align: center !important;
}
</style>

<script>
import Axios from "axios";
export default {
  name: "alert-seen",
  data() {
    return {
      page: 1,
      loading: false,
      alert: {},
      seen_count: {},
      copySupportRequests: [],
      loadMoreData: false,
      loadingData: false,
      iconToggle: false,
      headers: [
        { text: "الأسم", value: "name", width: "30%"},
        { text: "التاريخ", value: "created_at" , width: "30%"},
      ],
    };
  },

  watch: {
    page(val) {
      this.getTableData(val);
    },
  },

  methods: {
    setTbData(items) {
      this.$store.state.tableData.push({
        id: items.id,
        "name": items.name,
        "created_at" :  items.created_at,
      });
    },

    openAlert(id){
      // The last Task
      this.$store.state.open_edit_dialog_alert = true;
      this.$store.state.id_of_alert = id;
    },
    handleShowAlert(id){
      // The last Task
      this.$store.state.open_edit_dialog_alert = true;
      this.$store.state.id_of_alert = id;
      this.$router.push({
        name: "alert-seen",
        params: { id: id },
      });
    },

    getTableData(count) {
      this.$store.state.tableData = [];
      this.loading = true;
      Axios.get(`${this.$store.state.url}/employee/admin/alerts/openAlertSeenVue/${this.$router.currentRoute.params.id}?page=${count}`, {
        headers: this.request_headers(),
      }).then((res) => {
        this.alert = res.data.payload.announce;
        this.seen_count = res.data.payload.count_of_seen;
        res.data.payload.data.map((item) => {
          this.setTbData(item);
        });

        this.$store.state.pagination = {
          limit: 3,
          count: res.data.payload.count_of_seen,
        };

        this.loading = false;
      });
    },


    handleFilter(val) {
      this.$store.state.tableData = [];
      val.data.map((res) => {
        this.setTbData(res);
      });
    },

  },

  created() {
    this.$store.state.tableData = [];
    this.getTableData(1);
  },
};
</script>
