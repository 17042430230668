<template>
  <div>

    <v-row
      justify="center"
      class="mt-5 graphContent"
      style="margin: 0px; margin-bottom: -18px"
    >
      <DailyReportFilter @handleFilter="handleFilter" @exportsToExcel="exportsToExcel" />
    </v-row>

    <!-- <LatestReportFilter @filterData="handleFilter" /> -->
    <div v-if="!this.$store.state.loadingData" class="requests-chart-classification">
      <table-component
        :showSelect="false"
        itemKey="id"
        :headers="headers"
        :pagination="this.$store.state.pagination"
        :limit="$store.state.counterOfShow"
        :items="this.$store.state.tableData"
        :loading="loading"
        @refresh="getTableData"
        v-model="page"
        :requestSourceData="requestSourceData"
      >
      </table-component>
    </div>
    <div class="loadingMore" v-else>
      <LoadingMore />
    </div>

     <!-- chart -->
     <div style="box-shadow: 0 0 0 0 !important" class="adminGraph">
      <div v-if="!getDataDone">
        <div style="margin-top: 40px" v-if="!loading">
          <highcharts :options="chartOptions" style="height: 600px"></highcharts>
        </div>
      </div>
      <div v-else class="loader">
        <img src="../../../../assets/Comp-2.gif" />
      </div>
    </div>

  </div>
</template>

<style lang="scss">
.loadingMore {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}
</style>

<script>
import Axios from "axios";
// import LatestReportFilter from "./LatestReportFilter.vue";
import { Chart } from "highcharts-vue";
import Highcharts from "highcharts-vue";
import DailyReportFilter from "../../Charts/DailyReportFilter.vue";

export default {
  name: "AdminLatestReport",
  components: {/*LatestReportFilter,*/highcharts: Chart,DailyReportFilter},

  data() {
    return {
      page: 1,
      body: {},
      requestSourceData:{},
      loading: false,
      copySupportRequests: [],
      loadMoreData: false,
      loadingData: false,
      selected_requests: [],
      iconToggle: false,
      headers: [
        { text: "مصدر المعاملة", value: "مصدر المعاملة" },
        { text: "مجموع الايجابي", value: "مجموع الايجابي" },
        { text: "نسبة الايجابي", value: "نسبة الايجابي" },
        { text: "مجموع السلبي", value: "مجموع السلبي" },
        { text: "نسبة السلبي", value: "نسبة السلبي" },
        { text: "مجموع بدون", value: "مجموع بدون" }, 
        { text: "مجموع المفرغ", value: "مجموع المفرغ" }, 
        { text: "المجموع", value: "المجموع" },
      ],
      horizontalData: [],
      verticalData1: [],
      verticalData2: [],
      verticalData3: [],
      verticalData4: [],

      chartData: [],
      getDataDone: false,
      chartOptions: {
        chart: {
          type: "column",
        },
        title: {
          text: "تقرير مصادر المعاملات",
        },
        xAxis: {
          min: 0,
          title: {
            text: "مصادر المعاملات",
          },
          categories: [],
        },
        yAxis: {
          title: {
            text: "المجموع",
          },
        },
        tooltip: {
          headerFormat: "<b>{point.x}</b><br/>",
          pointFormat:
            '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
          shared: true,
        },
        plotOptions: {
          column: {
            dataLabels: {
              enabled: true,
            },
          },
        },
        series: [
          {
            name: "مجموع التصنيفات السلبيه",
            data: [],
            color: "#FF0000",
          },
          {
            name: "مجموع التصنيفات الايجابية",
            data: [],
            color: "#00FF00",
          },
          {
            name: "مجموع المفرغة",
            data: [],
            color: "#0000FF",
          },
          {
            name: "مجموع بدون",
            data: [],
            color: "#FFA500",
          },
        ],
      },
    };
  },

  watch: {
    page(val) {
      this.getTableData(val);
      this.getTableSumsData();
    },

    selected_requests(val) {
      this.selectedItems = val;
      this.$emit("requests", val);
    },

    "$store.state.counterOfShow": {
      handler(newval, oldvalue) {
        if (newval !== oldvalue && !this.$store.state.admin_search_active) {
          this.getTableData(this.page);
          this.getTableSumsData();
        }
      },
    },

    $route: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.$store.state.counterOfShow = 10;
        }
      },
    },

  },

  methods: {
    handleOrderRequest(item) {
      this.$router.push({
        name: "request_details",
        params: { id: item.request_id },
      });
    },
    handleOrdersRequest(item) {
      this.$router.push({
        name: "logs",
        params: { id: item.request_id },
      });
    },



    handleFilter(val) {
      // console.log("valval", val);
      this.body = new FormData();

      if (val.startDate) {
        this.body.append("start_date", val.startDate);
      }

      if (val.endDate) {
        this.body.append("end_date", val.endDate);
      }

      if (val.status === "استشاري قائم") {
        this.body.append("status", 1);
      }

      if (val.status === "استشاري مؤرشف") {
        this.body.append("status", 0);
      }

      if (val.range) {
        this.body.append("range", val.range);
      }

      if (val.managerId.length) {
        const managerProperties = {};

        val.managerId.forEach((item, index) => {
          this.body.append([`manager_id[${index}]`], item.id);
        });
      }

      if (val.agentId.length) {
        const managerProperties = {};

        val.agentId.forEach((item, index) => {
          this.body.append([`adviser_id[${index}]`], item.id);
        });
      }

      if (val.dayNumber) {
        this.body.append("range", val.dayNumber);
      }

      if (val.collaborator_id.length) {
        console.log('this is collaborator_id')
        console.log(val.collaborator_id)
        val.collaborator_id.forEach((item, index) => {
          this.body.append([`collaborator_id[${index}]`], item.collaborator_id);
        });
      }


      this.getTableData(1, "getDataDone");
      this.getTableSumsData();
    },

    setTbData(items) {
      this.$store.state.tableData.push({
        id: items.id,
        // request_id: items.request_id,
        "مصدر المعاملة": items.value,
        "مجموع السلبي": items.negative_requests_count,
        "نسبة السلبي": items.negative_requests_percentage,
        "مجموع الايجابي": items.positive_requests_count,
        "نسبة الايجابي": items.positive_requests_percentage,
        "مجموع المفرغ": items.completed_requests_count,
        "مجموع بدون": items.null_requests_count,
        "المجموع" : items.sum_of_source_row,
      });
    },

    // handleFilter(val) {
    //   this.$store.state.tableData = [];
    //   val.data.map((res) => {
    //     this.setTbData(res);
    //   });
    //   this.chartData = [];
    //   this.chartOptions.xAxis.categories = [];
    //   this.chartOptions.series[0].data = [];
    //   this.chartOptions.series[1].data = [];
    //   this.chartOptions.series[2].data = [];
    //   this.drawChart(val.chart);
    // },

    getTableData(count) {
      this.$store.state.tableData = [];
      this.loading = true;
      Axios.post(
        `${this.$store.state.url}/employee/admin/RequestSourcesReport?page=${count}&per_page=${this.$store.state.counterOfShow}`,this.body,
        {
          headers: this.request_headers(),
        }
      ).then((res) => {
        res.data.payload.data.map((item) => {
          this.setTbData(item);
        });

        this.$store.state.pagination = {
          limit: 3,
          count: res.data.payload.sum_of_resources,
        };

        this.drawChart(res.data.payload.data);
        this.loading = false;
      });
    },

    getTableSumsData() {
      Axios.post(
        `${this.$store.state.url}/employee/admin/RequestSourcesReportSum`,this.body,
        {
          headers: this.request_headers(),
        }
      ).then((res) => {
        this.requestSourceData = res.data.payload
        // this.loading = false;
      });
    },

    drawChart(data) {

      // redraw chart 
      this.chartData = [];
      this.chartOptions.xAxis.categories = [];
      this.chartOptions.series[0].data = [];
      this.chartOptions.series[1].data = [];
      this.chartOptions.series[2].data = [];
      this.chartOptions.series[3].data = [];

      this.chartData = data;
      this.horizontalData = this.chartData.map((item) => item.value);
      this.verticalData1 = this.chartData.map((item) => item.negative_requests_count);
      this.verticalData2 = this.chartData.map((item) => item.positive_requests_count);
      this.verticalData3 = this.chartData.map((item) => item.completed_requests_count);
      this.verticalData4 = this.chartData.map((item) => item.null_requests_count);
      this.chartOptions.xAxis.categories = this.horizontalData;
      this.chartOptions.series[0].data = this.verticalData1;
      this.chartOptions.series[1].data = this.verticalData2;
      this.chartOptions.series[2].data = this.verticalData3;
      this.chartOptions.series[3].data = this.verticalData4;
      this.loading = false;
    },

    exportsToExcel() {
      const table = document.querySelector(".requests-chart-classification table");
      this.exportTableToCsv(table, "تصنيفات مصادر المعاملات");
    },
  },

  created() {
    this.$store.state.tableData = [];
    // this.getTableData(1);
  },
};
</script>
