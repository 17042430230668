<template>
  <page-component style="position: relative">
    <template #content>
      <span class="font-weight-bold mb-5" style="display: inline"
        >تذاكر الطلب</span
      >
      <v-row class="mt-0">
        <v-col lg="4" md="6" cols="12">
          <v-sheet class="pb-5" style="height: 60vh; overflow: auto">
            <div class="py-5 mr-1 pr-4">
              التذاكر المرسله
              <span
                class="ml-4 font-weight-bold"
                style="float: left; color: #451646"
                >{{ sent_counter }}</span
              >
            </div>
            <v-divider class="mb-5" />
            <v-card
              elevation="0"
              style="border: 1px solid #e9edf4"
              class="pa-2 ma-2"
              v-for="ticket in sent_tickets"
              :key="ticket.id"
            >
              <div>
                {{ ticket.user.name }}
                <span
                  class="py-1 px-4"
                  style="float: left; background-color: #d8dffa"
                  >{{ ticket.status }}</span
                >
              </div>
              <div class="mt-3">{{ ticket.receive.name }}</div>
              <div class="mt-3">
                {{ ticket.created_at.split(".")[0].split("T").join(" | ") }}
                <span class="py-1 px-4" style="float: left"
                  ><v-icon @click="to_ticket_details(ticket.id)" color="primary"
                    >mdi-eye</v-icon
                  ></span
                >
              </div>
            </v-card>
          </v-sheet>
        </v-col>
        <v-col lg="4" md="6" cols="12">
          <v-sheet class="pb-5" style="height: 60vh; overflow: auto">
            <div class="py-5 mr-1 pr-4">
              التذاكر المستلمة
              <span
                class="ml-4 font-weight-bold"
                style="float: left; color: #451646"
                >{{ recieved_counter }}</span
              >
            </div>
            <v-divider class="mb-5" />
            <v-card
              elevation="0"
              style="border: 1px solid #e9edf4"
              class="pa-2 ma-2"
              v-for="ticket in recieved_tickets"
              :key="ticket.id"
            >
              <div>
                {{ ticket.user.name }}
                <span
                  class="py-1 px-4"
                  style="float: left; background-color: #d8dffa"
                  >{{ ticket.status }}</span
                >
              </div>
              <div class="mt-3">{{ ticket.receive.name }}</div>
              <div class="mt-3">
                <v-icon class="mb-1">mdi-calendar</v-icon>
                {{ ticket.created_at.split(".")[0].split("T").join(" | ") }}
                <span class="py-1 px-4" style="float: left"
                  ><v-icon @click="to_ticket_details(ticket.id)" color="primary"
                    >mdi-eye</v-icon
                  ></span
                >
              </div>
            </v-card>
          </v-sheet>
        </v-col>
        <v-col lg="4" md="6" cols="12">
          <v-sheet class="pb-5" style="height: 60vh; overflow: auto">
            <div class="py-5 mr-1 pr-4">
              التذاكر المكتملة
              <span
                class="ml-4 font-weight-bold"
                style="float: left; color: #451646"
                >{{ completed_counter }}</span
              >
            </div>
            <v-divider class="mb-5" />
            <v-card
              elevation="0"
              style="border: 1px solid #e9edf4"
              class="pa-2 ma-2"
              v-for="ticket in completed_tickets"
              :key="ticket.id"
            >
              <div>
                {{ ticket.user.name }}
                <span
                  class="py-1 px-4"
                  style="float: left; background-color: #d8dffa"
                  >{{ ticket.status }}</span
                >
              </div>
              <div class="mt-3">{{ ticket.receive.name }}</div>
              <div class="mt-3">
                {{ ticket.created_at.split(".")[0].split("T").join(" | ") }}
                <span class="py-1 px-4" style="float: left"
                  ><v-icon @click="to_ticket_details(ticket.id)" color="primary"
                    >mdi-eye</v-icon
                  ></span
                >
              </div>
            </v-card>
          </v-sheet>
        </v-col>
      </v-row>
    </template>
  </page-component>
</template>

<script>
import Axios from "axios";
export default {
  props: ["agent"],
  data() {
    return {
      agent_data: {},
      recieved_tickets: [],
      completed_tickets: [],
      sent_tickets: [],
      sent_counter: 0,
      recieved_counter: 0,
      completed_counter: 0,
    };
  },
  created() {
    this.agent_data = this.agent;
    this.get_request_tickets();
    this.setBreadCrumbs(["الرئيسية", "الطلبات", this.agent.customer.name]);
  },
  watch: {
    agent_data: {
      handler(val) {
        val.customer.birth_date = this.date.birth_date;
        val.customer.birth_date_higri = this.date.birth_date_higri;
        val.source = parseInt(val.source);
        val.customer.work = parseInt(val.customer.work);
        this.setBreadCrumbs(["الرئيسية", "الطلبات", val.customer.name]);
      },
    },
  },
  methods: {
    get_request_tickets() {
      Axios.get(`${this.$store.state.url}/agent/ReceivedTasks`, {
        headers: this.request_headers(),
      })
        .then((response) => {
          this.recieved_tickets = response.data.payload.filter(
            (el) => el.req_id == this.$route.params.id
          );
          this.recieved_counter = this.recieved_tickets.length;
        })
        .catch((err) => {
          this.showPopup("error", err.response.data.message);
        });
      Axios.get(`${this.$store.state.url}/agent/SentTasks`, {
        headers: this.request_headers(),
      })
        .then((response) => {
          this.sent_tickets = response.data.payload.filter(
            (el) => el.req_id == this.$route.params.id
          );
          this.sent_counter = this.sent_tickets.length;
        })
        .catch((err) => {
          this.showPopup("error", err.response.data.message);
        });
      Axios.get(`${this.$store.state.url}/agent/CompletedTasks`, {
        headers: this.request_headers(),
      })
        .then((response) => {
          this.completed_tickets = response.data.payload.filter(
            (el) => el.req_id == this.$route.params.id
          );
          this.completed_counter = this.completed_tickets.length;
        })
        .catch((err) => {
          this.showPopup("error", err.response.data.message);
        });
    },
    to_ticket_details(id) {
      this.$router.push({ name: "request_ticket", params: { ticket: id } });
    },
  },
};
</script>
<style scoped>
.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
  > .v-input__control
  > .v-input__slot
  fieldset {
  color: #ced4da !important;
}
</style>
