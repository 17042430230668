<template>
  <v-container>
    <v-row class="mt-7 pa-7 white rounded-lg">
      <v-col class="py-0" cols="12">
        <p class="font-weight-bold">إعدادت ظهور مصادر المعاملة (داخل ملف الطلب + السجل)</p>
      </v-col>
     
      <v-col
        cols="12"
        style="
          margin-top: 25px;
          margin-bottom: 15px;
          padding-top: 0;
          padding-bottom: 0;
          height: 0;
        "
      >
        <hr style="margin: 0; padding: 0" />
      </v-col>
      <v-col cols="12" sm="12">
        <p class="">المستخدمين</p>
        <v-row class="white rounded-lg">
          <v-col v-for="(item, index) in roles" :key="index" cols="3">
            <v-checkbox
              style="padding: 0; margin: 0; height: 16px"
              v-model="selected"
              :label="item.name"
              :value="item.value"
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-col>

      <v-col
        cols="12"
        style="
          margin-top: 25px;
          margin-bottom: 15px;
          padding-top: 0;
          padding-bottom: 0;
          height: 0;
        "
      >
        <hr style="margin: 0; padding: 0" />
      </v-col>
    
      <v-col class="py-5" cols="12">
        <v-btn class="primary px-6" @click="updateRequestSourceSettings">تحديث </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Axios from "axios";
export default {
  name: "RequestSetting",
  data() {
    return {
      roles: [],
      selected: [],
    };
  },
  mounted() {
    this.getRequestSettings();
  },
  methods: {
    async getRequestSettings() {
      await Axios.get(`${this.$store.state.url}/employee/request-source-settings`, {
        headers: this.request_headers(),
      }).then((response) => {
        this.roles = response.data.payload.roles;
        this.selected = response.data.payload.selected.map(value => parseInt(value));
      });
    },


    async updateRequestSourceSettings() {
      let body = new FormData();
      if (this.selected.length) {
        this.selected.forEach((item, index) => {
          body.append(`selected_roles[${index}]`, item);
        });
      }

      await Axios.post(
        `${this.$store.state.url}/employee/update-request-source-settings`,
        body,
        {
          headers: this.request_headers(),
        }
      ).then((response) => {
        this.showPopup("success", response.data.message);
      });
    },
  },
};
</script>

<style lang="scss">
.v-input__control {
  min-height: 40px !important;
}
</style>
