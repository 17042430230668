<template>
  <div style="box-shadow: 0 0 0 0 !important" class="adminGraph">
    <v-row justify="center" class="mt-5 graphContent" style="margin: 0px">
      <DailyReportFilter @handleFilter="handleFilter" />
    </v-row>

    <div v-if="!getDataDone">
      <SliderGroup
          :options="this.options"
          @removeRow="removeRow($event)"
          style="margin-bottom: 30px"
      />
      <table-component
          itemKey="id"
          :headers="tableHeaders"
          :formBody="body"
          :pagination="this.$store.state.pagination"
          :limit="$store.state.counterOfShow"
          :items="tableData"
          :loading="loading"
          v-model="page"
      >
        <template v-slot:[`item.التحكم`]="{ item }">
          <a :href="item.التحكم" style="text-decoration: none">
            <img src="@/assets/document-download-icon.svg" width="20px" height="30px" />
          </a>
        </template>
      </table-component>
    </div>
    <div v-else class="loader">
      <img src="../../../assets/Comp-2.gif" />
    </div>
  </div>
</template>
<style>
.bg-primary {
  background-color: rgb(63, 14, 64) !important;
  color: #fff !important;
  border: 1px solid #ddd !important;
}
.bg-primary * {
  color: #fff !important;
}

.v-data-table__wrapper > table > thead > tr > th.dimensions {
  text-align: center;
}

.v-data-table-header th.height,
.v-data-table-header th.base,
.v-data-table-header th.volume {
  display: none;
}

.v-data-table tr td.dimensions {
  text-align: center;
}

.v-data-table tr td.height,
.v-data-table tr td.base,
.v-data-table tr td.volume {
  display: none;
}
</style>
<script>
import DailyReportFilter from "./DailyReportFilter.vue";
import Axios from "axios";
import {SelectItems} from "@/mixins/selectItems";
import SliderGroup from "@/components/Charts/SliderGroup";

export default {
  components: {
    DailyReportFilter,
    SliderGroup,
  },

  data() {
    return {
      chartData: [],
      tableData: [],
      options: [],
      showArray: [],
      results: [],
      filterObj: {},
      body: {},
      page: 1,
      getDataDone: false,
      loading: false,

      tableHeaders: [],
    };
  },

  watch: {
    page(val) {
      this.getData(val, "loading");
    },

    "$store.state.counterOfShow": {
      handler(newval, oldvalue) {
        if (newval !== oldvalue && !this.$store.state.admin_search_active) {
          this.getData(this.page, "loading");
        }
      },
    },

    $route: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.$store.state.counterOfShow = 10;
        }
      },
    },
  },

  methods: {
    removeRow(headerValue) {
      let findIndex = this.tableHeaders.findIndex((item) => item.text === headerValue);

      if (findIndex !== -1) {
        this.tableHeaders.splice(findIndex, 1);
        this.showArray.splice(findIndex, 0, {
          id: findIndex,
          value: headerValue,
        });
      } else {
        let foundInsertedItem = this.options.findIndex((item) => item === headerValue);

        this.showArray.sort((a, b) => a.id - b.id);
        this.showArray.map((res, index) => {
          if (res.value === headerValue) {
            this.tableHeaders.splice(foundInsertedItem - (index - 1), 0, {
              text: headerValue,
              value: headerValue,
            });
            this.showArray.splice(index, 1);
          }
        });
      }
    },

    refresh() {
      this.$root.$emit("RD"); //like this
    },
    handleFilter(val) {
      this.body = new FormData();

      if (val.startDate) {
        this.body.append("start_date", val.startDate);
      }

      if (val.endDate) {
        this.body.append("end_date", val.endDate);
      }

      if (val.status === "استشاري قائم") {
        this.body.append("status", 1);
      }

      if (val.status === "استشاري مؤرشف") {
        this.body.append("status", 0);
      }

      if (val.range) {
        this.body.append("range", val.range);
      }

      if (val.managerId.length) {
        const managerProperties = {};

        val.managerId.forEach((item, index) => {
          this.body.append([`manager_id[${index}]`], item.id);
        });
      }

      if (val.agentId.length) {
        const managerProperties = {};

        val.agentId.forEach((item, index) => {
          this.body.append([`adviser_id[${index}]`], item.id);
        });
      }

      if (val.dayNumber) {
        this.body.append("range", val.dayNumber);
      }

      this.getData(1, "getDataDone");
    },

    getData(page, loader) {
      if (loader === "getDataDone") {
        this.getDataDone = true;
      } else if (loader === "loading") {
        this.loading = true;
      }
      this.options = [];
      this.tableData = [];
      let mapArray = [];

      let url = `${this.$store.state.url}/employee/admin/request-sources?page=${page}&per_page=${this.$store.state.counterOfShow}`;

      Axios.post(url, this.body, { headers: this.request_headers() }).then((res) => {
/*
        res.data.payload.data.forEach((item, index) => {
          let filtedObject= {};

          this.tableHeaders.forEach((it, ind) => {
            // let existkeyValue = Object.keys(item).find((val) => item[val] === 'name');
            let existkeyValue = Object.keys(item).includes(it.value); // name true
            //console.log("existkeyValue",existkeyValue)
            if (existkeyValue){
              filtedObject[it.value] = item[it.value];
            }else{
              filtedObject[it.value] = 0;
            }
          });

          mapArray.push(filtedObject)
          filtedObject= {};
        });*/

        this.setTableData(res.data.payload.data);
        this.setOptions();
        this.chartData = res.data.payload;
        this.$store.state.pagination = {
          limit: 3,
          count: res.data.payload.total_agents,
        };
        this.loading = false;
        this.getDataDone = false;
      });
    },


    setOptions() {
      this.tableHeaders.map((item, index) => {
        if (item.text !== "الإستشاري" && item.text !== "م" && item.text !== "التحكم") {
          this.options.push(item.text);
        }
      });
    },

    setTableData(data) {
      data.map((res) => {
        this.tableData.push(res)
      });
    },
  },

  created() {
    // this.getData(1, "getDataDone");
    this.$store.state.pagination = {};
    Axios.get(`${this.$store.state.url}/agent/Request/Sources`, {headers: this.request_headers()}
    ).then((res) => {
      let response = res.data.payload

/*
      this.tableHeaders.push({ text: "م", value: "م", align: "center" });
*/
      this.tableHeaders.push({ text: "الإستشاري", value: "الإستشاري", align: "center" , width: "170px"});
      this.tableHeaders.push({ text: "عدد مصادر المعاملات", value: "عدد مصادر المعاملات", align: "center" });

      response.forEach((item, index) => {
        this.tableHeaders.push({ text: item.value, value: item.value, align: "center" });
      });
    });

  },
};
</script>
