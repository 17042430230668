<template>
  <div class="header__icons">
    <v-btn
      @click="$store.state.search_funding_manager = true"
      fab
      class="mx-2 pa-0 genaralIcons"
      small
      style="left: 125px; border-radius: 8px"
      :color="$vuetify.theme.themes.light.BtnBKColor"
      depressed
      width="40"
      height="40"
    >
      <v-icon color="white">mdi-magnify</v-icon>
    </v-btn>
  </div>
</template>

<style lang="scss">
.genaralIcons {
  position: absolute;
  top: -50px;
  min-width: unset;
  z-index: 5;
}

.header__icons .v-input__slot {
  border: 1px solid white;
}
@media screen and (max-width: 626px) {
  .genaralIcons {
    top: -10px;
  }
}

.white-border {
  .v-input--outlined {
    border-color: white !important; /* Set the border color to white */
  }

  .v-label {
    color: white !important; /* Set the label color to white */
  }

  .v-icon {
    color: white !important; /* Set the icon color to white */
  }

  input {
    color: white !important; /* Set the input text color to white */
  }
}
</style>

<script>
import Axios from "axios";
// import TransferFilterDialog from "@/components/AdminComponent/TransferFilterDialog.vue";
// import SelectInput from "@/components/Charts/SelectInput.vue";
import { qualityUser } from "@/mixins/qualityUser";
// import { SendToQualityIcon } from "@/assets/adminIcons/sendToQualityIcon";

export default {
  name: "FundingHeaderIcons",
  components: {
    // SelectInput,
    // TransferFilterDialog,
    // SendToQualityIcon,
  },
  props: ["selectedItems", "startItems", "type"],
  data() {
    return {
      currentRole:0,
      qualityUsers: [],
      active: false,
      AllSelectedTable: [],
      dialog: false,
      backToReceivedDialog: false,
      message: "",
      snackColor: "",
      messages: "هل انت متاكد من رغبتك في ارشفت هذه الطلبات",
      allStatus: [],
      dialogQuality: false,
      selectedQuality: [],
      needToAction: [],
      snackMessage: "",
    };
  },
  computed: {
    appearThirdView() {
      return (
        window.location.pathname === "/admin/requests/featured" ||
        window.location.pathname === "/admin/requests/all"
      );
    },
    showFeaturedProcess() {
      return window.location.pathname === "/admin/requests/featured";
    },

    showNewView() {
      return window.location.pathname === "/admin/requests/all";
    },
  },
  methods: {
    handleTableDesign() {
      this.$store.state.views = "table";
    },

    handleSendUserToAgent() {
      this.dialogQuality = false;

      let url =
        `${this.$store.state.url}/v2/employee/Requests/moveToQualityReqArray`;
      let body = new FormData();

      this.selectedQuality.forEach((item, index) => {
        body.append(`quality[${index}]`, item["id"]);
      });

      this.selectedItems.length &&
        this.selectedItems.forEach((item, index) => {
          body.append(`request_ids[${index}]`, item["id"]);
        });

      this.$store.state.startElement.length &&
        this.$store.state.startElement.forEach((item, index) => {
          body.append(`request_ids[${index}]`, item["id"]);
        });

      Axios.post(url, body, { headers: this.request_headers() })
        .then((res) => {
          this.snackMessage = res.data.message;
          this.snackColor = "green";
          this.active = true;
        })
        .catch((err) => {
          let error = { err };
          this.snackMessage = error.err.response.data.message;
          this.snackColor = "red";
          this.active = true;
        });
    },

    getSelectedValueManger(val) {
      this.selectedQuality = val;
    },
    async convertRequest() {
      if (
        this.selectedItems.length ||
        this.$store.state.startElement.length > 0
      ) {
        this.dialogQuality = true;

        await qualityUser().then((res) => {
          this.qualityUsers = res.data.payload.map((item) => {
            item["color"] = "red";
            item["text"] = item["name_for_admin"] || item["name"];
            return item;
          });
        });
      }
    },

    moveToNeedAction() {
      if (
        this.selectedItems.length ||
        this.$store.state.startElement.length > 0
      ) {
        this.messages = "هل انت متأكد من تحويل هذه الطلبات";
        this.dialog = true;
      }
    },

    moveToFreez() {
      if (
        this.selectedItems.length ||
        this.$store.state.startElement.length > 0
      ) {
        this.messages = "  هل انت متأكد من تحويل هذه الطلبات الي سله التجميد";
        this.dialog = true;
      }
    },

    handleTurnuser() {
      if (
        this.selectedItems.length ||
        this.$store.state.startElement.length > 0
      ) {
        this.$store.state.turnuserDialog = true;
      }
    },

    handleTransferFilter() {
      if (
        this.selectedItems.length ||
        this.$store.state.startElement.length > 0
      ) {
        this.$store.state.TransferFilterDialog = true;
      }
    },

    requestArchived() {
      if (
        this.selectedItems.length ||
        this.$store.state.startElement.length > 0
      ) {
        this.dialog = true;
      }
    },

    backToReceivedBasket() {
      if (
        this.selectedItems.length ||
        this.$store.state.startElement.length > 0
      ) {
        this.backToReceivedDialog = true;
      }
    },

    sureToArchive() {
      if (this.messages === "هل انت متأكد من تحويل هذه الطلبات") {
        this.dialog = false;
        let url =
          `${this.$store.state.url}/v2/employee/Requests/moveToNeedActionReqArray`;
        let body = new FormData();
        this.selectedItems.length > 0 &&
          this.selectedItems.forEach((item, index) => {
            body.append(`request_ids[${index}]`, item["id"]);
          });

        this.$store.state.startElement.length > 0 &&
          this.$store.state.startElement.forEach((item, index) => {
            body.append(`request_ids[${index}]`, item["id"]);
          });

        Axios.post(url, body, { headers: this.request_headers() })
          .then((res) => {
            this.snackMessage = res.data.message;
            this.snackColor = "green";
            this.active = true;
          })
          .catch((err) => {
            let error = { err };
            this.snackMessage = error.err.response.data.message;
            this.snackColor = "red";
            this.active = true;
          });
      } else if (
        this.messages === "هل انت متاكد من رغبتك في ارشفت هذه الطلبات"
      ) {
        let url =
          `${this.$store.state.url}/v2/employee/Requests/Archive`;
        let body = new FormData();

        this.selectedItems.length &&
          this.selectedItems.forEach((item, index) => {
            body.append(`requests_ids[${index}]`, item["id"]);
          });

        this.$store.state.startElement.length &&
          this.$store.state.startElement.forEach((item, index) => {
            body.append(`requests_ids[${index}]`, item["id"]);
          });

        this.dialog = false;

        Axios.post(url, body, { headers: this.request_headers() })
          .then((res) => {
            this.snackMessage = res.data.payload;
            this.snackColor = "green";
            this.active = true;
          })
          .catch((err) => {
            let error = { err };
            this.snackMessage = error.err.response.data.message;
            this.snackColor = "red";
            this.active = true;
          });
      } else if (
        this.messages === "  هل انت متأكد من تحويل هذه الطلبات الي سله التجميد"
      ) {
        this.dialog = false;
        let url =
          `${this.$store.state.url}/v2/employee/Requests/transferToFreezeReqArray`;
        let body = new FormData();
        this.selectedItems.length &&
          this.selectedItems.forEach((item, index) => {
            body.append(`request_ids[${index}]`, item["id"]);
          });

        this.$store.state.startElement.length &&
          this.$store.state.startElement.forEach((item, index) => {
            body.append(`request_ids[${index}]`, item["id"]);
          });

        Axios.post(url, body, { headers: this.request_headers() })
          .then((res) => {
            this.snackMessage = res.data.message;
            this.snackColor = "green";
            this.active = true;
            this.$emit("getData");
          })
          .catch((err) => {
            let error = { err };
            this.snackMessage = error.err.response.data.message;
            this.snackColor = "red";
            this.active = true;
          });
      }
    },

    sureToBackToReceivedBasket() {
      let url =
        `${this.$store.state.url}/v2/employee/Requests/moveFromAllToRecieved`;
      let body = new FormData();
      this.selectedItems.length &&
        this.selectedItems.forEach((item, index) => {
          body.append(`request_ids[${index}]`, item["id"]);
        });

      this.$store.state.startElement.length &&
        this.$store.state.startElement.forEach((item, index) => {
          body.append(`request_ids[${index}]`, item["id"]);
        });

      this.backToReceivedDialog = false;

      Axios.post(url, body, { headers: this.request_headers() })
        .then((res) => {
          this.snackMessage = res.data.message;
          this.snackColor = "green";
          this.active = true;
          this.$emit("getData");
        })
        .catch((err) => {
          let error = { err };
          this.snackMessage = error.err.response.data.message;
          this.snackColor = "red";
          this.active = true;
        });
    },
  },
  mounted(){
    this.currentRole = localStorage["role"];
  },
};
</script>
