<template>
  <v-row justify="center">
    <v-dialog
      v-model="this.$store.state.SMRejectRequestDialog"
      persistent
      width="624"
    >
      <v-card>
        <v-card-title>
          <h3>هل أنت متأكد من رفضه ؟</h3>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" lg="12" md="12" sm="12" xs="12">
              <h4 class="mb-3">الملاحظة</h4>
              <v-textarea outlined v-model="comment" dense></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pl-sm-and-down-2 excluded">
          <v-spacer></v-spacer>
          <v-btn
            color="#5A6268"
            variant="text"
            @click="$store.state.SMRejectRequestDialog = false"
          >
            الغاء
          </v-btn>
          <v-btn
            color="primary"
            variant="text"
            @click="move_request_to_rejected"
          >
            رفض
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Axios from "axios";
export default {
  name: "SMRejectRequestDialog",
  props: ["reqId"],
  data() {
    return {
      comment: "",
    };
  },
  methods: {
    move_request_to_rejected() {
      let req_id = "";
      req_id = parseInt(this.reqId);
      const formData = new FormData();
      formData.append("comment", this.comment);
      Axios.post(
        `${this.$store.state.url}/v2/employee/Requests/reject/${req_id}`,
        formData,
        {
          headers: this.request_headers(),
        }
      )
        .then(() => {
          this.showPopup("success", "تم رفض الطلب");
          this.$store.state.SMRejectRequestDialog = false;
          setTimeout(() => {
            this.loading = true;
            setTimeout(() => {
              this.loading = false;
            }, 1000);
          }, 2000);
        })
        .catch((err) => {
          this.showPopup("error", err.response.data.errors["comment"][0]);
        });
    },
  },
};
</script>