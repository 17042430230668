<template>
  <div class="supportContainer">
    <div v-if="!loading" style="margin-bottom: 40px">
      <div>
        <v-card elevation="0">
          <v-card-title class="ma-5 d-flex align-center justify-center">
            <div>تفاصيل طلب الدعم</div>
            <v-badge
              color="success"
              v-if="status == 2"
              content="مكتملة"
              floating
            ></v-badge>
          </v-card-title>
        </v-card>
        
        <div v-for="item in ticket_details" :key="item.id">
          <div class="lightArrow" v-if="item.position_of_message == 'left'">
            <div style="text-align: end" class="dateContainer">
              <div>
                {{ item.created_at.replace(" ", " ,").split(" ,")[0] }}
              </div>
              <div>
                {{ item.created_at.replace(" ", " ,").split(" ,")[1] }}
              </div>
            </div>
            <img src="@/assets/arrowLight.svg" />
            <div style="width: 60px; height: 2px; background: #4d204d"></div>
            <div class="problemContainer">
              <div class="customerName">{{ item.name ?? item.user?.name }}</div>
              <a :href="item.images[0].image_path" target="_blank" v-if="item.images.length > 0">
                <img :src="item.images[0].image_path" alt="" width="200" height="50" />
              </a>
              <div>
                <v-row>
                  <v-col cols="12" sm="12">
                    <div>
                      <span>
                        {{item.descrebtion}}
                      </span>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </div>
          </div>

          <div class="darkArrow" v-if="item.position_of_message == 'right'">
            <div class="problemContainer">
              <div class="customerName">
                {{ item.name ?? item.user?.name }}
              </div>

              <a :href="item.images[0].image_path" target="_blank" v-if="item.images.length > 0">
                <img :src="item.images[0].image_path" alt="" width="200" height="50" />
              </a>
              
              <div>{{ item.descrebtion }}</div>
            </div>
            <div style="width: 60px; height: 2px; background: #4d204d"></div>
            <img src="@/assets/arrowDark.svg" />
            <div class="dateContainer">
              <div>
                {{ item.created_at.replace(" ", " ,").split(" ,")[0] }}
              </div>
              <div>
                {{ item.created_at.replace(" ", " ,").split(" ,")[1] }}
              </div>
            </div>
          </div>
        </div>

        <!-- <v-card elevation="0" v-if="showReplyTextbox">
          <v-card-title class="ma-5 d-flex align-center justify-center"
            >الرد</v-card-title
          >
          <v-textarea v-model="replay" class="mx-10 mb-5" outlined></v-textarea>
          <v-card-actions class="d-flex align-center justify-center"
            ><v-btn
              :loading="reply_loading"
              color="primary"
              @click="reply_ticket_content"
              class="font-weight-bold"
              >إرسال</v-btn
            ></v-card-actions
          >
        </v-card> -->


 


        <v-card elevation="0" v-if="showReplyTextbox">
          <v-card-title class="ma-5 d-flex align-center justify-center">
            الرد
          </v-card-title>

          <!-- Textarea with Attach Icon -->
          <v-textarea 
            v-model="replay" 
            class="mx-10 mb-5" 
            outlined 
            placeholder="أكتب رسالتك"
          >
            <!-- Append icon slot -->
            <template #append>
              <v-btn icon @click="triggerFileUpload">
                <v-icon>mdi-paperclip</v-icon>
              </v-btn>
              <!-- Hidden file input -->
              <input 
                type="file" 
                ref="fileInput" 
                multiple 
                class="d-none py-5" 
                @change="handleFileUpload"
              />
            </template>
          </v-textarea>

          <v-card-actions class="d-flex align-center justify-center"
            ><v-btn
              :loading="reply_loading"
              color="primary"
              @click="reply_ticket_content"
              class="font-weight-bold"
              >إرسال</v-btn
            ></v-card-actions
          >
        </v-card>




        <v-card elevation="0">
          <v-card-actions
            class="d-flex align-center justify-center"
            v-if="this.ticket_details[0].status === 2"
          >
            <v-btn color="success" class="font-weight-bold">التذكرة مكتملة</v-btn>
          </v-card-actions>

          <v-card-actions
            class="d-flex align-center justify-center"
            v-if="this.ticket_details[0].status === 2"
          >
            <v-btn
              style="background-color: #4d204d; color: white"
              @click="reOpenTicket"
              class="font-weight-bold"
              >{{ changeContentOfButton() }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </div>
    </div>
  </div>
</template>
  
  <script>
import Axios from "axios";
export default {
  props: ["agent"],
  watch: {
    status(nValue,oValue) {
      if(this.status === 2){
        this.showReplyTextbox = false;
      }else{
        this.showReplyTextbox = true;
      }
    },  
  },
  data() {
    return {
      ticket_details: [],
      loading: false,
      reply_loading: false,
      replay: "",
      complete_loading: false,
      incomplete_loading: false,
      close_loading: false,
      status: 0,
      showReplyTextbox: false,
      files: [],
    };
  },
  created() {
    this.get_ticket_details();
    this.setBreadCrumbs(["الرئيسية", "الدعم الفني", "تفاصيل طلب الدغم الفني"]);
    this.$store.state.opened = -1;
  },
  methods: {
    triggerFileUpload() {
      // Trigger file input when icon is clicked
      this.$refs.fileInput.click();
    },
    handleFileUpload(event) {
      // Handle file selection
      this.files = Array.from(event.target.files);
      this.showPopup("success", `${this.files.length} file(s) uploaded`);
    },

    get_ticket_details() {
      this.loading = true;
      Axios.get(
        `${this.$store.state.url}/employee/collaborator/open-technical-support-request/${this.$route.params.id}?sort_by=created_at&sort_type=asc`,
        { headers: this.request_headers() }
      )
        .then((response) => {
          this.getTopBarData();
          this.ticket_details = response.data.payload.data;
          this.sent_counter = this.ticket_details.length;
          if (response.data.payload.ticket !== undefined) {
            this.status = response.data.payload.ticket.status;
          }
        })
        .catch((err) => {
          this.showPopup("error", err.response.data.message);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    reply_ticket_content() {
      let formData = new FormData();
      formData.append("replay", this.replay);

      if (this.files) {
        if (Array.isArray(this.files)) {
          this.files.forEach((file, index) => {
            formData.append(`files[${index}]`, file);
          });
        } else {
          formData.append("files[0]", this.files);
        }
      }
      this.reply_loading = true;

      Axios.post(
        `${this.$store.state.url}/employee/collaborator/replay-to-technical-support-request/${this.$route.params.id}`,
        formData,
        { headers: this.request_headers() }
      )
        .then(() => {
          this.showPopup("success", "تم الرد بنجاح");
          this.replay = "";
          this.get_ticket_details();
        })
        .catch((err) => {
          this.showPopup("error", err.response.data.message);
        })
        .finally(() => {
          this.reply_loading = false;
        });
    },
    async getTopBarData() {
      await Axios.get(
        `${this.$store.state.url}/agent/topBarCount`,
        {
          headers: this.request_headers(),
        }
      )
        .then((res) => {
          res.data.message.forEach((el) => {
            if (el.technical_support_notifications_count) {
              this.$store.state.supportCount =
                el.technical_support_notifications_count;
              this.technical_support_notifications_count =
                el.technical_support_notifications_count;
            } else if (el.count_of_received_requests) {
              this.$store.state.recievedCount = el.count_of_received_requests;
              this.count_of_received_requests = el.count_of_received_requests;
            }
          });
        })
        .catch((err) => console.log(err));
    },

    reOpenTicket(){
      this.showReplyTextbox = !this.showReplyTextbox;
    },

    changeContentOfButton(){
      if(this.showReplyTextbox === true) {
        return ' الغاء فتح التذكرة';
      }else{
        return 'اعادة فتح التذكرة';
      }
    }
  },
};
</script>
  
<style lang="scss">
.ticket-details-page {
  .v-timeline-item__dot {
    width: 40px !important;
    height: 150px !important;
    border-radius: unset !important;
    clip-path: polygon(
      0% 0%,
      50% 10%,
      100% 0%,
      100% 85%,
      50% 100%,
      0% 85%,
      0% 0%
    ) !important;
    // background: red !important;
    .v-timeline-item__inner-dot {
      display: none !important;
    }
  }
  .cardo {
    margin-top: 20px !important;
  }
  .v-timeline-item.left-side {
    .v-timeline-item__dot {
      background: #f4b5ff !important;
      border: 1px solid #f4b5ff;
    }
    .cardo {
      &::before,
      &::after {
        transform: rotate(0);
        right: initial !important;
        border: none !important;
        width: 40px !important;
        height: 1px !important;
        background: #f4b5ff !important;
        left: -41px !important;
      }
    }
  }
  .v-timeline-item.right-side {
    .v-timeline-item__dot {
      background: #922595 !important;
      border: 1px solid #922595;
    }
    .cardo {
      //   margin-left: 13px;
      //   margin-right: auto !important;
      &::before,
      &::after {
        border: none !important;
        width: 40px !important;
        height: 1px !important;
        background: #922595 !important;
        left: -41px !important;
      }
    }
  }
}

@media (max-width: 960px) {
  .ticket-details-page {
    .cardo {
      padding-right: 10px;
      padding-left: 10px;
      padding-bottom: 10px;
    }
    .v-timeline-item.right-side {
      .cardo {
        // margin-right: unset !important;
        // margin-left: auto !important;
        // margin-right: 13px !important;
        margin-left: 0 !important;
        &::before,
        &::after {
          right: -41px !important;
        }
      }
    }
    .ticket-status-msg {
      margin-right: 0 !important;
    }
  }
}
@media (max-width: 500px) {
  .ticket-details-page {
    .eye-dad {
      .v-card {
        padding: 10px !important;
      }
    }
  }
}
/*
.right-side {
      flex-direction: row-reverse !important;
    }
  .right-side .v-timeline-item__opposite{
      text-align: left !important;
    }
    .left-side {
        flex-direction: row !important;
    }
    .left-side .v-timeline-item__opposite{
      text-align: right !important;
    }

    .left-side .v-timeline-item__body .cardo.v-card::after,
    .left-side .v-timeline-item__body .cardo.v-card::before{
      transform: rotate(180deg);
        right: initial !important;
        left: -42px !important;
    }
    .right-side .v-timeline-item__body .cardo.v-card::after,
    .right-side .v-timeline-item__body .cardo.v-card::before{
        right: -42px !important;
    }
*/
.right-side {
  flex-direction: row-reverse !important;
}
.right-side .v-timeline-item__opposite {
  text-align: left !important;
}
.left-side {
  flex-direction: row !important;
}
.left-side .v-timeline-item__opposite {
  text-align: right !important;
}

.right-side .v-timeline-item__body .cardo.v-card::after,
.right-side .v-timeline-item__body .cardo.v-card::before{
    right: -42px !important;
}
</style>
  