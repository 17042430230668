import Axios from "axios";
export const qualityUser = async (context) => {
    let url = `${context.$store.state.url}/v2/employee/quality-users`

    const theCookie1 =  document.cookie
    .split(";")
    .map((coki) => coki.split("="))
    .reduce(
      (acc, [key, val]) => ({
        ...acc,
        [key.trim()]: decodeURIComponent(val),
      }),
      {}
    )["wsataJwt"];
    return Axios.get( url , {
        headers: {
            "x-api-key":
                "WFKvB4Vjr8v5BVmxY7CDQ6ZMAU8DSHSBFKCMVpkfd5hhZyKTvnV3uh5XwxSwhnfeUbptZ7Z4XFJCR4vKXeHWGHTZ6djsYXjdAatf",
            Authorization: `Bearer ${JSON.parse(theCookie1).token}`,
        },
    })
}