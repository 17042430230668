<template>
  <div>
    <v-list-item @click="to_request_details">
      <v-list-item-icon class="ml-2">
        <v-icon>mdi-eye-outline</v-icon>
      </v-list-item-icon>
      <v-list-item-title>فتح</v-list-item-title>
    </v-list-item>

    <v-list-item @click="getRequestCalls">
      <v-list-item-icon class="ml-3">
        <RequestCallsIcon />
      </v-list-item-icon>
      <v-list-item-title>المكالمات</v-list-item-title>
    </v-list-item>

    <v-list-item @click="convertRequest">
      <v-list-item-icon class="ml-2">
        <ConvertRequestIcon />
      </v-list-item-icon>
      <v-list-item-title>تحويل الطلب</v-list-item-title>
    </v-list-item>

    <v-list-item @click="handleRefuse">
      <v-list-item-icon class="ml-2">
        <NotAllowIcon />
      </v-list-item-icon>
      <v-list-item-title>رفض الطلب</v-list-item-title>
    </v-list-item>

    <v-list-item @click="toOrderRequest">
      <v-list-item-icon class="ml-2">
        <OrderHistory />
      </v-list-item-icon>
      <v-list-item-title>سجل الطلب</v-list-item-title>
    </v-list-item>

   
  </div>
</template>

<script>
import { ConvertRequestIcon } from "@/assets/adminIcons/convertRequest";
import { OrderHistory } from "@/assets/adminIcons/orderHistory";
import { NotAllowIcon } from "@/assets/adminIcons/notAllowIcon";
import Axios from "axios";
import OrderRequestDialog from "@/components/AdminComponent/OrderRequestDialog.vue";
import { RequestCallsIcon } from "@/assets/adminIcons/requestCalls";

export default {
  components: {
    ConvertRequestIcon,
    OrderHistory,
    NotAllowIcon,
    RequestCallsIcon,
  },
  props: ["item"],
  data() {
    return {
      requestId: null,
    };
  },
  methods: {
    handleRefuse() {
      this.$store.state.refuseDialog = true;
      this.$store.state.refusedId = this.item;
    },

    toOrderRequest() {
      this.$store.state.orderRequestId = this.item.request.id;
      if(this.$store.state.showCards){
        this.$store.state.orderRequestDialog = true;
      }else{
        this.$store.state.openOrderRequestSection = true
      }  
    },

    getRequestCalls(){
      this.$store.state.idOfRequestCalls = this.item.request.id;
      this.$store.state.openCallsDialog = true;
    },

    to_request_details() {
      const requestId = this.item.request.id;
      this.$router.push({
        name: "request_details",
        params: { id: requestId },
      });
    },

    convertRequest() {
      this.getUserAdmin();
      this.$store.state.openDialog = true;
      this.$store.state.selectId = this.item.id;
    },

    getUserAdmin() {
      let url =
        `${this.$store.state.url}/v2/employee/sales-agent-users`;
      Axios.get(url, { headers: this.request_headers() }).then((res) => {
        this.$store.state.allStatusAgent = res.data.payload;
      });
    },
  },
};
</script>
