<template>
  <div style="box-shadow: 0 0 0 0 !important" class="adminGraph">
    <v-row justify="center" class="mb-3 mt-5 graphContent" style="margin: 0px">
      <!-- <DailyReportFilter @handleFilter="handleFilter" /> -->
      <DailyReportSalesFilter @handleFilter="handleFilter" />
    </v-row>
    <div v-if="!getDataDone">
      <table-component
        itemKey="id"
        :headers="tableHeaders"
        :formBody="body"
        :pagination="this.$store.state.pagination"
        :limit="$store.state.counterOfShow"
        :items="tableData"
        :loading="loading"
        v-model="page"
      >
        <template v-slot:[`item.التحكم`]="{ item }">
          <a :href="item.التحكم" style="text-decoration: none">
            <img src="@/assets/document-download-icon.svg" width="20px" height="30px" />
          </a>
        </template>
      </table-component>
    </div>
    <div v-else class="loader">
      <!--      <img src="../../../assets/Comp-2.gif" />-->
    </div>
  </div>
</template>
<style>
.bg-primary {
  background-color: rgb(63, 14, 64) !important;
  color: #fff !important;
  border: 1px solid #ddd !important;
}
.bg-primary * {
  color: #fff !important;
}
</style>
<script>
// import DailyReportFilter from "./DailyReportSalesFilter";
// import DailyReportFilter from "../DailyReport/NewDailyreportFilter.vue";
import DailyReportFilter from "@/views/admin/Charts/DailyReportFilter.vue";
import DailyReportSalesFilter from "./DailyReportSalesFilter.vue";
import Axios from "axios";

export default {
  components: {
    DailyReportSalesFilter,
  },

  data() {
    const yesterdayDate = new Date();
    yesterdayDate.setDate(yesterdayDate.getDate() - 1); // Subtract one day

    return {
      role: localStorage.getItem("role"),
      chartData: [],
      tableData: [],
      options: [],
      showArray: [],
      filterObj: {},
      body: {},
      page: 1,
      today:

          new Date().getFullYear() +
          "-" +
          ("0" + (new Date().getMonth() + 1)).slice(-2) +
          "-" +
          ("0" + new Date().getDate()).slice(-2),
      yesterday:
          yesterdayDate.getFullYear() +
          "-" +
          ("0" + (yesterdayDate.getMonth() + 1)).slice(-2) +
          "-" +
          ("0" + yesterdayDate.getDate()).slice(-2),
      getDataDone: false,
      loading: false,
      tableHeaders: [
        { text: "الاستشاري", value: "الاستشاري", align: "center" , width: "170px"},
        { text: "المستقبلة", value: "المستقبلة", align: "center" , tooltip: "الطلبات التي استقبلها الأستشاري سواء كانت تلقائي حسب الدور أو محولة إليه من مدير النظام أو طلب تم سحبه من إستشاري آخر " },
        { text: "قبول أولي", value: "قبول أولي", align: "center" , tooltip: "الطلبات التي تم الأجابة على  \n" +
              " هل بالامكان البيع للعميل؟\n" +
              "بنعم\n" },
        { text: "المتابعه", value: "المتابعه", align: "center" , tooltip: "لطلبات التي تم الأجابة على هل بالامكان البيع للعميل؟ بنعم ومازلت بنعم  " },
        { text: "%المتابعه", value: "%المتابعه", align: "center"  , tooltip: "نسبة المتابعة من الطلبات المستقبلة"},
        { text: "مرفوعه مبيعات", value: "مرفوعه مبيعات", align: "center"  , tooltip: "الطلبات التى تم رفعها لمدير المبيعات من قبل الاستشاري"},
        { text: "مرفوضه مبيعات", value: "مرفوضه مبيعات", align: "center" , tooltip: "الطلبات التي تم رفضها من قبل مدير المبيعات ومن ثم تعود للإستشاري للعمل عليها مرة أخري" },
        { text: "%مبيعات", value: "%مبيعات", align: "center"  , tooltip: "هي عبارة عن نسبة المرفوع مدير المبيعات إلى القبول الأولي"},
        { text: "مرفوعه تمويل", value: "مرفوعه تمويل", align: "center" , tooltip: "فى حالة كان الطلب نوعه شراء وقام مدير المبيعات برفعه يصل لمدير التمويل" },
        { text: "مرفوضة تمويل", value: "مرفوضة تمويل", align: "center" , tooltip: "فى حال رفض الطلب من قبل مدير التمويل يعود الطلب لمدير المبيعات" },
        { text: "قائم تمويل", value: "قائم تمويل", align: "center" , tooltip: "الطلبات التي حالتها بإنتظار موافقة مدير التمويل" },
        { text: "%تمويل", value: "%تمويل", align: "center" , tooltip: "نسبة المرفوع إلى مدير التمويل إلى القبول الأولي" },
        { text: "مرفوعه رهن", value: "مرفوعه رهن", align: "center" , tooltip: "فى حالة كان الطلب نوعه رهن وقام مدير المبيعات برفعه يصل لمدير الرهن" },
        { text: "مرفوضة رهن", value: "مرفوضة رهن", align: "center" , tooltip: "فى حال رفض الطلب من قبل مدير الرهن يعود الطلب لمدير المبيعات" },
        { text: "قائم رهن", value: "قائم رهن", align: "center" , tooltip: "الطلبات التي حالتها بإنتظار موافقة مدير الرهن" },
        { text: "%رهن", value: "%رهن", align: "center" , tooltip: "نسبة المرفوع إلى مدير الرهن إلى القبول الأولي" },
        { text: "مفرغة", value: "مفرغة", align: "center" , tooltip: "الطلب أفرغ من قبل المدير العام" },
        { text: "%مفرغة", value: "%مفرغة", align: "center"  , tooltip: "نسبة المفرغة إلى الطلبات المستقبلة"},
        // { text: "التحكم", value: "التحكم" },
      ],
    };
  },

  watch: {
    page(val) {
      this.getData(val, "loading");
    },

    "$store.state.counterOfShow": {
      handler(newval, oldvalue) {
        if (newval !== oldvalue && !this.$store.state.admin_search_active) {
          this.getData(this.page);
        }
      },
    },

    "$store.state.rates_in_admin_count": {
      handler(val) {
        if (val > this.$store.state.redirect_count_of_rates && localStorage["role"] == "1") {
          this.$store.state.showAlertOfRatingDidntProccess = true;
          this.$router.push({ name: "salesManager_SMRateServices" });
        }
      },
      immediate: true
    },

    $route: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.$store.state.counterOfShow = 10;
        }
      },
    },

  },

  methods: {
    removeRow(headerValue) {
      let findIndex = this.tableHeaders.findIndex((item) => item.text === headerValue);

      if (findIndex !== -1) {
        this.tableHeaders.splice(findIndex, 1);
        this.showArray.splice(findIndex, 0, {
          id: findIndex,
          value: headerValue,
        });
      } else {
        let foundInsertedItem = this.options.findIndex((item) => item === headerValue);

        this.showArray.sort((a, b) => a.id - b.id);
        this.showArray.map((res, index) => {
          if (res.value === headerValue) {
            this.tableHeaders.splice(foundInsertedItem - (index - 1), 0, {
              text: headerValue,
              value: headerValue,
            });
            this.showArray.splice(index, 1);
          }
        });
      }
    },

    handleFilter(val) {
      this.body = new FormData();
      if (val.startDate) {
        this.body.append("start_date", val.startDate);
      }/*else{
        val.start_date =this.yesterday; // default
        this.body.append("start_date", this.yesterday);

      }*/

      if (val.endDate) {
        this.body.append("end_date", val.endDate);
      }/*else{
        val.end_date = this.today; // default
        this.body.append("end_date", this.today);

      }*/
      if (val.status == undefined || val.status ==""){
        val.status = "استشاري قائم"; // default
        this.body.append("status", 1);
      }
      if (val.status === "استشاري قائم") {
        this.body.append("status", 1);
      }

      if (val.status === "استشاري مؤرشف") {
        this.body.append("status", 0);
      }

      if (val.range) {
        this.body.append("range", val.range);
      }

      if (val.managerId.length) {
        const managerProperties = {};

        val.managerId.forEach((item, index) => {
          this.body.append([`manager_id[${index}]`], item.id);
        });
      }

      if (val.agentId.length) {
        const managerProperties = {};

        val.agentId.forEach((item, index) => {
          this.body.append([`adviser_id[${index}]`], item.id);
        });
      }

      if (val.dayNumber) {
        this.body.append("range", val.dayNumber);
      }

      this.getData(1, "getDataDone");
    },

    getData(page, loader) {
      if (loader === "getDataDone") {
        this.getDataDone = true;
      } else if (loader === "loading") {
        this.loading = true;
      }
      this.options = [];
      this.tableData = [];
      let url = `${this.$store.state.url}/employee/admin/getProductivity?page=${page}`;

      Axios.post(url, this.body, { headers: this.request_headers() }).then((res) => {
        this.setTableData(res.data.payload.data);
        this.setOptions();
        this.chartData = res.data.payload;
        this.$store.state.pagination = {
          limit: 3,
          count: res.data.payload.total_agents,
        };
        this.loading = false;
        this.getDataDone = false;
      });
    },

    downloadExcelFile() {

      let url = `${this.$store.state.url}/salesmanager-reports/productivity`;

      Axios.post(url, this.body, { headers: this.request_headers() })

        .then((data) => {
          console.log('data', data.data.url);
          window.open(data.data.url,'_blank');
      });
    },

    setOptions() {
      this.tableHeaders.map((item, index) => {
        if (item.text !== "الاستشاري" && item.text !== "التحكم") {
          this.options.push(item.text);
        }
      });
    },

    setTableData(data) {
      data.map((res) => {
        this.tableData.push({
          "الاستشاري": res.name,
          "المستقبلة": res.total_received_request?.count,
          "قبول أولي": res.followed_basket_possible_to_sell?.count,
          "المتابعه": res.possible_to_sell_to_customer_count?.count,
          "%المتابعه": res.followed_basket_possible_to_sell?.percentage,
          "مرفوعه مبيعات": res.sent_basket?.count,
          "مرفوضه مبيعات": res.sent_basket_reject?.count,
          "%مبيعات": res.sent_basket?.percentage,

          "مرفوعه تمويل": res.sent_basket_funding?.count,
          "مرفوضة تمويل": res.sent_basket_funding_reject?.count,
          "قائم تمويل": res.waiting_fm_status?.count,
          "%تمويل": res.waiting_fm_status?.percentage,

          "مرفوعه رهن": res.sent_basket_mortgage?.count,
          "مرفوضة رهن": res.sent_basket_mortgage_reject?.count,
          "قائم رهن": res.waiting_mm_status?.count,
          "%رهن": res.waiting_mm_status?.percentage,
          "مفرغة": res.completed_request?.count,
          "%مفرغة": res.completed_request?.percentage,
        });
      });
    },
  },

  created() {
    this.handleFilter({});
    this.getData(1, "getDataDone");
    this.$store.state.pagination = {};
  },
};
</script>
