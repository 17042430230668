<template>
  <v-row justify="center">
    <v-dialog v-model="$store.state.FMSendRequestToBankDialog" persistent width="624">
      <v-card>
        <v-card-title>
          <h3>ارسال الطلب الي موظفي البنك</h3>
        </v-card-title>
        <v-card-text>
          <v-data-table v-if="!$store.state.bankUsers.isEmpty" :items="$store.state.bankUsers.data" :headers="headers" item-value="id" class="elevation-1">
            <template v-slot:body="{ items }">
              <tbody>
                <tr v-for="user in items" :key="user.id">
                  <td>{{ user.name }}</td>
                  <td>
                    <v-btn color="success"
                      class="mx-2"
                      v-if="(user.has_wsata_request && user.wsataRequest[0].req_type !== 'close') || !user.has_wsata_request"
                      :disabled="user.has_wsata_request"
                      @click="sendRequestToBank(user.id, 'send')">ارسال
                    </v-btn>

                    <v-btn color="primary" 
                      class="mx-2"
                      v-if="(user.has_wsata_request && (user.wsataRequest[0].req_type == 'send' || user.wsataRequest[0].req_type == 'push')) || !user.has_wsata_request"
                      :disabled="!user.has_wsata_request"
                      @click="sendRequestToBank(user.id, 'pull')">سحب
                    </v-btn>

                    <v-btn color="warning" 
                      class="mx-2"
                      v-if="user.has_wsata_request && user.wsataRequest[0].req_type == 'pull'"
                      :disabled="!user.has_wsata_request"
                      @click="sendRequestToBank(user.id, 'push')">ارجاع
                    </v-btn>

                    <v-btn color="error" 
                      class="mx-2"
                      v-if="(user.has_wsata_request && user.wsataRequest[0].req_type != 'close') || !user.has_wsata_request"
                      :disabled="!user.has_wsata_request"
                      @click="sendRequestToBank(user.id, 'close')">افراغ
                    </v-btn>
               
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>

          <h2 v-else class="text-center">عفوا هذا الطلب تم افراغه</h2>
        </v-card-text>
        <v-card-actions class="pl-sm-and-down-2 excluded">
          <v-spacer></v-spacer>
          <v-btn color="#5A6268" style="color: white;" variant="text" @click="$store.state.FMSendRequestToBankDialog = false">
            الغاء
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Axios from "axios";
import { axiosInstance } from "@/axios/config";

export default {
  name: "FMSendRequestToBankDialog",
  props: ["reqId"],
  data() {
    return {
      comment: "",
      headers: [
        { text: "اسم الموظف", value: "name", align: "center" },
        { text: "الإجراءات", value: "actions", sortable: false, align: "center"  },
      ],
    };
  },
  methods: {
    sendRequestToBank(user_id, type) {
      let req_id = "";
      req_id = parseInt(this.reqId);
      axiosInstance.get(`/employee/funding-manager/sendRequestToBank/${user_id}/${req_id}/${type}`, { headers: this.request_headers(), })
        .then((res) => {
          this.showPopup("success", res.data.message);
        })
        .catch((err) => {
          this.showPopup("error", err.response.data.message);
        });
    },
  },
};
</script>