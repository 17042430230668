import RequestDetails from "@/components/RequestDetails/RequestDetails.vue";
import AgentData from "@/components/RequestDetails/AgentData.vue";
import EstateData from "@/components/RequestDetails/EstateData.vue";
import FundData from "@/components/RequestDetails/FundData.vue";
import FundCalc from "@/components/RequestDetails/FundCalc.vue";
import RequestAttachments from "@/components/RequestDetails/RequestAttachments.vue";
import PrePayment from "@/components/RequestDetails/PrePayment.vue";
// import RequestLog from "@/components/RequestDetails/RequestLog.vue";
import RequestLog from "@/components/RequestDetails/RequestLogNew.vue";
import RequestTickets from "@/components/RequestDetails/RequestTickets.vue";
import RequestCalls from "@/components/RequestDetails/RequestCalls.vue";
import NotFound from "@/views/agent2/NotFound.vue";
import AlternativeVacation from "@/views/agent2/Vacations/StatusVacation.vue";
import QuestionAndAnswer2 from "@/views/agent2/questionAndAnswer2/PostDataDetails.vue";


export const commonRouter = [
    {
        path: "/requests/:id",
        name: "request_details",
        component: RequestDetails,
        meta: { icon: "", listed: false, page_name: "تفاصيل الطلب" },
        redirect: { name: "agent_data" },
        children: [
            {
                path: "agent-data",
                name: "agent_data",
                component: AgentData,
                meta: { icon: "", listed: false },
            },
            {
                path: "real-estate-data",
                name: "real_estate_data",
                component: EstateData,
                meta: { icon: "", listed: false },
            },
            {
                path: "fund-data",
                name: "fund_data",
                component: FundData,
                meta: { icon: "", listed: false },
            },
            {
                path: "fund-calculator",
                name: "fund_calc",
                component: FundCalc,
                meta: { icon: "", listed: false },
            },
            {
                path: "attachments",
                name: "attachments",
                component: RequestAttachments,
                meta: { icon: "", listed: false },
            },
            {
                path: "prepayment",
                name: "prepayment",
                component: PrePayment,
                meta: { icon: "", listed: false },
            },
            {
                path: "logs",
                name: "logs",
                component: RequestLog,
                meta: { icon: "", listed: false },
            },
            {
                path: "tickets",
                name: "tickets",
                component: RequestTickets,
                meta: { icon: "", listed: false },
            },
            {
                path: "calls",
                name: "calls",
                component: RequestCalls,
                meta: { icon: "", listed: false },
            },
        ],
    },

    {
        path: "/user/questionnaire-details/data/:id",
        name: 'questionnaire_details',
        component: QuestionAndAnswer2,
        meta: { icon: "", listed: false, page_name: "تفاصيل الاستبيان" },
    },

    {
        path: "/:pathMatch(.*)*",
        name: "NotFound",
        component: NotFound,
        meta: {
            title: "Page 404 Not Found",
        },
    },
    {
        path: "/vacations/status/:id",
        name: 'alternative_vacation_details',
        component: AlternativeVacation,
        meta: {},
    },
]