<template>
  <div class="mainContainer">
    <div style="width: 100%; margin-top: 0px !important" class="loginContainer">
      <div style="width: 50%; float: left; background: #f5f2f4; padding: 8%">
        <form @submit.prevent="validate">
          <div style="display: flex">
            <div>
              <img src="@/assets/Layer 2.svg" />
            </div>
          </div>
          <hr style="border: 1px solid #e9e6e8; margin-top: 25px" />
          <p style="font-size: 16px; color: #2f394e">تسجيل الدخول</p>
          <p
            style="
              font-weight: bold;
              line-height: 50%;
              font-size: 24px;
              margin-bottom: 37px;
            "
          >
            اهلا بك مجددا
          </p>
          <p
            style="margin-top: 30px; margin-bottom: 22px; font-size: 15px; color: #2f394e"
          >
            اسم المستخدم
          </p>

          <v-text-field
            placeholder="الاسم كامل"
            solo
            v-model="username"
            name="username"
          ></v-text-field>
          <span class="username-err">{{ errors.username }}</span>

          <p style="font-size: 15px; margin-bottom: 22px; color: #2f394e">كلمة المرور</p>
          <div>
            <v-text-field
              placeholder="كلمة المرور"
              solo
              name="password"
              v-model="password"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show1 ? 'text' : 'password'"
              @click:append="show1 = !show1"
            ></v-text-field>
          </div>
          <span class="username-err">{{ errors.password }}</span>

          <v-btn
            class="ma-2"
            style="
              background: #622663 !important;
              width: 100%;
              margin: 0px !important;
              height: 50px;
            "
            dark
            :loading="loginLoad"
            type="submit"
          >
            تسجيل الدخول
            <v-icon dark left style="margin-right: 10px; margin-top: 5px">
              mdi-arrow-left
            </v-icon>
          </v-btn>
        </form>
      </div>
      <div
        style="
          margin-left: 50%;
          height: 745px;
          background: #5c315d;
          display: flex;
          justify-content: center;
          align-items: center;
        "
      >
        <div class="bg-image img5"></div>
        <img src="@/assets/loginPhoto.svg" style="position: absolute" />
      </div>
    </div>

    <div class="screenMobileMedia">
      <div
        style="
          display: flex;
          width: 100vw;
          height: 285px;
          background-image: linear-gradient(#451546, #814f82);
          position: relative;
          justify-content: center;
        "
      >
        <div class="bg-image img4"></div>
        <div style="position: absolute; top: 10%">
          <img src="@/assets/LayerMobile.svg" />
        </div>
        <div style="position: absolute; top: 30%">
          <img src="@/assets/loginPhotoMobile.svg" />
        </div>
      </div>

      <div style="background: white; border: 1px solid #f0f0f0; padding: 5%">
        <form @submit.prevent="validate">
          <p style="font-size: 16px; color: #2f394e">تسجيل الدخول</p>
          <p
            style="
              font-weight: bold;
              line-height: 50%;
              font-size: 24px;
              margin-bottom: 37px;
            "
          >
            اهلا بك مجددا
          </p>
          <p
            style="margin-top: 50px; margin-bottom: 22px; font-size: 15px; color: #2f394e"
          >
            اسم المستخدم
          </p>

          <v-text-field
            placeholder="الاسم كامل"
            solo
            v-model="username"
            name="username"
          ></v-text-field>
          <span class="username-err">{{ errors.username }}</span>

          <p style="font-size: 15px; margin-bottom: 22px; color: #2f394e">كلمة المرور</p>
          <div>
            <v-text-field
              placeholder="كلمة المرور"
              solo
              name="password"
              v-model="password"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show1 ? 'text' : 'password'"
              @click:append="show1 = !show1"
            ></v-text-field>
          </div>
          <span class="username-err">{{ errors.password }}</span>

          <v-btn
            class="ma-2"
            style="
              background-image: linear-gradient(to right, #6d2e6e, #421143);
              width: 100%;
              margin-top: 12px !important;
              margin: 0px !important;
              height: 50px;
            "
            dark
            :loading="loginLoad"
            type="submit"
          >
            تسجيل الدخول
            <v-icon dark left style="margin-right: 10px; margin-top: 5px">
              mdi-arrow-left
            </v-icon>
          </v-btn>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
export default {
  data() {
    return {
      username: "",
      password: "",
      loginLoad: false,
      errors: {
        username: "",
        password: "",
      },
      show1: false,
    };
  },
  methods: {
    async submitSignin(uname, pass) {
      this.loginLoad = true;
      this.errors.password = "";
      const data = {
        username: uname,
        password: pass,
      };
      await Axios.post(
        `${this.$store.state.url}/agent/login`,
        data,
        {
          headers: {
            "x-api-key":
              "WFKvB4Vjr8v5BVmxY7CDQ6ZMAU8DSHSBFKCMVpkfd5hhZyKTvnV3uh5XwxSwhnfeUbptZ7Z4XFJCR4vKXeHWGHTZ6djsYXjdAatf",
          },
        }
      )
        .then((res) => {
          let expireTime = new Date();
          // expireTime = expireTime.setHours(expireTime.getHours() + 12);
          // document.cookie =
          //   `wsataJwt=${JSON.stringify(res.data.payload)};expires=${Date(
          //     expireTime
          //   )};path=/;domain=` +
          //   window.location.hostname +
          //   `;`;

          expireTime.setTime(expireTime.getTime() + 12 * 60 * 60 * 1000);

          // Format the expiration time into a string in the required format
          let expiresString = `expires=${expireTime.toUTCString()}`;

          // Set the cookie with proper attributes
          document.cookie = `wsataJwt=${JSON.stringify(
            res.data.payload
          )}; ${expiresString}; path=/; domain=${window.location.hostname};`;

          this.errors.password = "";
          localStorage.setItem("avatar", res.data.payload.avatar);
          localStorage.setItem("loggedIn_user_name",res.data.payload.agent_name);
          localStorage.setItem("hidden_baskets",JSON.stringify(res.data.payload.hidden_baskets));

          localStorage.setItem("role", res.data.payload.role);
          localStorage.setItem("id", res.data.payload.agent_id);
          if (res.data.payload.role === 7) {
            localStorage.setItem("isAdmin", true);
            localStorage.setItem("token", res.data.payload);
          } else {
            localStorage.removeItem("isAdmin");
          }
        })
        .then(() => {
          document.querySelectorAll("header").forEach((head) => {
            head.style.display = "block";
          });
          window.location.reload();
          this.loginLoad = false;
          this.$store.state.loggedIn = true;
          if (localStorage.getItem("role") == "1") {
            this.$router.push("/salesManager");
            // this.$router.go("/salesManager/requests/all");
          } else if (localStorage.getItem("role") == "0") {
            this.$router.go("/agent/requests/all");
          } else if (localStorage.getItem("role") == "7") {
            this.$router.push("/admin");
          } else if (localStorage.getItem("role") == "5") {
            this.$router.push("/quality/requests/all");
          } else if (localStorage.getItem("role") == "2") {
            this.$router.push("/funding-manager");
          } else {
            this.$router.push("/");
          }
        })
        .catch(() => {
          this.errors.password = "الحساب غير متوفر ";
          this.loginLoad = false;
        });
    },
    async validate() {
      document.forms[0].username.style.border = "none";
      document.forms[0].password.style.border = "none";
      this.errors.username = "";
      this.errors.password = "";
      if (!this.username) {
        this.errors.username = "الحقل مطلوب";
      } else if (!this.password) {
        this.errors.password = "الحقل مطلوب";
      } else {
        await this.submitSignin(this.username, this.password);
      }
    },
  },
  mounted() {
    document.querySelectorAll("header").forEach((head) => {
      head.style.display = "none";
    });
  },
};
</script>

<style>
@media only screen and (max-width: 1114px) {
  .mainContainer {
    overflow: hidden;
  }

  .loginContainer {
    display: none;
  }

  .screenMobileMedia {
    display: block;
  }
}

@media only screen and (min-width: 1114px) {
  .loginContainer {
    display: block;
  }

  .screenMobileMedia {
    display: none;
  }
}

.mainContainer__userNameInput {
  background-color: red;
}
.username-err {
  color: darkred;
  font-size: 12px;
  font-weight: 700;
  text-align: end;
  display: block;
  margin-bottom: 20px;
  margin-top: -20px;
}

.loginContainer .v-input__slot {
  border: 1px solid #e7e6e7 !important;
  height: 55px;
  /* padding-right: 1px !important; */
}

.screenMobileMedia .v-input__slot {
  border: 2px solid #ecedec !important;
  height: 55px;
  /* padding-right: 1px !important; */
}

.loginContainer .v-text-field.v-text-field--solo ::placeholder,
.screenMobileMedia .v-text-field.v-text-field--solo ::placeholder {
  font-size: 14px;
  font-weight: bold;
  color: #2f394e;
  padding-right: 10px;
}

.loginContainer .v-icon.v-icon.v-icon--link,
.screenMobileMedia .v-icon.v-icon.v-icon--link {
  color: #926b93 !important;
  margin-bottom: 20px;
}

.loginContainer .v-input__icon,
.screenMobileMedia .v-input__icon {
  visibility: visible !important;
}

.container {
  margin-top: 0px !important;
}

.loginContainer .v-btn__content,
.screenMobileMedia .v-btn__content {
  font-weight: bold;
  font-size: 16px;
}

.loginContainer .content-container,
.screenMobileMedia .content-container {
  margin-top: 0px !important;
}

.loginContainer,
.screenMobileMedia {
  font-family: DIN Next LT Arabic;
}

.bg-image {
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.img5 {
  background-image: url("../../assets/scrollGroup.png");
  width: 100%;
}

.img4 {
  background-image: url("../../assets/scrollGroubMobile.png");
  width: 100%;
}
</style>
