<template>
  <v-menu offset-y nudge-top="-20">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" height="32px" width="50px" style="min-width: unset" class="ml-5 mr-3 p-0"
        color="#FFFFFF" depressed>
        <v-icon color="#3F0E40" size="40">mdi-dots-horizontal</v-icon>
      </v-btn>
    </template>

    <v-list>

      <v-list-item @click="reqarchive" v-if="request_details_route && showArchiveButton"
        class="black--text font-weight-bold">
        <v-list-item-icon class="ml-2">
          <svg xmlns="http://www.w3.org/2000/svg" width="20.227" height="20.227" viewBox="0 0 20.227 20.227">
            <g id="Icon_feather-send" data-name="Icon feather-send" transform="translate(-2.5 -2.293)">
              <path id="Path_4004" data-name="Path 4004" d="M26.961,3,16.5,13.461" transform="translate(-4.941)"
                fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
              <path id="Path_4005" data-name="Path 4005" d="M22.02,3,15.363,22.02l-3.8-8.559L3,9.657Z" fill="none"
                stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
            </g>
          </svg>
        </v-list-item-icon>
        <v-list-item-title>تحويل الطلب للمتابعة</v-list-item-title>
      </v-list-item>

      <v-list-item @click="save_Request" v-if="request_details_route && showUpdateButton"
        class="black--text font-weight-bold">
        <v-list-item-icon class="ml-2">
          <svg xmlns="http://www.w3.org/2000/svg" width="19.55" height="19.55" viewBox="0 0 19.55 19.55">
            <g id="Icon_feather-save" data-name="Icon feather-save" transform="translate(-4 -4)">
              <path id="Path_4006" data-name="Path 4006"
                d="M20.989,23.05H6.561A2.061,2.061,0,0,1,4.5,20.989V6.561A2.061,2.061,0,0,1,6.561,4.5H17.9L23.05,9.653V20.989A2.061,2.061,0,0,1,20.989,23.05Z"
                fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
              <path id="Path_4007" data-name="Path 4007" d="M20.805,27.744V19.5H10.5v8.244"
                transform="translate(-1.878 -4.695)" fill="none" stroke="#000" stroke-linecap="round"
                stroke-linejoin="round" stroke-width="1" />
              <path id="Path_4008" data-name="Path 4008" d="M10.5,4.5V9.653h8.244" transform="translate(-1.878)"
                fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
            </g>
          </svg>
        </v-list-item-icon>
        <v-list-item-title>حفظ الطلب </v-list-item-title>
      </v-list-item>

      <v-list-item @click="reject_Request" v-if="request_details_route && showRejectButton"
        class="black--text font-weight-bold">
        <v-list-item-icon class="ml-2">
          <svg xmlns="http://www.w3.org/2000/svg" width="19.55" height="19.55" viewBox="0 0 19.55 19.55">
            <g id="Icon_feather-save" data-name="Icon feather-save" transform="translate(-4 -4)">
              <path id="Path_4006" data-name="Path 4006"
                d="M20.989,23.05H6.561A2.061,2.061,0,0,1,4.5,20.989V6.561A2.061,2.061,0,0,1,6.561,4.5H17.9L23.05,9.653V20.989A2.061,2.061,0,0,1,20.989,23.05Z"
                fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
              <path id="Path_4007" data-name="Path 4007" d="M20.805,27.744V19.5H10.5v8.244"
                transform="translate(-1.878 -4.695)" fill="none" stroke="#000" stroke-linecap="round"
                stroke-linejoin="round" stroke-width="1" />
              <path id="Path_4008" data-name="Path 4008" d="M10.5,4.5V9.653h8.244" transform="translate(-1.878)"
                fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
            </g>
          </svg>
        </v-list-item-icon>
        <v-list-item-title>رفض الطلب </v-list-item-title>
      </v-list-item>

      <v-list-item @click="sendFunding" v-if="request_details_route && showSendButton"
        class="black--text font-weight-bold">
        <v-list-item-icon class="ml-2">
          <svg xmlns="http://www.w3.org/2000/svg" width="20.227" height="20.227" viewBox="0 0 20.227 20.227">
            <g id="Icon_feather-send" data-name="Icon feather-send" transform="translate(-2.5 -2.293)">
              <path id="Path_4004" data-name="Path 4004" d="M26.961,3,16.5,13.461" transform="translate(-4.941)"
                fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
              <path id="Path_4005" data-name="Path 4005" d="M22.02,3,15.363,22.02l-3.8-8.559L3,9.657Z" fill="none"
                stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
            </g>
          </svg>
        </v-list-item-icon>
        <v-list-item-title>ارسال الطلب </v-list-item-title>
      </v-list-item>

      
      <!-- <v-list-item @click="sendRequestToBank" v-if="request_details_route && showSendToBankButton" -->
      <v-list-item @click="sendRequestToBank" v-if="request_details_route"
        class="black--text font-weight-bold">
        <v-list-item-icon class="ml-2">
          <svg xmlns="http://www.w3.org/2000/svg" width="20.227" height="20.227" viewBox="0 0 20.227 20.227">
            <g id="Icon_feather-send" data-name="Icon feather-send" transform="translate(-2.5 -2.293)">
              <path id="Path_4004" data-name="Path 4004" d="M26.961,3,16.5,13.461" transform="translate(-4.941)"
                fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
              <path id="Path_4005" data-name="Path 4005" d="M22.02,3,15.363,22.02l-3.8-8.559L3,9.657Z" fill="none"
                stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
            </g>
          </svg>
        </v-list-item-icon>
        <v-list-item-title>ارسال الطلب الي موظف البنك </v-list-item-title>
      </v-list-item>

      <v-list-item @click="addUnderProcess" v-if="request_details_route && showAddToFinancialReportButton"
        class="black--text font-weight-bold">
        <v-list-item-icon class="ml-2">
          <svg xmlns="http://www.w3.org/2000/svg" width="20.227" height="20.227" viewBox="0 0 20.227 20.227">
            <g id="Icon_feather-send" data-name="Icon feather-send" transform="translate(-2.5 -2.293)">
              <path id="Path_4004" data-name="Path 4004" d="M26.961,3,16.5,13.461" transform="translate(-4.941)"
                fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
              <path id="Path_4005" data-name="Path 4005" d="M22.02,3,15.363,22.02l-3.8-8.559L3,9.657Z" fill="none"
                stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
            </g>
          </svg>
        </v-list-item-icon>
        <v-list-item-title>اضافة الي التقارير الماليه </v-list-item-title>
      </v-list-item>

      <v-list-item @click="removeUnderProcess" v-if="request_details_route && showRemoveFromFinancialReportButton"
        class="black--text font-weight-bold">
        <v-list-item-icon class="ml-2">
          <svg xmlns="http://www.w3.org/2000/svg" width="19.55" height="19.55" viewBox="0 0 19.55 19.55">
            <g id="Icon_feather-save" data-name="Icon feather-save" transform="translate(-4 -4)">
              <path id="Path_4006" data-name="Path 4006"
                d="M20.989,23.05H6.561A2.061,2.061,0,0,1,4.5,20.989V6.561A2.061,2.061,0,0,1,6.561,4.5H17.9L23.05,9.653V20.989A2.061,2.061,0,0,1,20.989,23.05Z"
                fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
              <path id="Path_4007" data-name="Path 4007" d="M20.805,27.744V19.5H10.5v8.244"
                transform="translate(-1.878 -4.695)" fill="none" stroke="#000" stroke-linecap="round"
                stroke-linejoin="round" stroke-width="1" />
              <path id="Path_4008" data-name="Path 4008" d="M10.5,4.5V9.653h8.244" transform="translate(-1.878)"
                fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
            </g>
          </svg>
        </v-list-item-icon>
        <v-list-item-title>حذف من التقارير الماليه </v-list-item-title>
      </v-list-item>

      <v-list-item @click="approveMorPurRequest" v-if="request_details_route && showApprovetButton"
        class="black--text font-weight-bold">
        <v-list-item-icon class="ml-2">
          <svg xmlns="http://www.w3.org/2000/svg" width="19.55" height="19.55" viewBox="0 0 19.55 19.55">
            <g id="Icon_feather-save" data-name="Icon feather-save" transform="translate(-4 -4)">
              <path id="Path_4006" data-name="Path 4006"
                d="M20.989,23.05H6.561A2.061,2.061,0,0,1,4.5,20.989V6.561A2.061,2.061,0,0,1,6.561,4.5H17.9L23.05,9.653V20.989A2.061,2.061,0,0,1,20.989,23.05Z"
                fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
              <path id="Path_4007" data-name="Path 4007" d="M20.805,27.744V19.5H10.5v8.244"
                transform="translate(-1.878 -4.695)" fill="none" stroke="#000" stroke-linecap="round"
                stroke-linejoin="round" stroke-width="1" />
              <path id="Path_4008" data-name="Path 4008" d="M10.5,4.5V9.653h8.244" transform="translate(-1.878)"
                fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
            </g>
          </svg>
        </v-list-item-icon>
        <v-list-item-title>قبول الطلب </v-list-item-title>
      </v-list-item>

      <!-- payment send and update start -->

      <v-list-item @click="createPrepayment" v-if="request_details_route && showCreatePrepaymentButton"
        class="black--text font-weight-bold">
        <v-list-item-icon class="ml-2">
          <svg xmlns="http://www.w3.org/2000/svg" width="19.55" height="19.55" viewBox="0 0 19.55 19.55">
            <g id="Icon_feather-save" data-name="Icon feather-save" transform="translate(-4 -4)">
              <path id="Path_4006" data-name="Path 4006"
                d="M20.989,23.05H6.561A2.061,2.061,0,0,1,4.5,20.989V6.561A2.061,2.061,0,0,1,6.561,4.5H17.9L23.05,9.653V20.989A2.061,2.061,0,0,1,20.989,23.05Z"
                fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
              <path id="Path_4007" data-name="Path 4007" d="M20.805,27.744V19.5H10.5v8.244"
                transform="translate(-1.878 -4.695)" fill="none" stroke="#000" stroke-linecap="round"
                stroke-linejoin="round" stroke-width="1" />
              <path id="Path_4008" data-name="Path 4008" d="M10.5,4.5V9.653h8.244" transform="translate(-1.878)"
                fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
            </g>
          </svg>
        </v-list-item-icon>
        <v-list-item-title>انشاء الدفعة</v-list-item-title>
      </v-list-item>

      <v-list-item @click="updatePrepayment" v-if="request_details_route && showUpdatePrepaymentButton"
        class="black--text font-weight-bold">
        <v-list-item-icon class="ml-2">
          <svg xmlns="http://www.w3.org/2000/svg" width="19.55" height="19.55" viewBox="0 0 19.55 19.55">
            <g id="Icon_feather-save" data-name="Icon feather-save" transform="translate(-4 -4)">
              <path id="Path_4006" data-name="Path 4006"
                d="M20.989,23.05H6.561A2.061,2.061,0,0,1,4.5,20.989V6.561A2.061,2.061,0,0,1,6.561,4.5H17.9L23.05,9.653V20.989A2.061,2.061,0,0,1,20.989,23.05Z"
                fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
              <path id="Path_4007" data-name="Path 4007" d="M20.805,27.744V19.5H10.5v8.244"
                transform="translate(-1.878 -4.695)" fill="none" stroke="#000" stroke-linecap="round"
                stroke-linejoin="round" stroke-width="1" />
              <path id="Path_4008" data-name="Path 4008" d="M10.5,4.5V9.653h8.244" transform="translate(-1.878)"
                fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
            </g>
          </svg>
        </v-list-item-icon>
        <v-list-item-title>حفظ الدفعة</v-list-item-title>
      </v-list-item>

      <v-list-item @click="sendPrepayment" v-if="request_details_route && showSendPrepaymentButton"
        class="black--text font-weight-bold">
        <v-list-item-icon class="ml-2">
          <svg xmlns="http://www.w3.org/2000/svg" width="20.227" height="20.227" viewBox="0 0 20.227 20.227">
            <g id="Icon_feather-send" data-name="Icon feather-send" transform="translate(-2.5 -2.293)">
              <path id="Path_4004" data-name="Path 4004" d="M26.961,3,16.5,13.461" transform="translate(-4.941)"
                fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
              <path id="Path_4005" data-name="Path 4005" d="M22.02,3,15.363,22.02l-3.8-8.559L3,9.657Z" fill="none"
                stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
            </g>
          </svg>
        </v-list-item-icon>
        <v-list-item-title>ارسال الدفعة</v-list-item-title>
      </v-list-item>

      <v-list-item @click="cancelPrepayment" v-if="request_details_route && showCancelPrepaymentButton"
        class="black--text font-weight-bold">
        <v-list-item-icon class="ml-2">
          <svg xmlns="http://www.w3.org/2000/svg" width="20.227" height="20.227" viewBox="0 0 20.227 20.227">
            <g id="Icon_feather-send" data-name="Icon feather-send" transform="translate(-2.5 -2.293)">
              <path id="Path_4004" data-name="Path 4004" d="M26.961,3,16.5,13.461" transform="translate(-4.941)"
                fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
              <path id="Path_4005" data-name="Path 4005" d="M22.02,3,15.363,22.02l-3.8-8.559L3,9.657Z" fill="none"
                stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
            </g>
          </svg>
        </v-list-item-icon>
        <v-list-item-title>الغاء الدفعة</v-list-item-title>
      </v-list-item>


      <v-list-item @click="approvePrepayment" v-if="request_details_route && showApprovePrepaymentButton"
        class="black--text font-weight-bold">
        <v-list-item-icon class="ml-2">
          <svg xmlns="http://www.w3.org/2000/svg" width="20.227" height="20.227" viewBox="0 0 20.227 20.227">
            <g id="Icon_feather-send" data-name="Icon feather-send" transform="translate(-2.5 -2.293)">
              <path id="Path_4004" data-name="Path 4004" d="M26.961,3,16.5,13.461" transform="translate(-4.941)"
                fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
              <path id="Path_4005" data-name="Path 4005" d="M22.02,3,15.363,22.02l-3.8-8.559L3,9.657Z" fill="none"
                stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
            </g>
          </svg>
        </v-list-item-icon>
        <v-list-item-title>قبول الدفعة</v-list-item-title>
      </v-list-item>

    </v-list>
  </v-menu>
</template>

<script>
import Axios from "axios";
import { axiosInstance } from "@/axios/config";
import moment from "moment";
export default {
  name: "ReciveIcon",

  data() {
    return {

    };
  },

  computed: {
    request_details_route() {
      return (
        this.$route.name == "agent_data" ||
        this.$route.name == "real_estate_data" ||
        this.$route.name == "fund_data" ||
        this.$route.name == "fund_calc" ||
        this.$route.name == "attachments" ||
        this.$route.name == "logs" ||
        this.$route.name == "tickets" ||
        this.$route.name == "prepayment" ||
        this.$route.name == "request_details" ||
        this.$route.name == "calls"
      );
    },
    requests_routes() {
      return (
        this.$route.name == "fundingManagerAll" ||
        this.$route.name == "fundingManagerRecieved" ||
        this.$route.name == "fundingManagerFinanceReport"
      );
    },

    showArchiveButton() {
      return (
        this.$store.state.request_info.statusReq != 8 && [6, 13].includes(this.$store.state.request_info.statusReq)
      );
    },
    showUpdateButton() {
      return (
        [6, 8, 13, 21, 25].includes(this.$store.state.request_info.statusReq)
      );
    },
    showRejectButton() {
      return (
        [6, 8, 13].includes(this.$store.state.request_info.statusReq)
      );
    },
    showSendButton() {
      return (
        [6, 8, 13].includes(this.$store.state.request_info.statusReq)
      );
    },

    showSendToBankButton() {
      return (
        !this.$store.state.bankUsers.isEmpty
      );
    },

    showAddToFinancialReportButton() {
      return (
        this.$store.state.request_info.isUnderProcFund == 0
      );
    },

    showRemoveFromFinancialReportButton() {
      return (
        this.$store.state.request_info.isUnderProcFund == 1
      );
    },

    showApprovetButton() {
      return (
        [6, 21, 25].includes(this.$store.state.request_info.statusReq)
      );
    },

    showCreatePrepaymentButton() {
      return (
        !this.$store.state.request_info.prepayments && ['شراء','رهن-شراء'].includes(this.$store.state.request_info.type)
      );
    },
    showUpdatePrepaymentButton() {
      return (
        this.$store.state.request_info.prepayments != null && [0,3].includes(this.$store.state.request_info.prepayments.payStatus)
      );
    },
    showSendPrepaymentButton() {
      return (
        this.$store.state.request_info.prepayments != null && [0, 3].includes(this.$store.state.request_info.prepayments.payStatus)
      );
    },
    showCancelPrepaymentButton() {
      return (
        this.$store.state.request_info.prepayments != null && ['شراء', 'شراء-دفعة', 'رهن-شراء'].includes(this.$store.state.request_info.type) && ![2,9].includes(this.$store.state.request_info.prepayments.payStatus)
      );
    },

    showApprovePrepaymentButton() {
      return (
        this.$store.state.request_info.prepayments != null && this.$store.state.request_info.prepayments.payStatus == 7
      );
    },
  },

  methods: {

    // Update Request 
    save_Request() {
      console.log("iam here");
      this.saveErrs = {
        alert: false,
        error: [],
      };
      let payload = this.$store.state.request_info;
      //check request
      let check_class = true;
      if (parseInt(payload.class_id_agent) == 62) {
        // أجل التواصل
        if (payload.follow_date == null || payload.follow_date == "") {
          check_class = false;
          this.showPopup("error", "تاريخ المتابعة مطلوب في تصنيف أجل التواصل");
        } else if (payload.follow_date < moment(new Date()).format("YYYY-MM-DD")) {
          check_class = false;
          this.showPopup("error", "تاريخ المتابعة ينبغي أن يكون أكبر من التاريخ الحالي");
        } else if (payload.follow_date == moment(new Date()).format("YYYY-MM-DD")) {
          let today = new Date();
          let time =
            ("0" + today.getHours()).slice(-2) +
            ":" +
            ("0" + today.getMinutes()).slice(-2) +
            ":00";
          let date_format = payload.follow_time
            ? payload.follow_time.length == 8
              ? payload.follow_time
              : payload.follow_time
            : "00:00:00";
          if (date_format <= time) {
            check_class = false;
            this.showPopup(
              "error",
              "تاريخ المتابعة ينبغي أن يكون أكبر من التاريخ والوقت الحالي"
            );
          }
        }
      }
      if (check_class) {
        let update_request = {
          request_id: parseInt(payload.id),
          customerName: payload.customer.name,
          sex: payload.customer.sex,
          birth_date: payload.customer.birth_date,
          birth_date_higri: payload.customer.birth_date_higri,
          age: payload.customer.age,
          age_years: payload.customer.age_years,
          work: payload.customer.work,
          madany_id: !payload.customer.askary_id
            ? parseInt(payload.customer.madany_id)
            : undefined,
          job_title: payload.customer.job_title,
          askary_id: !payload.customer.madany_id
            ? parseInt(payload.customer.askary_id)
            : undefined,
          military_rank: payload.customer.military_rank,
          hiring_date: payload.customer.hiring_date,
          salary: payload.customer.salary,
          salary_id: parseInt(payload.customer.salary_id),
          is_supported: payload.customer.is_supported,
          basic_salary: payload.customer.basic_salary,
          without_transfer_salary: payload.customer.without_transfer_salary,
          add_support_installment_to_salary:
            payload.customer.add_support_installment_to_salary,
          guarantees: parseInt(payload.customer.guarantees),
          has_obligations: payload.customer.has_obligations,
          obligations_value: payload.customer.obligations_value,
          has_financial_distress: payload.customer.has_financial_distress,
          financial_distress_value: payload.customer.financial_distress_value,
          jointName: payload.joint.name,
          jointMobile: payload.joint.mobile,
          jointSalary: payload.joint.salary,
          jointbirth: payload.joint.birth_date,
          jointBirth_higri: payload.joint.birth_date_higri,
          jointAge: payload.joint.age,
          jointage_years: payload.joint.age_years,
          jointWork: payload.joint.work,
          joint_hiring_date: payload.joint.hiring_date,
          jointmadanyWork: !payload.joint.jointaskaryWork
            ? parseInt(payload.joint.madany_id)
            : undefined,
          jointJobTitle: payload.joint.job_title,
          jointaskaryWork: !payload.joint.jointmadanyWork
            ? parseInt(payload.joint.askary_id)
            : undefined,
          jointRank: payload.joint.military_rank,
          jointsalary_source: parseInt(payload.joint.salary_id),
          jointSupport: payload.joint.is_supported,
          joint_add_support_installment_to_salary:
            payload.joint.add_support_installment_to_salary,
          jointObligations: payload.joint.has_obligations,
          jointobligations_value: payload.joint.obligations_value,
          jointfunding_source: parseInt(payload.joint.funding_id),
          realName: payload.real_estate.name,
          realMobile: payload.real_estate.mobile,
          realCity: payload.real_estate.city,
          project_id: payload.real_estate.project_id,
          realRegion: payload.real_estate.region,
          realPursuit: payload.real_estate.pursuit,
          realStatus: payload.real_estate.status,
          realAge: payload.real_estate.age,
          residence_type: payload.real_estate.residence_type,
          realType: payload.real_estate.type || null,
          realCost: payload.real_estate.cost,
          owning_property: payload.real_estate.owning_property,
          has_property: payload.real_estate.has_property,
          evaluated: payload.real_estate.evaluated,
          tenant: payload.real_estate.tenant,
          mortgage: payload.real_estate.mortgage,
          product_type: payload.funding.product_code,
          funding_source: payload.funding.funding_source,
          fundDur: payload.funding.funding_duration,
          fundPers: payload.funding.personalFun_cost,
          fundPersPre: payload.funding.personalFun_pre,
          fundReal: payload.funding.realFun_cost,
          fundRealPre: payload.funding.realFun_pre,
          fundFlex: payload.funding.flexiableFun_cost,
          fundExten: payload.funding.extendFund_cost,
          personal_salary_deduction: payload.funding.personal_salary_deduction,
          personal_installment: payload.funding.personal_monthly_installment,
          fundDed: payload.funding.ded_pre,
          fundMonth: payload.funding.monthly_in,
          installment_after_support: payload.funding.monthly_installment_after_support,
          reqtyp: payload.type,
          possible_to_sell_to_customer: payload.possible_to_sell_to_customer,
          follow_date: payload.follow_date,
          follow_time: payload.follow_time
            ? payload.follow_time.length == 8
              ? payload.follow_time
              : payload.follow_time + ":00"
            : undefined,
          reqSource: payload.source,
          req_product_type: payload.req_product_type,

          customer_needs_id: payload.customer_needs_id,
          objections_challenges_id: payload.objections_challenges_id,
          obstacles_id: payload.obstacles_id,
          nationality_id: payload.customer.nationality_id,
          you_have: payload.customer.you_have,
          empBank: payload.empBank,
          reqNoBank: payload.reqNoBank,
          source_of_income: payload.customer.source_of_income,
          have_salary: payload.customer.have_salary,

          agent_identity_number: payload.agent_identity_number,
          class_agent: parseInt(payload.class_id_agent),
          rejection_id_agent: parseInt(payload.rejection_id_agent),
          collaborator_id: parseInt(payload.collaborator_id),
          comment: payload.agent_notes,
          // possible_to_sell_to_customer: payload.possible_to_sell_to_customer,
          // prepayment
          PrePrealCost:
            payload.prepayments !== undefined && payload.prepayments != null
              ? payload.prepayments.realCost
              : "",
          incValue:
            payload.prepayments !== undefined && payload.prepayments != null
              ? payload.prepayments.incValue
              : "",
          preValue:
            payload.prepayments !== undefined && payload.prepayments != null
              ? payload.prepayments.prepaymentVal
              : "",
          prePresent:
            payload.prepayments !== undefined && payload.prepayments != null
              ? payload.prepayments.prepaymentPre
              : "",
          preCost:
            payload.prepayments !== undefined && payload.prepayments != null
              ? payload.prepayments.prepaymentCos
              : "",
          preVisa:
            payload.prepayments !== undefined && payload.prepayments != null
              ? payload.prepayments.visa
              : "",
          carLo:
            payload.prepayments !== undefined && payload.prepayments != null
              ? payload.prepayments.carLo
              : "",
          personalLo:
            payload.prepayments !== undefined && payload.prepayments != null
              ? payload.prepayments.personalLo
              : "",
          realLo:
            payload.prepayments !== undefined && payload.prepayments != null
              ? payload.prepayments.realLo
              : "",
          credBank:
            payload.prepayments !== undefined && payload.prepayments != null
              ? payload.prepayments.credit
              : "",
          netCust:
            payload.prepayments !== undefined && payload.prepayments != null
              ? payload.prepayments.netCustomer
              : "",
          otherLo:
            payload.prepayments !== undefined && payload.prepayments != null
              ? payload.prepayments.other
              : "",
          debt:
            payload.prepayments !== undefined && payload.prepayments != null
              ? payload.prepayments.debt
              : "",
          morPresnt:
            payload.prepayments !== undefined && payload.prepayments != null
              ? payload.prepayments.mortPre
              : "",
          mortCost:
            payload.prepayments !== undefined && payload.prepayments != null
              ? payload.prepayments.mortCost
              : "",
          pursitPresnt:
            payload.prepayments !== undefined && payload.prepayments != null
              ? payload.prepayments.proftPre
              : "",
          deficitCust:
            payload.prepayments !== undefined && payload.prepayments != null
              ? payload.prepayments.deficitCustomer
              : "",
          profCost:
            payload.prepayments !== undefined && payload.prepayments != null
              ? payload.prepayments.profCost
              : "",
          addedValue:
            payload.prepayments !== undefined && payload.prepayments != null
              ? payload.prepayments.addedVal
              : "",
          adminFees:
            payload.prepayments !== undefined && payload.prepayments != null
              ? payload.prepayments.adminFee
              : "",
          realDisposition:
            payload.prepayments !== undefined && payload.prepayments != null
              ? payload.prepayments.Real_estate_disposition_value
              : "",
          purchaseTax:
            payload.prepayments !== undefined && payload.prepayments != null
              ? payload.prepayments.purchase_tax_value
              : "",
          // Real_estate_disposition_value: payload.prepayments.Real_estate_disposition_value,
          // purchase_tax_value: payload.prepayments.purchase_tax_value,
          /*
        
        real: payload.prepayments.realCost,
        incr: payload.prepayments.incValue,
        preval: payload.prepayments.prepaymentVal,
        prepre: payload.prepayments.prepaymentPre,
        precos: payload.prepayments.prepaymentCos,
        net: payload.prepayments.netCustomer,
        deficit: payload.prepayments.deficitCustomer,
        visa: payload.prepayments.visa,
        carlo: payload.prepayments.carLo,
        perlo: payload.prepayments.personalLo,
        realo: payload.prepayments.realLo,
        credban: payload.prepayments.credit,
        // Real_estate_disposition_value: payload.prepayments.Real_estate_disposition_value,
        // purchase_tax_value: payload.prepayments.purchase_tax_value,
        other1: payload.prepayments.other,
        debt: payload.prepayments.debt,
        morpre: payload.prepayments.mortPre,
        morcos: payload.prepayments.mortCost,
        propre: payload.prepayments.proftPre,
        procos: payload.prepayments.profCost,
        valadd: payload.prepayments.addedVal,
        admfe: payload.prepayments.adminFee,
        */
        };
        // console.log("payload", payload);
        // console.log("update_request", update_request);
        let update_payload = {};
        for (const prop in update_request) {
          if (update_request[prop] != null) {
            update_payload[prop] = update_request[prop];
          }
        }
        Axios.post(
          `${this.$store.state.url}/v2/employee/Requests`,
          update_payload,
          { headers: this.request_headers() }
        )
          .then((res) => {
            // this.showPopup("success", "تم تعديل الطلب بنجاح");
            this.showPopup("success", res.data.message);
          })
          .catch((err) => {
            // work here
            //this.saveErrs.error = err.response.data.message;
            if (err.response.data.code == 402) {
              this.$store.state.ValidationAsDialog = true;
              this.$store.state.ValidationAsDialogMessage = err.response.data.message;
            } else {
              if (Array.isArray(err.response.data.message)) {
                this.showPopupError("success", err.response.data.message);
              } else {
                // this.showPopupError("success", err.response.data.message[0].split(","));
                this.showPopup("error", err.response.data.message);
              }
            }
            // this.showPopupError("success", err.response.data.message[0].split(","));
            //this.saveErrs.alert = true;
          });
      }
    },

    // BackRrequest From Funding to Follow
    reqarchive() {
      axiosInstance.get(`/employee/funding-manager/reqarchive/${this.$route.params.id}`, { headers: this.request_headers(), })
        .then((res) => {
          this.showPopup("success", res.data.message);
        })
        .catch((err) => {
          this.showPopup("error", err.response.data.message);
        });
    },

    // Reject request From Funding Manager
    reject_Request() {
      this.$store.state.FMRejectRequestDialog = true;
    },

    // Send Funding Request
    sendFunding() {
      this.$store.state.FMSendRequestDialog = true;
    },

    listBankEmployee(){
      axiosInstance.get(`/employee/funding-manager/listBankEmployee/${this.$route.params.id}`, { headers: this.request_headers(), })
        .then((res) => {
          this.$store.state.bankUsers = res.data.payload;
        })
        .catch((err) => {
          this.showPopup("error", err.response.data.message);
        });
    },

    sendRequestToBank() {
      this.$store.state.FMSendRequestToBankDialog = true;
    },

    // Add Request In Financial Reports
    addUnderProcess() {
      axiosInstance.get(`/employee/funding-manager/addUnderProcess/${this.$route.params.id}`, { headers: this.request_headers(), })
        .then((res) => {
          this.showPopup("success", res.data.message);
        })
        .catch((err) => {
          this.showPopup("error", err.response.data.message);
        });
    },

    // Remove Request From Financial Reports
    removeUnderProcess() {
      axiosInstance.get(`/employee/funding-manager/removeUnderProcess/${this.$route.params.id}`, { headers: this.request_headers(), })
        .then((res) => {
          this.showPopup("success", res.data.message);
        })
        .catch((err) => {
          this.showPopup("error", err.response.data.message);
        });
    },

    // Approve Funding Request
    approveMorPurRequest() {
      this.$store.state.FMApproveRequestDialog = true;
    },

    // create Prepayment ======> Not Yet
    createPrepayment() {
      this.$store.state.FMCreatePaymentDialog = true;
    },

    updatePrepayment() {
      this.saveErrs = {
        alert: false,
        error: [],
      };
      let payload = this.$store.state.request_info;
      let update_request = {
        request_id: parseInt(payload.id),
        PrePrealCost: (payload.prepayments !== undefined && payload.prepayments != null) ? payload.prepayments.realCost : '',
        incValue: (payload.prepayments !== undefined && payload.prepayments != null) ? payload.prepayments.incValue : '',
        preValue: (payload.prepayments !== undefined && payload.prepayments != null) ? payload.prepayments.prepaymentVal : '',
        prePresent: (payload.prepayments !== undefined && payload.prepayments != null) ? payload.prepayments.prepaymentPre : '',
        preCost: (payload.prepayments !== undefined && payload.prepayments != null) ? payload.prepayments.prepaymentCos : '',
        preVisa: (payload.prepayments !== undefined && payload.prepayments != null) ? payload.prepayments.visa : '',
        carLo: (payload.prepayments !== undefined && payload.prepayments != null) ? payload.prepayments.carLo : '',
        personalLo: (payload.prepayments !== undefined && payload.prepayments != null) ? payload.prepayments.personalLo : '',
        realLo: (payload.prepayments !== undefined && payload.prepayments != null) ? payload.prepayments.realLo : '',
        credBank: (payload.prepayments !== undefined && payload.prepayments != null) ? payload.prepayments.credit : '',
        netCust: (payload.prepayments !== undefined && payload.prepayments != null) ? payload.prepayments.netCustomer : '',
        otherLo: (payload.prepayments !== undefined && payload.prepayments != null) ? payload.prepayments.other : '',
        debt: (payload.prepayments !== undefined && payload.prepayments != null) ? payload.prepayments.debt : '',
        morPresnt: (payload.prepayments !== undefined && payload.prepayments != null) ? payload.prepayments.mortPre : '',
        mortCost: (payload.prepayments !== undefined && payload.prepayments != null) ? payload.prepayments.mortCost : '',
        pursitPresnt: (payload.prepayments !== undefined && payload.prepayments != null) ? payload.prepayments.proftPre : '',
        deficitCust: (payload.prepayments !== undefined && payload.prepayments != null) ? payload.prepayments.deficitCustomer : '',
        profCost: (payload.prepayments !== undefined && payload.prepayments != null) ? payload.prepayments.profCost : '',
        addedValue: (payload.prepayments !== undefined && payload.prepayments != null) ? payload.prepayments.addedVal : '',
        adminFees: (payload.prepayments !== undefined && payload.prepayments != null) ? payload.prepayments.adminFee : '',
        realDisposition: (payload.prepayments !== undefined && payload.prepayments != null) ? payload.prepayments.Real_estate_disposition_value : '',
        purchaseTax: (payload.prepayments !== undefined && payload.prepayments != null) ? payload.prepayments.purchase_tax_value : '',
      };

      let update_payload = {};
      for (const prop in update_request) {
        if (update_request[prop] != null) {
          update_payload[prop] = update_request[prop];
        }
      }

      axiosInstance.post(`/employee/funding-manager/updatepre/${this.$route.params.id}`, update_payload, { headers: this.request_headers() })
        .then((res) => {
          this.showPopup("success", res.data.message);
        })
        .catch((err) => {
          if (Array.isArray(err.response.data.message)) {
            this.showPopupError("success", err.response.data.message);
          } else {
            this.showPopupError("success", err.response.data.message[0].split(","));
          }
        });
    },

    cancelPrepayment() {
      this.$store.state.FMCancelPaymentDialog = true;
    },

    approvePrepayment() {
      axiosInstance.get(`/employee/funding-manager/approvePrepayment/${this.$route.params.id}`, { headers: this.request_headers(), })
        .then((res) => {
          this.showPopup("success", res.data.message);
        })
        .catch((err) => {
          this.showPopup("error", err.response.data.message);
        });
    },

    sendPrepayment() {
      this.$store.state.FMSendPaymentDialog = true;
    },

    




    //  old code
    send_request() {
      let payload = this.$store.state.request_info;
      let update_request = {
        request_id: parseInt(payload.id),
        customerName: payload.customer.name,
        sex: payload.customer.sex,
        birth_date: payload.customer.birth_date,
        birth_date_higri: payload.customer.birth_date_higri,
        age: payload.customer.age,
        age_years: payload.customer.age_years,
        work: payload.customer.work,
        madany_id: !payload.customer.askary_id
          ? parseInt(payload.customer.madany_id)
          : undefined,
        job_title: payload.customer.job_title,
        askary_id: !payload.customer.madany_id
          ? parseInt(payload.customer.askary_id)
          : undefined,
        military_rank: payload.customer.military_rank,
        hiring_date: payload.customer.hiring_date,
        salary: payload.customer.salary,
        salary_id: parseInt(payload.customer.salary_id),
        is_supported: payload.customer.is_supported,
        basic_salary: payload.customer.basic_salary,
        without_transfer_salary: payload.customer.without_transfer_salary,
        add_support_installment_to_salary:
          payload.customer.add_support_installment_to_salary,
        guarantees: parseInt(payload.customer.guarantees),
        has_obligations: payload.customer.has_obligations,
        obligations_value: payload.customer.obligations_value,
        has_financial_distress: payload.customer.has_financial_distress,
        financial_distress_value: payload.customer.financial_distress_value,
        jointName: payload.joint.name,
        jointMobile: payload.joint.mobile,
        jointSalary: payload.joint.salary,
        jointbirth: payload.joint.birth_date,
        jointBirth_higri: payload.joint.birth_date_higri,
        jointAge: payload.joint.age,
        jointage_years: payload.joint.age_years,
        jointWork: payload.joint.work,
        joint_hiring_date: payload.joint.hiring_date,
        jointmadanyWork: !payload.joint.jointaskaryWork
          ? parseInt(payload.joint.madany_id)
          : undefined,
        jointJobTitle: payload.joint.job_title,
        jointaskaryWork: !payload.joint.jointmadanyWork
          ? parseInt(payload.joint.askary_id)
          : undefined,
        jointRank: payload.joint.military_rank,
        jointsalary_source: parseInt(payload.joint.salary_id),
        jointSupport: payload.joint.is_supported,
        joint_add_support_installment_to_salary:
          payload.joint.add_support_installment_to_salary,
        jointObligations: payload.joint.has_obligations,
        jointobligations_value: payload.joint.obligations_value,
        jointfunding_source: parseInt(payload.joint.funding_id),
        realName: payload.real_estate.name,
        realMobile: payload.real_estate.mobile,
        realCity: payload.real_estate.city,
        project_id: payload.real_estate.project_id,
        realRegion: payload.real_estate.region,
        realPursuit: payload.real_estate.pursuit,
        realStatus: payload.real_estate.status,
        realAge: payload.real_estate.age,
        residence_type: payload.real_estate.residence_type,
        realType: payload.real_estate.type || null,
        realCost: payload.real_estate.cost,
        owning_property: payload.real_estate.owning_property,
        has_property: payload.real_estate.has_property,
        evaluated: payload.real_estate.evaluated,
        tenant: payload.real_estate.tenant,
        mortgage: payload.real_estate.mortgage,
        product_type: payload.funding.product_code,
        funding_source: payload.funding.funding_source,
        fundDur: payload.funding.funding_duration,
        fundPers: payload.funding.personalFun_cost,
        fundPersPre: payload.funding.personalFun_pre,
        fundReal: payload.funding.realFun_cost,
        fundRealPre: payload.funding.realFun_pre,
        fundFlex: payload.funding.flexiableFun_cost,
        fundExten: payload.funding.extendFund_cost,
        personal_salary_deduction: payload.funding.personal_salary_deduction,
        personal_installment: payload.funding.personal_monthly_installment,
        fundDed: payload.funding.ded_pre,
        fundMonth: payload.funding.monthly_in,
        installment_after_support: payload.funding.monthly_installment_after_support,
        reqtyp: payload.type,

        customer_needs_id: payload.customer_needs_id,
        objections_challenges_id: payload.objections_challenges_id,
        obstacles_id: payload.obstacles_id,
        nationality_id: payload.customer.nationality_id,
        you_have: payload.customer.you_have,
        source_of_income: payload.customer.source_of_income,
        have_salary: payload.customer.have_salary,

        possible_to_sell_to_customer: payload.possible_to_sell_to_customer,
        follow_date: payload.req_date,
        follow_time: payload.follow_time
          ? payload.follow_time.length == 8
            ? payload.follow_time
            : payload.follow_time + ":00"
          : undefined,
        reqSource: payload.source,
        class_agent: parseInt(payload.class_id_agent),
        rejection_id_agent: parseInt(payload.rejection_id_agent),
        collaborator_id: parseInt(payload.collaborator_id),
        type_of_selected_product: payload.req_product_type,
        // possible_to_sell_to_customer: payload.possible_to_sell_to_customer,
        comment: payload.agent_notes,
      };
      let update_payload = {};
      for (const prop in update_request) {
        if (update_request[prop] != null) {
          update_payload[prop] = update_request[prop];
        }
      }
      this.saveErrs = {
        alert: false,
        error: [],
      };
      Axios.post(
        `${this.$store.state.url}/agent/CheckRequestFieldsAndSendRequest`,
        update_payload,
        {
          headers: this.request_headers(),
        }
      )
        .then(() => {
          this.showPopup("success", "تم إرسال الطلب بنجاح");
          setTimeout(() => {
            this.loading = true;
            setTimeout(() => {
              this.loading = false;
            }, 1000);
          }, 2000);
        })
        .catch((err) => {
          // work here
          //this.saveErrs.error = [err.response.data.message];
          if (err.response.data.code == 402) {
            this.$store.state.ValidationAsDialog = true;
            this.$store.state.ValidationAsDialogMessage = err.response.data.message;
          } else {
            if (Array.isArray(err.response.data.message)) {
              this.showPopupError("success", err.response.data.message);
            } else {
              this.showPopupError("success", [err.response.data.message]);
            }
          }
          // this.showPopupError("success", err.response.data.message);
          //this.saveErrs.alert = true;
        });
    },

    send_mor_pur_request() {
      let payload = this.$store.state.request_info;

      Axios.post(
        `${this.$store.state.url}/v2/employee/Requests/MortgagePurchase/send/${payload.id}`,
        {},
        {
          headers: this.request_headers(),
        }
      )
        .then(() => {
          this.showPopup("success", "تم إرسال الطلب بنجاح");
          setTimeout(() => {
            this.loading = true;
            setTimeout(() => {
              this.loading = false;
            }, 1000);
          }, 2000);
        })
        .catch((err) => {
          // work here
          //this.saveErrs.error = [err.response.data.message];
          if (err.response.data.code == 402) {
            this.$store.state.ValidationAsDialog = true;
            this.$store.state.ValidationAsDialogMessage = err.response.data.message;
          } else {
            if (Array.isArray(err.response.data.message)) {
              this.showPopupError("success", err.response.data.message);
            } else {
              this.showPopupError("success", [err.response.data.message]);
            }
          }
          // this.showPopupError("success", err.response.data.message);
          //this.saveErrs.alert = true;
        });
    },

    send_prpayment() {
      this.$store.state.AgentSendPaymentDialog = true;
    },

    update_prpaymentOld() {
      this.saveErrs = {
        alert: false,
        error: [],
      };
      let payload = this.$store.state.request_info;
      let update_request = {
        request_id: parseInt(payload.id),
        // prepayment
        PrePrealCost:
          payload.prepayments !== undefined && payload.prepayments != null
            ? payload.prepayments.realCost
            : "",
        incValue:
          payload.prepayments !== undefined && payload.prepayments != null
            ? payload.prepayments.incValue
            : "",
        preValue:
          payload.prepayments !== undefined && payload.prepayments != null
            ? payload.prepayments.prepaymentVal
            : "",
        prePresent:
          payload.prepayments !== undefined && payload.prepayments != null
            ? payload.prepayments.prepaymentPre
            : "",
        preCost:
          payload.prepayments !== undefined && payload.prepayments != null
            ? payload.prepayments.prepaymentCos
            : "",
        preVisa:
          payload.prepayments !== undefined && payload.prepayments != null
            ? payload.prepayments.visa
            : "",
        carLo:
          payload.prepayments !== undefined && payload.prepayments != null
            ? payload.prepayments.carLo
            : "",
        personalLo:
          payload.prepayments !== undefined && payload.prepayments != null
            ? payload.prepayments.personalLo
            : "",
        realLo:
          payload.prepayments !== undefined && payload.prepayments != null
            ? payload.prepayments.realLo
            : "",
        credBank:
          payload.prepayments !== undefined && payload.prepayments != null
            ? payload.prepayments.credit
            : "",
        netCust:
          payload.prepayments !== undefined && payload.prepayments != null
            ? payload.prepayments.netCustomer
            : "",
        otherLo:
          payload.prepayments !== undefined && payload.prepayments != null
            ? payload.prepayments.other
            : "",
        debt:
          payload.prepayments !== undefined && payload.prepayments != null
            ? payload.prepayments.debt
            : "",
        morPresnt:
          payload.prepayments !== undefined && payload.prepayments != null
            ? payload.prepayments.mortPre
            : "",
        mortCost:
          payload.prepayments !== undefined && payload.prepayments != null
            ? payload.prepayments.mortCost
            : "",
        pursitPresnt:
          payload.prepayments !== undefined && payload.prepayments != null
            ? payload.prepayments.proftPre
            : "",
        deficitCust:
          payload.prepayments !== undefined && payload.prepayments != null
            ? payload.prepayments.deficitCustomer
            : "",
        profCost:
          payload.prepayments !== undefined && payload.prepayments != null
            ? payload.prepayments.profCost
            : "",
        addedValue:
          payload.prepayments !== undefined && payload.prepayments != null
            ? payload.prepayments.addedVal
            : "",
        adminFees:
          payload.prepayments !== undefined && payload.prepayments != null
            ? payload.prepayments.adminFee
            : "",
        realDisposition:
          payload.prepayments !== undefined && payload.prepayments != null
            ? payload.prepayments.Real_estate_disposition_value
            : "",
        purchaseTax:
          payload.prepayments !== undefined && payload.prepayments != null
            ? payload.prepayments.purchase_tax_value
            : "",
        // Real_estate_disposition_value: payload.prepayments.Real_estate_disposition_value,
        // purchase_tax_value: payload.prepayments.purchase_tax_value,
        /*
        
        real: payload.prepayments.realCost,
        incr: payload.prepayments.incValue,
        preval: payload.prepayments.prepaymentVal,
        prepre: payload.prepayments.prepaymentPre,
        precos: payload.prepayments.prepaymentCos,
        net: payload.prepayments.netCustomer,
        deficit: payload.prepayments.deficitCustomer,
        visa: payload.prepayments.visa,
        carlo: payload.prepayments.carLo,
        perlo: payload.prepayments.personalLo,
        realo: payload.prepayments.realLo,
        credban: payload.prepayments.credit,
        // Real_estate_disposition_value: payload.prepayments.Real_estate_disposition_value,
        // purchase_tax_value: payload.prepayments.purchase_tax_value,
        other1: payload.prepayments.other,
        debt: payload.prepayments.debt,
        morpre: payload.prepayments.mortPre,
        morcos: payload.prepayments.mortCost,
        propre: payload.prepayments.proftPre,
        procos: payload.prepayments.profCost,
        valadd: payload.prepayments.addedVal,
        admfe: payload.prepayments.adminFee,
        */
      };

      // let update_request = {
      //   request_id: parseInt(payload.id),
      //   PrePrealCost: (payload.prepayments !== undefined && payload.prepayments != null) ? payload.prepayments.realCost : '',
      //   incValue: (payload.prepayments !== undefined && payload.prepayments != null) ? payload.prepayments.incValue : '',
      //   preValue: (payload.prepayments !== undefined && payload.prepayments != null) ? payload.prepayments.prepaymentVal : '',
      //   prePresent: (payload.prepayments !== undefined && payload.prepayments != null) ? payload.prepayments.prepaymentPre : '',
      //   preCost: (payload.prepayments !== undefined && payload.prepayments != null) ? payload.prepayments.prepaymentCos : '',
      //   preVisa: (payload.prepayments !== undefined && payload.prepayments != null) ? payload.prepayments.visa : '',
      //   carLo: (payload.prepayments !== undefined && payload.prepayments != null) ? payload.prepayments.carLo : '',
      //   personalLo: (payload.prepayments !== undefined && payload.prepayments != null) ? payload.prepayments.personalLo : '',
      //   realLo: (payload.prepayments !== undefined && payload.prepayments != null) ? payload.prepayments.realLo : '',
      //   credBank: (payload.prepayments !== undefined && payload.prepayments != null) ? payload.prepayments.credit : '',
      //   netCust: (payload.prepayments !== undefined && payload.prepayments != null) ? payload.prepayments.netCustomer : '',
      //   otherLo: (payload.prepayments !== undefined && payload.prepayments != null) ? payload.prepayments.other : '',
      //   debt: (payload.prepayments !== undefined && payload.prepayments != null) ? payload.prepayments.debt : '',
      //   morPresnt: (payload.prepayments !== undefined && payload.prepayments != null) ? payload.prepayments.mortPre : '',
      //   mortCost: (payload.prepayments !== undefined && payload.prepayments != null) ? payload.prepayments.mortCost : '',
      //   pursitPresnt: (payload.prepayments !== undefined && payload.prepayments != null) ? payload.prepayments.proftPre : '',
      //   deficitCust: (payload.prepayments !== undefined && payload.prepayments != null) ? payload.prepayments.deficitCustomer : '',
      //   profCost: (payload.prepayments !== undefined && payload.prepayments != null) ? payload.prepayments.profCost : '',
      //   addedValue: (payload.prepayments !== undefined && payload.prepayments != null) ? payload.prepayments.addedVal : '',
      //   adminFees: (payload.prepayments !== undefined && payload.prepayments != null) ? payload.prepayments.adminFee : '',
      //   realDisposition: (payload.prepayments !== undefined && payload.prepayments != null) ? payload.prepayments.Real_estate_disposition_value : '',
      //   purchaseTax: (payload.prepayments !== undefined && payload.prepayments != null) ? payload.prepayments.purchase_tax_value : '',
      // };

      let update_payload = {};
      for (const prop in update_request) {
        if (update_request[prop] != null) {
          update_payload[prop] = update_request[prop];
        }
      }

      axiosInstance.post(`/employee/funding-manager/updatepre/${this.$route.params.id}`, update_payload, { headers: this.request_headers() })
        .then((res) => {
          this.showPopup("success", res.data.message);
        })
        .catch((err) => {
          if (Array.isArray(err.response.data.message)) {
            this.showPopupError("success", err.response.data.message);
          } else {
            this.showPopupError("success", err.response.data.message[0].split(","));
          }
        });
    },

  },

  created() {
    this.listBankEmployee();
  }
};
</script>
