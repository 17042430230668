<template>
  <v-row justify="center">
    <v-dialog
        v-model="this.$store.state.FMSendPaymentDialog"
        persistent
        width="624"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5" style="margin-bottom: 30px">تاكيد ارسال الدفعة؟</span>
        </v-card-title>
        <v-card-text>
            <v-row>
            <v-col cols="12" lg="12" md="12" sm="12" xs="12" class="my-2">
              <div class="mb-2">
                الملاحظة
              </div>
              <v-textarea hide-details style="background-color: white ; border: 2px solid; padding: 5px;" v-model="note" 
                dense></v-textarea>
            </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue-darken-1"
              variant="text"
              @click="$store.state.FMSendPaymentDialog = false"
          >
            الغاء
          </v-btn>
          <v-btn
              color="blue-darken-1"
              variant="text"
              @click="sendPayment"
          >
            ارسال
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Axios from "axios";
import { axiosInstance } from "@/axios/config";

export default {
  name: 'FMSendPaymentDialog',
  props: ['reqId'],

  data(){
    return{
      sendTo: "",
      note: ""
    }
  },

  methods: {
    
    sendPayment() {
      let req_id = "";
        this.loading = true;
        req_id = parseInt(this.reqId);
        const formData = new FormData();
        formData.append("note", this.note);
        axiosInstance.get(`/employee/funding-manager/sendPrepayment/${req_id}`, { headers: this.request_headers(), })
        .then((res) => {
          this.$store.state.FMSendPaymentDialog = false;
          this.showPopup("success", res.data.message);
        })
        .catch((err) => {
          this.$store.state.FMSendPaymentDialog = false;
          this.showPopup("error", err.response.data.message);
        });
    },
  },

}
</script>