<template>
  <div style="box-shadow: 0 0 0 0 !important" class="adminGraph">
    <v-row justify="center" class="mb-3 mt-5 graphContent" style="margin: 0px">
      <DailyReportFilter @handleFilter="handleFilter" :role="role"/>
    </v-row>
    <div v-if="!getDataDone">

      <table-component
        itemKey="id"
        :headers="tableHeaders"
        :pagination="this.$store.state.pagination"
        :limit="$store.state.counterOfShow"
        :items="tableData"
        :loading="loading"
        v-model="page"
      >
            <template v-slot:[`item.rate_average`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <span v-on="on">{{
                    item.rate_average
                  }}</span>
                </template>
                <div class="font-weight-bold">
                  <span>
                    <span>
                      <v-list-item-icon  class="ma-0 pa-0" v-for="index in 1" :key="index">
                        <v-icon size="10" color="warning">mdi-star</v-icon>
                      </v-list-item-icon>
                    </span>
                    : {{item.one_star }}
                  </span>
                  <br>
                  <span>
                    <span >
                      <v-list-item-icon  class="ma-0 pa-0" v-for="index in 2" :key="index">
                        <v-icon size="10" color="warning">mdi-star</v-icon>
                      </v-list-item-icon>
                    </span>
                   : {{item.two_star }}
                  </span>
                  <br>
                  <span>
                    <span >
                      <v-list-item-icon  class="ma-0 pa-0" v-for="index in 3" :key="index">
                        <v-icon size="10" color="warning">mdi-star</v-icon>
                      </v-list-item-icon>
                    </span>
                   : {{item.three_star }}
                  </span>
                  <br>
                  <span>
                    <span >
                      <v-list-item-icon  class="ma-0 pa-0" v-for="index in 4" :key="index">
                        <v-icon size="10" color="warning">mdi-star</v-icon>
                      </v-list-item-icon>
                    </span>
                    : {{item.four_star }}
                  </span>
                  <br>
                  <span>
                    <span>
                      <v-list-item-icon  class="ma-0 pa-0" v-for="index in 5" :key="index">
                        <v-icon size="10" color="warning">mdi-star</v-icon>
                      </v-list-item-icon>
                    </span>
                     : {{item.five_star }}
                  </span>
                </div>
              </v-tooltip>
            </template>

      </table-component>

    </div>
    <div v-else class="loader">
      <img src="../../../assets/Comp-2.gif" />
    </div>
  </div>
</template>

<script>
import DailyReportFilter from "./DailyReportFilter.vue";
import Axios from "axios";

export default {
  components: {
    DailyReportFilter,
  },
  data() {
    return {
      tableData: [],
      adviser_id: [],
      body: {},
      page: 1,
      role : '',
      getDataDone: false,
      loading: false,
      tableHeaders: [
        { text: "الاستشاري", value: "agent" },
        { text: "عدد التقييمات", value: "rate_count" },
        { text: "متوسط التقييمات", value: "rate_average" },
      ],
    };
  },

  watch: {
    page(val) {
      this.getData(val, "loading");
    },

    "$store.state.counterOfShow": {
      handler(newval, oldvalue) {
        if (newval !== oldvalue && !this.$store.state.admin_search_active) {
          this.getData(this.page,'loading'); //getDataDone
        }
      },
    },

    $route: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.$store.state.counterOfShow = 10;
        }
      },
    },

  },

  methods: {
    handleFilter(val){

      this.body = new FormData();
          if (val.startDate == undefined) // default
            val.startDate = new Date().toLocaleDateString();
          if(val && val.startDate){
            this.body.append('start_date', val.startDate);
          }
        
          if (val.endDate == undefined) // default
            val.endDate = new Date().toLocaleDateString();
          if(val && val.endDate){
            this.body.append('end_date', val.endDate);
          }
        
          if (val.status == undefined)
            val.status = "استشاري قائم"; // default

          if(val && val.status === "استشاري قائم"){
            this.body.append('status', 1);
          }
          if(val && val.status === "استشاري مؤرشف"){
            this.body.append('status', 0);
          }
        
          if(val && val.range){
            this.body.append('range', val.range);
          }
        
          if (val && val.managerId && val.managerId.length) {
            val.managerId.forEach((item, index) => {
              this.body.append([`manager_id[${index}]`], item.id);
            });
          }
        
          if (val && val.agentId && val.agentId.length) {
            val.agentId.forEach((item, index) => {
              this.body.append([`adviser_id[${index}]`], item.id)
            });
          
          }

          if(val && val.dayNumber){
            this.body.append('range', val.dayNumber);
          }
          
      this.getData(1, "getDataDone")
  
    },

    getData(page, loader) {
      if (loader === "getDataDone") {
        this.getDataDone = true;
      } else if (loader === "loading") {
        this.loading = true;
      }
      this.tableData = [];

      let url = `${this.$store.state.url}/employee/rates/rate-average-report?page=${page}&per_page=${this.$store.state.counterOfShow}`;

      
      Axios.post(url, this.body, { headers: this.request_headers() }).then((res) => {
        this.setTableData(res.data.payload.data);
        this.$store.state.pagination = {
          limit: 3,
          count: res.data.payload.total,
        };
        this.loading = false;
        this.getDataDone = false;
      });
    },

    setTableData(data) {
      data.map((res) => {
        this.tableData.push({
          "agent": res.name,
          "rate_count": res.rate_count,
          "rate_average": res.average_stars,
          "one_star": res.one_star,
          "two_star": res.two_star,
          "three_star": res.three_star,
          "four_star": res.four_star,
          "five_star": res.five_star,
        });
      });
    },
  },

  created() {
    // this.handleFilter({});
    this.$store.state.pagination = {};
    this.role =localStorage['role'];
  },
};
</script>
