<template>
  <v-row justify="center">
    <v-dialog v-model="$store.state.openOneTaskContent" width="600">
      <v-card>
        <v-card-title>
          <div>محتوي التذكرة</div>
        </v-card-title>
        <v-card-text>
          <v-container>
            <div>
              <v-row
                style="
                  background-color: #f8f9fa;
                  border: 1px solid #eff2f7;
                  margin-top: 20px;
                "
                class="mx-0 file-info-row d-flex align-center text-center"
              >
              <h3>{{ content }}</h3>
              </v-row>
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="closeTaskContent"
          >
            الغاء
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
  
  <script>
import Axios from "axios";

export default {
  data() {
    return {
      content: null,
    };
  },

  methods: {
    get_content_for_task() {
      if (this.$store.state.idOfOneTaskContent) {
        Axios.get(
          `${this.$store.state.url}/agent/OneTask/Show/${this.$store.state.idOfOneTaskContent}`,
          { headers: this.request_headers() }
        )
          .then((response) => {
            this.content = response.data.payload.first_one_task_content[0].content;
          })
          .catch((err) => {
            this.showPopup("error", err.response.data.message);
          });
      }
    },

    closeTaskContent() {
      this.$store.state.idOfOneTaskContent = null;
      this.$store.state.openOneTaskContent = false;
    },
  },

  created() {
    if (this.$store.state.idOfOneTaskContent != null) {
      this.get_content_for_task();
    } else {
      console.log("There is an Erorr !");
    }
  },

  watch: {
    "$store.state.idOfOneTaskContent": {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.get_content_for_task();
        }
      },
    },
  },
};
</script>