export function getGlobalTapData(store) {
    const { bodySearch, GlobalTapData } = store.state;

    // Helper function to clear old keys from bodySearch
    const clearKeysByPrefix = (prefix) => {
        for (let key of bodySearch.keys()) {
            if (key.startsWith(prefix)) {
                bodySearch.delete(key);
            }
        }
    };

    // Helper function to update bodySearch with array values
    const updateBodySearchArray = (prefix, items) => {
        clearKeysByPrefix(prefix);
        items.forEach((item, index) => {
            bodySearch.set(`${prefix}[${index}]`, item["id"]);
        });
    };

    // Process selected agents
    if (GlobalTapData.selectAgent.length > 0) {
        updateBodySearchArray('agent_name', GlobalTapData.selectAgent);
    }

    // Process selected managers
    if (GlobalTapData.selectedMang.length > 0) {
        updateBodySearchArray('sales_manager_ids', GlobalTapData.selectedMang);
    }

    // Process customer phone number
    if (GlobalTapData.phoneNumber.length > 0) {
        bodySearch.set("customer_phone", GlobalTapData.phoneNumber);
    } else {
        bodySearch.delete("customer_phone");
    }

    // Process agent classifications
    if (GlobalTapData.selectAgentType.length > 0) {
        updateBodySearchArray('agent_classification', GlobalTapData.selectAgentType);
    }

    // Process agent positive/negative classification
    const classificationTypeMap = {
        "سلبي": 0,
        "ايجابي": 1,
        "بدون": 2,
    };

    if (Object.prototype.hasOwnProperty.call(classificationTypeMap, GlobalTapData.allType)) {
        bodySearch.set("agent_positive_or_negative_classification", classificationTypeMap[GlobalTapData.allType]);
    } else if (GlobalTapData.allType === "جميع التصنيفات") {
        bodySearch.delete("agent_positive_or_negative_classification");
    }
}
