<template>
  <div>
    <HeaderIconsForCitiesReport
      :selectedItems="selectedItems"
      type="moveAllRequests"
      @getData="reloadPage"
    />

    <div v-if="!this.$store.state.allLoading" style="margin-top: 40px">
      <table-component
        :showSelect="true"
        itemKey="id"
        :selectedRows.sync="selected_requests"
        :headers="headers"
        :pagination="this.$store.state.pagination"
        :limit="$store.state.counterOfShow"
        :items="this.$store.state.tableData"
        :loading="loading"
        @refresh="getTableData"
        v-model="page"
      >

      <template v-slot:[`item.action`]="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="#3f0e40" depressed small dark v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <router-link
                style="text-decoration: none"
                :to="{ name: 'request_details', params: { id: item.id } }"
              >
                <v-list-item>
                  <v-list-item-icon class="ml-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18.528"
                      height="15.111"
                      viewBox="0 0 13.528 10.111"
                    >
                      <g
                        id="Icon_feather-eye-dark"
                        data-name="Icon feather-eye-dark"
                        transform="translate(-1 -5.5)"
                      >
                        <path
                          id="Path_4040"
                          data-name="Path 4040"
                          d="M1.5,10.556S3.778,6,7.764,6s6.264,4.556,6.264,4.556-2.278,4.556-6.264,4.556S1.5,10.556,1.5,10.556Z"
                          fill="none"
                          stroke="#000"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1"
                        />
                        <path
                          id="Path_4041"
                          data-name="Path 4041"
                          d="M16.917,15.208A1.708,1.708,0,1,1,15.208,13.5,1.708,1.708,0,0,1,16.917,15.208Z"
                          transform="translate(-7.444 -4.653)"
                          fill="none"
                          stroke="#000"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1"
                        />
                      </g>
                    </svg>
                  </v-list-item-icon>
                  <v-list-item-title>فتح</v-list-item-title>
                </v-list-item>
              </router-link>


              <router-link
                style="text-decoration: none"
                target="_blank"
                :to="{ name: 'request_details', params: { id: item.id } }"
              >
                <v-list-item>
                  <v-list-item-icon class="ml-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18.528"
                      height="15.111"
                      viewBox="0 0 13.528 10.111"
                    >
                      <g
                        id="Icon_feather-eye-dark"
                        data-name="Icon feather-eye-dark"
                        transform="translate(-1 -5.5)"
                      >
                        <path
                          id="Path_4040"
                          data-name="Path 4040"
                          d="M1.5,10.556S3.778,6,7.764,6s6.264,4.556,6.264,4.556-2.278,4.556-6.264,4.556S1.5,10.556,1.5,10.556Z"
                          fill="none"
                          stroke="#000"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1"
                        />
                        <path
                          id="Path_4041"
                          data-name="Path 4041"
                          d="M16.917,15.208A1.708,1.708,0,1,1,15.208,13.5,1.708,1.708,0,0,1,16.917,15.208Z"
                          transform="translate(-7.444 -4.653)"
                          fill="none"
                          stroke="#000"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1"
                        />
                      </g>
                    </svg>
                  </v-list-item-icon>
                  <v-list-item-title> فتح في تاب جديد</v-list-item-title>
                </v-list-item>
              </router-link>
             
            </v-list>
          </v-menu>
        </template>

      </table-component>
      <OrderRequestSection
        v-if="this.$store.state.openOrderRequestSection"
        style="margin-top: 50px"
      />
    </div>

    <div
      style="
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
      "
      v-else
    >
      <LoadingMore />
    </div>
    <v-snackbar
      v-model="active"
      color="#40BE72"
      timeout="-1"
      right
      bottom
      class="ml-4"
      elevation="24"
      transition="fade-transition"
    >
      <span class="font-weight-bold" style="font-size: 16px">{{ message }}</span>
      <template v-slot:action>
        <v-btn icon small @click="active = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <FilterDialogForCitiesReport pageName="all" @handleFilter="searchRequest" />
    <OrderRequestDialog />

    <!-- chart -->
    <div style="box-shadow: 0 0 0 0 !important" class="adminGraph">
      <div v-if="!getDataDone">
        <div style="margin-top: 40px" v-if="!loading">
          <highcharts :options="chartOptions" style="height: 800px"></highcharts>
        </div>
      </div>
      <div v-else class="loader">
        <img src="../../../assets/Comp-2.gif" />
      </div>
    </div>
  </div>
</template>

<script>
import LoadingMore from "@/components/Charts/LoadingMore.vue";
import HeaderIconsForCitiesReport from "@/components/Charts/HeaderIconsForCitiesReport.vue";
import FilterDialogForCitiesReport from "@/components/Charts/FilterDialogForCitiesReport.vue";
import { formatDate } from "@/mixins/formatDate";
import { SourceValue } from "@/mixins/sourceValue";
import OrderRequestDialog from "@/components/AdminComponent/OrderRequestDialog.vue";
import OrderRequestSection from "@/components/AdminComponent/OrderRequestSection.vue";
import { axiosInstance } from "@/axios/config";
import { Chart } from "highcharts-vue";
import Highcharts from "highcharts-vue";

export default {
  name: "AdminSupport",
  components: {
    OrderRequestDialog,
    OrderRequestSection,
    FilterDialogForCitiesReport,
    LoadingMore,
    HeaderIconsForCitiesReport,
    highcharts: Chart,
  },

  data() {
    return {
      allGetData: [],
      selected_requests: [],
      pagination: {
        limit: 3,
        count: this.$store.state.searchedCount,
      },
      page: 1,
      selectedRows: [],
      allData: [],
      active: false,
      message: "",
      showSelect: { default: false },
      copySupportRequests: [],
      loadMoreData: false,
      count: null,
      loading: false,
      selectId: null,
      allStatus: [],
      label: "",
      selectedItems: [],
      countPage: 1,
      listenToFreezeAction: this.$store.state.freezeActionIsDone,
      headers: [
        { align: "center", value: "data-table-select", width: "5%" },
        { text: "رقم الطلب", value: "رقم الطلب", width: "100px" },
        { text: "عميل", value: "عميل", width: "170px" },
        { text: "الجوال", value: "الجوال", width: "120px" },
        { text: "تصنيف الاستشاري", value: "تصنيف الاستشاري", width: "150px" },
        {
          text: "المدينة",
          value: "مدينة العقار",
          width: "200px",
        },
        {
          text: "الحي",
          value: "منطقة العقار",
          width: "200px",
        },
        {
          text: "هل يمتلك عقار",
          value: "هل يمتلك عقار",
          width: "130px",
        },
        { text: "العمليات", value: "action", align: "center", width: "3%" },
      ],
      counterOfShow: 10,
      horizontalData: [],
      verticalData: [],
      chartData: [],
      getDataDone: false,
      chartOptions: {
        chart: {
          type: "column",
        },
        title: {
          text: "تقرير طلبات المدن",
        },
        xAxis: {
          min: 0,
          title: {
            text: "المدن",
          },
          categories: [],
        },
        yAxis: {
          title: {
            text: "المجموع",
          },
        },
        tooltip: {
          headerFormat: "<b>{point.x}</b><br/>",
          pointFormat:
            '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
          shared: true,
        },
        plotOptions: {
          column: {
            dataLabels: {
              enabled: true,
            },
          },
        },
        series: [
          {
            name: "مجموع الطلبات الخاصة بالمدن",
            data: [],
          },
        ],
      },
    };
  },

  watch: {
    page(val) {
      // if (this.$store.state.isSearch === false) {
      //   this.getTableData(val);
      // } else {
      //   this.searchRequest(val, "table");
      // }

      this.searchRequest(val, "table");
    },
    selected_requests(val) {
      this.selectedItems = val;
      this.$emit("requests", val);
    },

    "$store.state.counterOfShow": {
      handler(newval, oldvalue) {
        if (newval !== oldvalue && !this.$store.state.admin_search_active) {
          this.searchRequest(this.page, "table");
          // this.getTableData(this.page);
        }
      },
    },

    $route: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.$store.state.counterOfShow = 10;
        }
      },
    },

  },

  beforeDestroy() {
    this.$store.state.openOrderRequestSection = false;
  },

  methods: {
    reloadPage() {
      this.$store.state.allData = [];
      this.$store.state.tableData = [];
      this.allGetData = [];
      this.$store.state.followLoading = true;
      this.$store.state.isSearch = false;
      this.getAllRequests(1);
      this.$store.state.startElement = [];
    },

    setTblData(res) {
      this.$store.state.tableData.push({
        id: res.id,
        isSelected: false,
        "رقم الطلب": res.id,
        عميل: res.customer.name,
        الجوال: res.customer.mobile,
        "تصنيف الاستشاري": res.class_id_agent_text,
        "هل يمتلك عقار":
          res.real_estate?.has_property && res.real_estate?.has_property === 0
            ? "لا"
            : "نعم",
        "مدينة العقار": res.real_estate.realCity,
        "منطقة العقار": res.real_estate.region,
      });
    },

    // .get(`/v2/employee/Requests?type=all&page=${count}`, {
    getTableData(count) {
      this.$store.state.tableData = [];
      this.loading = true;
      axiosInstance
        .post(`/v2/employee/cities-report/search?per_page=${this.$store.state.counterOfShow}`, this.$store.state.bodySearch, {
          headers: this.request_headers(),
        })
        .then((res) => {
          let response = res.data.payload.data.map((item) => {
            this.setTblData(item);
          });

          this.$store.state.pagination = {
            limit: 3,
            count: res.data.payload.count_of_cities_report,
          };

          this.chartData = res.data.payload.chartData;
          this.horizontalData = this.chartData.map((item) => item.city_name);
          this.verticalData = this.chartData.map((item) => item.count);

          this.chartOptions.xAxis.categories = this.horizontalData;
          this.chartOptions.series[0].data = this.verticalData;

          this.loading = false;
        });
    },

    searchRequest(page, type) {
      this.loading = true;
      this.chartData = [];
      this.chartOptions.xAxis.categories = [];
      this.chartOptions.series[0].data = [];
      if (type === "table") {
        this.$store.state.tableData = [];
      }
      // this.$store.state.bodySearch.delete("page");
      this.$store.state.bodySearch.append("page", page);
      let url = `/v2/employee/cities-report/search?per_page=${this.$store.state.counterOfShow}`;
      axiosInstance
        .post(url, this.$store.state.bodySearch, {
          headers: this.request_headers(),
        })
        .then((res) => {
          this.$store.state.pagination = {
            limit: 3,
            count: res.data.payload.searched,
          };
          // let response = res.data.payload.data.map((item) => {
          //   if (item.length === 0) {
          //     this.loading = false;
          //   }
          //   this.setTblData(item);
          //   item["starChecked"] = false;
          //   return item;
          // });

          this.loading = false;
          let response = res.data.payload.data.map((item) => {
            this.setTblData(item);
          });

          this.chartData = res.data.payload.chartData;
          this.horizontalData = this.chartData.map((item) => item.city_name);
          this.verticalData = this.chartData.map((item) => item.count);

          this.chartOptions.xAxis.categories = this.horizontalData;
          this.chartOptions.series[0].data = this.verticalData;

          // this.$store.state.allSearch.push(...response);
          // this.removeDublicate(this.$store.state.allSearch);
        });
    },

    removeDublicate(array) {
      let uniqueArray = array.filter((item, index) => {
        return (
          index ===
          array.findIndex((obj) => {
            return obj.id === item.id;
          })
        );
      });
      this.$store.state.allData = uniqueArray;
    },

    // .get(`/v2/employee/Requests?type=all&page=${page}`)
    getAllRequests(page) {
      if (
        this.$store.state.allData.length === 0 ||
        this.copySupportRequests.length === 0
      ) {
        this.$store.state.allLoading = true;
      }
      this.loadMoreData = true;
      axiosInstance
        .post(`/v2/employee/cities-report/search`, this.$store.state.bodySearch)
        .then((res) => {
          this.$store.state.pagination = {
            limit: 3,
            count: res.data.payload.count_of_cities_report,
          };
          let response = res.data.payload.data.map((item) => {
            this.setTblData(item);
            item["starChecked"] = false;
            return item;
          });

          this.chartData = res.data.payload.chartData;
          this.horizontalData = this.chartData.map((item) => item.city_name);
          this.verticalData = this.chartData.map((item) => item.count);
          // Update the chart data
          this.chartOptions.xAxis.categories = this.horizontalData;
          this.chartOptions.series[0].data = this.verticalData;

          this.allGetData.push(...response);
          this.removeDublicate(this.allGetData);
          this.copySupportRequests.push(...response);
        })
        .catch((err) => {
          this.$store.state.allLoading = false;
          this.loadMoreData = false;
        })
        .finally(() => {
          this.loadMoreData = false;
          this.$store.state.allLoading = false;
        });
    },

    handleChangeInput(row) {
      row["selected"] = !row["selected"];
      let findRow = this.selectedRows.findIndex((item) => item["id"] === row["id"]);
      if (findRow !== -1) {
        this.selectedRows.splice(findRow, 1);
      } else {
        this.selectedRows.push(row);
      }
    },
  },

  created() {
    this.$store.state.tableData = [];
    this.$store.state.back = false;
    this.$store.state.isSearch = false;
    this.$store.state.bodySearch = new FormData();
    // this.getAllRequests(1);

    this.getTableData(1); // this.searchRequest(this.page, "table");
    this.$store.state.startElement = [];
  },
};
</script>

<style>
.noData {
  display: flex;
  justify-content: center;
  font-size: 22px;
  color: #3f0e40;
  margin-top: 70px;
  font-weight: bold;
}
.support_container {
  display: flex;
  text-align: center;
  justify-content: space-between;
  margin-top: 45px;
  margin-bottom: 20px;
}
</style>
